import React from 'react';
import './Register.css';
import { Link } from "react-router-dom";
import Button from './../Button/Button';

class RegisterAr extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  componentDidMount() {
    this.focusInput.focus();

    var inputFields = Array.from(document.getElementsByTagName("input")); // Array.from is to be able to deal with the NodeList as an array
    inputFields.forEach(inputField => {
      inputField.addEventListener("keyup", function (event) {
        if (event.keyCode === 13) {
          event.preventDefault();
          document.getElementById("submitButton").click();
        }
      });
    })
  }
  render() {
    return (
      <section className="Register--dialog">
        <div className="StartLayout--container">
          <h4>تسجيل حساب جديد</h4>
          {/* <p>الوصول إلى لوحة القيادة</p> */}
          <div className="Register--form">
            <div className="Register--form-field Register--form-fieldAr">
              <label for="">اسم المستخدم <span className="Register--required">*</span></label>
              <input
                type="text"
                ref={(input) => { this.focusInput = input; }}
              />
            </div>
            <div className="Register--form-field Register--form-fieldAr">
              <label for="">البريد الإلكتروني <span className="Register--required">*</span></label>
              <input type="email" />
            </div>
            <div className="Register--form-field Register--form-fieldAr">
              <label for="">كلمة المرور <span className="Register--required">*</span></label>
              <input type="password" />
            </div>
            <div className="Register--form-field Register--form-fieldAr">
              <label for="">اسم الشركة</label>
              <input type="text" />
            </div>
            <div className="Register--form-field Register--form-fieldAr">
              <label for="">نشاط الشركة</label>
              <input type="text" />
            </div>
            <div className="Register--form-field Register--form-fieldAr">
              <label for="">حجم الشركة</label>
              <input type="text" />
            </div>
            <div className="Register--form-field Register--form-fieldAr">
              <label for="">رقم هاتف الشركة</label>
              <input type="text" />
            </div>
            <div className="Register--form-field Register--form-fieldAr">
              <label for="">البلد <span className="Register--required">*</span></label>
              <input type="text" />
            </div>
            <div className="Register--form-field Register--form-fieldAr">
              <label for="">المدينة <span className="Register--required">*</span></label>
              <input type="text" />
            </div>
            <div className="Register--form-field Register--form-fieldAr">
              <label for="">ملاحظات</label>
              <input type="text" />
            </div>
            <Button
              id="submitButton"
              className="Button square gradient"
              onClick={() => this.tryLogin()}
            >
              تسجيل حساب جديد
            </Button>
            <p>
              لديك حساب؟
              <Link to="/Ar/TAW/Start/Login">
                <strong> تسجيل دخول</strong>
              </Link>
            </p>
          </div>
        </div>
      </section>
    )
  }
}

export default RegisterAr;