/* eslint-disable eqeqeq */
import React from 'react';
import './Exceptions.css';
import Dropdown from '../Dropdown/Dropdown';
import TextInput from '../TextInput/TextInput';
import Field from '../Field/Field';

class PermissionTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: ''
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.type !== prevState.type) {
      switch (this.state.type) {
        case 2:
          this.props.onChange(this.props.backNames[2], '')
          break;
        case 3:
          this.props.onChange(this.props.backNames[1], '')
          break;
        case 4:
          this.props.onChange(this.props.backNames[1], '')
          this.props.onChange(this.props.backNames[2], '')
          break;
        default:
          break;
      }
    }
  }

  render() {
    return (
      <div style={{ border: '0px' }} className="Exceptions--">
        <Field
          // ===========
          // eslint-disable-next-line react/style-prop-object
          style="col-lg-12"
          // ===========
          title={["Permission Type", <span style={{color:"red"}}>*</span>, ":"]}
          inputComponent={[
            <Dropdown
              placeholder={`Choose or type`}
              value={this.props.items.find(item => item.value === this.props.values[0])}
              options={this.props.items}
              onChange={(value) => {
                this.setState({ type: value });
                this.props.onChange(this.props.backNames[0], value);
              }}
            />
          ]}
        />
        {
          this.state.type === 1 || this.state.type === 2 ?
            <div className='Exceptions--exception'>
              <Field
                title={["Time in", <span style={{color:"red"}}>*</span>, ":"]}
                noFormCont={true}
                style="col-lg-12"
                inputComponent={[
                  <TextInput
                  
                    placeholder={`Word or some of it`}
                    type="time"
                    value={this.props.values[1]}
                    onChange={(value) => this.props.onChange(this.props.backNames[1], value)}
                  />
                ]}
              />
            </div>
            :
            null
        }
        {
          this.state.type === 1 || this.state.type === 3 ?
            <div className='Exceptions--exception'>
              <Field
                title={["Time out", <span style={{color:"red"}}>*</span>, ":"]}
                noFormCont={true}
                style="col-lg-12"
                inputComponent={[
                  <TextInput
                
                    placeholder={`Word or some of it`}
                    type="time"
                    value={this.props.values[2]}
                    onChange={(value) => this.props.onChange(this.props.backNames[2], value)}
                  />
                ]}
              />
            </div>
            :
            null
        }
      </div>
    )
  }
}

export default PermissionTypes;
