import React from 'react';
import './ToggleSwitch.css';
import Switch from 'react-switch';

class ToggleSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.uncheckedIcon = Array.from(document.getElementsByClassName("uncheckedIcon"));
    this.onTextLength = props.onText ? props.onText.length : 0;
    this.offTextLength = props.offText ? props.offText.length : 0;
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(checked) {
    this.setState({ checked });
    if (this.props.onChange) {
      this.props.onChange(this.props.backName, checked)
    }
  }
  componentDidMount() {
    this.uncheckedIcon = Array.from(document.getElementsByClassName("uncheckedIcon"))[0];

  }
  render() {
    const {
      height,
      width,
      // uncheckedIconMarginLeft,
      // uncheckedIconWidth,
      // checkedIconWidth,

      checkedIconMarginLeft,
      uncheckedIconFontSize, offText,
      checkedIconFontSize, onText,
      value,
      className
    } = this.props;

    // const width = false;
    // this.offTextLength > this.onTextLength ? (this.offTextLength * (120 / 8)) : (this.onTextLength *( 120 / 8));
    let uncheckedIconWidth = this.offTextLength * 10;
    let checkedIconWidth = this.onTextLength * 10;
    let uncheckedIconMarginLeft = (this.offTextLength * -5.5);

    const uncheckedIconStyle = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      color: "#000",
      width: "unset",/* uncheckedIconWidth? uncheckedIconWidth + "px": "0px" */  //for long words not to be wrapped
      marginLeft: uncheckedIconMarginLeft ? uncheckedIconMarginLeft + "px" : "0px", //for long words to be closer to the handler
      fontSize: uncheckedIconFontSize ? uncheckedIconFontSize : 15
    };
    const checkedIconStyle = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      color: "#000",
      width: "unset",/* checkedIconWidth + "px" */  //for long words not to be wrapped
      marginLeft: checkedIconMarginLeft ? checkedIconMarginLeft : "0px", //for long words to be closer to the handler
      fontSize: checkedIconFontSize ? checkedIconFontSize : 15
    };

    const $height = height ? height : 28;
    let $width = width ? width : 50;
    return (
      <>
        <label className={`ToggleSwitchLabel ${className}`}>
          <Switch
            checked={value ? true : false}
            onChange={this.handleChange}
            handleDiameter={$height * 28 / 40}
            offColor={this.props.offColor ? this.props.offColor : "#fcebeb"}
            onColor={this.props.onColor ? this.props.onColor : "#ebf7fc"}
            offHandleColor={this.props.offHandleColor ? this.props.offHandleColor : "#F44336"}
            onHandleColor={this.props.onHandleColor ? this.props.onHandleColor : "#00c5fb"}
            height={$height}
            width={$width}
            uncheckedIcon={
              <div className="uncheckedIcon" style={uncheckedIconStyle}>
                {offText}
              </div>
            }
            checkedIcon={
              <div style={checkedIconStyle}>
                {onText}
              </div>
            }
          />
        </label>
      </>
    )
  }
}

export default ToggleSwitch;