import React from 'react';
import './ChooseApp.css';
import { Link, Redirect } from "react-router-dom";
import Button from './../Button/Button'
import Loading from '../Loading/Loading';
import Overlay from '../Overlay/Overlay';
import { ip } from './../../data/APIs';
import Alert from '../Alert/Alert';

class ChooseApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyName: '',
      exist: false,
      message: '',
      loading: false
    }
  }


  componentDidMount() {
    // Focus on first input
    this.focusInput.focus();
    //get all inputs in an array
    var inputFields = Array.from(document.getElementsByTagName("input")); // Array.from is to be able to deal with the NodeList as an array
    //add an event listener to all fo the inputs, if 'Enter' key is pressed then click on submitButton
    inputFields.forEach(inputField => {
      inputField.addEventListener("keyup", function (event) {
        if (event.keyCode === 13) {
          event.preventDefault();
          document.getElementById("submitButton").click();
        }
      });

    })
  }

  Check() {
    // Take a reference from 'this'
    let me = this;
    // Show loading spinner
    this.setState({ loading: true });

    // Check if company name exists
    fetch(ip + '/api/CheckCompanyName?CompanyUniqueName=' + this.state.companyName, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then((data) => {

        if (data.success) {
          if (data.data) {
            this.setState({ exist: data.data, loading: false });
          } else {
            this.setState({ message: "Company Name Doesn't Exist.", exist: data.data, loading: false });
            setTimeout(function () { me.setState({ message: '' }); }, 10000);
          }
        }

        else {
          this.setState({ message: data.message, loading: false });
        }
      })
      .catch(error => console.error('Unable to Check.', error));
  }

  render() {
    return (

      <section className="ChooseApp en">
        {/* loading compnonent showed only if this.state.loading == true */}
        <Overlay visible={this.state.loading}>
          <Loading visible={this.state.loading} />
        </Overlay>
        <div className="StartLayout--container">
          <h4>Companies</h4>
          <h6>Write your company name </h6>
          <div className="our-apps row">
            <div className="col-md-12 mb-4 mt-3">
              <div className="Login--form-field  text-left">
                {/* if there is a messege show it in an alert */}
                {
                  this.state.message ?
                    <Alert className="Alert danger">{this.state.message}</Alert>
                    :

                    null
                }
                <label className="Login--user-name" htmlFor="">Company name</label>
                <input
                  type="text"
                  value={this.state.companyName}
                  onChange={(e) => this.setState({ companyName: e.target.value })}
                  ref={(input) => { this.focusInput = input; }}
                />
              </div>
            </div>
            <div className="col-md-12">
              <Button
                id="submitButton"
                className="Button square gradient"
                onClick={() => this.Check()}
              >
                Continue
            </Button>
            </div>
          </div>
        </div>
        {this.state.exist ? <Redirect to={"/En/TAW/Start/Login/" + this.state.companyName} /> : null}
      </section>
    );
  }
}

export default ChooseApp;