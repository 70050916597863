import React from 'react';
import './Exceptions.css';

class ExceptionsAr extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activated: false
    }
  }

  timeOut;

  activateExceptions(value) {

    if (!value) { // To remove all checked values
      clearTimeout(this.timeOut);
      for (const backName of this.props.backNames) {
        this.props.onChange(backName, false)
      }
    } else {
      let me = this;
      if (this.props.values.every((value) => value === false)) {
        this.timeOut = setTimeout(() => me.setState({ activated: false }), 10000);
      }
    }
    this.setState({ activated: value });
  }

  static getDerivedStateFromProps(props, state) {

    if (props.values.some((value) => value === true)) {
      if (state.activated) {
        return null;
      } else {
        return { activated: true };
      }
    }
    return null;
  }

  render() {

    return (
      <div className="Exceptions">

        <label className="settings">
          <input
            id="checkbox"
            type="checkbox"
            checked={this.state.activated}
            onChange={(e) => this.activateExceptions(e.currentTarget.checked)}
          />
          <span>الإعدادات</span>
        </label>


        <label className={`exception${this.state.activated ? '' : ' deactivated'}`}>
          <input
            type="checkbox"
            checked={this.props.values[0]}
            onChange={(e) => {
              clearTimeout(this.timeOut);
              this.props.onChange(this.props.backNames[0], e.currentTarget.checked);
            }}
            disabled={!this.state.activated}
          />
          <span>خصم التأخير من الوقت الإضافي</span>
        </label>

        <label className={`exception${this.state.activated ? '' : ' deactivated'}`}>
          <input
            type="checkbox"
            checked={this.props.values[1]}
            onChange={(e) => {
              clearTimeout(this.timeOut);
              this.props.onChange(this.props.backNames[1], e.currentTarget.checked);
            }}
            disabled={!this.state.activated}
          />
          <span>حساب الوقت الإضافي قبل الدوام</span>
        </label>

        <label className={`exception${this.state.activated ? '' : ' deactivated'}`}>
          <input
            type="checkbox"
            checked={this.props.values[2]}
            onChange={(e) => {
              clearTimeout(this.timeOut);
              this.props.onChange(this.props.backNames[2], e.currentTarget.checked);
            }}
            disabled={!this.state.activated}
          />
          <span>حساب الوقت الإضافي بعد الدوام</span>
        </label>

        <label className={`exception${this.state.activated ? '' : ' deactivated'}`}>
          <input
            type="checkbox"
            checked={this.props.values[3]}
            onChange={(e) => {
              clearTimeout(this.timeOut);
              this.props.onChange(this.props.backNames[3], e.currentTarget.checked);
            }}
            disabled={!this.state.activated}
          />
          <span>إضافة ساعات العمل أيام الأجازات</span>
        </label>

        <label className={`exception${this.state.activated ? '' : ' deactivated'}`}>
          <input
            type="checkbox"
            checked={this.props.values[4]}
            onChange={(e) => {
              clearTimeout(this.timeOut);
              this.props.onChange(this.props.backNames[4], e.currentTarget.checked);
            }}
            disabled={!this.state.activated}
          />
          <span>تسجيل خروج بدون بصمة</span>
        </label>

      </div>
    )
  }
}

export default ExceptionsAr;
