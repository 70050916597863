import React from 'react';
import './ChooseApp.css';
import { Link, Redirect } from "react-router-dom";
import Button from './../Button/Button'
import Loading from '../Loading/Loading';
import Overlay from '../Overlay/Overlay';
import { ip } from './../../data/APIs';
import Alert from '../Alert/Alert';

class ChooseAppAr extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyName: '',
      exist: false,
      message: '',
      loading: false
    }
  }

  Check() {
    let me = this;
    this.setState({ loading: true });
    fetch(ip + '/api/CheckCompanyName?CompanyUniqueName=' + this.state.companyName, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'lang': 'ar'
      }
    })
      .then(response => response.json())
      .then((data) => {
        if (data.success) {
          if (data.data) {
            this.setState({ exist: data.data, loading: false });
          } else {
            this.setState({ message: "اسم الشركة غير موجود.", exist: data.data, loading: false });
            setTimeout(function () { me.setState({ message: '' }); }, 10000);
          }
        } else {
          this.setState({ message: data.message, loading: false });
        }
      })
      .catch(error => console.error('Unable to Check.', error));
  }
  componentDidMount() {
    this.focusInput.focus();
    var inputFields = Array.from(document.getElementsByTagName("input")); // Array.from is to be able to deal with the NodeList as an array
    inputFields.forEach(inputField => {
      inputField.addEventListener("keyup", function (event) {
        if (event.keyCode === 13) {
          event.preventDefault();
          document.getElementById("submitButton").click();
        }
      });

    })
  }

  render() {
    return (
      // <section className="ChooseApp">
      //   <div className="StartLayout--container">
      //     <h4>التطبيقات</h4>
      //     <h6>اختر التطبيق الذي تريده</h6>
      //     <div className="our-apps row">
      //       <div className="col-md-6">
      //       <Link to='/Ar/TAW/Start/Login'>
      //         <Button className="btn add square gradient">
      //           APEX TAW APP
      //         </Button>
      //       </Link>
      //       </div>
      //       <div className="col-md-6">

      //       <a href='#void'>
      //         <Button className="btn add square gradient">
      //           CRM
      //         </Button>
      //       </a>

      //       </div>

      //     </div>
      //   </div>
      // </section>
      <section className="ChooseApp en">
        <Overlay visible={this.state.loading}>
          <Loading visible={this.state.loading} />
        </Overlay>
        <div className="StartLayout--container">
          <h4>الشركات</h4>
          <h6>اكتب اسم شركتك  </h6>

          <div className="our-apps row">
            <div className="col-md-12 mb-4 mt-3">
              <div className="Login--form-field text-right">
                {
                  this.state.message ?
                    <Alert className="Alert danger">{this.state.message}</Alert>
                    :

                    null
                }
                <label className="Login--user-name" htmlFor="">اسم الشركة</label>
                <input
                  type="text"
                  value={this.state.companyName}
                  onChange={(e) => this.setState({ companyName: e.target.value })}
                  ref={(input) => { this.focusInput = input; }}
                />
              </div>
            </div>
            <div className="col-md-12">
              <Button
                id="submitButton"
                className="Button square gradient"
                onClick={() => this.Check()}
              >
                متابعة
          </Button>
            </div>
          </div>
        </div>
        {this.state.exist ? <Redirect to={"/Ar/TAW/Start/Login/" + this.state.companyName} /> : null}
      </section>
    );
  }

}

export default ChooseAppAr;