import React from 'react';
import './Tabs.css';

class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <nav className="TabsNav--">
        <span
          className={"TabsNav-- " + (this.props.selected === 1 ? 'active' : '')}
          onClick={() => this.props.onChange(1)}
        >{this.props.ar ? 'أيام السنة' : 'Year Days'}</span>
        <span
          className={"TabsNav-- " + (this.props.selected === 2 ? 'active' : '')}
          onClick={() => this.props.onChange(2)}
        >{this.props.ar ? 'أيام رمضان' : 'Ramadan Days'}</span>

        {/* DON'T FILL THE LAST SPAN ( LEAVE IT EMPTY ) */}
        <span className="TabsNav--"></span>
      </nav>
    )
  }
}

export default Tabs;