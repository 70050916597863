/* eslint-disable eqeqeq */
import React from 'react';
import './TableSpan.css';

const TableSpanAr = (props) => {
  //Days in back-end Order
  let Days = ['الإثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت', 'الأحد'];

  //Creating Table Rows Data
  let tableData = props.data.map((data) => {
    let row = [];

    //Day Name
    row.push(Days[data.dayId - 1]);

    //if not open Working time
    if (props.open !== 'true') {
      //Add the four shifts Columns (inTime & outTime)
      for (let index = 0; index < 4; index++) {
        //if there is a shift with the number 'seq' (index + 1) , then show the inTime of it, otherwise show '00:00:00'
        row.push(
          data.periodShiftTimes.find((shift) => (shift.seq - 1) === index) ?
            data.periodShiftTimes.find((shift) => (shift.seq - 1) === index).inTime
            :
            '00:00:00'
        );
        //if there is a shift with the number (index + )1 , then show the outTime of it, otherwise show '00:00:00'
        row.push(
          data.periodShiftTimes.find((shift) => (shift.seq - 1) === index) ?
            data.periodShiftTimes.find((shift) => (shift.seq - 1) === index).outTime
            :
            '00:00:00'
        );
      }
    }
    //totalDayHours
    row.push(data.totalDayHours);
    //Vacation
    row.push(data.isVacation ? 'عطلة' : 'عمل');
    return row;
  }).slice();

  //Creating Table Rows
  let tableRows = tableData.map((row) =>
    <tr key={row[0]}>
      {row.map((data, i) => <td key={i}>{data}</td>)}
      <td className="td-action">
        {
          props.noEdit ?
            null
            :
            <i
              className="fa fa-pencil"
              onClick={(e) => props.onEditClick(e)}
            >
            </i>
        }
      </td>
    </tr>
  );

  return (
    <div className="TableSpan--">
      <table>
        <thead>

          {
            //Not Open Working Time
            props.open !== 'true' ?
              <>
                <tr>
                  <th>اليوم</th>
                  <th colSpan="2">الوردية الأولى</th>
                  <th colSpan="2">الوردية الثانية</th>
                  <th colSpan="2">الوردية الثالثة</th>
                  <th colSpan="2">الوردية الرابعة</th>
                  <th>ساعات عمل اليوم</th>
                  <th>حالة اليوم</th>
                  <th className="action">العمليات</th>
                </tr>
                <tr>
                  <td></td>
                  <td><span className="badge agree">حضور</span></td>
                  <td><span className="badge alert">انصراف</span></td>
                  <td><span className="badge agree">حضور</span></td>
                  <td><span className="badge alert">انصراف</span></td>
                  <td><span className="badge agree">حضور</span></td>
                  <td><span className="badge alert">انصراف</span></td>
                  <td><span className="badge agree">حضور</span></td>
                  <td><span className="badge alert">انصراف</span></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </>
              :
              //Open Working Time
              <tr>
                <th>اليوم</th>
                <th>ساعات العمل</th>
                <th>حالة اليوم</th>
                <th className="action">العمليات</th>
              </tr>
          }
        </thead>
        <tbody>
          {tableRows}
        </tbody>
      </table>
    </div>
  )
}

export default TableSpanAr;