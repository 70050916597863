import React from 'react';
import './SideNav.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCodeBranch, faBriefcase, faProjectDiagram, faGlobe, faTasks, faHourglass
  , faUserTie, faUserFriends, faPlane, faGamepad, faUserSecret, faDownload, faTv, faPlus,
  faExchangeAlt, faPowerOff, faPencilAlt, faEdit, faSmile, faInfo, faUsers, faIdCardAlt, faUserMinus,
  faLaptop, faChartLine, faSearchPlus, faQuestionCircle, faIdBadge, faBars, faAlignRight, faAlignLeft, faHourglassEnd,
  faPuzzlePiece, faHandPaper, faListOl, faBusinessTime, faSchool, faStopCircle, faWalking, faBed, faStopwatch, faSignInAlt, faFileAlt, faUsersSlash, faMoon

} from "@fortawesome/free-solid-svg-icons";
const toggleSubMenu = (e) => {
  const toggledMenu = e.currentTarget.nextElementSibling;
  toggledMenu.classList.toggle("visible");
}

const SideNav = ({ activatePage, toggleSideNav, hideSideNav, activePage, permissions }) => {
  let mywidth = document.getElementsByTagName('body')[0].offsetWidth;
  const toggleNav = () => {
    if (mywidth < 991) return toggleSideNav();
  }

  // let mywidth=document.getElementsByTagName('body')[0].offsetWidth;
  return (
    <aside className='side-en en'>
      <div className='header--side-toggle-button'>

        <button
          id='SideToggleButton'
          onClick={toggleSideNav}
        >
          <FontAwesomeIcon id="toggle_btn" className="d-none d-md-block" icon={faAlignRight}></FontAwesomeIcon>
          <FontAwesomeIcon id="mobile_btn" className="mobile_btn  d-block d-md-none" icon={faBars}></FontAwesomeIcon>
        </button>
      </div>
      <div id='sideNav' className='SideNav-- show-side-nav'>

        {permissions ?
          <>
            {permissions.find(tab => tab.id === 1) ?
              <nav className='SideNav--main'>
                <button
                  className='SideNav--main'
                  onClick={(e) => toggleSubMenu(e)}
                >
                  <span>Basic Info</span>
                </button>
                <nav className='SideNav--sub visible'>
                  {permissions.find(tab => tab.id === 1).data.find(page => page.id === 1)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "Branches" ? "active" : null}`}
                      onClick={() => { activatePage("Branches"); toggleNav(); }}
                      to='/En/TAW/Main/Branches'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faCodeBranch} /> &nbsp;
                      <span>Branches</span>
                    </Link>
                    :
                    null
                  }
                  {permissions.find(tab => tab.id === 1).data.find(page => page.id === 2)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "Jobs" ? "active" : null}`}
                      onClick={() => { activatePage("Jobs"); toggleNav(); }}
                      to='/En/TAW/Main/Jobs'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faBriefcase} />&nbsp;
                      <span>Jobs</span>
                    </Link>

                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 1).data.find(page => page.id === 3)?.data.find(permission => permission.nameEn === 'Get') ?

                    <Link
                      className={`${activePage === "Projects" ? "active" : null}`}
                      onClick={() => { activatePage("Projects"); toggleNav(); }}
                      to='/En/TAW/Main/Projects'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faProjectDiagram} />&nbsp;
                      <span>Projects</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 1).data.find(page => page.id === 4)?.data.find(permission => permission.nameEn === 'Get') ?

                    <Link
                      className={`${activePage === "Nationalities" ? "active" : null}`}
                      onClick={() => { activatePage("Nationalities"); toggleNav(); }}
                      to='/En/TAW/Main/Nationalities'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faGlobe} />&nbsp;
                      <span>Nationalities</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 1).data.find(page => page.id === 5)?.data.find(permission => permission.nameEn === 'Get') ?

                    <Link
                      className={`${activePage === "Tasks" ? "active" : null}`}
                      onClick={() => { activatePage("Tasks"); toggleNav(); }}
                      to='/En/TAW/Main/Tasks'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faTasks} />&nbsp;
                      <span>Tasks</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 1).data.find(page => page.id === 6)?.data.find(permission => permission.nameEn === 'Get') ?

                    <Link
                      className={`${activePage === "WorkingTime" ? "active" : null}`}
                      onClick={() => { activatePage("WorkingTime"); toggleNav(); }}
                      to='/En/TAW/Main/WorkingTime'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faHourglass} />&nbsp;
                      <span>Working Time</span>
                    </Link>
                    :
                    null
                  }


                  {permissions.find(tab => tab.id === 1).data.find(page => page.id === 7)?.data.find(permission => permission.nameEn === 'Get') ?

                    <Link
                      className={`${activePage === "Employees" ? "active" : null}`}
                      onClick={() => { activatePage("Employees"); toggleNav(); }}
                      to='/En/TAW/Main/Employees'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faUserTie} />&nbsp;
                      <span>Employees</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 1).data.find(page => page.id === 8)?.data.find(permission => permission.nameEn === 'Get') ?

                    <Link
                      className={`${activePage === "EmployeesGroups" ? "active" : null}`}
                      onClick={() => { activatePage("EmployeesGroups"); toggleNav(); }}
                      to='/En/TAW/Main/EmployeesGroups'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faUserFriends} />&nbsp;
                      <span>Employees Groups</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 1).data.find(page => page.id === 9)?.data.find(permission => permission.nameEn === 'Get') ?

                    <Link
                      className={`${activePage === "VacationsTypes" ? "active" : null}`}
                      onClick={() => { activatePage("VacationsTypes"); toggleNav(); }}
                      to='/En/TAW/Main/VacationsTypes'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faPlane} />&nbsp;
                      <span>Vacations Types</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 1).data.find(page => page.id === 10)?.data.find(permission => permission.nameEn === 'Get') ?

                    <Link
                      className={`${activePage === "OfficialHolidays" ? "active" : null}`}
                      onClick={() => { activatePage("OfficialHolidays"); toggleNav(); }}
                      to='/En/TAW/Main/OfficialHolidays'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faGamepad} />&nbsp;
                      <span>Official Holidays</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 1).data.find(page => page.id === 11)?.data.find(permission => permission.nameEn === 'Get') ?

                    <Link
                      className={`${activePage === "UnregisteredEmployees" ? "active" : null}`}
                      onClick={() => { activatePage("UnregisteredEmployees"); toggleNav(); }}
                      to='/En/TAW/Main/UnregisteredEmployees'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faUserSecret} />&nbsp;
                      <span>Unregistered Employees</span>
                    </Link>
                    :
                    null
                  }
                </nav>
              </nav>
              :
              null
            }

            {permissions.find(tab => tab.id === 2) ?
              <nav className='SideNav--main'>
                <button
                  className='SideNav--main'
                  onClick={(e) => toggleSubMenu(e)}
                >
                  <span>Procedure</span>
                </button>
                <nav className='SideNav--sub visible'>
                  {permissions.find(tab => tab.id === 2).data.find(page => page.id === 12)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "GetBranchTransactions" ? "active" : null}`}
                      onClick={() => { activatePage("GetBranchTransactions"); toggleNav(); }}
                      to='/En/TAW/Main/GetBranchTransactions'
                      style={{ fontSize: '14px' }}
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faDownload} />&nbsp;
                      <span>Get Branches Trans. (FTP)</span>
                    </Link>
                    :
                    null
                  }


                  {/* <Link
              className={`${activePage === "cancel" ? "active" : null}`}
              onClick={() => {activatePage("cancel")}
              to='/En/TAW/Main/cancel'
            >
              <FontAwesomeIcon className="sideNav-icon" icon={faBriefcase}/>&nbsp;
              <span>Cancel (FTP)</span>
            </Link> */}
                  {permissions.find(tab => tab.id === 2).data.find(page => page.id === 14)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "DisplayTransactions" ? "active" : null}`}
                      onClick={() => { activatePage("DisplayTransactions"); toggleNav(); }}
                      to='/En/TAW/Main/DisplayTransactions'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faTv} />&nbsp;
                      <span>Display Trans.</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 2).data.find(page => page.id === 17)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "AddPermission" ? "active" : null}`}
                      onClick={() => { activatePage("AddPermission"); toggleNav(); }}
                      to='/En/TAW/Main/AddPermission'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faPlus} />&nbsp;
                      <span>Add Permission</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 2).data.find(page => page.id === 18)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "AddVacation" ? "active" : null}`}
                      onClick={() => { activatePage("AddVacation"); toggleNav(); }}
                      to='/En/TAW/Main/AddVacation'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faEdit} />&nbsp;
                      <span>Add Vacation</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 2).data.find(page => page.id === 13)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "TransferTransactions" ? "active" : null}`}
                      onClick={() => { activatePage("TransferTransactions"); toggleNav(); }}
                      to='/En/TAW/Main/TransferTransactions'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faExchangeAlt} />&nbsp;
                      <span>Transfer Trans.</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 2).data.find(page => page.id === 15)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "CancelTransferTransactions" ? "active" : null}`}
                      onClick={() => { activatePage("CancelTransferTransactions"); toggleNav(); }}
                      to='/En/TAW/Main/CancelTransferTransactions'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faPowerOff} />&nbsp;
                      <span>Cancel Transfer Trans.</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 2).data.find(page => page.id === 16)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "AddEditTransactions" ? "active" : null}`}
                      onClick={() => { activatePage("AddEditTransactions"); toggleNav(); }}
                      to='/En/TAW/Main/AddEditTransactions'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faPencilAlt} />&nbsp;
                      <span>Add & Edit Trans.</span>
                    </Link>
                    :
                    null
                  }

                  {/* <Link
              className={`${activePage === "CancelManuallyTrans" ? "active" : null}`}
              onClick={() => {activatePage("CancelManuallyTrans")}
              to='/En/TAW/Main/CancelManuallyTrans'
            >
              <FontAwesomeIcon className="sideNav-icon" icon={faBriefcase}/>&nbsp;
              <span>Cancel Manually Trans.</span>
            </Link> */}

                  {/* <Link
              className={`${activePage === "DeleteEmployeesData" ? "active" : null}`}
              onClick={() => {activatePage("DeleteEmployeesData")}
              to='/En/TAW/Main/DeleteEmployeesData'
            >
              <FontAwesomeIcon className="sideNav-icon" icon={faBriefcase}/>&nbsp;
              <span>Delete Employees Data</span>
            </Link> */}
                </nav>
              </nav>
              :
              null
            }

            {permissions.find(tab => tab.id === 3) ?
              <nav className='SideNav--main'>
                <button
                  className='SideNav--main'
                  onClick={(e) => toggleSubMenu(e)}
                >
                  <span>Reports</span>
                </button>
                <nav className='SideNav--sub visible'>
                  {permissions.find(tab => tab.id === 3).data.find(page => page.id === 19)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "EmployeesReport" ? "active" : null}`}
                      onClick={() => { activatePage("EmployeesReport"); toggleNav(); }}
                      to='/En/TAW/Main/EmployeesReport'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faIdBadge} />&nbsp;
                      <span>Employees</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 3).data.find(page => page.id === 20)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "WorkingTimeReport" ? "active" : null}`}
                      onClick={() => { activatePage("WorkingTimeReport"); toggleNav(); }}
                      to='/En/TAW/Main/WorkingTimeReport'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faIdBadge} />&nbsp;
                      <span>Working Time</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 3).data.find(page => page.id === 21)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "DayStatusReport" ? "active" : null}`}
                      onClick={() => { activatePage("DayStatusReport"); toggleNav(); }}
                      to='/En/TAW/Main/DayStatusReport'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faQuestionCircle} />&nbsp;
                      <span>Day Status</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 3).data.find(page => page.id === 22)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "DetailedAttendanceReport" ? "active" : null}`}
                      onClick={() => { activatePage("DetailedAttendanceReport"); toggleNav(); }}
                      to='/En/TAW/Main/DetailedAttendanceReport'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faSearchPlus} />&nbsp;
                      <span>Detailed Attendance</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 3).data.find(page => page.id === 23)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "TotalAttendanceReport" ? "active" : null}`}
                      onClick={() => { activatePage("TotalAttendanceReport"); toggleNav(); }}
                      to='/En/TAW/Main/TotalAttendanceReport'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faChartLine} />&nbsp;
                      <span>Total Attendance</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 3).data.find(page => page.id === 24)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "AbsenceReport" ? "active" : null}`}
                      onClick={() => { activatePage("AbsenceReport"); toggleNav(); }}
                      to='/En/TAW/Main/AbsenceReport'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faUserMinus} />&nbsp;
                      <span>Absence</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 3).data.find(page => page.id === 25)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "DelayReport" ? "active" : null}`}
                      onClick={() => { activatePage("DelayReport"); toggleNav(); }}
                      to='/En/TAW/Main/DelayReport'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faHourglassEnd} />&nbsp;
                      <span>Delay</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 3).data.find(page => page.id === 26)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "InCompleteTransReport" ? "active" : null}`}
                      onClick={() => { activatePage("InCompleteTransReport"); toggleNav(); }}
                      to='/En/TAW/Main/InCompleteTransReport'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faPuzzlePiece} />&nbsp;
                      <span>Incompleted Trans.</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 3).data.find(page => page.id === 27)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "EarlyAttendanceReport" ? "active" : null}`}
                      onClick={() => { activatePage("EarlyAttendanceReport"); toggleNav(); }}
                      to='/En/TAW/Main/EarlyAttendanceReport'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faHandPaper} />&nbsp;
                      <span>Early Attendance</span>
                    </Link>
                    :
                    null
                  }
                  {permissions.find(tab => tab.id === 3).data.find(page => page.id === 28)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "AttendanceSummaryReport" ? "active" : null}`}
                      onClick={() => { activatePage("AttendanceSummaryReport"); toggleNav(); }}
                      to='/En/TAW/Main/AttendanceSummaryReport'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faSignInAlt} />&nbsp;
                      <span>Attendance Report Summary</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 3).data.find(page => page.id === 29)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "TotalAttendanceSummaryReport" ? "active" : null}`}
                      onClick={() => { activatePage("TotalAttendanceSummaryReport"); toggleNav(); }}
                      to='/En/TAW/Main/TotalAttendanceSummaryReport'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faListOl} />&nbsp;
                      <span> Total Attendance Summary for Branches</span>
                    </Link>
                    :
                    null
                  }


                  {permissions.find(tab => tab.id === 3).data.find(page => page.id === 30)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "OvertimeReport" ? "active" : null}`}
                      onClick={() => { activatePage("OvertimeReport"); toggleNav(); }}
                      to='/En/TAW/Main/OvertimeReport'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faBusinessTime} />&nbsp;
                      <span>Overtime</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 3).data.find(page => page.id === 31)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "WorkingTimeDetailsReport" ? "active" : null}`}
                      onClick={() => { activatePage("WorkingTimeDetailsReport"); toggleNav(); }}
                      to='/En/TAW/Main/WorkingTimeDetailsReport'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faFileAlt} />&nbsp;
                      <span>Working Time Details</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 3).data.find(page => page.id === 32)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "BranchAttendanceReport" ? "active" : null}`}
                      onClick={() => { activatePage("BranchAttendanceReport"); toggleNav(); }}
                      to='/En/TAW/Main/BranchAttendanceReport'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faSchool} />&nbsp;
                      <span>Branch Attendance</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 3).data.find(page => page.id === 33)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "VacationsReport" ? "active" : null}`}
                      onClick={() => { activatePage("VacationsReport"); toggleNav(); }}
                      to='/En/TAW/Main/VacationsReport'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faStopCircle} />&nbsp;
                      <span>Vacations</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 3).data.find(page => page.id === 34)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "PermissionsReport" ? "active" : null}`}
                      onClick={() => { activatePage("PermissionsReport"); toggleNav(); }}
                      to='/En/TAW/Main/PermissionsReport'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faWalking} />&nbsp;
                      <span>Permissions</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 3).data.find(page => page.id === 35)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "DelayEarlyReport" ? "active" : null}`}
                      onClick={() => { activatePage("DelayEarlyReport"); toggleNav(); }}
                      to='/En/TAW/Main/DelayEarlyReport'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faBed} />&nbsp;
                      <span>Delay and Early Leave</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 3).data.find(page => page.id === 36)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "TotalAbsenceReport" ? "active" : null}`}
                      onClick={() => { activatePage("TotalAbsenceReport"); toggleNav(); }}
                      to='/En/TAW/Main/TotalAbsenceReport'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faUsersSlash} />&nbsp;
                      <span>Total Absence</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 3).data.find(page => page.id === 37)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "TotalDelayReport" ? "active" : null}`}
                      onClick={() => { activatePage("TotalDelayReport"); toggleNav(); }}
                      to='/En/TAW/Main/TotalDelayReport'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faStopwatch} />&nbsp;
                      <span>Total Delay</span>
                    </Link>
                    :
                    null
                  }
                </nav>
              </nav>
              :
              null
            }

            {permissions.find(tab => tab.id === 4) ?
              <nav className='SideNav--main'>
                <button
                  className='SideNav--main'
                  onClick={(e) => toggleSubMenu(e)}
                >
                  <span>Users</span>
                </button>
                <nav className='SideNav--sub visible'>
                  {permissions.find(tab => tab.id === 4).data.find(page => page.id === 38)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "Devices" ? "active" : null}`}
                      onClick={() => { activatePage("Devices"); toggleNav(); }}
                      to='/En/TAW/Main/Devices'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faLaptop} />&nbsp;
                      <span>Devices</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 4).data.find(page => page.id === 39)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "SpecialWorkingDays" ? "active" : null}`}
                      onClick={() => { activatePage("SpecialWorkingDays"); toggleNav(); }}
                      to='/En/TAW/Main/SpecialWorkingDays'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faMoon} />&nbsp;
                      <span>Ramadan Working Times</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 4).data.find(page => page.id === 40)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "UserPermission" ? "active" : null}`}
                      onClick={() => { activatePage("UserPermission"); toggleNav(); }}
                      to='/En/TAW/Main/UserPermission'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faIdCardAlt} />&nbsp;
                      <span>User Permissions</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 4).data.find(page => page.id === 43)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "UsersAccounts" ? "active" : null}`}
                      onClick={() => { activatePage("UsersAccounts"); toggleNav(); }}
                      to='/En/TAW/Main/UsersAccounts'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faUsers} />&nbsp;
                      <span>Users Accounts</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 4).data.find(page => page.id === 42)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`${activePage === "CompanyInfo" ? "active" : null}`}
                      onClick={() => { activatePage("CompanyInfo"); toggleNav(); }}
                      to='/En/TAW/Main/CompanyInfo'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faInfo} />&nbsp;
                      <span>Company Info.</span>
                    </Link>
                    :
                    null
                  }

                  {permissions.find(tab => tab.id === 4).data.find(page => page.id === 41)?.data.find(permission => permission.nameEn === 'Get') ?
                    <Link
                      className={`pb-40 ${activePage === "GeneralSettings" ? "active" : null}`}
                      onClick={() => { activatePage("GeneralSettings"); toggleNav(); }}
                      to='/En/TAW/Main/GeneralSettings'
                    >
                      <FontAwesomeIcon className="sideNav-icon" icon={faSmile} />&nbsp;
                      <span>General Settings</span>
                    </Link>
                    :
                    null
                  }

                </nav>
              </nav>
              :
              null
            }
          </>
          :
          null}
      </div>
      <div className="overlay" onClick={hideSideNav}></div>
    </aside>
  )
}
export default SideNav;