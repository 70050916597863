import React from 'react';
import './Field.css';

// We declared all component props by destructuring the props object
// Destructuring is a ES6 feature written in this way:
// const { property } = object;.

// Each prop type will be mentioned beside it.

// if prop describes a tagName , then prop will take the same naming as following:
// className={className} ==> tagName={prop}.

const Field = ({
  title,
  inputComponent,
  className,
  long,
  style,
  titleSize,
  dropdownAlign,
  noFormCont
}) => {
  return (
    <div className={`form-group col-md-12 col-lg-3 ${titleSize ? "row" : ""} ${style ? " " + style : ""}${className ? " " + className : ""}`}>
      {
        title ?
          titleSize ?
            <div className={titleSize + " mb-0"}  >
              <label className={` title d-flex ${long ? " long" : ""}`}>{title}</label>
            </div>
            :
            <label className={` title d-flex ${long ? " long" : ""}`}>{title}</label>
          :
          null
      }



      <div className={title ? ` ${dropdownAlign ? dropdownAlign : " "}${noFormCont == true ? " " : "form-control"} ${titleSize ? "col h-100 mt-2 py-0" : ""}` : 'col-sm-12 p-0'} style={{ whiteSpace: 'nowrap' }}>
        {
          inputComponent
        }
      </div>
    </div>

  )
}
{/* <label className={`Field${style ? " " + style : ""}${className ? " " + className : ""}`}>
      {
        title ? 
          <p className={`title${long ? " long" : ""}`}>{title}</p>
        :
          null
      }
      {
        inputComponent
      }
    </label> */}
export default Field;