/* eslint-disable eqeqeq */
import React from 'react';
import './BasicPage.css';
import { Link } from 'react-router-dom';
import Table from './../Table/Table';
import Cookie from "js-cookie";
import ModalAr from './../ModalAr/ModalAr';
import PageHeaderAr from '../PageHeaderAr/PageHeaderAr';
import FiltersAr, { hideFilters, showFilters } from '../FiltersAr/FiltersAr';
import TableFooterAr from '../TableFooterAr/TableFooterAr';
import Loading from '../Loading/Loading';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import ExceptionsAr from '../ExceptionsAr/ExceptionsAr';
import Overlay from '../Overlay/Overlay';
import ShiftEndNextDay from '../ShiftEndNextDay/ShiftEndNextDay';
import TextInput from '../TextInput/TextInput';
import Dropdown from '../Dropdown/Dropdown';
import SelectShiftAr from '../SelectShiftAr/SelectShiftAr';
import PermissionTypesAr from '../PermissionTypesAr/PermissionTypesAr';
import Field from '../Field/Field';
import Alert from './../Alert/Alert';

class BasicPageAr extends React.Component {
  constructor(props) {
    super(props);

    //Create empty readEntity (for the body of the read request)
    let tempReadEntity = props.read.body;
    if (props.read.body && props.searchFields) {
      for (let field of props.searchFields) {
        if (field.backSearch) {
          tempReadEntity.search[field.backName] = '';
        }
      }
    }

    this.state = {
      createEntity: props.create.body,
      readEntity: props.read.body ? props.read.body : (props.read.path ? props.read.path : props.read.query),
      updateEntity: props.update.body,
      deleteEntity: props.delete.query ? props.delete.query : props.delete.body,
      createErrors: [],
      updateErrors: [],

      //for pagination
      rowsNumber: 10,
      rowsTotal: 0,
      currentPage: 1,

      //For alert
      message: { text: '', style: '' },

      loading: false,
      //for modals show
      add: false,
      edit: false,
      delete: false,
      search: false,
      //fetched data from back-end
      data: [],
      //for Search Modal
      modalUpdated: false,
      'نوع الجهاز': [
        {
          "id": 1,
          "nameEn": "ZKTeco",
          "nameAr": "ZKTeco"
        },
        {
          "id": 2,
          "nameEn": "Timmy TM-F630",
          "nameAr": "Timmy TM-F630"
        }
      ]
    }
    // Get the Search Fields 'select elements' items from the Database when the page displayed for the first time
    if (props.searchFields) { // to prevent undefined error
      for (let field of props.searchFields) {
        if (field.type === 'select' || field.type == 'multiSelect') {

          this.GetSelect(field.url, field.body, field.label);
        }

      }
    }

    // Get the Create & Update 'select elements' items from the Database when the page displayed for the first time
    for (let prop of props.createEntityProps) {
      // if the items already exist in the Search Field Don't Get it from the Database again
      if (prop.type === 'select' && props.searchFields.find(field => field.label === prop.label) === undefined) {
        this.GetSelect(prop.url, prop.body, prop.label);
        // selectShift Components don't have a Label
      } else if (prop.type === 'selectShift') {
        this.GetSelect(prop.url, prop.body, prop.label);
      }
    }
  }

  // Styling:
  // ========
  // set the model name true to display the wanted modal 
  showModal(modalName) {
    const mainLayout = document.querySelector("#mainLayout");
    this.setState({ [modalName]: true });
    mainLayout.style.overflow = "visible";
  }

  hideModal(modalName) {
    const mainLayout = document.querySelector("#mainLayout");
    this.setState({ [modalName]: false });

    // return the Modal elements to the default State
    // loop on createEntityProps to reset them properly according to their types
    if (modalName === 'add') {
      const entity = this.state.createEntity;
      for (let field of this.props.createEntityProps) {
        if (field.type === 'text' || field.type === 'email' || field.type === 'select' || field.type === 'date' || field.type === 'time' || field.type === 'password') {
          entity[field.backName] = '';
        }
        if (field.type === 'checkbox') {
          //for employee active checkbox
          if (field.label === 'نشط') {
            entity[field.backName] = true;
          } else {
            entity[field.backName] = false;
          }
        }
        //in Working time page
        if (field.type === 'shiftEndNextDay') {
          //Checkbox
          entity[field.backName[0]] = false;
          //input
          entity[field.backName[1]] = '';
        }
        //In Employees page
        if (field.type === 'exceptions') {
          let backNames = [];
          for (const backName of field.backName) {
            backNames.push(backName);
          }
          for (const backName of backNames) {
            entity[backName] = false;
          }
        }
        //Add permission page
        if (field.type === 'selectShift') {
          let backNames = [];
          for (const backName of field.backName) {
            backNames.push(backName);
          }
          for (const backName of backNames) {
            if (typeof backName != 'object') {
              entity[backName] = '';
            }
          }
        }
        //Add permission page
        if (field.type === 'permissionType') {
          let backNames = [];
          for (const backName of field.backName) {
            backNames.push(backName);
          }
          for (const backName of backNames) {
            entity[backName] = '';
          }
        }
      }
      this.setState({ createEntity: entity, createErrors: [] })
    } else if (modalName === 'search') {
      //For Search Modal
      const entity = this.state.createEntity;
      //set the createEntity in which the modal controls to an empty array
      entity[this.props.modalBackName] = [];
      this.setState({ createEntity: entity, createErrors: [] })
    } else if (modalName === 'edit') {
      const entity = this.state.updateEntity;
      for (let field of this.props.createEntityProps) {
        if (field.type === 'text' || field.type === 'email' || field.type === 'select' || field.type === 'date' || field.type === 'time' || field.type === 'password') {
          entity[field.backName] = '';
        }
        if (field.type === 'checkbox') {
          entity[field.backName] = true;
        }
        if (field.type === 'shiftEndNextDay') {
          entity[field.backName[0]] = false;
          entity[field.backName[1]] = '';
        }
      }
      this.setState({ updateEntity: entity, updateErrors: [] })
    }
  }
  // ========

  componentDidMount() {
    //if permissions loaded
    if (this.props.permissions !== null) {
      //Clear search fields
      this.Clear();
      //Get data to show from back-end
    } else {
      //if not loaded, wait for a second and try again
      let me = this;
      setTimeout(function () {
        me.componentDidMount();
      }, 1000);
    }

  }

  // A method for changing the entity properties,
  // inputs:
  // state = Name of the entity, e.g: createEntity, updateEntity ... 
  // name = Name of the property, e.g: createEntity.nameAr, updateEntity.nameEn ... 
  // value = value of the property, e.g: createEntity.nameAr = 'أحمد', updateEntity.nameEn = 'Ahmad'... 
  ChangeEntityState(state, name, value) {
    let tempEntity = this.state[state];
    tempEntity[name] = value;
    this.setState({ [state]: tempEntity });
  }

  // A method for changing the state,
  // inputs:
  // key = Name of the state
  // value = value of the state
  ChangeState(key, value) {
    this.setState({ [key]: value });
  }


  // A method for Validating the user inputs before Creating or Updating,
  // inputs:
  // entity = the entity that will be sent to the Server for Creating or Updating, e.g: current createEntity.
  // output:
  // Array of errors if exist, otherwise an empty Array
  Validation(entity) {
    let errors = [];
    for (let prop of this.props.createEntityProps) {
      if (prop.validation) {
        if (prop.validation.includes('Required')) {
          if (!entity[prop.backName]) {
            errors = errors.concat(prop.label + ' حقل إجباري.');
          }
        }
        //Depends on property of RequiredCondition
        if (prop.validation.includes('ConditionalReq')) {
          if (entity[prop.RequiredCondition] && !entity[prop.backName]) {
            errors = errors.concat(prop.label + ' حقل إجباري.');
          }
        }
        //Depends on property of RequiredCondition
        if (prop.validation.includes('orReq')) {
          if (!entity[prop.RequiredCondition] && !entity[prop.backName]) {
            errors = errors.concat(prop.label + ' حقل إجباري.');
          }
        }
        if (prop.validation.includes('from')) {
          let toProp = this.props.createEntityProps.find(prop => prop.validation.includes('to'));
          if ((Date.parse(entity[prop.backName]) > Date.parse(entity[toProp.backName]))) {
            errors = errors.concat(prop.label + ' يجب أن يكون أقل من أو يساوي "' + toProp.label + '".');
          }
        }
        if (prop.validation.includes('to')) {
          let fromProp = this.props.createEntityProps.find(prop => prop.validation.includes('from'));

          if (Date.parse(entity[prop.backName]) < Date.parse(entity[fromProp.backName])) {
            errors = errors.concat(prop.label + ' يجب أن يكون أكبر من أو يساوي "' + fromProp.label + '".');
          }
        }
      }

      if (prop.type === 'email') {
        let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (entity[prop.backName] && !entity[prop.backName].match(mailformat)) {
          errors = errors.concat(prop.label + ' يجب أن يكون بريد إلكتروني صحيح.');
        }
      }
      if (prop.type === 'shiftEndNextDay') {
        //if checkbox checked
        if (entity[prop.backName[0]]) {
          if (!entity[prop.backName[1]]) {
            errors = errors.concat(prop.label[1] + ' حقل إجباري.');
          }
        }
      }
      if (prop.type === 'selectShift') {
        //Employee select is required
        if (!entity[prop.backName[0]]) {
          errors = errors.concat('الموظف حقل إجباري.');
        }
        //Date select is required
        if (!entity[prop.backName[1]]) {
          errors = errors.concat('التاريخ حقل إجباري.');
        }
        //prop.backName[2]
        //shift select is required
        if (!entity.periodShiftId && !entity.periodShiftFourthId
          && !entity.periodShiftSecondId && !entity.periodShiftThirdId) {

          errors = errors.concat('اختيار الوردية إجباري.');
        }
        //if open working time there are no shifts to select
        if (entity[prop.backName[2]] === 'open') {
          entity[prop.backName[2]] = '';
        }
      }
      if (prop.type === 'permissionType') {
        //permission type select is required
        if (!entity[prop.backName[0]]) {
          errors = errors.concat('نوع الإذن حقل إجباري.');
        } else {
          let timeIn;
          let timeOut;
          let shiftIn;
          let shiftOut;
          let time;
          //permission type
          switch (entity[prop.backName[0]]) {
            //if temporary
            case 1:
              if (!entity[prop.backName[1]]) {
                errors = errors.concat('وقت الحضور حقل إجباري.');
              } else {
                if (entity.periodShiftId) {
                  //split the time entered by the user to get hours and minuts in an array
                  time = entity[prop.backName[1]].split(':');
                  //Create a date object of the entered time
                  timeIn = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  //split the inTime of the shift to get hours and minuts in an array
                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).inTime.split(':');
                  //Create a date object of inTime of the shift
                  shiftIn = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));
                  //split the outTime of the shift to get hours and minuts in an array
                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).outTime.split(':');
                  //Create a date object of outTime of the shift
                  shiftOut = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  //check if entered time is between shift inTime and outTime
                  if (!(timeIn >= shiftIn && timeIn <= shiftOut)) {
                    errors = errors.concat('يجب أن يكون وقت حضور الإذن داخل وقت الوردية المحددة.');
                  }
                }
              }
              if (!entity[prop.backName[2]]) {
                errors = errors.concat('وقت الانصراف حقل إجباري.');
              } else {
                if (entity.periodShiftId) {
                  time = entity[prop.backName[2]].split(':');
                  timeOut = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).inTime.split(':');
                  shiftIn = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).outTime.split(':');
                  shiftOut = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  if (!(timeOut >= shiftIn && timeOut <= shiftOut)) {
                    errors = errors.concat('يجب أن يكون وقت انصراف الإذن داخل وقت الوردية المحددة.');
                  }
                }
              }

              break;
            case 2:
              if (!entity[prop.backName[1]]) {
                errors = errors.concat('وقت الحضور حقل إجباري.');
              } else {
                if (entity.periodShiftId) {
                  time = entity[prop.backName[1]].split(':');
                  timeIn = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).inTime.split(':');
                  shiftIn = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).outTime.split(':');
                  shiftOut = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  if (!(timeIn >= shiftIn && timeIn <= shiftOut)) {
                    errors = errors.concat('يجب أن يكون وقت حضور الإذن داخل وقت الوردية المحددة.');
                  }
                }
              }
              break;
            case 3:
              if (!entity[prop.backName[2]]) {
                errors = errors.concat('وقت الانصراف حقل إجباري.');
              } else {
                if (entity.periodShiftId) {
                  time = entity[prop.backName[2]].split(':');
                  timeOut = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).inTime.split(':');
                  shiftIn = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).outTime.split(':');
                  shiftOut = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  if (!(timeOut >= shiftIn && timeOut <= shiftOut)) {
                    errors = errors.concat('يجب أن يكون وقت انصراف الإذن داخل وقت الوردية المحددة.');
                  }
                }
              }
              break;
            case 4:
              errors = errors.filter((error) => error !== 'اختيار الوردية إجباري.');
              break;
            default:
              break;
          }
        }
      }
    }
    return errors;
  }

  // A method for making a fetch Request,
  // inputs:
  // entity = the entity that will be sent to the Server for Creating, Reading, Updating, or Deleting, e.g: current deleteEntity...
  // token = the JWT Barear Authorization token.
  // operation = the operation that will be done against the entity, e.g: create, read, update, or delete
  // then = the method that will be executed when we receive the response
  Fetch(entity, token, operation, then) {
    //Creating the request url 
    let url = this.props[operation].path ?
      //if path, put the entity properties in the url separated by '/' e.g: /api/Shift/GetShiftDetails/{shiftId}/{shiftType}
      this.props[operation].url + '/' + Object.keys(entity).map((key) => entity[key]).join('/')
      :
      //if query, put the entity properties in the url in a query string format e.g: /api/Shift/GetShiftDetails?shiftId=1&shiftType=2
      this.props[operation].query ?
        this.props[operation].url + '?' + Object.keys(entity).map((key) => {
          if (typeof entity[key] === 'object') {
            return Object.keys(entity[key]).map((inkey) => key + '.' + inkey + '=' + entity[key][inkey]).join('&')
          }
          return key + '=' + entity[key];
        }).join('&')
        :
        this.props[operation].url;

    //if the url ends with '&'
    if (url.endsWith('&')) {
      url = url.substring(0, url.length - 1);
    }
    let end;
    let start = Date.now();
    console.log('Start: ' + start);
    fetch(url, {
      //Post or get
      method: this.props[operation].verb,
      headers: {
        'Accept': 'application/json',
        'Content-Type': this.props[operation].verb === 'POST' ? 'text/json' : 'text/plain',
        'Authorization': `Bearer ${token}`,
        'lang': 'ar'
      },
      body: this.props[operation].body ? JSON.stringify(entity) : null
    })
      .then(response => response.json())
      .then((response) => {
        end = Date.now();
        console.log('End: ' + end);
        console.log('Time: ' + (end - start));
        let me = this;
        then(response, me);
      })
      .catch(error => console.error('Unable to ' + operation + '.', error));
  }

  // A method for Creating a new entity in the Database,
  // inputs:
  // entity = the entity that will be sent to the Server for Creating (current createEntity)
  Create(entity) {
    // Trimming the 'text' user inputs '   ahmed   ' => 'ahmed'
    for (let prop of this.props.createEntityProps) {
      if (prop.type === 'text') {
        entity[prop.backName] = entity[prop.backName] ? entity[prop.backName].toString().trim() : '';
      }
    }

    let errors = this.Validation(entity);

    this.setState({ createErrors: errors });

    //if there is any validation error don't proceed
    if (errors.length === 0) {
      this.setState({ loading: true });
      let token = Cookie.get('token');
      // After the fetch ened do 'then' function
      let then = (response, me) => {
        // if the response isokay 
        if (response.status === 200 || response.success) {
          if (response.success) {
            if (me.props.useSearchModal) {
              // Do reset to the modal and close 
              me.hideModal('search');
            }
            me.hideModal('add');

            me.setState({ message: { text: response.message, style: 'success' }, loading: false });
            setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
            me.Read(me.state.readEntity);
          } else {
            if (me.props.useSearchModal) {
              me.hideModal('search');
            }
            me.hideModal('add');
            me.setState({ message: { text: response.message, style: 'danger' }, loading: false });
            setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
          }
        } else {
          if (response.message) {
            errors.push(response.message);
            me.setState({ createErrors: errors, loading: false });
          }
            if(me.props.addType){
          me.hideModal('search');
          me.setState({ message: { text: response.message, style: 'success' }, loading: false });
          setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
          me.Read(me.state.readEntity);
        }
        else {
          console.error(response);

        }
        }
      }
      this.Fetch(entity, token, 'create', then);
    }
  }

  // A method for Reading data from Database,
  // inputs:
  // entity = the entity that will be sent to the Server in the reaquest body (readEntity)
  Read(entity) {
    // cheach on pemition if allow read
    if (this.props.permissions?.data.find(permission => permission.nameEn === 'Get') || this.props.allPermissions) {
      this.setState({ loading: true });
      let token = Cookie.get('token');
      console.log(token);
      // the then function 
      let then = (response, me) => {
        if (response.status === 200 || response.success) {
          if (response.success) {
            // if the repponce data return as data of data then 

            if (response.data.data) {
              me.setState(
                {
                  data: response.data.data.map((row) => {
                    for (const key in me.props.params) {
                      // add the parameter data for all incoming data row 
                      row[key] = me.props.params[key];
                    }
                    return row;
                  }),
                  rowsTotal: response.data.listCount,
                  loading: false,
                  modalUpdated: false
                }
              );
            } else {
              me.setState(
                {
                  data: response.data.map((row) => {
                    for (const key in me.props.params) {
                      row[key] = me.props.params[key];
                    }
                    return row;
                  }),
                  rowsTotal: response.data.listCount,
                  loading: false,
                  modalUpdated: false
                }
              );
            }
          } else {
            //if not success 
            me.setState({ message: { text: response.message, style: 'danger' }, loading: false });
            setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
          }
        } else {
          //if not status == 200
          if (response.message) {
            me.setState({ message: { text: response.message, style: 'danger' }, loading: false });
            setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
          }
          console.error(response);
        }
      }
      this.Fetch(entity, token, 'read', then);
    }


  }

  // A method for Updating an existing entity in the Database,
  // inputs:
  // entity = the entity that will be sent to the Server for Updating (current updateEntity)
  Update(entity) {
    // Trimming the 'text' user inputs '   ahmed   ' => 'ahmed'
    for (let prop of this.props.createEntityProps) {
      if (prop.type === 'text') {
        entity[prop.backName] = entity[prop.backName] ? entity[prop.backName].toString().trim() : '';
      }
    }

    let errors = this.Validation(entity);

    this.setState({ updateErrors: errors });

    // if there is any validation error don't proceed
    if (errors.length === 0) {
      this.setState({ loading: true });
      let token = Cookie.get('token');
      let then = (response, me) => {
        if (response.status === 200 || response.success) {
          if (response.success) {
            me.hideModal('edit');
            me.setState({ message: { text: response.message, style: 'success' }, loading: false });
            setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
            me.Read(me.state.readEntity);
          } else {
            me.hideModal('edit');
            me.setState({ message: { text: response.message, style: 'danger' }, loading: false });
            setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
          }
        } else {
          if (response.message) {
            errors.push(response.message);
            me.setState({ updateErrors: errors, loading: false });
          }
            if(me.props.addType){
              me.hideModal('edit');
              me.setState({ message: { text: response.message, style: 'success' }, loading: false });
              setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
              me.Read(me.state.readEntity);
        }
        else {
          console.error(response);

        }
        }
      }
      this.Fetch(entity, token, 'update', then);
    }
  }

  // A method for Deleting an existing entity in the Database,
  // inputs:
  // entity = the entity that will be sent to the Server for Delete (current deleteEntity)
  Delete(entity) {
    this.setState({ loading: true });
    let token = Cookie.get('token');
    let then = (response, me) => {
      if (response.status === 200 || response.success) {
        if (response.success) {
          me.hideModal('delete');
          me.setState({ message: { text: response.message, style: 'success' }, loading: false });
          setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
          me.Read(me.state.readEntity);
        } else {
          me.hideModal('delete');
          me.setState({ message: { text: response.message, style: 'danger' }, loading: false });
          setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
        }
      } else {
        if (response.message && !me.props.addType) {
          me.hideModal('delete');
          me.setState({ message: { text: response.message, style: 'danger' }, loading: false });
          setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
        }
        if(me.props.addType){
          me.hideModal('delete');
          me.setState({ message: { text: response.message, style: 'success' }, loading: false });
          setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
          me.Read(me.state.readEntity);
        }
        else {
          console.error(response);

        }
      }
    }
    this.Fetch(entity, token, 'delete', then);
  }

  // A method for getting the data of the selected row and putting it in the updateEntity,
  // inputs:
  // id = Selected row ID
  GetForUpdate(id) {
    // اhena ana b7ot el updateEntity 'body el fade' fe 'temp' w b 'search' fe el data 3ashan ageb el obj. 
    // w b3d kda a7otho fe el update entity tany
    let tempEntity = this.state.updateEntity;
    for (let prop in tempEntity) {
      //if an item found with this 'id'
      tempEntity[prop] = this.state.data.find(item => item.id === id) ?
        //if thr property 'prop' in the founded row is undefined
        this.state.data.find(item => item.id === id)[prop] === undefined ?
          //leave it as it is
          tempEntity[prop]
          :
          //assign the property value
          this.state.data.find(item => item.id === id)[prop]
        :
        //if there is no item with this 'id' 
        //find the row which the first property == 'id', if the 'prop' of this row == undefined
        this.state.data.find(item => item[Object.keys(item)[0]] === id)[prop] === undefined ?
          //leave it as it is
          tempEntity[prop]
          :
          //assign the property value
          this.state.data.find(item => item[Object.keys(item)[0]] === id)[prop];
    }
    this.setState({ updateEntity: tempEntity });
  }

  // A method for getting the id of the selected row and putting it in the deleteEntity,
  // inputs:
  // id = Selected row ID
  GetIdForDelete(id) {
    let tempEntity;
    if (this.props.addType) {
      tempEntity = {
        "id": id,
        "type": this.props.addType
      }
    }
    else {
      tempEntity = this.state.deleteEntity;
      if (Array.isArray(tempEntity[Object.keys(tempEntity)[0]])) {
        tempEntity[Object.keys(tempEntity)[0]] = [id];
      } else {
        tempEntity[Object.keys(tempEntity)[0]] = id;
      }
    }


    this.setState({ deleteEntity: tempEntity });
  }

  // A method for getting the select items from the Database,
  // inputs:
  // url = Web API Service url
  // body = Request body
  // name = the name of the state that the data will be stored in
  GetSelect(url, body, name) {

    let token = Cookie.get('token');
    fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then((response) => {
        if (response.success) {
          //Create a state with the label of the select ('name' == label)
          this.setState({ [name]: response.data.data });
        } else {
          console.error(response);
        }
      })
      .catch(error => console.error('Unable to Read.', error));
  }

  // A method for Re setting the Search fields values
  Clear() {
    let tempEntity = this.state.readEntity;
    if (this.props.searchFields) {
      for (let field of this.props.searchFields) {
        if (field.type === 'date') {
          tempEntity.search[field.backName] = '';
        } else if (!Number.isNaN(Number.parseInt(tempEntity.search[field.backName]))) {
          tempEntity.search[field.backName] = 0;
        }
        else if (field.type === 'multiSelect') {
          tempEntity.search[field.backName] = [];
        }
        else {
          tempEntity.search[field.backName] = '';
        }
      }
    }

    this.setState({ readEntity: tempEntity });
    if (!this.props.showEmpty) {
      this.Read(this.state.readEntity);
    }

  }

  render() {
    let me = this;
    let tableRawData = this.state.data;


    // Getting only the required columns for display + the row id
    tableRawData = tableRawData.map((row) => {
      let tablerow = {};
      for (let column of this.props.columns) {
        tablerow[column['backName']] = row[column['backName']];
      }
      tablerow.id = row.id;

      return tablerow;
    });

    // Creating the Breadcrumb links
    let Path = this.props.path.map((folder) => <Link className="TreeNav--" to={folder.path} key={folder.title}>{folder.title}</Link>);

    // Creating the SearchFields
    let SearchFields
    if (this.props.searchFields) { // to prevent undefined error
      SearchFields = this.props.searchFields.map(
        (field) => {
          let items;
          switch (field.type) {
            case 'text':
              return (
                <React.Fragment key={field.label}>
                  <Field
                    title={`${field.label}:`}
                    titleSize={field.titleSize}
                    inputComponent={[
                      <TextInput
                        key={field.label}
                        placeholder={field.label}
                        type="text"
                        value={this.state.readEntity?.search[field.backName]}
                        onChange={(value) => {
                          let tempEntity = this.state.readEntity;
                          tempEntity.search[field.backName] = value;
                          this.setState({ readEntity: tempEntity });
                        }}
                      />
                    ]}
                  />
                </React.Fragment>
              )
            case 'number':
              return (
                <React.Fragment key={field.label}>
                  <Field
                    title={`${field.label}:`}
                    titleSize={field.titleSize}
                    inputComponent={[
                      <TextInput
                        key={field.label}
                        placeholder={field.label}
                        type="number"
                        value={this.state.readEntity?.search[field.backName]}
                        onChange={(value) => {
                          let tempEntity = this.state.readEntity;
                          tempEntity.search[field.backName] = value;
                          this.setState({ readEntity: tempEntity });
                        }}
                      />
                    ]}
                  />
                </React.Fragment>
              )
            case 'date':
              return (
                <React.Fragment key={field.label}>
                  <Field
                    title={`${field.label}:`}
                    titleSize={field.titleSize}
                    inputComponent={[
                      <TextInput
                        key={field.label}
                        arDate={true}
                        placeholder={field.label}
                        type="date"
                        value={this.state.readEntity?.search[field.backName]}
                        onChange={(value) => {
                          let tempEntity = this.state.readEntity;
                          tempEntity.search[field.backName] = value;
                          this.setState({ readEntity: tempEntity });
                        }}
                      />
                    ]}
                  />
                </React.Fragment>
              )
            case 'select':
              //for Branches, Managements, Departments Dropdown connection
              if (field.label === 'الإدارة') {
                //if there is no state with this name 'field.label'
                items = this.state[field.label] === undefined ?
                  //return empty array
                  []
                  :
                  //return only the managements in the selected branch
                  this.state[field.label].filter(management => management.branchId === this.state.readEntity.search[this.props.searchFields.find(field => field.label === 'الفرع').backName])
                    .map((item) => { return { label: item[field.item.label], value: item[field.item.value] } });
              } else if (field.label === 'القسم') {
                items = this.state[field.label] === undefined ?
                  []
                  :
                  this.state[field.label].filter(department => department.administrationId === this.state.readEntity.search[this.props.searchFields.find(field => field.label === 'الإدارة').backName])
                    .map((item) => { return { label: item[field.item.label], value: item[field.item.value] } });
              } else {
                //for normal select
                items = this.state[field.label] === undefined ?
                  []
                  :
                  this.state[field.label].map((item) => {
                    return {
                      label: item[field.item.label],
                      value: item[field.item.value]
                    }
                  });
              }

              return (
                <Field
                  key={field.label}
                  title={`${field.label}:`}
                  titleSize={field.titleSize}
                  inputComponent={[
                    <Dropdown
                      rtl={true}
                      key={field.label}
                      placeholder={field.label}
                      value={items.find(item => item.value === this.state.readEntity.search[field.backName])}
                      options={items}
                      onChange={(value) => {
                        let tempEntity = this.state.readEntity;
                        tempEntity.search[field.backName] = value;
                        this.setState({ readEntity: tempEntity });
                      }}
                    />
                  ]}
                />
              )
            case 'multiSelect':
              items = this.state[field.label] === undefined ?
                []
                :
                this.state[field.label].map((item) => {
                  return {
                    label: item[field.item.label],
                    value: item[field.item.value]
                  }
                });
              return (
                <Field
                  key={field.label}
                  title={`${field.label}:`}
                  titleSize={field.titleSize}
                  inputComponent={[
                    <Dropdown
                      rtl={true}
                      multi={true}
                      key={field.label}
                      placeholder={field.label}
                      options={items}
                      value={field.backSearch ?
                        items?.find(item => item.value === this.state.readEntity.search[field.backName]) :
                        (field.innerSearch ?
                          items.find(item => item.value === this.state.searchEntity[field.backName + ',inner']) :
                          items.find(item => item.value === this.state.searchEntity[field.backName]))}

                      onChange={(value) => {
                        if (field.backSearch) {
                          let tempEntity = this.state.readEntity;
                          tempEntity.search[field.backName] = value;

                          this.setState({ readEntity: tempEntity });
                        } else if (field.innerSearch) {
                          this.ChangeEntityState('searchEntity', field.backName + ',inner', value)
                        } else {
                          this.ChangeEntityState('searchEntity', field.backName, value)
                        }
                      }}
                    />
                  ]}
                />
              );
            case 'staticSelect':
              // for select fields with staic options (not from the back end)
              return (
                <Field
                  key={field.label}
                  titleSize={field.titleSize}
                  title={`${field.label}:`}
                  inputComponent={[
                    <Dropdown
                      rtl={true}
                      key={field.label}
                      placeholder={field.label}
                      value={field.items.find(item => item.value === this.state.readEntity.search[field.backName])}
                      //items from PageProps
                      options={field.items}
                      onChange={(value) => {
                        let tempEntity = this.state.readEntity;
                        tempEntity.search[field.backName] = value;
                        this.setState({ readEntity: tempEntity });
                      }}
                    />
                  ]}
                />
              )
            default:
              // if type is none of the above then return a text input
              return (
                <Field
                  key={field.label}
                  titleSize={field.titleSize}
                  title={`${field.label}:`}
                  inputComponent={[
                    <TextInput
                      key={field.label}
                      placeholder={field.label}
                      type="text"
                      value={this.state.readEntity?.search[field.backName]}
                      onChange={(value) => {
                        let tempEntity = this.state.readEntity;
                        tempEntity.search[field.backName] = value;
                        this.setState({ readEntity: tempEntity });
                      }}
                    />
                  ]}
                />
              )
          }
        }
      );

    }

    // Creating Table Headers
    let TableHeaders = this.props.columns.map((column) => <th key={column['name']}>{column['name']}</th>)
      .concat([me.props.noDelete && me.props.noEdit ? null : <th className="action" key={'Actions'} >العمليات</th>]);
    let index = 0;
    // Display Data properly in the table
    let TableData = tableRawData
      // .slice(((this.state.rowsNumber * this.state.currentPage) - this.state.rowsNumber), (this.state.rowsNumber * this.state.currentPage))
      .map(
        function (row) {
          return (
            <tr key={row.id}>
              {
                Object.keys(row)
                  .map((columnName, columnIndex) => {
                    // if the current property name is 'id' and there is no column with the backname 'id' 
                    if (columnName === 'id' && me.props.columns.find(column => column.backName === 'id') === undefined && !me.props.devices) {
                      // don't render a cell  
                      return null;
                    }
                    if (columnName === 'id' && me.props.devices) {
                      return <td> {index += 1} </td>;
                    }

                    //if there is a property called 'link' in the currunt column
                    return me.props.columns[columnIndex]['link'] ?
                      //render a cell with a <Link> in it
                      <td key={row[0] + ',' + columnIndex}>
                        {/* assign the 'to' property from the PageProps and add to the url the 'routeValues' separated by a '/' */}
                        <Link to={me.props.columns[columnIndex]['link']['to'].toString() + me.props.columns[columnIndex]['link']['routeValues'].map((value) => me.state.data.find(data => data[Object.keys(row)[0]] === row[Object.keys(row)[0]])[value]).join('/')}>
                          {/* the text in the link */}
                          {
                            // if there is a property called 'icon' in 'link'
                            me.props.columns[columnIndex]['link']['icon'] ?
                              //render the 'icon' value in the link
                              me.props.columns[columnIndex]['link']['icon']
                              :
                              // if there is a property called 'text' in 'link'
                              me.props.columns[columnIndex]['link']['text'] ?
                                //render the 'text' value in the link
                                me.props.columns[columnIndex]['link']['text']
                                :
                                //else, render the value in the current row of data
                                row[columnName]
                          }
                        </Link>
                      </td>
                      :
                      // if there is a property called 'boolean' in current column
                      // me.props.columns[columnIndex]['boolean'] ?
                      //   // then render a green badge if true and red badge if false
                      //   <td key={row[0] + ',' + columnIndex}>
                      //     {me.props.columns[columnIndex]['enum']?.find((item) => item.value == row[columnName]) ? me.props.columns[columnIndex]['enum'].find((item) => item.value == row[columnName])['display'] : row[columnName]}
                      //   </td>
                      me.props.columns[columnIndex]['boolean'] ?
                        <td key={row[0] + ',' + columnIndex}>
                          {row[columnName] ? <span className='badge agree working-time'>نعم</span> : <span className='badge alert working-time'>لا</span>}
                        </td>
                        :
                        // if there is a property called 'enum' in current column
                        me.props.columns[columnIndex]['enum'] ?
                          // enum is an array of { value: '', display: '' }, 
                          // render the 'display' value of the element with the 'value' == row[columnName]
                          //if no element in the enum array has the 'value' == row[columnName], then return the row[columnName] as it is 
                          <td key={row[0] + ',' + columnIndex}>
                            {me.props.columns[columnIndex]['enum'].find((item) => item.value == row[columnName]) ? me.props.columns[columnIndex]['enum'].find((item) => item.value == row[columnName])['display'] : row[columnName]}
                          </td>
                          :
                          //if no 'boolean' or 'enum' ... render the value in data as it is
                          <td key={row[0] + ',' + columnIndex}>
                            {row[columnName]}
                          </td>;
                  })
                  //add the Actions Column
                  //noDelete property to not show the delete button, noEdit property to not show the edit button
                  //if both are not true then don't render the Actions Column
                  .concat([me.props.noDelete && me.props.noEdit ? null :
                    <td className="td-action" key={row[0] + ',Actions'}>
                      {/* check if there is a permission to delete */}
                      {me.props.noDelete ||
                        (me.props.permissions ? !me.props.permissions.data.find(permission => permission.nameEn === 'Delete') : true) ?

                        me.props.allPermissions == true ?
                          <i
                            className="fa fa-trash-o"
                            onClick={(e) => {
                              me.showModal("delete");
                              //if there is an 'id' then return id, else return the value of the first property 
                              me.GetIdForDelete(row.id ? row.id : row[Object.keys(row)[0]]);
                            }}></i>
                          :
                          null
                        :
                        <i
                          className="fa fa-trash-o"
                          onClick={(e) => {
                            me.showModal("delete");
                            //if there is an 'id' then return id, else return the value of the first property 
                            me.GetIdForDelete(row.id ? row.id : row[Object.keys(row)[0]]);
                          }}></i>}
                      {/* check if there is a permission to delete */}
                      {me.props.noEdit && !me.props.allPermissions || (me.props.permissions ? !me.props.permissions.data.find(permission => permission.nameEn === 'Edite ') : true) ?
                        me.props.allPermissions == true ?
                          <i
                            className="fa fa-pencil"
                            onClick={(e) => {
                              me.showModal("edit");
                              //if there is an 'id' then return id, else return the value of the first property
                              me.GetForUpdate(row.id ? row.id : row[Object.keys(row)[0]]);
                            }}
                          >
                          </i>
                          :
                          null
                        :
                        me.props.routOut ?
                          <Link to={`/Ar/TAW/Main/EditEmployee/${row.id ? row.id : row[Object.keys(row)[0]]}`}
                          >
                            <i
                              className="fa fa-pencil" style={{ color: "var(--text-color)" }}

                            >
                            </i>
                          </Link>

                          :
                          me.props.routUnregistered ?
                            <Link to={`/Ar/TAW/Main/AddUnsignEmployee/${row.id ? row.id : row[Object.keys(row)[0]]}`}
                            >
                              <i
                                className="fa fa-pencil" style={{ color: "var(--text-color)" }}

                              >
                              </i>
                            </Link>
                            :
                            me.props.routUnregistered ?
                              <Link to={`/Ar/TAW/Main/AddUnsignEmployee/${row.id ? row.id : row[Object.keys(row)[0]]}`}
                              >
                                <i
                                  className="fa fa-pencil" style={{ color: "var(--text-color)" }}

                                >
                                </i>
                              </Link>
                              :
                              <i
                                className="fa fa-pencil"
                                onClick={(e) => {
                                  me.showModal("edit");
                                  //if there is an 'id' then return id, else return the value of the first property
                                  me.GetForUpdate(row.id ? row.id : row[Object.keys(row)[0]]);
                                }}
                              >
                              </i>
                      }
                    </td>
                  ])
              }
            </tr>
          )
        }
      );
    // Creating Add Modal Fields
    let CreateEntityProperties = this.props.createEntityProps.map(
      (prop) => {
        let backNames;
        let values;
        let items;
        switch (prop.type) {
          case 'text':
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                // ===========
                title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={prop.label}
                    type="text"

                    value={this.state.createEntity[prop.backName]}
                    onChange={(value) => this.ChangeEntityState('createEntity', prop.backName, value)}
                  />
                ]}
              />
            );
          case 'number':
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                // ===========
                title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={prop.label}
                    type="number"
                    value={this.state.createEntity[prop.backName]}
                    onChange={(value) => this.ChangeEntityState('createEntity', prop.backName, value)}
                  />
                ]}
              />
            );
          case 'email':
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                // ===========
                title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={prop.label}
                    type="email"
                    value={this.state.createEntity[prop.backName]}
                    onChange={(value) => this.ChangeEntityState('createEntity', prop.backName, value)}
                  />
                ]}
              />
            );
          case 'date':
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                // ===========
                title={[prop.label, prop.validation === 'Required' ||  prop.validation === 'Required to' || prop.validation === 'Required from' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <TextInput
                    arDate={true}
                    key={prop.label}
                    placeholder={prop.label}
                    type="date"
                    value={this.state.createEntity[prop.backName]}
                    onChange={(value) => this.ChangeEntityState('createEntity', prop.backName, value)}
                  />
                ]}
              />
            );
          case 'time':
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                noFormCont={prop.noFormCont}
                // ===========
                title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={prop.label}
                    type="time"
                    //get only hours and minutes
                    value={this.state.createEntity[prop.backName]}
                    onChange={(value) => this.ChangeEntityState('createEntity', prop.backName, value + ":00")}
                  />
                ]}
              />
            );

          case 'password':
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                // ===========
                title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={prop.label}
                    type="password"
                    value={this.state.createEntity[prop.backName]}
                    onChange={(value) => this.ChangeEntityState('createEntity', prop.backName, value)}
                  />
                ]}
              />
            );
          case 'select':
            //Explained in the search fields
            if (prop.label === 'الإدارة') {
              items = this.state[prop.label] === undefined ?
                []
                :
                this.state[prop.label].filter(management => management.branchId === this.state.createEntity[this.props.createEntityProps.find(prop => prop.label === 'الفرع').backName]).map((item) => { return { label: item[prop.item.label], value: item[prop.item.value] } });
            } else if (prop.label === 'القسم') {
              items = this.state[prop.label] === undefined ?
                []
                :
                this.state[prop.label].filter(department => department.administrationId === this.state.createEntity[this.props.createEntityProps.find(prop => prop.label === 'الإدارة').backName]).map((item) => { return { label: item[prop.item.label], value: item[prop.item.value] } });
            } else if (prop.label === 'الموظف') {
              items = this.state[prop.label] === undefined ?
                []
                :
                this.state[prop.label].map((item) => { return { label: item.employeeCode + ': ' + item[prop.item.label], value: item[prop.item.value] } });
            } else {
              items = this.state[prop.label] === undefined ?
                []
                :
                this.state[prop.label].map((item) => { return { label: item[prop.item.label], value: item[prop.item.value] } });
            }

            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                // ===========
                title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <Dropdown
                    rtl={true}
                    key={prop.label}
                    placeholder={prop.label}
                    value={items.find(item => item.value === this.state.createEntity[prop.backName])}
                    options={items}
                    onChange={(value) => this.ChangeEntityState('createEntity', prop.backName, value)}
                  />
                ]}
              />
            );
          case 'checkbox':
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                noFormCont={prop.noFormCont}
                // ===========
                title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <ToggleSwitch
                    noFormCont={prop.noFormCont}
                    value={this.state.createEntity[prop.backName]}
                    backName={prop.backName}
                    onChange={(name, value) => this.ChangeEntityState('createEntity', name, value)}
                    onText={'نعم'}
                    offText={'لا'}
                    height={30}
                    width={70}
                    onHandleColor={this.props.checkboxgreen ? "#70c767" : " "}
                    onColor={this.props.checkboxgreen ? "#e1fcde" : " "}
                    // uncheckedIconWidth="80px"
                    // uncheckedIconMarginLeft="-38px"
                    checkedIconMarginLeft="10px"
                  />
                ]}
              />
            );
          case 'exceptions':
            //in the Employees page

            backNames = [];
            for (const backName of prop.backName) {
              backNames.push(backName);
            }
            values = [];
            for (const backName of backNames) {
              values.push(this.state.createEntity[backName])
            }
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                // ===========
                title={prop.label}
                inputComponent={[
                  <ExceptionsAr
                    values={values}
                    backNames={backNames}
                    onChange={(name, value) => this.ChangeEntityState('createEntity', name, value)}
                  />
                ]}
              />
            );
          case 'shiftEndNextDay':
            //in working times page
            backNames = [];
            for (const backName of prop.backName) {
              backNames.push(backName);
            }
            values = [];
            for (const backName of backNames) {
              values.push(this.state.createEntity[backName])
            }
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                noFormCont={prop.noFormCont}
                // ===========
                title={''}
                inputComponent={[
                  <ShiftEndNextDay
                    onHandleColor={this.props.checkboxgreen ? "#70c767" : " "}
                    onColor={this.props.checkboxgreen ? "#e1fcde" : " "}
                    titleSize={prop.titleSize}
                    ar={true}
                    type={prop.inputType}
                    labels={prop.label}
                    values={values}
                    backNames={backNames}
                    onChange={(name, value) => this.ChangeEntityState('createEntity', name, value)}
                  />
                ]}
              />
            );
          case 'selectShift':
            //in add pemission page
            backNames = [];
            for (const backName of prop.backName) {
              backNames.push(backName);
            }
            values = [];
            for (const backName of backNames) {
              if (typeof backName == 'object') {
                for (const key in backName) {
                  backName[key] = this.state.createEntity[key]
                }
                values.push(backName);
              } else {
                values.push(this.state.createEntity[backName])
              }
            }
            items = this.state[prop.label] === undefined ? [] : this.state[prop.label].map((item) => { return { label: item.employeeCode + ': ' + item[prop.item.label], value: item[prop.item.value] } });

            return (
              <Field
                // ===========
                style={"col-lg-12"}
                key={'selectShift'}
                titleSize={prop.titleSize}

                // ===========
                title={prop.label}
                inputComponent={[
                  <SelectShiftAr
                    items={items}
                    values={values}
                    backNames={backNames}
                    onChange={(name, value) => this.ChangeEntityState('createEntity', name, value)}
                    parent={this}
                  />
                ]}
              />
            );
          case 'permissionType':
            //in add permission page
            backNames = [];
            for (const backName of prop.backName) {
              backNames.push(backName);
            }
            values = [];
            for (const backName of backNames) {
              values.push(this.state.createEntity[backName])
            }

            items = this.state.createEntity.periodShiftId !== 'open' ?
              [
                { label: 'مؤقت', value: 1 },
                { label: 'حضور متأخر', value: 2 },
                { label: 'انصراف مبكر', value: 3 },
                { label: 'يوم كامل', value: 4 }
              ]
              :
              [
                { label: 'يوم كامل', value: 4 }
              ];
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                key={'permissionType'}
                // ===========
                title={prop.label}
                inputComponent={[
                  <PermissionTypesAr
                    items={items}
                    values={values}
                    backNames={backNames}
                    onChange={(name, value) => this.ChangeEntityState('createEntity', name, value)}
                  />
                ]}
              />
            );
          default:
            //text is the default type if none of the above
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                // ===========
                title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={prop.label}
                    type="text"
                    value={this.state.createEntity[prop.backName]}
                    onChange={(value) => this.ChangeEntityState('createEntity', prop.backName, value)}
                  />
                ]}
              />
            );
        }
      }
    );

    let UpdateEntityProperties;

    // if there is an Update operation in this page, Create Edit Modal Fields
    if (!this.props.noEdit) {
      UpdateEntityProperties = this.props.updateEntityProps.map(
        (prop) => {
          let backNames;
          let values;
          let items;
          switch (prop.type) {
            case 'text':
              return (
                <Field
                  // ===========
                  style={prop.style}
                  titleSize={prop.titleSize}
                  // ===========
                  title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                  inputComponent={[
                    <TextInput
                      key={prop.label}
                      placeholder={prop.label}
                      type="text"
                      value={this.state.updateEntity[prop.backName]}
                      onChange={(value) => this.ChangeEntityState('updateEntity', prop.backName, value)}
                    />
                  ]}
                />
              );
            case 'number':
              return (
                <Field
                  // ===========
                  style={prop.style}
                  titleSize={prop.titleSize}
                  // ===========
                  title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                  inputComponent={[
                    <TextInput
                      key={prop.label}
                      placeholder={prop.label}
                      type="number"
                      value={this.state.createEntity[prop.backName]}
                      onChange={(value) => this.ChangeEntityState('updateEntity', prop.backName, value)}
                    />
                  ]}
                />
              );
            case 'email':
              return (
                <Field
                  // ===========
                  style={prop.style}
                  titleSize={prop.titleSize}
                  // ===========
                  title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                  inputComponent={[
                    <TextInput
                      key={prop.label}
                      placeholder={prop.label}
                      type="email"
                      value={this.state.updateEntity[prop.backName]}
                      onChange={(value) => this.ChangeEntityState('updateEntity', prop.backName, value)}
                    />
                  ]}
                />
              );
            case 'date':
              return (
                <Field
                  // ===========
                  style={prop.style}
                  titleSize={prop.titleSize}
                  // ===========
                  title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                  inputComponent={[
                    <TextInput
                      arDate={true}
                      key={prop.label}
                      placeholder={prop.label}
                      type="date"
                      value={this.state.updateEntity[prop.backName]}
                      onChange={(value) => this.ChangeEntityState('updateEntity', prop.backName, value)}
                    />
                  ]}
                />
              );
            case 'time':
              return (
                <Field
                  // ===========
                  style={prop.style}
                  titleSize={prop.titleSize}
                  noFormCont={prop.noFormCont}
                  // ===========
                  title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                  inputComponent={[
                    <TextInput
                      key={prop.label}
                      placeholder={prop.label}
                      type="time"
                      //hours and minutes only
                      value={this.state.updateEntity[prop.backName]}
                      onChange={(value) => {
                        this.ChangeEntityState('updateEntity', prop.backName, value + ":00")
                      }}
                    />
                  ]}
                />
              );


            case 'password':
              return (
                <Field
                  // ===========
                  style={prop.style}
                  titleSize={prop.titleSize}
                  // ===========
                  title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                  inputComponent={[
                    <TextInput
                      key={prop.label}
                      placeholder={prop.label}
                      type="password"
                      value={this.state.updateEntity[prop.backName]}
                      onChange={(value) => this.ChangeEntityState('updateEntity', prop.backName, value)}
                    />
                  ]}
                />
              );
            case 'select':
              //explained in search fields
              if (prop.label === 'الإدارة') {
                items = this.state[prop.label] === undefined ?
                  []
                  :
                  this.state[prop.label].filter(management => management.branchId === this.state.updateEntity[this.props.updateEntityProps.find(prop => prop.label === 'الفرع').backName]).map((item) => { return { label: item[prop.item.label], value: item[prop.item.value] } });
              } else if (prop.label === 'القسم') {
                items = this.state[prop.label] === undefined ?
                  []
                  :
                  this.state[prop.label].filter(department => department.administrationId === this.state.updateEntity[this.props.updateEntityProps.find(prop => prop.label === 'الإدارة').backName]).map((item) => { return { label: item[prop.item.label], value: item[prop.item.value] } });
              } else if (prop.label === 'الموظف') {
                items = this.state[prop.label] === undefined ?
                  []
                  :
                  this.state[prop.label].map((item) => { return { label: item.employeeCode + ': ' + item[prop.item.label], value: item[prop.item.value] } });
              } else {
                items = this.state[prop.label] === undefined ?
                  []
                  :
                  this.state[prop.label].map((item) => { return { label: item[prop.item.label], value: item[prop.item.value] } });
              }

              return (
                <Field
                  // ===========
                  style={prop.style}
                  titleSize={prop.titleSize}
                  // ===========
                  title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                  inputComponent={[
                    <Dropdown
                      rtl={true}
                      key={prop.label}
                      placeholder={prop.label}
                      value={items.find(item => item.value === this.state.updateEntity[prop.backName])}
                      options={items}
                      onChange={(value) => this.ChangeEntityState('updateEntity', prop.backName, value)}
                    />
                  ]}
                />
              );
            case 'checkbox':
              return (
                <Field
                  // ===========
                  style={prop.style}
                  titleSize={prop.titleSize}
                  noFormCont={prop.noFormCont}
                  // ===========
                  title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                  inputComponent={[
                    <ToggleSwitch
                      noFormCont={prop.noFormCont}
                      value={this.state.updateEntity[prop.backName]}
                      backName={prop.backName}
                      onChange={(name, value) => this.ChangeEntityState('updateEntity', name, value)}
                      onText={'نعم'}
                      offText={'لا'}
                      height={30}
                      width={70}
                      // uncheckedIconWidth="80px"
                      // uncheckedIconMarginLeft="-38px"
                      checkedIconMarginLeft="10px"
                    />
                  ]}
                />
              );
            case 'exceptions':
              //in Employees page
              backNames = [];
              for (const backName of prop.backName) {
                backNames.push(backName);
              }
              values = [];
              for (const backName of backNames) {
                values.push(this.state.updateEntity[backName])
              }
              return (
                <Field
                  // ===========
                  style={prop.style}
                  titleSize={prop.titleSize}
                  // ===========
                  title={prop.label}
                  inputComponent={[
                    <ExceptionsAr
                      values={values}
                      backNames={backNames}
                      onChange={(name, value) => this.ChangeEntityState('updateEntity', name, value)}
                    />
                  ]}
                />
              );
            case 'shiftEndNextDay':
              //in Working time page
              backNames = [];
              for (const backName of prop.backName) {
                backNames.push(backName);
              }
              values = [];
              for (const backName of backNames) {
                values.push(this.state.updateEntity[backName])
              }
              return (
                <Field
                  // ===========
                  style={prop.style}
                  titleSize={prop.titleSize}
                  noFormCont={prop.noFormCont}
                  // ===========
                  title={''}
                  inputComponent={[
                    <ShiftEndNextDay
                      ar={true}
                      type={prop.inputType}
                      titleSize={prop.titleSize}
                      labels={prop.label}
                      values={values}
                      backNames={backNames}
                      onChange={(name, value) => this.ChangeEntityState('updateEntity', name, value)}
                    />
                  ]}
                />
              );
            case 'selectShift':
              //in add permission page
              backNames = [];
              for (const backName of prop.backName) {
                backNames.push(backName);
              }
              values = [];
              for (const backName of backNames) {
                if (typeof backName == 'object') {
                  for (const key in backName) {
                    backName[key] = this.state.updateEntity[key]
                  }
                  values.push(backName);
                } else {
                  values.push(this.state.updateEntity[backName])
                }
              }
              items = this.state[prop.label] === undefined ? [] : this.state[prop.label].map((item) => { return { label: item.employeeCode + ': ' + item[prop.item.label], value: item[prop.item.value] } });

              return (
                <Field
                  // ===========
                  style={prop.style, "col-lg-12"}
                  key={'selectShift'}
                  titleSize={prop.titleSize}
                  // ===========
                  title={prop.label}
                  inputComponent={[
                    <SelectShiftAr
                      items={items}
                      values={values}
                      backNames={backNames}
                      onChange={(name, value) => this.ChangeEntityState('updateEntity', name, value)}
                      parent={this}
                    />
                  ]}
                />
              );
            case 'permissionType':
              // in add permission page
              backNames = [];
              for (const backName of prop.backName) {
                backNames.push(backName);
              }
              values = [];
              for (const backName of backNames) {
                values.push(this.state.updateEntity[backName])
              }

              items = this.state.updateEntity.periodShiftId !== 'open' ?
                [
                  { label: 'مؤقت', value: 1 },
                  { label: 'حضور متأخر', value: 2 },
                  { label: 'انصراف مبكر', value: 3 },
                  { label: 'يوم كامل', value: 4 }
                ]
                :
                [
                  { label: 'يوم كامل', value: 4 }
                ];

              return (
                <Field
                  // ===========
                  style={prop.style}
                  titleSize={prop.titleSize}
                  key={'permissionType'}
                  // ===========
                  title={prop.label}
                  inputComponent={[
                    <PermissionTypesAr
                      items={items}
                      values={values}
                      backNames={backNames}
                      onChange={(name, value) => this.ChangeEntityState('updateEntity', name, value)}
                    />
                  ]}
                />
              );
            default:
              //default type is text
              return (
                <Field
                  // ===========
                  style={prop.style}
                  titleSize={prop.titleSize}
                  // ===========
                  title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                  inputComponent={[
                    <TextInput
                      key={prop.label}
                      placeholder={prop.label}
                      type="text"
                      value={this.state.updateEntity[prop.backName]}
                      onChange={(value) => this.ChangeEntityState('updateEntity', prop.backName, value)}
                    />
                  ]}
                />
              );
          }

        }
      );
    }


    return (
      <div className="BasicPage">
        {/* startOverlayComponents */}
        {/* Add modal */}
        <Overlay visible={this.state.add}>
          <ModalAr
            title={this.props.title}
            action="add"
            visible={this.state.add}
            hideModal={(name) => this.hideModal(name)}
            //inputs in the modal
            EntityProperties={CreateEntityProperties}
            //errors to show in modal
            errors={this.state.createErrors}
            //on Submit function
            fetch={() => this.Create(this.state.createEntity)}
            removeModalTitleName={this.props.removeModalTitleName}
          />
        </Overlay>

        {/* Edit Modal */}
        <Overlay visible={this.state.edit}>
          <ModalAr
            title={this.props.title}
            action="edit"
            visible={this.state.edit}
            hideModal={(name) => this.hideModal(name)}
            EntityProperties={UpdateEntityProperties}
            errors={this.state.updateErrors}
            fetch={() => this.Update(this.state.updateEntity)}
          />
        </Overlay>

        {/* Delete Modal */}
        <Overlay visible={this.state.delete}>
          <ModalAr
            title={this.props.title}
            action="delete"
            visible={this.state.delete}
            hideModal={(name) => this.hideModal(name)}
            EntityProperties={[]}
            errors={[]}
            deleteMessage={this.props.deleteMessage}
            fetch={() => this.Delete(this.state.deleteEntity)}
          />
        </Overlay>

        {/* Search Modal */}
        <Overlay visible={this.state.search}>
          <ModalAr
            title={this.props.title}
            action="search"
            visible={this.state.search}
            hideModal={(name) => this.hideModal(name)}
            searchFields={this.props.modalSearchFields}
            EntityProperties={CreateEntityProperties}
            //Columns of the table in modal
            columns={this.props.modalColumns ? this.props.modalColumns : [
              { name: 'الكود', backName: 'employeeCode' },
              { name: 'الإسم', backName: 'nameAr' }
            ]}
            errors={[]}
            //to set the State of the basic page from the inside of the modal component
            parent={this}
            backName={this.props.modalBackName}
            //the modal table read url, body, ... to get data from server and display it in the modal
            read={this.props.modalRead}
            //on Submit
            fetch={() => this.Create(this.state.createEntity)}
            //a flag to indicate whether the modal table data should be fetched again from the server or not
            updated={this.state.modalUpdated}
          />
        </Overlay>

        <Overlay visible={this.state.loading}>
          <Loading visible={this.state.loading} />
        </Overlay>
        {/* endOverlayComponents */}


        <div className="MainLayout--container">
          <PageHeaderAr
            // Path for breadcrumb
            Path={Path}
            title={this.props.title}
            mainTitle={this.props.mainTitle}
            // =====================
            hideFilters={SearchFields ? () => hideFilters() : null}
            showFilters={SearchFields ? () => showFilters() : null}
            showModal={(name) => this.showModal(name)}
            routOut={this.props.routOut}
            //Show Create button or not
            noCreate={(this.props.noCreate ||
              !this.props.permissions?.data.find(permission => permission.nameEn === 'Add') && !this.props.allPermissions) ? true : false}
            useSearchModal={this.props.useSearchModal ? true : false}
          // =====================
          />
          <div className="alerts">
            {
              // if there is a message show with proper style (success or danger)
              this.state.message ?
                this.state.message['text'] ?
                  <Alert
                    className={"Alert " + this.state.message['style']}
                  >
                    {this.state.message['text']}
                  </Alert>
                  :
                  null
                :
                null
            }
          </div>
          {
            // if there is searchfields render the filters compnent
            SearchFields ?
              <FiltersAr
                // inputs
                SearchFields={SearchFields}
                // on clear
                Clear={() => this.Clear()}
                // on search
                Search={() => this.Read(this.state.readEntity)}
              />
              :
              null
          }
          {/* for pagination */}
          <TableFooterAr
            // number of rows per page
            rowsNumber={this.state.readEntity.pageSize}
            // on change the value of the rows number input
            rowsNumberChange={(value) => {
              // if the value is less than 1, then make it 1 (can't be 0 or less)
              value = value > 1 ? value : 1;
              //assign the value to the pageSize property in readEntity
              this.ChangeEntityState('readEntity', 'pageSize', value);
              //return to the first page
              this.ChangeEntityState('readEntity', 'pageNumber', 0);
            }}
            //Total number of rows in all pages
            total={this.state.rowsTotal}
            //selected page
            currentPage={this.state.readEntity.pageNumber + 1}
            //on selecting a page to show
            currentPageChange={(value) => {
              //in back-end the pages are numbered starting from 0 not from 1 so we convert the value to a number if it is a string and subtract it by 1
              this.ChangeEntityState('readEntity', 'pageNumber', Number(value) - 1);
              //get the new page from back-end
              this.Read(this.state.readEntity);
            }}
          />
          {/* table component takes an array of column headers and an array of rows for data */}
          <Table TableHeaders={TableHeaders} data={TableData} />

        </div>

      </div>
    );
  }
}

export default BasicPageAr;
