import React from 'react';
import './Login.css';
import { Link, Redirect } from "react-router-dom";
import Cookie from "js-cookie";
import Loading from '../Loading/Loading';
import Overlay from '../Overlay/Overlay';
import Alert from '../Alert/Alert';
import Button from '../Button/Button';
import { ip, adminIp } from './../../data/APIs';

class LoginAr extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      authenticated: false,
      failed: false,
      message: '',
      loading: false,
      exist: false,
      checked: false,
      RenewPlan: null
    }
  }
  componentDidMount() {
    this.Check();
    this.focusInput.focus();

    var inputFields = Array.from(document.getElementsByTagName("input")); // Array.from is to be able to deal with the NodeList as an array
    inputFields.forEach(inputField => {
      inputField.addEventListener("keyup", function (event) {
        if (event.keyCode === 13) {
          event.preventDefault();
          document.getElementById("submitButton").click();
        }
      });

    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.companyName !== this.props.companyName) {
      this.Check();
    }
  }

  ChangeState(event, name) {
    this.setState({ [name]: event.target.value });
  }

  Check() {
    this.setState({ loading: true });
    fetch(ip + '/api/CheckCompanyName?CompanyUniqueName=' + this.props.companyName, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'lang': 'ar'
      }
    })
      .then(response => response.json())
      .then((data) => {
        if (data.success) {
          this.setState({ exist: data.data, checked: true, loading: false });
        } else {
          console.error(data);
        }
      })
      .catch(error => console.error('Unable to Check.', error));
  }

  tryLogin() {
    let user = {
      userName: this.state.username,
      password: this.state.password,
      companyUniqueName: this.props.companyName
    }
    this.setState({ loading: true });
    fetch(ip + '/api/Login', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'lang': 'ar'
      },
      body: JSON.stringify(user)
    })
      .then(response => response.json())
      .then((data) => {
        if (data.success && data.data !== null && data.data != false) {

          Cookie.set('token', data.data.token, { SameSite: 'Lax' });
          Cookie.set('userName', user.userName, { SameSite: 'Lax' });
          Cookie.set('companyName', this.props.companyName, { SameSite: 'Lax' });

          this.setState({ authenticated: true, loading: false });
        } 
        else if(data.success && data.data == false || data.message == "مدة الاشتراك انتهت." ){
          this.setState({ failed: true, message: data.message, loading: false , RenewPlan :true });
        }
        else {
          this.setState({ failed: true, message: data.message, loading: false });
        }
      })
      .catch(error => console.error('Unable to Login.', error));
  }

  render() {
    return (
      <section className="Login" >

        <Overlay visible={this.state.loading}>
          <Loading visible={this.state.loading} />
        </Overlay>

        <div className="StartLayout--container">
          <div className="text-right">
            <h2 className="LoginBoxTtAR">تسجيل الدخول</h2>
          </div>
          <div className="Login--form row">
            {
              this.state.message ?
                <Alert className="Alert danger">
                  { this.state.RenewPlan ?
                  <span>Your package has expired. You can renew it from 
                    <a href={adminIp + "/renew?companyName=" + this.props.companyName +"&status=0"} > here </a>
                  </span>
                  :
                  this.state.message
                  }</Alert>
                :
                null
            }
            <div className="Login--form-field col-md-4">
              <label htmlFor="">اسم المستخدم</label>

              <input
                type="text"
                value={this.state.username}
                onChange={(e) => this.ChangeState(e, 'username')}
                ref={(input) => { this.focusInput = input; }}
              />
            </div>
            <div className="Login--form-field col-md-4">
              <label htmlFor="">كلمة المرور</label>
              <input
                type="password"
                value={this.state.password}
                onChange={(e) => this.ChangeState(e, 'password')}
              />

            </div>
            <div class="col-md-4 d-flex align-items-end" >
              <Button
                id="submitButton"
                className="Button square gradient"
                onClick={() => this.tryLogin()}
              >
                تسجيل دخول
            </Button>
            </div>
            <Link className="Login--forgot" to={'/Ar/TAW/Start/ForgotPassword/' + this.props.companyName} style={{ color: 'white' }}>نسيت كلمة المرور؟</Link>

            {/* <p>
              ليس لديك حساب بعد؟
              <Link to="/Ar/TAW/Start/Register">

                <strong> تسجيل حساب جديد</strong>
              </Link>
            </p> */}
          </div>
        </div>
        {!this.state.exist && this.state.checked ? <Redirect to={"/"} /> : null}
        {this.state.authenticated ? <Redirect to="/Ar/TAW/Main/Dashboard" /> : null}
      </section>
    );
  }
}

export default LoginAr;