import React from 'react'
import './PageHeaderAr.css';
import Button from './../Button/Button';
import { PDFDownloadLink, Document, Page } from '@react-pdf/renderer';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import axios from 'axios';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ReactExport from "react-data-export";

class PageHeaderAr extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      excelHeader: props.excelHeader ? props.excelHeader : []
    }
  }

  render() {
    //For Print Excel Button
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    let multiDataSet = [];
    if (this.props.excel) {
      this.props.excelHeader.push({ backName: "", name: " " })
      if (this.props.singleTable && !this.props.colored) {
        let columns = this.props.excelHeader.map((h, i) => {
          return {
            title: h.name,
            width: { wch: h.backName == 'employeeCode' ? 10 : 30 },

            style: i == this.props.excelHeader.length - 1 ?
              { alignment: { wrapText: false, readingOrder: 2 } }
              :
              { fill: { patternType: "solid", fgColor: { rgb: "A9A9A9" } }, alignment: { wrapText: false, readingOrder: 2 } }

          }
        });

        multiDataSet = [
          {
            xSteps: 1, // Will start putting cell with 1 empty cell on left most
            ySteps: 5, //will put space of 5 rows,
            columns: columns,
            data: this.props.excelData?.map((d, inx) => {
              let row = this.props.excelHeader.map((col, i) => {
                if (i != this.props.excelHeader.length - 1)
                  return {
                    value: d[col.backName] ? d[col.backName] : '',
                    style:
                      inx % 2 != 0 ?
                        {
                          alignment: { wrapText: false, readingOrder: 2 },
                          fill: { patternType: "solid", fgColor: { rgb: "DCDCDC" } }
                        }
                        :
                        { alignment: { wrapText: false, readingOrder: 2 }, }
                  }
              });
              return row;
            })
          }
        ];
      } else if (this.props.overtime) {
        if (this.props.excelData?.length > 0) {
          for (let x = 0; x < this.props.excelData?.length; x++) {
            let header1 = [], header2 = [];
            for (let j = 0; j < this.props.excelData[x].data.length; j++) {
              header1 = []; header2 = [];
              header1.push(
                {
                  title: 'كود الموظف', width: { wch: 10 },
                  style: { fill: { patternType: "solid", fgColor: { rgb: "A9A9A9" } }, alignment: { wrapText: false, readingOrder: 2 } }
                }
              )
              header1.push(
                {
                  title: 'إسم الموظف', width: { wch: 40 },
                  style: { fill: { patternType: "solid", fgColor: { rgb: "A9A9A9" } }, alignment: { wrapText: false, readingOrder: 2 } }
                }
              )
              header2.push(
                { value: '' }
              )
              header2.push(
                { value: '' }
              )
              for (let i = 0; i < this.props.excelData[x].data[j].data.length; i++) {
                header1.push(
                  {
                    title: this.props.excelData[x].data[j].data[i].date.toString(), width: { wch: 10 },
                    style: { fill: { patternType: "solid", fgColor: { rgb: "A9A9A9" } }, alignment: { wrapText: false, readingOrder: 2 } }
                  }
                )
                header2.push(
                  { value: this.props.excelData[x].data[j].data[i].day.toString() }
                )
              }
            }
            header1.push(
              {
                title: 'اجمالي الساعات', width: { wch: 20 },
                style: { fill: { patternType: "solid", fgColor: { rgb: "A9A9A9" } }, alignment: { wrapText: false, readingOrder: 2 } }
              }
            )
            header2.push(
              { value: '' }
            )

            let OverTableData = [];
            // creating table data
            let outCounter = 0;
            for (const raw of this.props.excelData[x].data) {
              outCounter += 1;
              let row = [];
              let counter = 0;
              for (const column of this.props.excelHeader) {
                counter += 1;
                if (column.backName == 'date') {
                  for (const col of raw.data) {
                    row.push({
                      value: col.extraMinutes ? col.extraMinutes.toString() : '0',
                      style:
                        outCounter % 2 != 0 ?
                          {
                            alignment: { wrapText: false, readingOrder: 2 },
                            fill: { patternType: "solid", fgColor: { rgb: "DCDCDC" } }
                          }
                          :
                          { alignment: { wrapText: false, readingOrder: 2 }, }

                    }
                    );
                  }
                } else {
                  if (counter != this.props.excelHeader.length - 1) {
                    row.push({
                      value: raw[column.backName] ? raw[column.backName].toString() : '  ',
                      style:
                        outCounter % 2 != 0 ?
                          {
                            alignment: { wrapText: false, readingOrder: 2 },
                            fill: { patternType: "solid", fgColor: { rgb: "DCDCDC" } }
                          }
                          :
                          { alignment: { wrapText: false, readingOrder: 2 }, }
                    });
                  }
                }
              }
              OverTableData.push(row);
            }
            let pretable = [];
            for (const pre of this.props.preTableFields) {
              pretable.push({ title: pre.name, width: { wch: 10 } })
              pretable.push({ title: this.props.excelData[x][pre.backName] ? this.props.excelData[x][pre.backName].toString() : '', width: { wch: 10 } })
            }

            if (this.props.period) {
              pretable.push({ title: "الفترة من:", width: { wch: 10 } })
              pretable.push({ title: this.props.excelData[x].from ? this.props.excelData[x].from.toString() : '', width: { wch: 10 } })
              pretable.push({ title: "إلى:", width: { wch: 10 } })
              pretable.push({ title: this.props.excelData[x].to ? this.props.excelData[x].to.toString() : '', width: { wch: 10 } })
            }

            OverTableData.unshift(header2);

            multiDataSet.push({
              ySteps: 5,
              columns: pretable,
              data: [],

            });
            multiDataSet.push({
              ySteps: 1,
              columns: header1,
              data: OverTableData,

            });
          }

        }
      } else if (this.props.colored) {
        if (this.props.excelData?.length > 0) {
          let coloredHeaders = [];
          if (this.props.colored) {
            coloredHeaders.push({
              title: 'كود الموظف', width: { wch: 10 },
              style: { fill: { patternType: "solid", fgColor: { rgb: "A9A9A9" } }, alignment: { wrapText: false, readingOrder: 2 } }
            })
            coloredHeaders.push({
              title: 'إسم الموظف', width: { wch: 40 },
              style: { fill: { patternType: "solid", fgColor: { rgb: "A9A9A9" } }, alignment: { wrapText: false, readingOrder: 2 } }
            })
            for (let x = 0; x < this.props.excelData[0].data.length; x++) {
              coloredHeaders.push({
                title: this.props.excelData[0].data[x].date ? this.props.excelData[0].data[x].date.toString() : '',
                width: { wch: 10 },
                style: { fill: { patternType: "solid", fgColor: { rgb: "A9A9A9" } }, alignment: { wrapText: false, readingOrder: 2 } }
              })
            }
            coloredHeaders.push({
              title: 'أيام العمل', width: { wch: 20 },
              style: { fill: { patternType: "solid", fgColor: { rgb: "A9A9A9" } }, alignment: { wrapText: false, readingOrder: 2 } }
            })

          }

          let coloredTableData = [];
          coloredTableData = this.props.excelData?.map((raw, inx) => {
            let arr = [];
            for (const column of this.props.excelHeader) {
              let counter = 0;
              if (column.backName == 'data') {
                for (const col of raw.data) {
                  arr.push({
                    value: col.timeIn ? col.timeIn.toString() : '0',
                    style:
                      inx % 2 != 0 ?
                        {
                          alignment: { wrapText: false, readingOrder: 2 },
                          fill: { patternType: "solid", fgColor: { rgb: "DCDCDC" } }
                        }
                        :
                        { alignment: { wrapText: false, readingOrder: 2 }, }
                  });
                }
              } else {
                if (counter != this.props.excelHeader.length - 1) {
                  arr.push({
                    value: raw[column.backName] ? raw[column.backName].toString() : ' ',
                    style:
                      inx % 2 != 0 ?
                        {
                          alignment: { wrapText: false, readingOrder: 2 },
                          fill: { patternType: "solid", fgColor: { rgb: "DCDCDC" } }
                        }
                        :
                        { alignment: { wrapText: false, readingOrder: 2 }, }
                  });
                }
              }
            }
            return arr;
          })

          let pretable = [];

          if (this.props.period) {
            pretable.push({ title: "الفترة من:", width: { wch: 10 } })
            pretable.push({ title: this.props.excelData[0].from ? this.props.excelData[0].from.toString() : '', width: { wch: 10 } })
            pretable.push({ title: "إلى:", width: { wch: 10 } })
            pretable.push({ title: this.props.excelData[0].to ? this.props.excelData[0].to.toString() : '', width: { wch: 10 } })
          }

          multiDataSet.push({
            ySteps: 5,
            columns: pretable,
            data: [],

          });
          multiDataSet.push({
            ySteps: 1,
            columns: coloredHeaders,
            data: coloredTableData,

          });

        }
      } else {
        if (this.props.excelData) {
          for (const data of this.props.excelData) {
            let columns = this.props.excelHeader.map((h, i) => {
              return {
                title: h.name,
                width: { wch: h.backName == 'employeeCode' ? 10 : 30 },

                style: i == this.props.excelHeader.length - 1 ?
                  { alignment: { wrapText: false, readingOrder: 2 } }
                  :
                  { fill: { patternType: "solid", fgColor: { rgb: "A9A9A9" } }, alignment: { wrapText: false, readingOrder: 2 } }

              }
            });

            let body = data.data.map((d, inx) => {
              let row = this.props.excelHeader?.map((col, i) => {
                if (i != this.props.excelHeader.length - 1)
                  return {
                    value: d[col.backName] ? d[col.backName] : '',
                    style:
                      inx % 2 != 0 ?
                        {
                          alignment: { wrapText: false, readingOrder: 2 },
                          fill: { patternType: "solid", fgColor: { rgb: "DCDCDC" } }
                        }
                        :
                        { alignment: { wrapText: false, readingOrder: 2 }, }
                  }
              });
              return row;
            });

            let pretable = [];
            if (this.props.preTableFields) {
              for (const pre of this.props.preTableFields) {
                pretable.push({ title: pre.name, width: { wch: 10 } })
                pretable.push({ title: data[pre.backName] ? data[pre.backName].toString() : '', width: { wch: 10 } })
              }
            }
            if (this.props.period) {
              pretable.push({ title: "الفترة من:", width: { wch: 10 } })
              pretable.push({ title: this.props.excelData[0].from ? this.props.excelData[0].from.toString() : '', width: { wch: 10 } })
              pretable.push({ title: "إلى:", width: { wch: 10 } })
              pretable.push({ title: this.props.excelData[0].to ? this.props.excelData[0].to.toString() : '', width: { wch: 10 } })

            }


            multiDataSet.push({
              xSteps: 1,
              ySteps: 5,
              columns: pretable,
              data: []
            });
            if (this.props.excelHeader && pretable) {
              if (this.props.excelHeader.length < pretable.length) {
                let loops = this.props.excelHeader.length / pretable.length
                for (let x = 1; x <= Math.round(loops); x++) {
                  let pretable2 = []
                  let index = (this.props.excelHeader.length - 1) * x
                  for (let i = index; i < pretable.length; i++) {
                    pretable2.push(pretable[i])
                  }
                  multiDataSet.push({
                    xSteps: 1,
                    ySteps: 1,
                    columns: pretable2,
                    data: []
                  });
                }
              }
            }
            multiDataSet.push({
              xSteps: 1,
              ySteps: 1,
              columns: columns,
              data: body
            });
          }
        }

      }

    }


    return (
      <header className="PageHeader--">
        <header className="row w-100 m-auto">
          <nav className="TreeNav-- TreeNav--ar  col-md-6">
            <h3 className="page-title">{this.props.mainTitle}</h3>
            {this.props.Path}
            <p className="TreeNav-- active">{this.props.title}</p>

          </nav>
          {this.props.nobtn ?
            null
            :
            <div className="action-buttons pl-0 col-md-6">
              {this.props.hideFilters && this.props.showFilters ?
                <div className="show-hide-filters">
                  <Button
                    id="hideFiltersButton"
                    className="btn gray move-up"
                    onClick={this.props.hideFilters}
                  >
                    <i className="fa fa-angle-up"></i>
                  إخفاء البحث
                </Button>
                  <Button
                    id="showFiltersButton"
                    className="btn gray move-down d-none"
                    onClick={this.props.showFilters}
                  >
                    <i className="fa fa-angle-down"></i>
                  إظهار البحث
                </Button>
                </div>
                :
                null
              }

              {
                this.props.report ?

                  this.props.print ?
                    <Dropdown className="">
                      <Dropdown.Toggle variant="link" className='btn add flashing' id="dropdown">
                        <span>تصدير </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="text-center">
                        <Dropdown.Item href="#">
                          <ExcelFile filename={this.props.title} element={<button className="">Excel</button>}>
                            <ExcelSheet dataSet={multiDataSet} name={this.props.title} />
                          </ExcelFile>


                        </Dropdown.Item>
                        <Dropdown.Item href="">
                          <Button

                            className=""
                            onClick={() => this.props.printPDF()}

                          >
                            PDF
                    </Button> </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    :
                    null
                  :
                  this.props.procedure ?
                    <Button
                      className="btn delete flashing"
                      onClick={() => this.props.Action()}
                      btnClr={this.props.btnClr}
                    >
                      {this.props.btnTxt ? this.props.btnTxt : "Button Name"}
                    </Button>
                    :
                    this.props.save ?
                      <Button
                        className="btn active flashing"
                        onClick={() => this.props.Action()}
                      >
                        حفظ
                </Button>
                      :
                      this.props.noCreate ?
                        null
                        :
                        this.props.routOut ?
                          <Link to="/Ar/TAW/Main/AddEmployee" className="btn add flashing" >

                            <i className="fa">+ </i> إضافة {/* if you remove className="fa" animation will not work, and I don't know why */}
                          </Link>
                          :
                          <Button
                            className="btn add flashing"
                            onClick={() => this.props.showModal(this.props.useSearchModal ? 'search' : "add")}
                          >
                            <i className="fa">+ </i>إضافة {/* if you remove className="fa" animation will not work, and I don't know why */}
                          </Button>
              }
            </div>}
        </header>
      </header >
    )
  }
}
export default PageHeaderAr;