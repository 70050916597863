/* eslint-disable eqeqeq */
import React from 'react';
import './WorkingTime.css';
import TableSpan from '../TableSpan/TableSpan';
import Tabs from '../Tabs/Tabs';
import Modal from '../Modal/Modal';
import Table from '../Table/Table';
import Overlay from '../Overlay/Overlay';
import Cookie from "js-cookie";
import Loading from '../Loading/Loading';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import TextInput from '../TextInput/TextInput';
import Alert from './../Alert/Alert';
import PageHeader from '../PageHeader/PageHeader';
import { Link } from "react-router-dom";
import TimeField from 'react-simple-timefield';


class WorkingHours extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      readEntity: props.read.body ? props.read.body : props.read.path,
      updateEntity: props.update.body,
      updateErrors: [],
      edit: false,
      loading: false,
      data: [],
      message: { text: '', style: '' },
      shiftType: 1,
      dayId: ''
    }
  }

  showModal(modalName) {
    // const sideNav = document.querySelector("#sideNav");
    const mainLayout = document.querySelector("#mainLayout");
    this.setState({ [modalName]: true });
    // sideNav.style.overflow = "visible";
    mainLayout.style.overflow = "visible";
  }

  hideModal(modalName) {
    // const sideNav = document.querySelector("#sideNav");
    const mainLayout = document.querySelector("#mainLayout");
    this.setState({ [modalName]: false });
    // sideNav.style.overflow = "auto";
    // mainLayout.style.overflow = "auto";
    this.setState({ updateErrors: [] });
  }

  componentDidMount() {
    if (this.props.permissions !== null) {
      this.Read(this.state.readEntity);
    } else {
      let me = this;
      setTimeout(function () {
        me.componentDidMount();
      }, 1000);
    }
  }

  ChangeEntityState(state, name, value) {
    let tempEntity = this.state[state];
    tempEntity[name] = value;
    this.setState({ [state]: tempEntity });
  }

  Fetch(entity, token, operation, then) {
    fetch(
      this.props[operation].path ?
        this.props[operation].url + '/' + Object.keys(entity).map((key) => entity[key]).join('/')
        :
        this.props[operation].query ?
          this.props[operation].url + '?' + Object.keys(entity).map((key) => key + '=' + entity[key]).join('&')
          :
          this.props[operation].url, {
      method: this.props[operation].verb,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: this.props[operation].body ? JSON.stringify(entity) : null
    })
      .then(response => response.json())
      .then((response) => {
        let me = this;
        then(response, me);
      })
      .catch(error => console.error('Unable to ' + operation + '.', error));
  }

  Read(entity) {
    if (this.props.permissions?.data.find(permission => permission.nameEn === 'Get')) {
      this.setState({ loading: true });
      let token = Cookie.get('token');
      console.log(Cookie.get('token'));
      let then = (response, me) => {
        if (response.status === 200 || response.success) {
          if (response.success) {
            me.setState(
              {
                data: response.data.data.map((row) => {
                  for (const key in me.props.params) {
                    row[key] = me.props.params[key];
                  }
                  return row;
                }).slice(),
                loading: false
              }
            );
          } else {
            me.setState({ message: { text: response.message, style: 'danger' }, loading: false });
            setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
          }
        } else {
          if (response.message) {
            me.setState({ message: { text: response.message, style: 'danger' }, loading: false });
            setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
          }
          console.error(response);
        }
      }
      this.Fetch(entity, token, 'read', then);
    }
  }
  stringToTime(strTime) {
    var time = strTime.split(':');
    var sec = Math.ceil(parseFloat(time[1]) * 5 / 3);
    time = time[0] + '.' + sec;
    time = parseFloat(time);
    return time
  }

  Update(entity) {
    let errors = [];

    if (this.props.open === 'false') {
      for (const shift of entity.periodShiftParameterList) {
        if (!shift.inTime && shift.outTime) {
          errors.push("Shift " + shift.seq + " Attendance Field is Required.");
        } else if (shift.inTime && !shift.outTime) {
          errors.push("Shift " + shift.seq + " Leave Field is Required.");
        }
      }
      if (entity.periodShiftParameterList.every(shift => !shift.inTime && !shift.outTime) && !entity.isVacation) {
        errors.push("At Least one Shift is Required.");
      }

    } else {

      entity.totalDayHours = this.stringToTime(entity.totalDayHours);

    }

    this.setState({ updateErrors: errors });

    if (errors.length === 0) {
      this.setState({ loading: true });
      let token = Cookie.get('token');
      let then = (response, me) => {
        if (response.status === 200 || response.success) {
          if (response.success) {
            me.hideModal('edit');
            me.setState({ message: { text: response.message, style: 'success' }, loading: false });
            setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
            me.Read(me.state.readEntity);
          } else {
            me.hideModal('edit');
            me.setState({ message: { text: response.message, style: 'danger' }, loading: false });
            setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
          }
        } else {
          if (response.message) {
            errors.push(response.message);
            me.setState({ updateErrors: errors, loading: false });
          }
          console.error(response);
        }
      }
      this.Fetch(entity, token, 'update', then);
    }
  }

  GetForUpdate(firstCell) {
    let Days = ['Mon.', 'Tue.', 'Wed.', 'Thu.', 'Fri.', 'Sat', 'Sun'];
    let dayId = Days.findIndex(day => day === firstCell) + 1;
    let index = this.state.data.findIndex(day => day.dayId === dayId);
    let tempData = this.state.data.slice();
    let shiftDay = Object.assign({}, tempData[index]);
    let tempEntity = Object.assign({}, this.state.updateEntity, {
      id: shiftDay.shiftDayId,
      dayId: dayId,
      shiftType: this.state.shiftType,
      isVacation: shiftDay.isVacation,
      periodShiftParameterList: shiftDay.periodShiftTimes.map(shift => {
        for (let prop in shift) {
          if (shift[prop] === null) {
            shift[prop] = '';
          }
        }
        return shift;
      }).slice()
    })


    if (this.props.open === 'true') {
      tempEntity = Object.assign({}, tempEntity, { totalDayHours: shiftDay.totalDayHours });
    }

    this.setState({ dayId: dayId, updateEntity: Object.assign({}, tempEntity) });
  }
  OnTimeChange(seq, type, value) {
    let tempEntity = Object.assign({}, this.state.updateEntity);
    let tempShifts = tempEntity.periodShiftParameterList.slice();
    tempShifts[tempEntity.periodShiftParameterList.findIndex(shift => shift.seq === seq)] = Object.assign({},
      tempShifts[tempEntity.periodShiftParameterList.findIndex(shift => shift.seq === seq)], { [type]: value });
    tempEntity = Object.assign({}, tempEntity, { periodShiftParameterList: tempShifts.slice() });
    this.setState({ updateEntity: Object.assign({}, tempEntity) });

  }

  render() {
    let Days = ['Mon.', 'Tue.', 'Wed.', 'Thu.', 'Fri.', 'Sat', 'Sun'];

    let UpdateEntityProperties = [
      <div className={`Modal--field`} key={'isVacation'}>
        {/* <label className="Modal--field" htmlFor="">Weekend</label> */}
        {/* <input
          type="checkbox"
          checked={this.state.updateEntity.isVacation}
          onChange={(e) => {
            this.ChangeEntityState('updateEntity', 'isVacation', e.target.checked)
          }}
        /> */}
        <ToggleSwitch
          className="ToggleSwitch"
          value={this.state.updateEntity.isVacation}
          onChange={(name, value) => {
            if (this.props.open === 'false') {
              if (value) {
                let tempShifts = this.state.updateEntity.periodShiftParameterList.slice();
                tempShifts = tempShifts.map(shift => {
                  for (let prop in shift) {
                    if (prop === 'inTime' || prop === 'outTime' || prop === 'startIn' || prop === 'endIn' || prop === 'startOut' || prop === 'endOut' || prop === 'availableEarlyTime' || prop === 'availableLateTime') {
                      shift = Object.assign({}, shift, { [prop]: "" });
                    }
                  }
                  return shift;
                }).slice();

                this.ChangeEntityState('updateEntity', 'periodShiftParameterList', tempShifts);
              }
            } else {
              if (value) {
                this.ChangeEntityState('updateEntity', 'totalDayHours', '00:00')
              }
            }
            this.ChangeEntityState('updateEntity', 'isVacation', value);
          }}
          onText="Weekend"
          offText="Work"
          height={30}
          width={120}
          uncheckedIconWidth="80px"
          uncheckedIconMarginLeft="-42px"
          checkedIconMarginLeft="40px"
        />
      </div>,
      // <div className={`Modal--field`}>
      //   <label className="readOnly">
      //     <p>
      //       Working Hours Total:
      // </p>
      //     <TextInput
      //       className="readOnly-TextInput"
      //       value="20"
      //       disabled
      //       onChange={() => null}
      //     />
      //   </label>
      // </div>,
      this.props.open === 'false' ?
        <div className={`Modal--field double`} key={'Table'}>
          <Table
            TableHeaders={[
              <th key={''}></th>,
              <th key={'inStart'}>Att. Start</th>,
              <th key={'in'}>Attendance</th>,
              <th key={'delay'}>Allowed Delay</th>,
              <th key={'inEnd'}>Att. End</th>,
              <th key={'outStart'}>Leave Start</th>,
              <th key={'Early'}>Early Leave</th>,
              <th key={'out'}>Leave</th>,
              <th key={'outEnd'}>Leave End</th>
            ]}
            data={[
              <tr key={1}>
                <td>Shift 1</td>
                <td>
                  <TimeField
                    
                    style={{ width: '75px' ,textAlign:'center' }}
                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 1).startIn}
                    onChange={(e, value) => { this.OnTimeChange(1, 'startIn', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />

                </td>
                <td>
                  <TimeField

                    
                    style={{ width: '75px' ,textAlign:'center' }}

                    step={1}
                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 1).inTime}
                    onChange={(e, value) => { this.OnTimeChange(1, 'inTime', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
                <td>
                  <TimeField

                    
                    style={{ width: '75px' ,textAlign:'center' }}

                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 1).availableLateTime}
                    onChange={(e, value) => { this.OnTimeChange(1, 'availableLateTime', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
                <td>
                  <TimeField

                    style={{ width: '75px' ,textAlign:'center' }}

                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 1).endIn}
                    onChange={(e, value) => { this.OnTimeChange(1, 'endIn', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
                <td>
                  <TimeField

                    
                    style={{ width: '75px' ,textAlign:'center' }}

                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 1).startOut}
                    onChange={(e, value) => { this.OnTimeChange(1, 'startOut', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
                <td>
                  <TimeField

                    
                    style={{ width: '75px' ,textAlign:'center' }}

                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 1).availableEarlyTime}
                    onChange={(e, value) => { this.OnTimeChange(1, 'availableEarlyTime', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
                <td>
                  <TimeField

                    
                    style={{ width: '75px' ,textAlign:'center' }}


                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 1).outTime}
                    onChange={(e, value) => { this.OnTimeChange(1, 'outTime', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
                <td>
                  <TimeField
                    style={{ width: '75px' ,textAlign:'center' }}

                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 1).endOut}
                    onChange={(e, value) => { this.OnTimeChange(1, 'endOut', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
              </tr>,
              <tr key={2}>
                <td>Shift 2</td>
                <td>
                  <TimeField
                
                    style={{ width: '75px' ,textAlign:'center' }}

                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 2).startIn}
                    onChange={(e, value) => { this.OnTimeChange(2, 'startIn', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
                <td>
                  <TimeField                  
                    style={{ width: '75px' ,textAlign:'center' }}
                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 2).inTime}
                    onChange={(e, value) => { this.OnTimeChange(2, 'inTime', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
                <td>
                  <TimeField                  
                    style={{ width: '75px' ,textAlign:'center' }}

                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 2).availableLateTime}
                    onChange={(e, value) => { this.OnTimeChange(2, 'availableLateTime', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
                <td>
                  <TimeField
                    
                    style={{ width: '75px' ,textAlign:'center' }}

                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 2).endIn}
                    onChange={(e, value) => { this.OnTimeChange(2, 'endIn', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
                <td>
                  <TimeField
                    
                    style={{ width: '75px' ,textAlign:'center' }}

                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 2).startOut}
                    onChange={(e, value) => { this.OnTimeChange(2, 'startOut', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
                <td>
                  <TimeField
                    
                    style={{ width: '75px' ,textAlign:'center' }}

                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 2).availableEarlyTime}
                    onChange={(e, value) => { this.OnTimeChange(2, 'availableEarlyTime', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
                <td>
                  <TimeField
                    
                    style={{ width: '75px' ,textAlign:'center' }}

                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 2).outTime}
                    onChange={(e, value) => { this.OnTimeChange(2, 'outTime', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
                <td>
                  <TimeField
                    
                    style={{ width: '75px' ,textAlign:'center' }}

                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 2).endOut}
                    onChange={(e, value) => { this.OnTimeChange(2, 'endOut', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
              </tr>,
              <tr key={3}>
                <td>Shift 3</td>
                <td>
                  <TimeField                    
                    style={{ width: '75px' ,textAlign:'center' }}

                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 3).startIn}
                    onChange={(e, value) => { this.OnTimeChange(3, 'startIn', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
                <td>
                  <TimeField                    
                    style={{ width: '75px' ,textAlign:'center' }}

                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 3).inTime}
                    onChange={(e, value) => { this.OnTimeChange(3, 'inTime', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
                <td>
                  <TimeField
                    
                    style={{ width: '75px' ,textAlign:'center' }}

                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 3).availableLateTime}
                    onChange={(e, value) => { this.OnTimeChange(3, 'availableLateTime', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
                <td>
                  <TimeField                    
                    style={{ width: '75px' ,textAlign:'center' }}
                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 3).endIn}
                    onChange={(e, value) => { this.OnTimeChange(3, 'endIn', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
                <td>
                  <TimeField
                 
                    style={{ width: '75px' ,textAlign:'center' }}

                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 3).startOut}
                    onChange={(e, value) => { this.OnTimeChange(3, 'startOut', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
                <td>
                  <TimeField                   
                    style={{ width: '75px' ,textAlign:'center' }}

                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 3).availableEarlyTime}
                    onChange={(e, value) => { this.OnTimeChange(3, 'availableEarlyTime', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
                <td>
                  <TimeField                    
                    style={{ width: '75px' ,textAlign:'center' }}

                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 3).outTime}
                    onChange={(e, value) => { this.OnTimeChange(3, 'outTime', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
                <td>
                  <TimeField                    
                    style={{ width: '75px' ,textAlign:'center' }}

                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 3).endOut}
                    onChange={(e, value) => { this.OnTimeChange(3, 'endOut', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
              </tr>,
              <tr key={4}>
                <td>Shift 4</td>
                <td>
                  <TimeField
                    
                    style={{ width: '75px' ,textAlign:'center' }}
                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 4).startIn}
                    onChange={(e, value) => { this.OnTimeChange(4, 'startIn', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
                <td>
                  <TimeField
                    
                    style={{ width: '75px' ,textAlign:'center' }}

                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 4).inTime}
                    onChange={(e, value) => { this.OnTimeChange(4, 'inTime', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
                <td>
                  <TimeField
                    
                    style={{ width: '75px' ,textAlign:'center' }}

                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 4).availableLateTime}
                    onChange={(e, value) => { this.OnTimeChange(4, 'availableLateTime', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
                <td>
                  <TimeField
                    
                    style={{ width: '75px' ,textAlign:'center' }}

                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 4).endIn}
                    onChange={(e, value) => { this.OnTimeChange(4, 'endIn', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
                <td>
                  <TimeField
                    style={{ width: '75px' ,textAlign:'center' }}

                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 4).startOut}
                    onChange={(e, value) => { this.OnTimeChange(4, 'startOut', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
                <td>
                  <TimeField
                    
                    style={{ width: '75px' ,textAlign:'center' }}

                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 4).availableEarlyTime}
                    onChange={(e, value) => { this.OnTimeChange(4, 'availableEarlyTime', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
                <td>
                  <TimeField
                    
                    style={{ width: '75px' ,textAlign:'center' }}

                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 4).outTime}
                    onChange={(e, value) => { this.OnTimeChange(4, 'outTime', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
                <td>
                  <TimeField
                    
                    style={{ width: '75px' ,textAlign:'center' }}
                    value={this.state.updateEntity.periodShiftParameterList.find(day => day.seq === 4).endOut}
                    onChange={(e, value) => { this.OnTimeChange(4, 'endOut', value) }}
                    disabled={this.state.updateEntity.isVacation}
                  />
                </td>
              </tr>
            ]}
          />
        </div>
        :
        <div className={`Modal--field`} key={'Hours'}>
          <label className="Modal--field" htmlFor="">Hours <span style={{color:"red"}}>*</span></label>
          <TextInput
            type="time"
            value={this.state.updateEntity.totalDayHours}
            onChange={(value) => this.ChangeEntityState('updateEntity', 'totalDayHours', value)}
            disabled={this.state.updateEntity.isVacation}
          />
        </div>
    ]
    // this.state.data.sort((a, b) => a.dayId - b.dayId);
    let Path = this.props.path.map((folder) => <Link className="TreeNav--" to={folder.path} key={folder.title}>{folder.title}</Link>);

    return (
      <>
        <Overlay visible={this.state.edit}>
          <Modal
            title={Days[this.state.updateEntity.dayId - 1]}
            action="edit"
            className={this.props.open !== 'true' ? "table" : ''}
            visible={this.state.edit}
            hideModal={(name) => this.hideModal(name)}
            EntityProperties={UpdateEntityProperties}
            errors={this.state.updateErrors}
            fetch={() => this.Update(this.state.updateEntity)}
            updateAll={() => {
              let entity = Object.assign({}, this.state.updateEntity);
              entity.dayId = null;
              this.Update(entity);
            }}
          />
        </Overlay>

        <Overlay visible={this.state.loading}>
          <Loading visible={this.state.loading} />
        </Overlay>

        <div className="MainLayout--container">
          {
            this.state.message['text'] ?
              <Alert
                className={"Alert " + this.state.message['style']}
              >
                {this.state.message['text']}
              </Alert>
              :
              null
          }
          <PageHeader
            Path={Path}
            mainTitle={this.props.mainTitle}
            title={this.props.title}
            noCreate={true}
          />
          <Tabs
            selected={this.state.shiftType}
            onChange={(value) => {
              let tempEntity = this.state.readEntity;
              tempEntity.shiftType = value;
              this.Read(tempEntity);
              this.setState({ shiftType: value, readEntity: tempEntity });
            }}
          />
          <TableSpan
            open={this.props.open}
            data={this.state.data.slice()}
            onEditClick={(e) => {
              this.setState({ edit: true });
              this.GetForUpdate(e.currentTarget.parentElement.parentElement.children[0].innerHTML);
            }}
            noEdit={!this.props.permissions?.data.find(permission => permission.nameEn === 'Edite ') ? true : false}
          />
        </div>
      </>
    )
  }
}

export default WorkingHours;