/* eslint-disable eqeqeq */
import React from 'react';
import './Exceptions.css';
import Dropdown from '../Dropdown/Dropdown';
import TextInput from '../TextInput/TextInput';
import Table from './../Table/Table';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import Cookie from "js-cookie";
import Overlay from '../Overlay/Overlay';
import Loading from '../Loading/Loading';
import Field from '../Field/Field';
import { ip } from './../../data/APIs';

class SelectShiftAr extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      updated: false,
      prevValues: null,
      counter: 0
    }
  }

  Fetch(entity, token, operation, then) {
    fetch(ip + '/api/AskPermission/SearchPeriodShiftTimeForEmployee', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(entity)
    })
      .then(response => response.json())
      .then((response) => {
        let me = this;
        then(response, me);
      })
      .catch(error => console.error('Unable to ' + operation + '.', error));
  }

  Read(entity) {
    this.setState({ loading: true });
    let token = Cookie.get('token');
    console.log(Cookie.get('token'));
    let then = (response, me) => {
      if (response.status === 200 || response.success) {
        if (response.success) {
          me.setState(
            {
              data: response.data.data,
              loading: false,
              updated: true
            }
          );
          me.props.parent.setState({ shiftsData: response.data.data })
        } else {
          me.setState(
            {
              data: [],
              loading: false,
              updated: true
            }
          );
          console.error(response);
        }
      } else if (response.status === 501) {
        me.setState(
          {
            data: response.message,
            loading: false,
            updated: true
          }
        );
        me.props.onChange(me.props.backNames[2], 'open')
        console.error(response);
      } else {
        me.setState(
          {
            data: [],
            loading: false,
            updated: true
          }
        );
        console.error(response);
      }
    }
    this.Fetch(entity, token, 'read', then);
  }

  static getDerivedStateFromProps(props, state) {
    // Store prevValues in state so we can compare when props change.   
    // Clear out previously-loaded data (so we don't render stale stuff).
    if (state.prevValues) {
      if (props.values[0] && props.values[1]) {
        if (props.values[0] !== state.prevValues[0] || props.values[1] !== state.prevValues[1]) {
          // if (props.values[2] != '') {
          //   props.onChange(props.backNames[2], '')
          // }
          return { data: null, prevValues: props.values };
        }
      } else {
        if (props.values[2].periodShiftId != '') {
          props.onChange('periodShiftId', '')
        }
        if (props.values[2].periodShiftSecondId != '') {
          props.onChange('periodShiftSecondId', '')
        }
        if (props.values[2].periodShiftThirdId != '') {
          props.onChange('periodShiftThirdId', '')
        }
        if (props.values[2].periodShiftFourthId != '') {
          props.onChange('periodShiftFourthId', '')
        }
        return { data: [], prevValues: props.values };
      }
    } else {
      return { data: null, prevValues: props.values };
    }
    // No state update necessary    
    return null;
  }

  componentDidUpdate() {
    if (this.state.data === null && !this.state.loading) {
      let entity = {
        "type": 1,
        "value": this.props.values[0].toString(),
        "selectedDate": this.props.values[1]
      };
      this.Read(entity);
    }
  }

  render() {
    let tableRows = [];
    if (this.state.data) {
      if (Array.isArray(this.state.data)) {
        this.state.data.sort((a, b) => a.sequence - b.sequence);
        tableRows = this.state.data.map(row => <tr key={row.sequence}>
          <td>
            {row.inTime ?
              <ToggleSwitch
                value={
                  row.sequence == 1 ?
                    (this.props.values[2].periodShiftId == row.periodShiftId ? true : false)
                    :
                    row.sequence == 2 ?
                      (this.props.values[2].periodShiftSecondId == row.periodShiftId ? true : false)
                      :
                      row.sequence == 3 ?
                        (this.props.values[2].periodShiftThirdId == row.periodShiftId ? true : false)
                        :
                        row.sequence == 4 ?
                          (this.props.values[2].periodShiftFourthId == row.periodShiftId ? true : false)
                          : false
                       
                }
                backName={this.props.backNames[2]}
                onChange={(name, value) => {
                  if (value) {
                    switch (row.sequence) {
                      case 1:
                        this.props.onChange('periodShiftId', row.periodShiftId)
                        this.setState({counter : this.state.counter+=1})
                        break;
                      case 2:
                        this.props.onChange('periodShiftSecondId', row.periodShiftId)
                        this.setState({counter : this.state.counter+=1})
                        break;
                      case 3:
                        this.props.onChange('periodShiftThirdId', row.periodShiftId)
                        this.setState({counter : this.state.counter+=1})
                        break;
                      case 4:
                        this.props.onChange('periodShiftFourthId', row.periodShiftId)
                        this.setState({counter : this.state.counter+=1})
                        break;
  
                      default:
                        break;
                    }
                  } else {
                    switch (row.sequence) {
                      case 1:
                        this.props.onChange('periodShiftId', '')
                        this.setState({counter : this.state.counter-=1})
                        break;
                      case 2:
                        this.props.onChange('periodShiftSecondId', '')
                        this.setState({counter : this.state.counter-=1})
                        break;
                      case 3:
                        this.props.onChange('periodShiftThirdId', '')
                        this.setState({counter : this.state.counter-=1})
                        break;
                      case 4:
                        this.props.onChange('periodShiftFourthId', '')
                        this.setState({counter : this.state.counter-=1})
                        break;
  
                      default:
                        break;
                    }
                  }
                  
                  // this.props.onChange(this.props.backNames[2], row.periodShiftId)
       
                }}
                width={50}
                height={25}
              />
              :
              <ToggleSwitch
                value={
                  row.sequence == 1 ?
                    (this.props.values[2].periodShiftId == row.periodShiftId ? true : false)
                    :
                    row.sequence == 2 ?
                      (this.props.values[2].periodShiftSecondId == row.periodShiftId ? true : false)
                      :
                      row.sequence == 3 ?
                        (this.props.values[2].periodShiftThirdId == row.periodShiftId ? true : false)
                        :
                        row.sequence == 4 ?
                          (this.props.values[2].periodShiftFourthId == row.periodShiftId ? true : false)
                          : false
                }
                backName={this.props.backNames[2]}
                width={50}
                height={25}
              />}
          </td>
          <td>{row.sequence}</td>
          <td>{row.inTime}</td>
          <td>{row.outTime}</td>
        </tr>);
      } else {
        tableRows = [<tr key={'open'}>
          <td colSpan={4}>
            {this.state.data}
          </td>
        </tr>];
      }
    }

    return (
      <>
        <Overlay visible={this.state.loading}>
          <Loading visible={this.state.loading} />
        </Overlay>

        <div style={{ border: '0px' }}
          //  className="Exceptions--"
          className=" row"
        >
          <div
            className="col-md-6"
          //  className='Exceptions--exception'
          >
            <Field
              title={["الموظف", <span style={{ color: "red" }}>*</span>, ":"]}
              style={'col-lg-12'}
              inputComponent={[
                <Dropdown
                  rtl={true}
                  placeholder={`الموظف`}
                  value={this.props.items.find(item => item.value === this.props.values[0])}
                  options={this.props.items}
                  onChange={(value) => this.props.onChange(this.props.backNames[0], value)}
                />
              ]}
            />
          </div>
          <div className="col-md-6"
          // className='Exceptions--exception'
          >
            <Field
              title={["التاريخ", <span style={{ color: "red" }}>*</span>, ":"]}
              style={'col-lg-12'}
              inputComponent={[
                <TextInput
                  placeholder={`Word or some of it`}
                  type="date"
                  value={this.props.values[1]}
                  onChange={(value) => this.props.onChange(this.props.backNames[1], value)}
                />
              ]}
            />
          </div>
          <Table
            TableHeaders={
              [
                <th key={'radio'}></th>,
                <th key={'shift'}>الوردية</th>,
                <th key={'in'}>وقت الحضور</th>,
                <th key={'out'}>وقت الانصراف</th>
              ]
            }
            data={tableRows}
          />
        </div>
      </>
    )
  }
}

export default SelectShiftAr;
