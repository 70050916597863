import React, { I18nManager } from 'react';
import './DashBoard.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faDotCircle, faGlobe
} from "@fortawesome/free-solid-svg-icons";
import Moment from 'react-moment';
// import StackedColumnChart from '../../column-charts/Stacked Column Chart';
// import StackedColumnChartAr from '../../column-charts/Stacked Column ChartAr';
import $ from 'jquery';
import avatar from '../../avatar.png';
import { ip } from './../../data/APIs';
import Cookie from "js-cookie";
import Overlay from '../Overlay/Overlay';
import ModalAr from './../ModalAr/ModalAr';
import Modal from './../Modal/Modal';
import Chart from "react-google-charts";
import Loading from '../Loading/Loading';

function upLayer() {
  const mainLayout = document.querySelector("#mainLayout");
  mainLayout.style.zIndex = "";
}

function downLayer() {
  const mainLayout = document.querySelector("#mainLayout");
  mainLayout.style.zIndex = "-1";
}
let sourceCode;

class DashBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      delay: '',
      absence: '',
      attendance: '',
      vacations: '',
      display: false,
      modalData: [],
      modalColumns: [],
      modalTitle: '',
      today: '',
      chartabsence: '',
      TodayTransactions: [],
      todayAr: ' ',
      totalsData: '',
      chartData: [],
      loading: false,
      modalMessage: '',
      modalAction: 'display'
    }
    // let newDate = new Date();
    // let date = newDate.toLocaleDateString('ar-EG-u-nu-latn',{weekday: 'long', year: 'numeric', month: 'short', day: 'numeric'});
    // this.setState({todayAr : date})

  }

  showModal(modalName) {
    // const sideNav = document.querySelector("#sideNav");
    const mainLayout = document.querySelector("#mainLayout");
    this.setState({ [modalName]: true });
    // sideNav.style.overflow = "visible";
    mainLayout.style.overflow = "visible";
  }

  hideModal(modalName) {
    // const sideNav = document.querySelector("#sideNav");
    // this.setState({modalData:[]});
    const mainLayout = document.querySelector("#mainLayout");
    this.setState({ [modalName]: false });
    // sideNav.style.overflow = "auto";
    // mainLayout.style.overflow = "auto";

    // return the Modal elements to the default State
    if (modalName === 'add') {
      const entity = this.state.createEntity;
      for (let field of this.props.createEntityProps) {
        if (field.type === 'text' || field.type === 'email' || field.type === 'select' || field.type === 'date' || field.type === 'time' || field.type === 'password') {
          entity[field.backName] = '';
        }
        if (field.type === 'checkbox') {
          if (field.label === 'نشط') {
            entity[field.backName] = true;
          } else {
            entity[field.backName] = false;
          }
        }
        if (field.type === 'shiftEndNextDay') {
          entity[field.backName[0]] = false;
          entity[field.backName[1]] = '';
        }
        if (field.type === 'exceptions') {
          let backNames = [];
          for (const backName of field.backName) {
            backNames.push(backName);
          }
          for (const backName of backNames) {
            entity[backName] = false;
          }
        }
        if (field.type === 'selectShift') {
          let backNames = [];
          for (const backName of field.backName) {
            backNames.push(backName);
          }
          for (const backName of backNames) {
            entity[backName] = '';
          }
        }
        if (field.type === 'permissionType') {
          let backNames = [];
          for (const backName of field.backName) {
            backNames.push(backName);
          }
          for (const backName of backNames) {
            entity[backName] = '';
          }
        }
      }
      this.setState({ createEntity: entity, createErrors: [] })
    } else if (modalName === 'search') {
      const entity = this.state.createEntity;
      entity[this.props.modalBackName] = [];
      this.setState({ createEntity: entity, createErrors: [] })
    } else if (modalName === 'edit') {
      const entity = this.state.updateEntity;
      for (let field of this.props.createEntityProps) {
        if (field.type === 'text' || field.type === 'email' || field.type === 'select' || field.type === 'date' || field.type === 'time' || field.type === 'password') {
          entity[field.backName] = '';
        }
        if (field.type === 'checkbox') {
          entity[field.backName] = true;
        }
        if (field.type === 'shiftEndNextDay') {
          entity[field.backName[0]] = false;
          entity[field.backName[1]] = '';
        }
      }
      this.setState({ updateEntity: entity, updateErrors: [] })
    }
  }

  componentDidMount() {
    const signalR = require("@microsoft/signalr");
    let me = this;
    this.connection = new signalR.HubConnectionBuilder().withUrl(ip + "/TransactionsHub").build();

    this.connection.on("ReceiveTransaction", function (transaction) {

      let lang;
      if (window.location.hash.indexOf("/Ar/") !== -1) {
        lang = 0;
      } else {
        lang = 1;
      }
      transaction.employeeName = transaction.employeeName[lang];
      transaction.branchName = transaction.branchName[lang];
      transaction.shiftName = transaction.shiftName[lang];
      let temp = me.state.TodayTransactions.slice();
      temp.unshift(transaction);
      me.setState({ TodayTransactions: temp })
    });

    this.connection.on("Log", function (message) {
      console.log(message);
    });

    this.connection.start().then(function () {
      me.connection.invoke("AddToGroup", Cookie.get('companyName')).catch(function (err) {
        return console.error(err.toString());
      });
      console.log("Connection Started")
    }).catch(function (err) {
      return console.error(err.toString());
    });
    let token = Cookie.get('token');
    const moment = require('moment');
    let today = moment().format("YYYY-MM-DD");
    fetch(ip + '/api/MovedTransaction/EmployeeCountsDashBoard', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'lang': this.props.lang,
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => response.json())
      .then((data) => {
        if (data.success) {
          this.setState({ totalsData: data.data[0] })
        } else {
          console.error(data);

        }
      })
      .catch(error => console.error('Unable to Check.', error));

    this.EmployeeChartCountsDashBoard();
    fetch(ip + '/api/MovedTransaction/GetAllMachineTransactionsDashBoard', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'lang': this.props.lang,
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "search": {
          "date": today,
          "branchIds": [
          ],
          "employeeIds": [
          ],
          "employeeName": ""
        }
      })
    })
      .then(response => response.json())
      .then((data) => {
        if (data.success) {
          this.setState({ TodayTransactions: data.data })
        } else {
          // console.log("111")

          console.error(data);
        }
      })
      .catch(error => console.error('Unable to Read.', error));

  }
  //Chart data 
  EmployeeChartCountsDashBoard() {

    let token = Cookie.get('token');
    fetch(ip + '/api/MovedTransaction/EmployeeChartCountsDashBoard', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'lang': this.props.lang,
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => response.json())
      .then((data) => {
        if (data.success) {
          this.setState({ chartData: data.data })
          console.log("Chart data ", this.state.chartData)

        } else {
          console.error(data);
        }
      })
      .catch(error => console.error('Unable to Check.', error));
  }


  // totals modal data 
  GetLateEmployeesData() {

    if (this.state.totalsData?.lateTimeCount != 0) {
      let token = Cookie.get('token');
      const moment = require('moment');
      let today = moment().format("YYYY-MM-DD");
      this.setState({ loading: true });

      fetch(ip + '/api/MovedTransaction/GetLateEmployeesData', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'lang': this.props.lang,
          'Authorization': `Bearer ${token}`
        }
      })

        .then(response => response.json())
        .then((data) => {
          if (data.success) {
            this.setState({ delay: data.data[0] })
            this.setState({ loading: false });
            if (this.props.lang == 'en') {
              this.setState({
                display: true, modalAction: "display",modalMessage:null, modalData: this.state.delay?.data, modalTitle: 'Late', modalColumns: [
                  { name: 'Code', backName: 'employeeCode' },
                  { name: 'Name', backName: 'employeeName' },
                  { name: 'Branch', backName: 'branchName' },
                  { name: 'Shift', backName: 'shiftName' },
                  { name: 'Late time ', backName: 'lateTime' },
                  { name: 'Date', backName: 'date' },
                ]
              })
            }
            else {
              this.setState({
                display: true, modalAction: "display",modalMessage:null, modalData: this.state.delay?.data, modalTitle: 'التأخير', modalColumns: [
                  { name: 'الكود', backName: 'employeeCode' },
                  { name: 'الإسم', backName: 'employeeName' },
                  { name: 'الفرع', backName: 'branchName' },
                  { name: 'الدوام', backName: 'shiftName' },
                  { name: 'مدة التأخير', backName: 'lateTime' },
                  { name: 'التاريخ', backName: 'date' },
                ]
              })
            }

          } else {
            console.error(data);
          }
        })
        .catch(error => console.error('Unable to Check.', error));


    }
    else {
      this.setState({
        display: true, modalMessage: this.props.lang == 'ar' ?"لا توجد بيانات" :"No data"
      })
    }

  }
  GetAttendanceDashBoardData() {
    if (this.state.totalsData?.attendanceTimeCount != 0) {
      let token = Cookie.get('token');
      const moment = require('moment');
      let today = moment().format("YYYY-MM-DD");
      this.setState({ loading: true });
      fetch(ip + '/api/MovedTransaction/GetAttendanceDashBoardData?from=' + today + '&to=' + today, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'lang': this.props.lang,
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => response.json())
        .then((data) => {
          if (data.success) {
            this.setState({ attendance: data.data[0] })
            this.setState({ loading: false });

            if (this.props.lang == 'ar') {
              this.setState({
                display: true, modalAction: "display",modalMessage:null, modalData: this.state.attendance?.data, modalTitle: 'الحضور', modalColumns: [
                  { name: 'الكود', backName: 'employeeCode' },
                  { name: 'الإسم', backName: 'employeeName' },
                  { name: 'الفرع', backName: 'branchName' },
                  { name: 'الدوام', backName: 'shiftName' },
                  { name: 'التاريخ', backName: 'date' },
                ]
              })
            }
            else {
              this.setState({
                display: true, modalAction: "display",modalMessage:null, modalData: this.state.attendance?.data, modalTitle: 'Attendance', modalColumns: [
                  { name: 'Code', backName: 'employeeCode' },
                  { name: 'Name', backName: 'employeeName' },
                  { name: 'Branch', backName: 'branchName' },
                  { name: 'Shift', backName: 'shiftName' },
                  { name: 'Date', backName: 'date' },
                ]
              })
            }
          } else {
            console.error(data);
          }
        })
        .catch(error => console.error('Unable to Check.', error));
    }
    else {
      this.setState({
        display: true, modalMessage: this.props.lang == 'ar' ?"لا توجد بيانات" :"No data"
      })
    }
  }
  GetVacationsDashBoardData() {
    if (this.state.totalsData?.vAcationTimeCount != 0) {
      let token = Cookie.get('token');
      const moment = require('moment');
      this.setState({ loading: true });

      fetch(ip + '/api/MovedTransaction/GetVacationsDashBoardData', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'lang': this.props.lang,
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => response.json())
        .then((data) => {
          if (data.success) {
            this.setState({ vacations: data.data[0] })
            this.setState({ loading: false });

            if (this.props.lang == 'ar') {
              this.setState({
                display: true, modalAction: "display",modalMessage:null, modalData: this.state.vacations?.data, modalTitle: 'الاجازات', modalColumns: [
                  { name: 'الكود', backName: 'employeeCode' },
                  { name: 'الإسم', backName: 'employeeName' },
                  { name: 'الفرع', backName: 'branchName' },
                  { name: 'الدوام', backName: 'shiftName' },
                  { name: 'التاريخ', backName: 'date' },
                ]
              })
            }
            else {
              this.setState({
                display: true, modalAction: "display",modalMessage:null, modalData: this.state.vacations?.data, modalTitle: 'vacations', modalColumns: [
                  { name: 'Code', backName: 'employeeCode' },
                  { name: 'Name', backName: 'employeeName' },
                  { name: 'Branch', backName: 'branchName' },
                  { name: 'Shift', backName: 'shiftName' },
                  { name: 'Date', backName: 'date' },
                ]
              })
            }


          } else {
            console.error(data);
          }
        })
        .catch(error => console.error('Unable to Check.', error));
    }
    else {
      this.setState({
        display: true, modalMessage: this.props.lang == 'ar' ?"لا توجد بيانات" :"No data"
      })
    }
  }
  GetAbsentEmployeesData() {
    if (this.state.totalsData?.absentTimeCount != 0) {
      let token = Cookie.get('token');
      const moment = require('moment');
      this.setState({ loading: true });

      fetch(ip + '/api/MovedTransaction/GetAbsentEmployeesData',
        // ?from=' + today + '&to=' + today, 
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'lang': this.props.lang,
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response => response.json())
        .then((data) => {
          if (data.success) {
            this.setState({ absence: data.data[0] })
            this.setState({ loading: false });

            if (this.props.lang == 'ar') {
              this.setState({
                display: true, modalAction: "display",modalMessage:null, modalData: this.state.absence?.data, modalTitle: 'الغياب', modalColumns: [
                  { name: 'الكود', backName: 'employeeCode' },
                  { name: 'الإسم', backName: 'employeeName' },
                  { name: 'الفرع', backName: 'branchName' },
                  { name: 'الدوام', backName: 'shiftName' },
                  { name: 'التاريخ', backName: 'date' },
                ]
              })
            }
            else {
              this.setState({
                display: true, modalAction: "display",modalMessage:null, modalData: this.state.absence?.data, modalTitle: 'Abcence', modalColumns: [
                  { name: 'Code', backName: 'employeeCode' },
                  { name: 'Name', backName: 'employeeName' },
                  { name: 'Branch', backName: 'branchName' },
                  { name: 'Shift name', backName: 'shiftName' },
                  { name: 'Date', backName: 'date' },
                ]
              })
            }


          } else {
            console.error(data);
          }
        })
        .catch(error => console.error('Unable to Check.', error));
    }
    else {
      this.setState({
        display: true, modalMessage: this.props.lang == 'ar' ?"لا توجد بيانات" :"No data"
      })
    }
  }

  componentWillUnmount() {
    // this.connection.invoke("RemoveFromGroup", Cookie.get('companyName')).catch(function (err) {
    //   return console.error(err.toString());
    // });

    this.connection.stop().then(function () {
      // document.getElementById("sendButton").disabled = false;
      console.log("Connection Stopped")
    }).catch(function (err) {
      return console.error(err.toString());
    });
  }

  getPeriodCount(array) {
    const moment = require('moment');
    let myData = [];
    if (this.props.lang === 'ar') {
      myData = [['اليوم',
        'غياب ',
        'تأخير',
        'إنصراف مبكر']];
    }
    else {
      myData = [['Day',
        'Abcent ',
        'Late',
        ' Early leave']];
    }
    for (let i = 0; i < array.length; i++) {
      let label = array[i].date;
      let y1 = array[i].absentTimeCount;
      let y2 = array[i].lateTimeCount;
      let y3 = array[i].earlyLeaveTimeCount;
      myData.push([label, y1, y2, y3]);
    }
    return myData;
  }


  render() {

    if (document.querySelector("#lists")) {
      const lists = document.querySelector("#lists");
      lists.addEventListener("mouseenter", () => { downLayer(); });
      lists.addEventListener("mouseleave", () => { upLayer(); });
    }
    if (this.props.lang == 'ar') {
      require('moment/locale/ar-dz');
    }
    else {
      require('moment/locale/en-au');
    }
    let data = []
    if (this.state.chartData) {
      data = this.getPeriodCount(this.state.chartData);
    }
    if (this.props.lang === 'en') {
      return (
        <div className="DashBoard">
          <Overlay visible={this.state.loading}>
            <Loading visible={this.state.loading} />
          </Overlay>
          <Overlay visible={this.state.display}>
            <Modal
              title={this.state.modalTitle}
              action={this.state.modalAction}
              visible={this.state.display}
              columns={this.state.modalColumns}
              data={this.state.modalData}
              modalMessage={this.state.modalMessage}
              hideModal={(name) => this.hideModal(name)}
            />
          </Overlay>
          {/* {props.children} */}
          <div className="container" >
            <div className="row border-bottom">
              <div className="col-12 d-flex align-items-baseline">
                <p className="gray-color mr-2  my-2"> Welcome </p>
                <FontAwesomeIcon icon={faDotCircle} className="gray-color  m-2" style={{ fontSize: '7px' }}></FontAwesomeIcon>
                <p className="gray-color mr-2  my-2">Administrator</p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 ">
                <Moment local='en-au' format="dddd DD/MM/YYYY" className='gray-color2 font-24 font-weight-light'>
                </Moment>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div class="dashboard-stat blue">
                  <div class="visual">
                    <i class="fa fa-comments"></i>
                  </div>
                  <div class="details">
                    <div class="number">
                      <span data-counter="counterup" data-value="1349">{this.state.totalsData?.lateTimeCount}</span>
                    </div>
                    <div class="desc"> Today Late </div>
                  </div>
                  <a class="more" onClick={() => this.GetLateEmployeesData()}>More
                <i class="m-icon-swapright m-icon-white"></i> </a>

                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div class="dashboard-stat red">
                  <div class="visual">
                    <i class="fa fa-bar-chart-o"></i>
                  </div>
                  <div class="details">
                    <div class="number">
                      <span data-counter="counterup" data-value="1349">{this.state.totalsData?.absentTimeCount}</span>
                    </div>
                    <div class="desc">  Month Absent </div>
                  </div>
                  <a class="more" onClick={() => this.GetAbsentEmployeesData()}> More
                <i class="m-icon-swapright m-icon-white"></i> </a>

                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div class="dashboard-stat green">
                  <div class="visual">
                    <i class="fa fa-shopping-cart"></i>
                  </div>
                  <div class="details">
                    <div class="number">
                      <span data-counter="counterup" data-value="1349">{this.state.totalsData?.attendanceTimeCount}</span>
                    </div>
                    <div class="desc">  Attendance Percentage </div>
                  </div>
                  <a class="more" onClick={() => this.GetAttendanceDashBoardData()}>  More
                <i class="m-icon-swapright m-icon-white"></i> </a>

                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div class="dashboard-stat purple">
                  <div class="visual">
                    <i class="fa fa-globe"></i>
                  </div>
                  <div class="details">
                    <div class="number">
                      <span data-counter="counterup" data-value="1349">{this.state.totalsData?.vAcationTimeCount}</span>
                    </div>
                    <div class="desc">  Month Late  </div>
                  </div>
                  <a class="more" onClick={() => this.GetVacationsDashBoardData()}> More
                <i class="m-icon-swapright m-icon-white"></i> </a>

                </div>
              </div>
            </div>

            <div className="row">
              <div id='table' className="col-md-6 col-sm-6 mt-3">
                <div class="card card-table flex-fill h-100">
                  <div class="card-header">
                    <div class="caption d-flex align-items-center">
                      <FontAwesomeIcon icon={faGlobe} className="font-green-sharp i h5 mb-0 mr-1"></FontAwesomeIcon>
                      <h5 class="card-title mb-0 font-green-sharp bold uppercase mr-2">Day Transactions , </h5>
                      <Moment local format="dd DD/MM/YYYY" className='caption-helper h5 mb-0'>
                      </Moment>
                      <span className="larger" onClick={() => {
                        $('.larger').addClass('d-none');
                        $('.smaller').removeClass('d-none');
                        $('#table').addClass('col-md-12');
                        $('#chartAr').addClass('col-md-12');
                        $('#chartAr').addClass('mt-3');
                      }}>
                        <FontAwesomeIcon icon={faAngleDoubleRight} style={{ fontSize: '20px' }} color="#9eacb4"> </FontAwesomeIcon>
                      </span>
                      <span className="smaller d-none" onClick={() => {
                        $('.larger').removeClass('d-none');
                        $('.smaller').addClass('d-none');
                        $('#table').removeClass('col-md-12');
                        $('#chart').removeClass('col-md-12');
                        $('#chart').removeClass('mt-3');
                      }}>
                        <FontAwesomeIcon icon={faAngleDoubleLeft} style={{ fontSize: '20px' }} color="#9eacb4"> </FontAwesomeIcon>
                      </span>
                    </div>
                  </div>
                  <div class="card-body en">

                    {this.state.TodayTransactions.length !== 0 ?
                      <div class="Table-- table-responsive" style={{ height: "550px" }}>

                        <table class="table custom-table mb-0">
                          <thead class="thead-dark">
                            <tr>
                              <th>Photo</th>
                              <th> Code</th>
                              <th>Name</th>
                              <th>Transaction</th>
                              <th> Branch</th>

                            </tr>
                          </thead>
                          <tbody>

                            {this.state.TodayTransactions.map((row) =>
                              <tr>
                                <td> <img src={row.userImage ? row.userImage : avatar} className='userAvatar' /></td>
                                <td>{row.employeeCode}</td>
                                <td>  {row.employeeName} </td>
                                <td>{row.date}</td>
                                <td>{row.branchName}</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      :
                      <h2> Thair is no Transactions Today </h2>
                    }


                  </div>

                </div>

              </div>
              <div id='chart' className="col-md-6 col-sm-6 mt-3">
                <div class="card card-table flex-fill h-100">
                  <div class="card-header">
                    <div class="caption d-flex align-items-center">
                      <h5 class="card-title -subject bold uppercase"> Last 10 days </h5>

                    </div>
                  </div>
                  <div class="card-body ar" >

                    {data.length > 2 ?
                      <Chart
                        height={600}
                        chartType="AreaChart"
                        loader={<div>Loading Chart</div>}
                        data={data}
                        options={{
                          chartArea: { width: '80%' },
                          legend: "top",
                          hAxis: {
                            title: 'Tootal ',
                            minValue: 0,

                          },
                          vAxis: {
                            title: 'Days',
                          },
                          colors: ['#e7505a', '#3598dc', '#8E44AD']
                        }}
                        legendToggle
                      />
                      :
                      null
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      );
    }
    else if (this.props.lang === 'ar') {

      return (
        <div className="DashBoard text-right">
          <Overlay visible={this.state.loading}>
            <Loading visible={this.state.loading} />
          </Overlay>
          {/* {props.children} */}
          <Overlay visible={this.state.display}>
            <ModalAr
              title={this.state.modalTitle}
              action={this.state.modalAction}
              visible={this.state.display}
              columns={this.state.modalColumns}
              modalMessage={this.state.modalMessage}
              data={this.state.modalData}
              hideModal={(name) => this.hideModal(name)}
            />
          </Overlay>
          <div className="container" >
            <div className="row border-bottom">
              <div className="col-12 d-flex align-items-baseline">
                <p className="gray-color mr-2  my-2"> مرحباً </p>
                <FontAwesomeIcon icon={faDotCircle} className="gray-color  m-2" style={{ fontSize: '7px' }}></FontAwesomeIcon>
                <p className="gray-color mr-2  my-2">مدير النظام</p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 ">
                <Moment local={'ar'} format="dddd DD/MM/YYYY" className='gray-color2 font-24 font-weight-light'>
                </Moment>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div class="dashboard-stat blue">
                  <div class="visual">
                    <i class="fa fa-comments"></i>
                  </div>
                  <div class="details">
                    <div class="number">
                      <span data-counter="counterup" data-value="1349">{this.state.totalsData?.lateTimeCount}</span>
                    </div>
                    <div class="desc">  التأخير </div>
                  </div>
                  <a class="more" onClick={() =>
                    this.GetLateEmployeesData()}> المزيد
                            <i class="m-icon-swapright m-icon-white"></i> </a>

                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div class="dashboard-stat red">
                  <div class="visual">
                    <i class="fa fa-bar-chart-o"></i>
                  </div>
                  <div class="details">
                    <div class="number">
                      <span data-counter="counterup" data-value="1349">{this.state.totalsData?.absentTimeCount}</span>
                    </div>
                    <div class="desc">  الغياب  </div>
                  </div>
                  <a class="more" onClick={() =>
                    this.GetAbsentEmployeesData()
                  }> المزيد
                            <i class="m-icon-swapright m-icon-white"></i> </a>

                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div class="dashboard-stat green">
                  <div class="visual">
                    <i class="fa fa-shopping-cart"></i>
                  </div>
                  <div class="details">
                    <div class="number">
                      <span data-counter="counterup" >{this.state.totalsData?.attendanceTimeCount}</span>
                    </div>
                    <div class="desc">   الحضور </div>
                  </div>
                  <a class="more" onClick={() => this.GetAttendanceDashBoardData()}> المزيد
                            <i class="m-icon-swapright m-icon-white"></i> </a>

                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div class="dashboard-stat purple">
                  <div class="visual">
                    <i class="fa fa-globe"></i>
                  </div>
                  <div class="details">
                    <div class="number">
                      <span data-counter="counterup" data-value="1349">{this.state.totalsData?.vAcationTimeCount}</span>
                    </div>
                    <div class="desc">   الأجازات  </div>
                  </div>
                  <a class="more" onClick={() => this.GetVacationsDashBoardData()}> المزيد
                            <i class="m-icon-swapright m-icon-white"></i> </a>

                </div>
              </div>
            </div>

            <div className="row">
              <div id='tableAr' className="col-md-6 col-sm-6 mt-3">
                <div class="card card-table flex-fill h-100">
                  <div class="card-header">
                    <div class="caption d-flex align-items-center">
                      <FontAwesomeIcon icon={faGlobe} className="font-green-sharp i h5"></FontAwesomeIcon>
                      <h5 class="card-title mb-0 font-green-sharp bold uppercase mr-2">حركات يوم  , </h5>

                      <Moment local={'ar'} format="dddd DD/MM/YYYY" className='caption-helper h5 mb-0'>
                      </Moment>
                      <span className="largerAr" onClick={() => {
                        $('.largerAr').addClass('d-none');
                        $('.smallerAr').removeClass('d-none');
                        $('#tableAr').addClass('col-md-12');
                        $('#chartAr').addClass('col-md-12');
                        $('#chartAr').addClass('mt-3');
                      }}>
                        <FontAwesomeIcon icon={faAngleDoubleLeft} style={{ fontSize: '20px' }} color="#9eacb4"> </FontAwesomeIcon>
                      </span>
                      <span className="smallerAr d-none" onClick={() => {
                        $('.largerAr').removeClass('d-none');
                        $('.smallerAr').addClass('d-none');
                        $('#tableAr').removeClass('col-md-12');
                        $('#chartAr').removeClass('col-md-12');
                        $('#chartAr').removeClass('mt-3');
                      }}>
                        <FontAwesomeIcon icon={faAngleDoubleRight} style={{ fontSize: '20px' }} color="#9eacb4"> </FontAwesomeIcon>
                      </span>
                    </div>
                  </div>
                  <div class="card-body ar d-flex">


                    {this.state.TodayTransactions.length !== 0 ?
                      <div class="Table-- table-responsive" style={{ height: "550px" }}>

                        <table class="table custom-table mb-0">
                          <thead class="thead-dark">
                            <tr>
                              <th>الصورة </th>
                              <th>كود الموظف</th>
                              <th>الاسم</th>
                              <th>الحركة</th>
                              <th> الفرع</th>
                            </tr>
                          </thead>
                          <tbody>

                            {this.state.TodayTransactions.map((row) =>
                              <tr>
                                <td> <img src={row.userImage ? row.userImage : avatar} className='userAvatar' /></td>
                                <td>{row.employeeCode}</td>
                                <td>  {row.employeeName} </td>
                                <td>{row.date}</td>
                                <td>{row.branchName}</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      :
                      <h2>لا توجد حركات اليوم </h2>
                    }


                  </div>

                </div>

              </div>
              <div id='chartAr' className="col-md-6 col-sm-6 mt-3">
                <div class="card card-table flex-fill h-100" >
                  <div class="card-header">
                    <div class="caption d-flex align-items-center">
                      <h5 class="card-title bold uppercase">حركات آخر 10 ايام </h5>
                    </div>
                  </div>
                  <div class="card-body ar" dir="ltr" >

                    {/* <StackedColumnChartAr days={10} lang='ar'></StackedColumnChartAr> */}
                    {data.length > 2 ?
                      <Chart
                        height={600}
                        chartType="AreaChart"
                        loader={<div>Loading Chart</div>}
                        data={data}
                        options={{
                          chartArea: { width: '80%' },
                          legend: "top",
                          hAxis: {
                            title: 'الاجمالي ',
                            minValue: 0,
                          },
                          vAxis: {
                            title: 'الابام',
                          },
                          colors: ['#e7505a', '#3598dc', '#8E44AD']
                        }}
                        legendToggle
                      />
                      :
                      null
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      );
    }
  }
}

export default DashBoard;