import { API, ip } from './APIs';
import React from 'react';

export const Branches = {
  mainTitle: 'Branches',
  title: 'Branches',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    // { label: 'ID', type: 'number', backName: 'id' },
    { label: 'Branches', type: 'text', backName: 'nameEn' }
  ],
  columns: [
    {
      name: 'ID',
      backName: 'id'
    },
    {
      name: 'Branches',
      backName: 'nameEn',
      link: { to: '/En/TAW/Main/Managements/', routeValues: ['id', 'nameEn'] }
    },
    {
      name: 'Manager',
      backName: 'managerName'
    },
    {
      name: 'Employees',
      backName: 'employeesCount'
    },
    {
      name: 'Managements',
      backName: 'administrationsCount'
    }
  ],
  createEntityProps: [
    { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' },
    {
      label: 'Manager', type: 'select', validation: '', style: 'col-lg-6', backName: 'managerId', url: API.Employees.read,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    }
  ],
  updateEntityProps: [
    { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' },
    {
      label: 'Manager', type: 'select', validation: '', style: 'col-lg-6', backName: 'managerId', url: API.Employees.read,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    }
  ],
  deleteMessage: 'All Related Managements and Departments will be Deleted, Are you sure you want to Delete this Branch?',
  create: {
    url: API.Branches.create,
    body: {
      nameAr: '',
      nameEn: '',
      managerId: ''
    },
    verb: 'POST'
  },
  read: {
    url: API.Branches.read,
    body: {
      pageSize: 10,
      pageNumber: 0,
      search: {
        "id": 0,
        "nameEn": "",
        "nameAr": ""
      }
    },
    verb: 'POST'
  },
  update: {
    url: API.Branches.update,
    body: {
      id: '',
      nameAr: '',
      nameEn: '',
      managerId: ''
    },
    verb: 'POST'
  },
  delete: {
    url: API.Branches.delete,
    query: {
      branchId: ''
    },
    verb: 'POST'
  }
}
export const BranchesAr = {
  mainTitle: 'الفروع',
  title: 'الفروع',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    // { label: 'الرقم', type: 'text', backName: 'id' },
    { label: 'الفرع', type: 'text', backName: 'nameAr' }
  ],
  columns: [
    // {
    //   name: 'الرقم',
    //   backName: 'id'
    // },
    {
      name: 'الفرع',
      backName: 'nameAr',
      link: { to: '/En/TAW/Main/Managements/', routeValues: ['id', 'nameEn'] }
    },
    {
      name: 'المدير',
      backName: 'managerName'
    },
    {
      name: 'الموظفون',
      backName: 'employeesCount'
    },
    {
      name: 'الإدارات',
      backName: 'administrationsCount'
    }
  ],
  createEntityProps: [
    { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' },
    {
      label: 'Manager', type: 'select', validation: '', style: '', backName: 'managerId', url: API.Employees.read,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    }
  ],
  updateEntityProps: [
    { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' },
    {
      label: 'Manager', type: 'select', validation: '', style: '', backName: 'managerId', url: API.Employees.read,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    }
  ],
  deleteMessage: 'All Related Managements and Departments will be Deleted, Are you sure you want to Delete this Branch?',
  create: {
    url: API.Branches.create,
    body: {
      nameAr: '',
      nameEn: '',
      managerId: ''
    },
    verb: 'POST'
  },
  read: {
    url: API.Branches.read,
    body: {
      pageSize: 10,
      pageNumber: 0,
      search: {
      }
    },
    verb: 'POST'
  },
  update: {
    url: API.Branches.update,
    body: {
      id: '',
      nameAr: '',
      nameEn: '',
      managerId: ''
    },
    verb: 'POST'
  },
  delete: {
    url: API.Branches.delete,
    query: {
      branchId: ''
    },
    verb: 'POST'
  }
}
export const Jobs = {
  mainTitle: 'Jobs',
  title: 'Jobs',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    //{ label: 'ID', type: 'number', backName: 'id' },
    { label: 'Jobs', type: 'text', backName: 'nameEn' }
  ],
  columns: [
    {
      name: 'ID',
      backName: 'id'
    },
    {
      name: 'Jobs',
      backName: 'nameEn'
    }
  ],
  createEntityProps: [
    { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' }
  ],
  updateEntityProps: [
    { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' }
  ],
  deleteMessage: 'Are you sure you want to Delete?',
  create: {
    url: API.Jobs.create,
    body: {
      nameAr: '',
      nameEn: ''
    },
    verb: 'POST'
  },
  read: {
    url: API.Jobs.read,
    body: {
      pageSize: 10,
      pageNumber: 0,
      search: {
        "id": 0,
        "nameEn": "",
        "nameAr": ""
      }
    },
    verb: 'POST'
  },
  update: {
    url: API.Jobs.update,
    body: {
      id: '',
      nameAr: '',
      nameEn: ''
    },
    verb: 'POST'
  },
  delete: {
    url: API.Jobs.delete,
    query: {
      jobId: ''
    },
    verb: 'POST'
  }
}
export const Projects = {
  mainTitle: 'Projects',
  title: 'Projects',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    //{ label: 'ID', type: 'number', backName: 'id' },
    { label: 'Projects', type: 'text', backName: 'nameEn' }
  ],
  columns: [
    {
      name: 'ID',
      backName: 'id'
    },
    {
      name: 'Projects',
      backName: 'nameEn'
    }
  ],
  createEntityProps: [
    { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' }
  ],
  updateEntityProps: [
    { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' }
  ],
  deleteMessage: 'Are you sure you want to Delete?',
  create: {
    url: API.Projects.create,
    body: {
      nameAr: '',
      nameEn: ''
    },
    verb: 'POST'
  },
  read: {
    url: API.Projects.read,
    body: {
      pageSize: 10,
      pageNumber: 0,
      search: {
        "id": 0,
        "nameEn": "",
        "nameAr": ""
      }
    },
    verb: 'POST'
  },
  update: {
    url: API.Projects.update,
    body: {
      id: '',
      nameAr: '',
      nameEn: ''
    },
    verb: 'POST'
  },
  delete: {
    url: API.Projects.delete,
    query: {
      projectId: ''
    },
    verb: 'POST'
  }
}
export const Nationalities = {
  mainTitle: 'Nationalities',
  title: 'Nationalities',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    // { label: 'ID', type: 'number', backName: 'id' },
    { label: 'Nationalities', type: 'text', backName: 'nameEn' }
  ],
  columns: [
    {
      name: 'ID',
      backName: 'id'
    },
    {
      name: 'Nationalities',
      backName: 'nameEn'
    }
  ],
  createEntityProps: [
    { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' }
  ],
  updateEntityProps: [
    { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' }
  ],
  deleteMessage: 'Are you sure you want to Delete?',
  create: {
    url: API.Nationalities.create,
    body: {
      nameAr: '',
      nameEn: ''
    },
    verb: 'POST'
  },
  read: {
    url: API.Nationalities.read,
    body: {
      pageSize: 10,
      pageNumber: 0,
      search: {
        "id": 0,
        "nameEn": "",
        "nameAr": ""
      }
    },
    verb: 'POST'
  },
  update: {
    url: API.Nationalities.update,
    body: {
      id: '',
      nameAr: '',
      nameEn: ''
    },
    verb: 'POST'
  },
  delete: {
    url: API.Nationalities.delete,
    query: {
      jobId: ''
    },
    verb: 'POST'
  }
}
export const Tasks = {
  mainTitle: 'Tasks',
  title: 'Tasks',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    // { label: 'ID', type: 'number', backName: 'id' },
    { label: 'Tasks', type: 'text', backName: 'nameEn' }
  ],
  columns: [
    {
      name: 'ID',
      backName: 'id'
    },
    {
      name: 'Tasks',
      backName: 'nameEn'
    }
  ],
  createEntityProps: [
    { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' }
  ],
  updateEntityProps: [
    { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' }
  ],
  deleteMessage: 'Are you sure you want to Delete?',
  create: {
    url: API.Tasks.create,
    body: {
      nameAr: '',
      nameEn: ''
    },
    verb: 'POST'
  },
  read: {
    url: API.Tasks.read,
    body: {
      pageSize: 10,
      pageNumber: 0,
      search: {
        "id": 0,
        "nameEn": "",
        "nameAr": ""
      }
    },
    verb: 'POST'
  },
  update: {
    url: API.Tasks.update,
    body: {
      id: '',
      nameAr: '',
      nameEn: ''
    },
    verb: 'POST'
  },
  delete: {
    url: API.Tasks.delete,
    query: {
      jobId: ''
    },
    verb: 'POST'
  }
}
export const WorkingTime = {
  mainTitle: 'Working Time',
  title: 'Working Time',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    // { label: 'ID', type: 'number', backName: 'id' },
    { label: 'Working Time', type: 'text', backName: 'nameEn', }
  ],
  columns: [
    { name: 'ID', backName: 'id' },
    { name: 'Working Time', backName: 'nameEn', link: { to: '/En/TAW/Main/WorkingTime/WorkingHours/', routeValues: ['id', 'isShiftOpen', 'nameEn'] } },
    { name: 'Total', backName: 'totalHours' },
    { name: 'Day End Time', backName: 'dayEndTime' },
    { name: 'Open Working Time', backName: 'isShiftOpen', boolean: true },
  ],
  createEntityProps: [
    { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' },
    { label: 'Open Shift', type: 'checkbox', validation: '', noFormCont: true, style: 'col-lg-6', backName: 'isShiftOpen' },
    { label: ['Shift end next day', 'Day end time'], type: 'shiftEndNextDay', validation: '', style: 'col-lg-6', backName: ['isEndNextDay', 'dayEndTime'], inputType: 'time' }
  ],
  updateEntityProps: [
    { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' },
    { label: 'Open Shift', type: 'checkbox', validation: '', noFormCont: true, style: 'col-lg-6', backName: 'isShiftOpen' },
    { label: ['Shift end next day', 'Day end time'], type: 'shiftEndNextDay', validation: '', style: 'col-lg-6', backName: ['isEndNextDay', 'dayEndTime'], inputType: 'time' }
  ],
  deleteMessage: 'Are you sure you want to Delete?',
  create: {
    url: API.Shifts.create,
    body: {
      "nameEn": "",
      "nameAr": "",
      "isShiftOpen": false,
      "isEndNextDay": false,
      "dayEndTime": ""
    },
    verb: 'POST'
  },
  read: {
    url: API.Shifts.search,
    body: {
      "pageSize": 100,
      "pageNumber": 0,
      "search": {
      }
    },
    verb: 'POST'
  },
  update: {
    url: API.Shifts.update,
    body: {
      id: '',
      "nameEn": "",
      "nameAr": "",
      "isShiftOpen": false,
      "isEndNextDay": false,
      "dayEndTime": ""
    },
    verb: 'POST'
  },
  delete: {
    url: API.Shifts.delete,
    query: {
      hoursId: ''
    },
    verb: 'POST'
  }
}
export const Employees = {
  mainTitle: 'Employees',
  title: 'Employees',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'ID (Code)', type: 'number', backName: 'employeeCode' },
    { label: 'Employees', type: 'text', backName: 'nameEn' },
    {
      label: 'Branch', type: 'select', backName: 'branchId', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Management', type: 'select', backName: 'adminstratId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },

      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Department', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },

      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Working Time', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    }
  ],
  columns: [
    { name: 'ID (Code)', backName: 'employeeCode' },
    { name: 'Employees', backName: 'nameEn' },
    { name: 'Branch', backName: 'branchName' },
    { name: 'Working Time', backName: 'shiftName' },
    { name: 'Active', backName: 'active', boolean: true },
  ],
  createEntityProps: [
    { label: 'ID (Code)', type: 'number', validation: 'Required', style: 'col-lg-5', backName: 'employeeCode' },
    { label: 'Active', type: 'checkbox', validation: '', noFormCont: true, style: 'col-lg-6', backName: 'active' },
    { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' },
    {
      label: 'Branch', type: 'select', validation: 'Required', style: 'col-lg-6', backName: 'branchId', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Management', type: 'select', validation: '', style: 'col-lg-6', backName: 'adminstratId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },

      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Department', type: 'select', validation: '', style: 'col-lg-6', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },

      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Working Time', type: 'select', validation: 'Required', style: 'col-lg-6', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Job', type: 'select', validation: '', style: 'col-lg-6', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Group', type: 'select', validation: '', style: 'col-lg-6', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },

      item: { value: 'id', label: 'nameEn' }
    },
    { label: 'National ID', type: 'text', validation: '', style: 'col-lg-6', backName: 'nationalityNumber' },
    { label: 'Email', type: 'email', validation: '', style: 'col-lg-6', backName: 'email' },
    {
      label: 'Nationality', type: 'select', validation: '', style: 'col-lg-6', backName: 'nationalitiesId', url: ip + '/api/Nationalities/GetAllNationalitiesDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Task', type: 'select', validation: '', style: 'col-lg-6', backName: 'workTasksId', url: ip + '/api/WorkingTasks/GetAllTasksDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Project', type: 'select', validation: '', style: 'col-lg-6', backName: 'projectId', url: ip + '/api/Project/GetAllProjectsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    { label: 'Phone', type: 'text', validation: '', style: 'col-lg-6', backName: 'phoneNumber' },
    {
      label: '', type: 'exceptions', validation: '', style: ' col-lg-12', backName: [
        'provideDelayDiscountingOvertime',
        'extraTimeBeforeWorking',
        'extraTimeAfterWorking',
        'addWorkingHoursOnHoliday',
        'logOutWithoutFingerprint']
    }
  ],
  updateEntityProps: [
    { label: 'ID (Code)', type: 'number', validation: 'Required', style: 'col-lg-5', backName: 'employeeCode' },
    { label: 'Active', type: 'checkbox', validation: '', noFormCont: true, style: 'col-lg-6', backName: 'active' },
    { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' },
    {
      label: 'Branch', type: 'select', validation: 'Required', style: 'col-lg-6', backName: 'branchId', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Management', type: 'select', validation: '', style: 'col-lg-6', backName: 'adminstratId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Department', type: 'select', validation: '', style: 'col-lg-6', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },

      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Working Time', type: 'select', validation: 'Required', style: 'col-lg-6', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Job', type: 'select', validation: '', style: 'col-lg-6', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Group', type: 'select', validation: '', style: 'col-lg-6', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },

      item: { value: 'id', label: 'nameEn' }
    },
    { label: 'National ID', type: 'text', validation: '', style: 'col-lg-6', backName: 'nationalityNumber' },
    { label: 'Email', type: 'email', validation: '', style: 'col-lg-6', backName: 'email' },
    {
      label: 'Nationality', type: 'select', validation: '', style: 'col-lg-6', backName: 'nationalitiesId', url: ip + '/api/Nationalities/GetAllNationalitiesDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Task', type: 'select', validation: '', style: 'col-lg-6', backName: 'workTasksId', url: ip + '/api/WorkingTasks/GetAllTasksDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Project', type: 'select', validation: '', style: 'col-lg-6', backName: 'projectId', url: ip + '/api/Project/GetAllProjectsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    { label: 'Phone', type: 'text', validation: '', style: 'col-lg-6', backName: 'phoneNumber' },
    {
      label: '', type: 'exceptions', validation: '', style: 'col-lg-12', backName: [
        'provideDelayDiscountingOvertime',
        'extraTimeBeforeWorking',
        'extraTimeAfterWorking',
        'addWorkingHoursOnHoliday',
        'logOutWithoutFingerprint']
    }
  ],
  deleteMessage: 'Are you sure you want to Delete?',
  create: {
    url: ip + '/api/Employee/AddEmployee',
    body: {
      "employeeCode": '',
      "nameEn": '',
      "nameAr": '',
      "phoneNumber": '',
      "placeStay": '',
      "nationalityNumber": '',
      "email": '',
      "branchId": '',
      "departmentId": '',
      "adminstratId": '',
      "jobId": '',
      "groupId": '',
      "projectId": '',
      "nationalitiesId": '',
      "workTasksId": '',
      "shiftId": '',
      "extraTimeAfterWorking": false,
      "extraTimeBeforeWorking": false,
      "addWorkingHoursOnHoliday": false,
      "logOutWithoutFingerprint": false,
      "provideDelayDiscountingOvertime": false,
      "active": true
    },
    verb: 'POST'
  },
  createUnrigestered: {
    url: ip + '/api/Employee/AddEmployee',
    body: {
      "employeeCode": '',
      "nameEn": '',
      "nameAr": '',
      "phoneNumber": '',
      "placeStay": '',
      "nationalityNumber": '',
      "email": '',
      "branchId": '',
      "departmentId": '',
      "adminstratId": '',
      "jobId": '',
      "groupId": '',
      "projectId": '',
      "nationalitiesId": '',
      "workTasksId": '',
      "shiftId": '',
      "extraTimeAfterWorking": false,
      "extraTimeBeforeWorking": false,
      "addWorkingHoursOnHoliday": false,
      "logOutWithoutFingerprint": false,
      "provideDelayDiscountingOvertime": false,
      "active": true
    },
    verb: 'POST'
  },
  read: {
    url: API.Employees.search,
    body: {
      pageSize: 10,
      pageNumber: 0,
      search: {
        "employeeCode": 0,
        "nameEn": "",
        "nameAr": "",
        "phoneNumber": "",
        "placeStay": "",
        "email": "",
        "branchId": 0,
        "departmentId": 0,
        "adminstratId": 0,
        "jobId": 0,
        "projectId": 0,
        "shiftId": 0,
        "nationalitiesId": 0
      }
    },
    verb: 'POST'
  },
  update: {
    url: ip + '/api/Employee/UpdateEmployee',
    body: {
      "id": '',
      "employeeCode": '',
      "nameEn": '',
      "nameAr": '',
      "phoneNumber": '',
      "placeStay": '',
      "nationalityNumber": '',
      "email": '',
      "branchId": '',
      "departmentId": '',
      "adminstratId": '',
      "jobId": '',
      "groupId": '',
      "projectId": '',
      "nationalitiesId": '',
      "workTasksId": '',
      "shiftId": '',
      "extraTimeAfterWorking": false,
      "extraTimeBeforeWorking": false,
      "addWorkingHoursOnHoliday": false,
      "logOutWithoutFingerprint": false,
      "provideDelayDiscountingOvertime": false,
      "active": false
    },
    verb: 'POST'
  },
  delete: {
    url: ip + '/api/Employee/DeleteEmployee',
    query: {
      jobId: ''
    },
    verb: 'POST'
  }
}
export const EmployeesGroups = {
  mainTitle: 'Employees Groups',
  title: 'Employees Groups',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    // { label: 'ID', type: 'number', backName: 'id' },
    { label: 'Employees Groups', type: 'text', backName: 'nameEn' },
  ],
  columns: [
    {
      name: 'ID',
      backName: 'id'
    },
    {
      name: 'Employees Groups',
      backName: 'nameEn'
    },
    {
      name: 'Employees',
      backName: '',
      link: { to: '/En/TAW/Main/EmployeesGroups/Employees/', routeValues: ['id', 'nameEn'], text: 'Employees' }
    },
  ],
  createEntityProps: [
    { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' }
  ],
  updateEntityProps: [
    { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' }
  ],
  deleteMessage: 'Are you sure you want to Delete?',
  create: {
    url: ip + '/api/EmployeeGroups/AddEmployeeGroups',
    body: {
      nameAr: '',
      nameEn: ''
    },
    verb: 'POST'
  },
  read: {
    url: ip + '/api/EmployeeGroups/SearchAndGetAllEmployeeGroups',
    body: {
      pageSize: 10,
      pageNumber: 0,
      search: {
        "id": 0,
        "nameEn": "",
        "nameAr": ""
      }
    },
    verb: 'POST'
  },
  update: {
    url: ip + '/api/EmployeeGroups/UpdateEmployeeGroups',
    body: {
      id: '',
      nameAr: '',
      nameEn: ''
    },
    verb: 'POST'
  },
  delete: {
    url: ip + '/api/EmployeeGroups/DeleteEmployeeGroups',
    query: {
      EmployeeGroupId: ''
    },
    verb: 'POST'
  }
}
export const VacationsTypes = {
  mainTitle: 'Vacations Types',
  title: 'Vacations Types',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    // { label: 'ID', type: 'number', backName: 'id' },
    { label: 'Vacations Types', type: 'text', backName: 'nameEn' }
  ],
  columns: [
    {
      name: 'ID',
      backName: 'id'
    },
    {
      name: 'Vacations Types',
      backName: 'nameEn'
    }
  ],
  createEntityProps: [
    { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' }
  ],
  updateEntityProps: [
    { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' }
  ],
  deleteMessage: 'Are you sure you want to Delete?',
  create: {
    url: API.VacationsTypes.create,
    body: {
      nameAr: '',
      nameEn: ''
    },
    verb: 'POST'
  },
  read: {
    url: API.VacationsTypes.read,
    body: {
      pageSize: 10,
      pageNumber: 0,
      search: {
        "id": 0,
        "nameEn": "",
        "nameAr": ""
      }
    },
    verb: 'POST'
  },
  update: {
    url: API.VacationsTypes.update,
    body: {
      id: '',
      nameAr: '',
      nameEn: ''
    },
    verb: 'POST'
  },
  delete: {
    url: API.VacationsTypes.delete,
    query: {
      jobId: ''
    },
    verb: 'POST'
  }
}
export const OfficialHolidays = {
  mainTitle: 'Official Holidays',
  title: 'Official Holidays',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    // { label: 'ID', type: 'number', backName: 'id' },
    { label: 'Official Holidays', type: 'text', backName: 'nameEn' }
  ],
  columns: [
    { name: 'ID', backName: 'id' },
    { name: 'Official Holidays', backName: 'nameEn', link: { to: '/En/TAW/Main/OfficialHolidays/', routeValues: ['id', 'nameEn'] } },
    { name: 'From', backName: 'from' },
    { name: 'To', backName: 'to' },
    { name: 'Duration', backName: 'officialVacationTime' },
    {
      name: 'Status', backName: 'status',
      enum: [
        { value: '1', display: <span className='badge warning status'>Not Started yet</span> },
        { value: '2', display: <span className='badge agree status'>Started</span> },
        { value: '3', display: <span className='badge alert status'>Ended</span> }
      ]
    }
  ],
  createEntityProps: [
    { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' },
    { label: 'From', type: 'date', validation: 'Required from', style: 'col-lg-6', backName: 'from' },
    { label: 'To', type: 'date', validation: 'Required to', style: 'col-lg-6', backName: 'to' }
  ],
  updateEntityProps: [
    { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' },
    { label: 'From', type: 'date', validation: 'Required from', style: 'col-lg-6', backName: 'from' },
    { label: 'To', type: 'date', validation: 'Required to', style: 'col-lg-6', backName: 'to' }
  ],
  deleteMessage: 'Are you sure you want to Delete?',
  create: {
    url: ip + '/api/OfficialHolidays/AddOfficialHolidays',
    body: {
      "nameEn": "",
      "nameAr": "",
      "from": "",
      "to": ""
    },
    verb: 'POST'
  },
  read: {
    url: ip + '/api/OfficialHolidays/SearchOfficialHolidays',
    body: {
      pageSize: 10,
      pageNumber: 0,
      search: {
        "id": 0,
        "nameEn": "",
        "nameAr": ""
      }
    },
    verb: 'POST'
  },
  update: {
    url: ip + '/api/OfficialHolidays/UpdateOfficialHolidays',
    body: {
      "id": '',
      "nameEn": "",
      "nameAr": "",
      "from": "",
      "to": ""
    },
    verb: 'POST'
  },
  delete: {
    url: ip + '/api/OfficialHolidays/DeleteOfficialHolidays',
    query: {
      officialHolidaysId: ''
    },
    verb: 'POST'
  }
}
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
export const GetBranchTransactions = {
  mainTitle: 'Get Branch Transactions',
  title: 'Get Branch Transactions',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  columns: [
    {
      name: 'Transactions',
      backName: 'value'
    }
  ],
  actionEntityProps: [
    { label: 'From', type: 'date', validation: '', style: '', backName: 'from' },
    { label: 'To', type: 'date', validation: '', style: '', backName: 'to' },
    {
      label: 'Branches', type: 'multiSelect', validation: '', style: '', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, backSearch: true
    }
  ],
  action: {
    url: ip + '/api/Transaction/ReadMachineTransaction',
    body: {
      "from": "",
      "to": "",
      "branchIds": [],
      "employeeCode": 0
  },
    verb: 'POST',
    readAction: true
  }
}

export const AddPermission = {
  mainTitle: 'Add Permission',
  title: 'Add Permission',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'Employee Code', type: 'text', backName: 'employeeCode' },
    { label: 'Employee Name', type: 'text', backName: 'employeeName' },
    {
      label: 'Branch', type: 'select', backName: 'branchId', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Management', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },

      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Department', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Job', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Group', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },

      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Working Time', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Permission Type', type: 'staticSelect', backName: 'permissionType',
      items: [
        { label: 'Temporary', value: 1 },
        { label: 'Late Attendance', value: 2 },
        { label: 'Early Leave', value: 3 },
        { label: 'All Day', value: 4 }
      ]
    },
    { label: 'From', type: 'date', backName: 'from', backSearch: true },
    { label: 'To', type: 'date', backName: 'to', backSearch: true }
  ],
  columns: [
    {
      name: 'Employee Code',
      backName: 'employeeCode'
    },
    {
      name: 'Employee Name',
      backName: 'employeeName'
    },
    {
      name: 'Date',
      backName: 'permissionDate'
    },
    {
      name: 'Branch',
      backName: 'branchName'
    },
    {
      name: 'Working Time',
      backName: 'shiftName'
    },
    {
      name: 'Permission Type',
      backName: 'permissionType',
      enum: [{ value: '1', display: 'Temporary' }, { value: '2', display: 'late Attendance' }, { value: '3', display: 'Early Leave' }, { value: '4', display: 'All Day' }]
    },
    {
      name: 'Shift',
      backName: 'sequence',
      enum: [{ value: '1', display: 'First' }, { value: '2', display: 'Second' }, { value: '3', display: 'Third' }, { value: '4', display: 'Forth' }]
    },
    {
      name: 'Time in',
      backName: 'timingOfEnty'
    },
    {
      name: 'Time out',
      backName: 'timingOfExit'
    }
  ],
  createEntityProps: [
    {
      label: '', type: 'selectShift', validation: '', style: 'col-lg-6', backName: ['employeeId', 'permissionDate', 'periodShiftId'], url: API.Employees.read,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    { label: '', type: 'permissionType', validation: '', style: 'col-lg-6', backName: ['permissionType', 'timingOfEnty', 'timingOfExit'] },
    { label: 'Notes', type: 'text', validation: '', style: 'col-lg-6', backName: 'notes' }
  ],
  updateEntityProps: [
    {
      label: '', type: 'selectShift', validation: '', style: 'col-lg-6', backName: ['employeeId', 'permissionDate', 'periodShiftId'], url: API.Employees.read,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    { label: '', type: 'permissionType', validation: '', style: 'col-lg-6', backName: ['permissionType', 'timingOfEnty', 'timingOfExit'] },
    { label: 'Notes', type: 'text', validation: '', style: 'col-lg-6', backName: 'notes' }
  ],
  deleteMessage: 'Are you sure you want to Delete?',
  create: {
    url: API.AddPermission.create,
    body: {
      "employeeId": '',
      "permissionType": '',
      "periodShiftId": '',
      "permissionDate": "",
      "timingOfEnty": "",
      "timingOfExit": "",
      "notes": ""
    },
    verb: 'POST'
  },
  read: {
    url: API.AddPermission.search,
    body: {
      pageSize: 10,
      pageNumber: 0,
      search: {
        "id": 0,
        "employeeId": 0,
        "employeeCode": 0,
        "permissionType": 0,
        "branchName": "",
        "employeeName": "",
        "administrationName": "",
        "departmentName": "",
        "jobName": "",
        "groupName": "",
        "shiftName": "",
        "timingOfEnty": "",
        "timingOfExit": "",
        "from": "",
        "to": ""
      }
    },
    verb: 'POST'
  },
  update: {
    url: API.AddPermission.update,
    body: {
      "id": '',
      "employeeId": '',
      "permissionType": '',
      "periodShiftId": '',
      "permissionDate": "",
      "timingOfEnty": "",
      "timingOfExit": "",
      "notes": ""
    },
    verb: 'POST'
  },
  delete: {
    url: API.AddPermission.delete,
    query: {
      jobId: ''
    },
    verb: 'POST'
  }
}
export const AddVacation = {
  mainTitle: 'Add Vacation',
  title: 'Add Vacation',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'Employee Code', type: 'text', backName: 'employeeNumber' },
    { label: 'Employee Name', type: 'text', backName: 'employeeName' },
    {
      label: 'Branch', type: 'select', backName: 'branchId', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Management', type: 'select', backName: 'adminstratId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Department', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Job', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Group', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Working Time', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Vacation Type', type: 'select', backName: 'vocationId', url: API.VacationsTypes.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    { label: 'From', type: 'date', backName: 'from', backSearch: true },
    { label: 'To', type: 'date', backName: 'to', backSearch: true }
  ],
  columns: [
    {
      name: 'Employee Code',
      backName: 'employeeNumber'
    },
    {
      name: 'Employee Name',
      backName: 'employeeName'
    },
    {
      name: 'Branch',
      backName: 'branchName'
    },
    {
      name: 'Vacation Type',
      backName: 'vacationType'
    },
    {
      name: 'From',
      backName: 'dateFrom'
    },
    {
      name: 'To',
      backName: 'dateTo'
    },
    {
      name: 'Duration',
      backName: 'daysNumber'
    },
    {
      name: 'Notes',
      backName: 'description'
    },
  ],
  createEntityProps: [
    {
      label: 'Employee', type: 'select', validation: 'Required', style: 'col-lg-6', backName: 'employeeId', url: API.Employees.read,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Vacation Type', type: 'select', validation: 'Required', style: 'col-lg-6', backName: 'vocationId', url: API.VacationsTypes.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    { label: 'From', type: 'date', validation: 'Required from', style: 'col-lg-6', backName: 'dateFrom' },
    { label: 'To', type: 'date', validation: 'Required to', style: 'col-lg-6', backName: 'dateTo' },
    { label: 'Description', type: 'text', validation: '', style: 'col-lg-12', backName: 'description' }
  ],
  updateEntityProps: [
    {
      label: 'Employee', type: 'select', validation: 'Required', style: 'col-lg-6', backName: 'employeeId', url: API.Employees.read,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Vacation Type', type: 'select', validation: 'Required', style: 'col-lg-6', backName: 'vocationId', url: API.VacationsTypes.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    { label: 'From', type: 'date', validation: 'Required from', style: 'col-lg-6', backName: 'dateFrom' },
    { label: 'To', type: 'date', validation: 'Required to', style: 'col-lg-6', backName: 'dateTo' },
    { label: 'Description', type: 'text', validation: '', style: 'col-lg-12', backName: 'description' }
  ],
  deleteMessage: 'Are you sure you want to Delete?',
  create: {
    url: API.AddVacation.create,
    body: {
      "employeeId": 0,
      "vocationId": 0,
      "description": "",
      "dateFrom": "",
      "dateTo": ""
    },
    verb: 'POST'
  },
  read: {
    url: API.AddVacation.read,
    body: {
      pageSize: 10,
      pageNumber: 0,
      search: {
        "id": 0,
        "employeeId": 0,
        "branchId": 0,
        "administrationId": 0,
        "departmentId": 0,
        "jobId": 0,
        "groupId": 0,
        "shiftId": 0,
        "from": "",
        "to": ""
      }
    },
    verb: 'POST'
  },
  update: {
    url: API.AddVacation.update,
    body: {
      "id": 0,
      "employeeId": 0,
      "vocationId": 0,
      "description": "",
      "dateFrom": "",
      "dateTo": ""
    },
    verb: 'POST'
  },
  delete: {
    url: API.AddVacation.delete,
    query: {
      employeevacationId: ''
    },
    verb: 'POST'
  }
}
export const TransferTransactions = {
  mainTitle: 'Transfer Transactions',
  title: 'Transfer Transactions',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  columns: [
    { name: 'ID (Code)', backName: 'employeeCode' },
    { name: 'Employees', backName: 'nameEn' },
    { name: 'Branch', backName: 'branchName' }
  ],
  read: {
    url: API.Employees.search,
    body: {
      pageSize: 100,
      pageNumber: 0,
      search: {
        "employeeCode": 0,
        "nameEn": "",
        "nameAr": "",
        "phoneNumber": "",
        "placeStay": "",
        "email": "",
        "branchId": 0,
        "departmentId": 0,
        "adminstratId": 0,
        "jobId": 0,
        "projectId": 0,
        "shiftId": 0,
        "nationalitiesId": 0
      }
    },
    verb: 'POST'
  },
  actionEntityProps: [
    { label: 'From', type: 'date', validation: '', style: '', backName: 'from' },
    { label: 'To', type: 'date', validation: '', style: '', backName: 'to' },
    { label: 'Employees', type: 'searchModal', validation: '', style: '', backName: 'employeeIds' }
  ],
  btnText: 'Get Branch Transactions',

}
export const AddEditTransactions = {
  mainTitle: 'Add & Edit Transactions',
  title: 'Add & Edit Transactions',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'Employee Code', type: 'number', backName: 'employeeCode' },
    { label: 'Employee Name', type: 'text', backName: 'employeeName' },
    {

      // label: 'Branch', type: 'select', backName: 'branchIdIn', url: API.Branches.dropdown,
      label: 'Branchs', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, backSearch: true
    },
    {
      label: 'Management', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Department', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Working Time', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    { label: 'From', type: 'date', backName: 'from', backSearch: true },
    { label: 'To', type: 'date', backName: 'to', backSearch: true },
  ],
  columns: [
    {
      name: 'ID',
      backName: 'id'
    },
    {
      name: 'Employee Code',
      backName: 'employeeCode'
    },
    {
      name: 'Employee Name',
      backName: 'employeeName'
    },
    {
      name: 'Date',
      backName: 'date'
    },
    {
      name: 'Time in',
      backName: 'attendanceTime'
    },
    {
      name: 'Time out',
      backName: 'leaveTime'
    },
  ],
  createEntityProps: [
    {
      label: 'Employee', type: 'select', validation: 'Required', style: 'col-lg-6', backName: 'employeeId', url: API.Employees.read,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    { label: 'Date', type: 'date', validation: 'Required', style: 'col-lg-6', backName: 'date' },
    { label: 'Admin Password', type: 'password', validation: 'Required', style: 'col-lg-6', backName: 'password' },
    { label: 'Time in', type: 'time', validation: 'orReq', RequiredCondition: 'leaveTime', style: 'col-lg-6', backName: 'attendanceTime' },
    {
      label: 'Branch in', type: 'select', validation: 'ConditionalReq', RequiredCondition: 'attendanceTime', style: 'col-lg-6', backName: 'branchIdIn', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    { label: 'Time out', type: 'time', validation: 'orReq', RequiredCondition: 'attendanceTime', style: 'col-lg-6', backName: 'leaveTime' },
    {
      label: 'Branch out', type: 'select', validation: 'ConditionalReq', RequiredCondition: 'leaveTime', style: 'col-lg-6', backName: 'branchIdOut', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    }
  ],
  updateEntityProps: [
    {
      label: 'Employee', type: 'select', validation: 'Required', style: 'col-lg-6', backName: 'employeeId', url: API.Employees.read,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    { label: 'Date', type: 'date', validation: 'Required', style: 'col-lg-6', backName: 'date' },
    { label: 'Admin Password', type: 'password', validation: 'Required', style: 'col-lg-6', backName: 'password' },
    { label: 'Time in', type: 'time', validation: '', style: 'col-lg-6', backName: 'attendanceTime' },
    {
      label: 'Branch in', type: 'select', validation: 'Required', style: 'col-lg-6', backName: 'branchIdIn', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    { label: 'Time out', type: 'time', validation: '', style: 'col-lg-6', backName: 'leaveTime' },
    {
      label: 'Branch out', type: 'select', validation: 'Required', style: 'col-lg-6',
      backName: 'branchIdOut', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    }
  ],
  deleteMessage: 'Are you sure you want to Delete this ?',
  create: {
    url: API.AddEditTransactions.create,
    body: {
      employeeId: '',
      branchIdIn: '',
      branchIdOut: '',
      password: '',
      date: '',
      attendanceTime: '',
      leaveTime: ''
    },
    verb: 'POST'
  },
  read: {
    url: API.AddEditTransactions.read,
    body: {
      pageSize: 10,
      pageNumber: 0,
      search: {
        "from": "",
        "to": "",
        "branchIds": [
        ],
        "employeeIds": [
        ],
        "employeeCode": 0,
        "employeeName": "",
        "branchId": 0,
        "administrationId": 0,
        "departmentId": 0,
        "shiftId": 0
      }
    },
    verb: 'POST'
  },
  update: {
    url: API.AddEditTransactions.update,
    body: {
      id: '',
      employeeId: '',
      branchIdIn: '',
      branchIdOut: '',
      password: '',
      date: '',
      attendanceTime: '',
      leaveTime: ''
    },
    verb: 'POST'
  },
  delete: {
    url: API.AddEditTransactions.delete,
    query: {
      transactionId: ''
    },
    verb: 'POST'
  }
}
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
export const EmployeesReport = {

  mainTitle: 'Employees Report',
  title: 'Employees Report',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'Employee Code', type: 'text', backName: 'employeeCode', backSearch: true },
    { label: 'Employee Name', type: 'text', backName: 'nameEn', backSearch: true },
    {
      label: 'Branch', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, backSearch: true
    },
    {
      label: 'Management', type: 'select', backName: 'adminstratId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, backSearch: true
    },
    {
      label: 'Department', type: 'select', backName: 'departmentId', url: ip + '/api/Department/GetAllDepartmentDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, backSearch: true
    },
    {
      label: 'Job', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, backSearch: true
    },
    {
      label: 'Group', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Working Time', type: 'select', backName: 'shiftId', url: ip + '/api/Shift/GetAllShiftDropdown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, backSearch: true
    }
  ],
  columns: [
    { name: 'Emp Code', backName: 'employeeCode' },
    { name: 'Employee Name', backName: 'nameEn' },
    { name: 'Branch', backName: 'branchName' },
    { name: 'Management', backName: 'administrationName' },
    { name: 'Department', backName: 'departmentName' },
    { name: 'Job', backName: 'jobName' },
    { name: 'Group', backName: 'groupName' },
    { name: 'Working Time', backName: 'shiftName' }
  ],
  read: {
    url: API.EmployeesReport.read,
    body: {
      "pageSize": 10,
      "pageNumber": 0,
      "search": {
        "employeeCode": '',
        "nameEn": "",
        "nameAr": "",
        "phoneNumber": "",
        "placeStay": "",
        "email": "",
        "branchIds": [],
        "departmentId": 0,
        "adminstratId": 0,
        "jobId": 0,
        "projectId": 0,
        "shiftId": 0,
        "nationalitiesId": 0
      }
    },
    verb: 'POST'
  },
  print: true
}
export const WorkingTimeReport = {
  mainTitle: 'Working Time Report',
  title: 'Working Time Report',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'Name', type: 'text', backName: 'nameEn', backSearch: true },
    {
      label: 'Working Time Type', type: 'staticSelect', backName: 'isShiftOpen',
      items: [
        { label: 'Normal', value: false },
        { label: 'Open', value: true },
        { label: 'All', value: null },
      ], backSearch: true
    }
  ],
  // workingTimeReport: true,
  preTableFields: [
    { name: 'Code', backName: 'shiftName' },
    { name: 'Working Time', backName: 'nameEn' },
    { name: 'Tot. Hours', backName: 'totalHours' },
    {
      name: 'Working Time Type',
      backName: 'isShiftOpen',
      boolean: true,
      yes: 'Open',
      no: 'Closed'
    }
  ],
  columns: [
    {
      name: 'Day', backName: 'dayId', enum: [
        { value: '1', display: 'Monday' },
        { value: '2', display: 'Tuesday' },
        { value: '3', display: 'Wednesday' },
        { value: '4', display: 'Thursday' },
        { value: '5', display: 'Friday' },
        { value: '6', display: 'Saturday' },
        { value: '7', display: 'Sunday' }
      ]
    },
    { name: 'Shift 1 Att.', backName: 'timeIn1' },
    { name: 'Shift 1 Leave', backName: 'timeOut1' },
    { name: 'Shift 2 Att.', backName: 'timeIn2' },
    { name: 'Shift 2 Leave', backName: 'timeOut2' },
    { name: 'Shift 3 Att.', backName: 'timeIn3' },
    { name: 'Shift 3 Leave', backName: 'timeOut3' },
    { name: 'Shift 4 Att.', backName: 'timeIn4' },
    { name: 'Shift 4 Leave', backName: 'timeOut4' },
    { name: 'Total Hours', backName: 'totalDayHours' },
    {
      name: 'Day Status',
      backName: 'isVacation',
      boolean: true,
      yes: 'Vacation',
      no: 'Work'
    }
  ],
  read: {
    url: API.WorkingTimeReport.read,
    body: {
      "pageSize": 100,
      "pageNumber": 0,
      "search": {
        "nameAr": "",
        "nameEn": "",
        "isShiftOpen": null
      }
    },
    verb: 'POST'
  },
  dataHandler: function (data) {
    // let seqs = [];

  },
  print: true
}
export const DayStatusReport = {
  mainTitle: 'Day Status Report',
  title: 'Day Status Report',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'Emp. Code', type: 'text', backName: 'employeeCode', innerSearch: true },
    { label: 'Emp. Name', type: 'text', backName: 'employeeName', innerSearch: true, },
    {
      label: 'Branch', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, backSearch: true
    },
    {
      label: 'Management', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Department', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Job', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, innerSearch: true
    },
    {
      label: 'Group', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, innerSearch: true
    },
    {
      label: 'Working Time', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, innerSearch: true
    },
    { label: 'From', type: 'date', backName: 'from', backSearch: true },
    { label: 'To', type: 'date', backName: 'to', backSearch: true },
  ],

  preTableFields: [
    { name: 'Branch', backName: 'branchName' },
    { name: 'Day', backName: 'day' },
    { name: 'Date', backName: 'date' }
  ],
  period: true,
  columns: [
    { name: 'Emp. Code', backName: 'employeeCode' },
    { name: 'Emp. Name', backName: 'employeeName' },
    { name: 'Working Time', backName: 'shiftName' },
    { name: 'Shift 1 Att.', backName: 'timeIn1' },
    { name: 'Shift 1 Leave', backName: 'timeOut1' },
    { name: 'Shift 2 Att.', backName: 'timeIn2' },
    { name: 'Shift 2 Leave', backName: 'timeOut2' },
    { name: 'Shift 3 Att.', backName: 'timeIn3' },
    { name: 'Shift 3 Leave', backName: 'timeOut3' },
    { name: 'Shift 4 Att.', backName: 'timeIn4' },
    { name: 'Shift 4 Leave', backName: 'timeOut4' },
    { name: 'Working Hours', backName: 'totalHourDay' },
    { name: 'Delay Hours', backName: 'hourDayDelayTime' },
    { name: 'Over Time Hours', backName: 'hourDayOverTime' },
    { name: 'Tot. Working Hours', backName: 'totalWorkingHours' },
    { name: 'Day Status', backName: 'dayStatus' }
  ],
  read: {
    url: API.DayStatusReport.read,
    body: {
      "search": {
        "from": "",
        "to": "",
        "branchIds": [
        ],
        "employeeIds": [
        ],
        "employeeName": ""
      }
    },
    verb: 'POST'
  },
  print: true
}

export const DetailedAttendanceReport = {
  mainTitle: 'Detailed Attendance Report',
  title: 'Detailed Attendance Report',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'Emp. Code', type: 'text', backName: 'employeeCode' },
    { label: 'Emp. Name', type: 'text', backName: 'employeeName', },
    {
      label: 'Branch', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, backSearch: true
    },
    {
      label: 'Management', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Department', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Job', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Group', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Working Time', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    { label: 'From', type: 'date', backName: 'from', backSearch: true },
    { label: 'To', type: 'date', backName: 'to', backSearch: true }
  ],
  preTableFields: [
    { name: 'Emp. Code', backName: 'employeeCode', style: "col-lg-2", },
    { name: 'Emp. Name', backName: 'employeeName', style: "col-lg-2", },
    { name: 'Branch', backName: 'branchName', style: "col-lg-2" },
    { name: 'Job', backName: 'jobName', style: "col-lg-2" },
    { name: 'Working Time', backName: 'shiftName', style: "col-lg-2", }
  ],
  period: true,
  columns: [
    { name: 'Day', backName: 'day' },
    { name: 'Date', backName: 'date' },
    { name: 'Shift 1 Att.', backName: 'timeIn1' },
    { name: 'Shift 1 Leave', backName: 'timeOut1' },
    { name: 'Shift 2 Att.', backName: 'timeIn2' },
    { name: 'Shift 2 Leave', backName: 'timeOut2' },
    { name: 'Shift 3 Att.', backName: 'timeIn3' },
    { name: 'Shift 3 Leave', backName: 'timeOut3' },
    { name: 'Shift 4 Att.', backName: 'timeIn4' },
    { name: 'Shift 4 Leave', backName: 'timeOut4' },
    { name: 'Working Hours', backName: 'totalHourDay' },
    { name: 'Delay Hours', backName: 'hourDayDelayTime' },
    { name: 'Over Time Hours', backName: 'hourDayOverTime' },
    { name: 'Tot. Working Hours', backName: 'totalWorkingHours' },
    { name: 'Day Status', backName: 'dayStatus' }
  ],
  totals: [
    { name: 'Day', backName: '' },
    { name: 'Date', backName: '' },
    { name: 'Shift 1 Att.', backName: '' },
    { name: 'Shift 1 Leave', backName: '' },
    { name: 'Shift 2 Att.', backName: '' },
    { name: 'Shift 2 Leave', backName: '' },
    { name: 'Shift 3 Att.', backName: '' },
    { name: 'Shift 3 Leave', backName: '' },
    { name: 'Shift 4 Att.', backName: '' },
    { name: 'Shift 4 Leave', backName: '' },
    { name: 'Working Hours', backName: 'totalWorkHour' },
    { name: 'Delay Hours', backName: 'totalHourDayDelayTime' },
    { name: 'Over Time Hours', backName: 'totalHourDayOverTime' },
    { name: 'Tot. Working Hours', backName: 'totalWorkingHoursDays' },
    { name: 'Day Status', backName: '' }
  ],
  read: {
    url: API.DetailedAttendanceReport.read,
    body: {
      "search": {
        "from": "",
        "to": "",
        "branchIds": [],
        "employeeIds": [],
        "employeeName": ""
      }
    },
    verb: 'POST'
  },
  print: true
}
export const TotalAttendanceReport = {
  mainTitle: 'Total Attendance Report',
  title: 'Total Attendance Report',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'Emp. Code', type: 'text', backName: 'employeeCode', innerSearch: true },
    { label: 'Emp. Name', type: 'text', backName: 'employeeName', innerSearch: true, },
    {
      label: 'Branch', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, backSearch: true
    },
    {
      label: 'Management', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, innerSearch: true
    },
    {
      label: 'Department', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, innerSearch: true
    },
    {
      label: 'Job', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, innerSearch: true
    },
    {
      label: 'Group', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, innerSearch: true
    },
    {
      label: 'Working Time', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, innerSearch: true
    },
    { label: 'From', type: 'date', backName: 'from', backSearch: true },
    { label: 'To', type: 'date', backName: 'to', backSearch: true }
  ],
  period: true,
  columns: [
    { name: 'Emp. Code', backName: 'employeeCode' },
    { name: 'Emp. Name', backName: 'employeeName' },
    { name: 'Branch', backName: 'branchName' },
    { name: 'Job', backName: 'jobName' },
    { name: 'Working Time', backName: 'shiftName' },
    { name: 'Working Hours', backName: 'shiftHours' },
    { name: 'Delay', backName: 'lateHours' },
    { name: 'Absence', backName: 'absentHours' },
    { name: 'Vacations', backName: 'vacationsDays' },
    { name: 'Overtime', backName: 'extraHours' },
    { name: 'Actual Working Hours', backName: 'workingHours' }
  ],
  read: {
    url: API.TotalAttendanceReport.read,
    body: {
      "search": {
        "from": "",
        "to": "",
        "branchIds": [
        ],
        "employeeIds": [
        ],
        "employeeName": ""
      }
    },
    verb: 'POST'
  },
  print: true
}
export const AbsenceReport = {
  mainTitle: 'Absence Report',
  title: 'Absence Report',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'Emp. Code', type: 'text', backName: 'employeeCode' },
    { label: 'Emp. Name', type: 'text', backName: 'employeeName' },
    {
      label: 'Branch', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, backSearch: true
    },
    {
      label: 'Management', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Department', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Job', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Group', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Working Time', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    { label: 'From', type: 'date', backName: 'from', backSearch: true },
    { label: 'To', type: 'date', backName: 'to', backSearch: true }
  ],
  columns: [
    { name: 'Day', backName: 'day' },
    { name: 'Date', backName: 'date' },
    { name: 'Emp. Code', backName: 'employeeCode' },
    { name: 'Emp. Name', backName: 'employeeName' },
    { name: 'Branch', backName: 'branchName' },
    { name: 'Management', backName: 'administrationName' },
    { name: 'Department', backName: 'departmentName' },
    { name: 'Job', backName: 'jobName' },
    { name: 'Working Time', backName: 'shiftName' }
  ],
  read: {
    url: API.AbsenceReport.read,
    body: {
      "search": {
        "from": "",
        "to": "",
        "branchIds": [
        ],
        "employeeIds": [
        ],
        "employeeName": ""
      }
    },
    verb: 'POST'
  },
  print: true
}
export const DelayReport = {
  mainTitle: 'Delay Report',
  title: 'Delay Report',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'Emp. Code', type: 'text', backName: 'employeeCode' },
    { label: 'Emp. Name', type: 'text', backName: 'employeeName' },
    {
      label: 'Branch', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, backSearch: true
    },
    {
      label: 'Management', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Department', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Job', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Group', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Working Time', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    { label: 'From', type: 'date', backName: 'from', backSearch: true },
    { label: 'To', type: 'date', backName: 'to', backSearch: true }
  ],
  period: true,
  columns: [
    { name: 'Day', backName: 'day' },
    { name: 'Date', backName: 'date' },
    { name: 'Emp. Code', backName: 'employeeCode' },
    { name: 'Emp. Name', backName: 'employeeName' },
    { name: 'Branch', backName: 'branchName' },
    { name: 'Working Time', backName: 'shiftName' },
    { name: 'Delay Hours', backName: 'lateHours' }
  ],
  read: {
    url: API.DelayReport.read,
    body: {
      "pageSize": 1000,
      "pageNumber": 0,
      "search": {
        "from": "",
        "to": "",
        "branchIds": [],
        "employeeIds": [],
        "employeeCode": 0,
        "employeeName": "",
        "branchId": 0,
        "administrationId": 0,
        "departmentId": 0,
        "shiftId": 0
      }
    },
    verb: 'POST'
  },
  print: true
}
export const InCompleteTransReport = {
  mainTitle: 'InComplete Trans. Report',
  title: 'InComplete Trans. Report',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'Emp. Code', type: 'text', backName: 'employeeCode' },
    { label: 'Emp. Name', type: 'text', backName: 'employeeName' },
    {
      label: 'Branch', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, backSearch: true
    },
    {
      label: 'Management', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Department', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Job', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Group', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Working Time', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    { label: 'From', type: 'date', backName: 'from', backSearch: true },
    { label: 'To', type: 'date', backName: 'to', backSearch: true }
  ],
  preTableFields: [
    { name: 'Day', backName: 'day' },
    { name: 'Date', backName: 'date' }
  ],
  columns: [
    { name: 'Emp. Code', backName: 'employeeCode' },
    { name: 'Emp. Name', backName: 'employeeName' },
    { name: 'Branch', backName: 'branchName' },
    { name: 'Working Time', backName: 'shiftName' },
    { name: 'Shift', backName: 'seq' },
    { name: 'Att. Time', backName: 'timeIn' },
    { name: 'Leave Time', backName: 'timeOut' }
  ],
  read: {
    url: API.InCompleteTransReport.read,
    body: {
      "pageSize": 0,
      "pageNumber": 0,
      "search": {
        "from": "",
        "to": "",
        "branchIds": [],
        "employeeIds": [],
        "employeeCode": 0,
        "employeeName": "",
        "branchId": 0,
        "administrationId": 0,
        "departmentId": 0,
        "shiftId": 0
      }
    },
    verb: 'POST'
  },
  print: true,
  period: true
}
export const EarlyAttendanceReport = {
  mainTitle: 'Early Attendance Report',
  title: 'Early Attendance Report',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'Emp. Code', type: 'text', backName: 'employeeCode' },
    { label: 'Emp. Name', type: 'text', backName: 'employeeName' },
    {
      label: 'Branch', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, backSearch: true
    },
    {
      label: 'Management', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Department', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Job', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Group', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Working Time', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    { label: 'From', type: 'date', backName: 'from', backSearch: true },
    { label: 'To', type: 'date', backName: 'to', backSearch: true }
  ],
  preTableFields: [
    { name: 'Day', backName: 'day' },
    { name: 'Date', backName: 'date' }
  ],
  columns: [
    { name: 'Emp. Code', backName: 'employeeCode' },
    { name: 'Emp. Name', backName: 'employeeName' },
    { name: 'Branch', backName: 'branchName' },
    { name: 'Working Time', backName: 'shiftName' },
    { name: 'Shift', backName: 'seq' },
    { name: 'Att. Time', backName: 'timeIn' },
    { name: 'Shift Time', backName: 'timeInShift' }
  ],
  read: {
    url: API.EarlyAttendanceReport.read,
    body: {
      "pageSize": 0,
      "pageNumber": 0,
      "search": {
        "from": "",
        "to": "",
        "branchIds": [],
        "employeeIds": [],
        "employeeCode": 0,
        "employeeName": "",
        "branchId": 0,
        "administrationId": 0,
        "departmentId": 0,
        "shiftId": 0
      }
    },
    verb: 'POST'
  },
  print: true
}
export const AttendanceSummaryReport = {
  mainTitle: 'Attendance Summary Report',
  title: 'Attendance Summary Report',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'Emp. Code', type: 'text', backName: 'employeeCode' },
    { label: 'Emp. Name', type: 'text', backName: 'employeeName' },
    {
      label: 'Branch', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, backSearch: true
    },
    {
      label: 'Management', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Department', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Job', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Group', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Working Time', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    { label: 'From', type: 'date', backName: 'from', backSearch: true },
    { label: 'To', type: 'date', backName: 'to', backSearch: true }
  ],
  period: true,
  columns: [
    { name: 'Emp. Code', backName: 'employeeCode' },
    { name: 'Emp. Name', backName: 'employeeName' },
    { name: 'Date', backName: 'data' },
    { name: 'Working Days', backName: 'workingDays' }
  ],
  read: {
    url: API.AttendanceSummaryReport.read,
    body: {
      "pageSize": 0,
      "pageNumber": 0,
      "search": {
        "from": "",
        "to": "",
        "branchIds": [],
        "employeeIds": [],
        "employeeCode": 0,
        "employeeName": "",
        "branchId": 0,
        "administrationId": 0,
        "departmentId": 0,
        "shiftId": 0
      }
    },
    verb: 'POST'
  },
  print: true
}
export const TotalAttendanceSummaryReport = {
  mainTitle: 'Total Attendance Summary Report',
  title: 'Total Attendance Summary Report',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'Emp. Code', type: 'text', backName: 'employeeCode' },
    { label: 'Emp. Name', type: 'text', backName: 'employeeName' },
    {
      label: 'Branch', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, backSearch: true
    },
    {
      label: 'Management', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Department', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Job', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Group', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Working Time', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    { label: 'From', type: 'date', backName: 'from', backSearch: true },
    { label: 'To', type: 'date', backName: 'to', backSearch: true }
  ],
  preTableFields: [
    { name: 'Branch', backName: 'branchName' }
  ],
  period: true,
  columns: [
    { name: 'Emp. Code', backName: 'employeeCode' },
    { name: 'Emp. Name', backName: 'employeeName' },
    { name: 'Job', backName: 'jobName' },
    { name: 'Working Time', backName: 'shiftName' },
    { name: 'Working Hours', backName: 'shiftHours' },
    { name: 'Delay', backName: 'lateHours' },
    { name: 'Early Att.', backName: 'earlyLeave' },
    { name: 'Absence', backName: 'absentHours' },
    { name: 'Vacations', backName: 'absentHours' },
    { name: 'Overtime', backName: 'extraHours' },
    { name: 'Actual W H', backName: 'workingHours' },
    { name: 'W H Ratio', backName: 'rate' }
  ],
  read: {
    url: API.TotalAttendanceSummaryReport.read,
    body: {
      "pageSize": 0,
      "pageNumber": 0,
      "search": {
        "from": "",
        "to": "",
        "branchIds": [],
        "employeeIds": [],
        "employeeCode": 0,
        "employeeName": "",
        "branchId": 0,
        "administrationId": 0,
        "departmentId": 0,
        "shiftId": 0
      }
    },
    verb: 'POST'
  },
  print: true
}
export const OvertimeReport = {
  mainTitle: 'Overtime Report',
  title: 'Overtime Report',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'Emp. Code', type: 'text', backName: 'employeeCode' },
    { label: 'Emp. Name', type: 'text', backName: 'employeeName' },
    {
      label: 'Branch', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, backSearch: true
    },
    {
      label: 'Management', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Department', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Job', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Group', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Working Time', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    { label: 'From', type: 'date', backName: 'from', backSearch: true },
    { label: 'To', type: 'date', backName: 'to', backSearch: true }
  ],
  preTableFields: [
    { name: 'Branch', backName: 'branchName' }
  ],
  period: true,
  columns: [
    { name: 'Emp. Code', backName: 'employeeCode' },
    { name: 'Emp. Name', backName: 'employeeName' },
    { name: 'Date', backName: 'date' },
    { name: 'Tot. Overtime', backName: 'totalExtraHours' }
  ],
  read: {
    url: API.OvertimeReport.read,
    body: {
      "pageSize": 0,
      "pageNumber": 0,
      "search": {
        "from": "",
        "to": "",
        "branchIds": [],
        "employeeIds": [],
        "employeeCode": 0,
        "employeeName": "",
        "branchId": 0,
        "administrationId": 0,
        "departmentId": 0,
        "shiftId": 0
      }
    },
    verb: 'POST'
  },
  print: true
}
export const WorkingTimeDetailsReport = {
  mainTitle: 'Working Time Details Report',
  title: 'Working Time Details Report',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'Emp. Code', type: 'text', backName: 'employeeCode' },
    { label: 'Emp. Name', type: 'text', backName: 'employeeName' },
    {
      label: 'Branch', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, backSearch: true
    },
    {
      label: 'Management', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Department', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Job', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Group', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Working Time', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    { label: 'From', type: 'date', backName: 'from', backSearch: true },
    { label: 'To', type: 'date', backName: 'to', backSearch: true }
  ],
  preTableFields: [
    { name: 'Emp. Code', backName: 'employeeCode' },
    { name: 'Emp. Name', backName: 'employeeName' },
    { name: 'Branch', backName: 'branchName' },
    { name: 'Management', backName: 'administrationName' },
    { name: 'Department', backName: 'departmentName' },
    { name: 'Job', backName: 'jobName' },
    { name: 'Working Time', backName: 'shiftName' }
  ],
  period: true,
  columns: [
    { name: 'No. of Working Days', backName: 'shiftDays' },
    { name: 'No. of Vacation Days', backName: 'vacationsDays' },
    { name: 'No. of Absence Days', backName: 'absentDays' },
    { name: 'No. of Working Hours', backName: 'shiftHours' },
    { name: 'No. of Actual Working Hours', backName: 'workingHours' },
    { name: 'No. of Delay Hours', backName: 'lateHours' },
    { name: 'No. of Overtime Hours', backName: 'extraHours' },
    { name: 'No. of Overtime Hours in Vacations', backName: 'extraHoursInVactions' }
  ],
  read: {
    url: API.WorkingTimeDetailsReport.read,
    body: {
      "pageSize": 1000,
      "pageNumber": 0,
      "search": {
        "from": "",
        "to": "",
        "branchIds": [
        ],
        "employeeIds": [
        ],
        "employeeCode": 0,
        "employeeName": "",
        "branchId": 0,
        "administrationId": 0,
        "departmentId": 0,
        "shiftId": 0
      }
    },
    verb: 'POST'
  },
  print: true
}
export const BranchAttendanceReport = {
  mainTitle: 'Branch Attendance Report',
  title: 'Branch Attendance Report',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'Emp. Code', type: 'text', backName: 'employeeCode' },
    { label: 'Emp. Name', type: 'text', backName: 'employeeName' },
    {
      label: 'Branch', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, backSearch: true
    },
    {
      label: 'Management', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Department', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Job', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Group', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Working Time', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    { label: 'From', type: 'date', backName: 'from', backSearch: true },
    { label: 'To', type: 'date', backName: 'to', backSearch: true }
  ],
  preTableFields: [
    { name: 'Emp. Code', backName: 'employeeCode' },
    { name: 'Emp. Name', backName: 'employeeName' },
    { name: 'Branch', backName: 'branchName' },
    { name: 'Job', backName: 'JobName' },
    { name: 'Working Time', backName: 'shiftName' }
  ],
  period: true,
  columns: [
    { name: 'Day', backName: 'day' },
    { name: 'Date', backName: 'date' },
    { name: 'Working Time', backName: 'shiftName' },
    { name: 'Shift 1 Att.', backName: 'timeIn' },
    { name: 'Shift 1 Leave', backName: 'timeOut' },
    { name: 'Shift 2 Att.', backName: 'timeIn2' },
    { name: 'Shift 2 Leave', backName: 'timeOut2' },
    { name: 'Shift 3 Att.', backName: 'timeIn3' },
    { name: 'Shift 3 Leave', backName: 'timeOut3' },
    { name: 'Shift 4 Att.', backName: 'timeIn4' },
    { name: 'Shift 4 Leave', backName: 'timeOut4' },
    { name: 'Branch', backName: 'branchName' }
  ],
  read: {
    url: API.BranchAttendanceReport.read,
    body: {
      "pageSize": 0,
      "pageNumber": 0,
      "search": {
        "from": "",
        "to": "",
        "branchIds": [],
        "employeeIds": [],
        "employeeCode": 0,
        "employeeName": "",
        "branchId": 0,
        "administrationId": 0,
        "departmentId": 0,
        "shiftId": 0
      }
    },
    verb: 'POST'
  },
  print: true
}
export const VacationsReport = {
  mainTitle: 'Vacations Report',
  title: 'Vacations Report',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'Emp. Code', type: 'text', backName: 'employeeCode' },
    { label: 'Emp. Name', type: 'text', backName: 'employeeName' },
    {
      label: 'Branch', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, backSearch: true
    },
    {
      label: 'Management', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Department', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Job', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Group', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Working Time', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    { label: 'From', type: 'date', backName: 'from', backSearch: true },
    { label: 'To', type: 'date', backName: 'to', backSearch: true },
    {
      label: 'Vacation Type', type: 'select', backName: '', url: API.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    }
  ],
  preTableFields: [
    { name: 'Emp. Code', backName: 'employeeCode' },
    { name: 'Emp. Name', backName: 'employeeName' },
    { name: 'Branch', backName: 'branchName' },
    { name: 'Job', backName: 'JobName' },
    { name: 'Working Time', backName: 'shiftName' }
  ],
  period: true,
  columns: [
    { name: 'Vacation Type', backName: 'vacationType' },
    { name: 'Starts', backName: 'dateFrom' },
    { name: 'Ends', backName: 'dateTo' },
    { name: 'Period', backName: 'daysNumber' },
    { name: 'Notes', backName: 'description' }
  ],
  totals: [
    { name: 'Vacation Type', backName: '' },
    { name: 'Starts', backName: '' },
    { name: 'Ends', backName: '' },
    { name: 'Period', backName: 'totalOfVacations' },
    { name: 'Notes', backName: '' }
  ],

  read: {
    url: API.VacationsReport.read,
    body: {
      "pageSize": 100,
      "pageNumber": 0,
      "search": {}
    },
    verb: 'POST'
  },
  print: true
}
export const PermissionsReport = {
  mainTitle: 'Permissions Report',
  title: 'Permissions Report',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'Emp. Code', type: 'text', backName: 'employeeCode' },
    { label: 'Emp. Name', type: 'text', backName: 'employeeName' },
    {
      label: 'Branch', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, backSearch: true
    },
    {
      label: 'Management', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Department', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Job', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Group', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Working Time', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    { label: 'From', type: 'date', backName: 'from', backSearch: true },
    { label: 'To', type: 'date', backName: 'to', backSearch: true },
    {
      label: 'Vacation Type', type: 'select', backName: '', url: API.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    }
  ],
  preTableFields: [
    { name: 'Emp. Code', backName: 'employeeCode' },
    { name: 'Emp. Name', backName: 'employeeName' },
    { name: 'Branch', backName: 'branchName' },
    { name: 'Job', backName: 'JobName' },
    { name: 'Working Time', backName: 'shiftName' }
  ],
  period: true,
  columns: [
    { name: 'Day', backName: 'day' },
    { name: 'Date', backName: 'permissionDate' },
    {
      name: 'Permission Type',
      backName: 'permissionType',
      enum: [
        { value: '1', display: 'Temporary' },
        { value: '2', display: 'late Attendance' },
        { value: '3', display: 'Early Leave' },
        { value: '4', display: 'All Day' }
      ]
    },
    {
      name: 'Shift',
      backName: 'sequence',
      enum: [
        { value: '1', display: 'First' },
        { value: '2', display: 'Second' },
        { value: '3', display: 'Third' },
        { value: '4', display: 'Forth' }
      ]
    },
    { name: 'Att. in', backName: 'timingOfEnty' },
    { name: 'Leave in', backName: 'timingOfExit' },
    { name: 'Notes', backName: 'notes' }
  ],
  read: {
    url: API.PermissionsReport.read,
    body: {
      "pageSize": 100,
      "pageNumber": 0,
      "search": {
        "id": 0,
        "employeeId": 0,
        "employeeCode": 0,
        "permissionType": 0,
        "branchIds": [],
        "employeeName": "",
        "administrationName": "",
        "departmentName": "",
        "jobName": "",
        "groupName": "",
        "shiftName": "",
        "timingOfEnty": "",
        "timingOfExit": "",
        "from": "",
        "to": ""
      }
    },
    verb: 'POST'
  },
  print: true
}
export const DelayEarlyReport = {
  mainTitle: 'Delay and Early Leave Report',
  title: 'Delay and Early Leave Report',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'Emp. Code', type: 'text', backName: 'employeeCode' },
    { label: 'Emp. Name', type: 'text', backName: 'employeeName' },
    {
      label: 'Branch', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, backSearch: true
    },
    {
      label: 'Management', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Department', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Job', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Group', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Working Time', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    { label: 'From', type: 'date', backName: 'from', backSearch: true },
    { label: 'To', type: 'date', backName: 'to', backSearch: true }
  ],
  columns: [
    { name: 'Emp. Code', backName: 'employeeCode' },
    { name: 'Emp. Name', backName: 'employeeName' },
    { name: 'Date', backName: 'date' },
    { name: 'Att.', backName: 'timeIn1' },
    { name: 'Leave', backName: 'timeOut1' },
    { name: 'Delay', backName: 'hourDayDelayTime' },
    { name: 'Early Leave', backName: 'earlyLeave' },
    { name: 'Absence', backName: 'isAbsent', boolean: true }
  ],
  read: {
    url: API.DelayEarlyReport.read,
    body: {
      "pageSize": 100,
      "pageNumber": 0,
      "search": {
        "from": "",
        "to": "",
        "branchIds": [],
        "employeeIds": [],
        "employeeCode": 0,
        "employeeName": "",
        "branchId": 0,
        "administrationId": 0,
        "departmentId": 0,
        "shiftId": 0
      }
    },
    verb: 'POST'
  },
  print: true
}
export const TotalAbsenceReport = {
  mainTitle: 'Total Absence Report',
  title: 'Total Absence Report',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'Emp. Code', type: 'text', backName: 'employeeCode' },
    { label: 'Emp. Name', type: 'text', backName: 'employeeName' },
    {
      label: 'Branch', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, backSearch: true
    },
    {
      label: 'Management', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Department', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Job', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Group', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Working Time', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    { label: 'From', type: 'date', backName: 'from', backSearch: true },
    { label: 'To', type: 'date', backName: 'to', backSearch: true }
  ],
  period: true,
  columns: [
    { name: 'Emp. Code', backName: 'employeeCode' },
    { name: 'Emp. Name', backName: 'employeeName' },
    { name: 'Branch', backName: 'branchName' },
    { name: 'Job', backName: 'jobName' },
    { name: 'working Time', backName: 'shiftName' },
    { name: 'Total Absence', backName: 'absentDays' }
  ],
  read: {
    url: API.TotalAbsenceReport.read,
    body: {
      "pageSize": 100,
      "pageNumber": 0,
      "search": {
        "from": "",
        "to": "",
        "branchIds": [],
        "employeeIds": [],
        "employeeCode": 0,
        "employeeName": "",
        "branchId": 0,
        "administrationId": 0,
        "departmentId": 0,
        "shiftId": 0
      }
    },
    verb: 'POST'
  },
  print: true
}
export const TotalDelayReport = {
  mainTitle: 'Total Delay Report',
  title: 'Total Delay Report',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'Emp. Code', type: 'text', backName: 'employeeCode' },
    { label: 'Emp. Name', type: 'text', backName: 'employeeName' },
    {
      label: 'Branch', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }, backSearch: true
    },
    {
      label: 'Management', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Department', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Job', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Group', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    {
      label: 'Working Time', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    { label: 'From', type: 'date', backName: 'from', backSearch: true },
    { label: 'To', type: 'date', backName: 'to', backSearch: true }
  ],
  period: true,
  columns: [
    { name: 'Emp. Code', backName: 'employeeCode' },
    { name: 'Emp. Name', backName: 'employeeName' },
    { name: 'Branch', backName: 'branchName' },
    { name: 'Job', backName: 'jobName' },
    { name: 'working Time', backName: 'shiftName' },
    { name: 'Total Delay', backName: 'lateHours' }
  ],
  read: {
    url: API.TotalDelayReport.read,
    body: {
      "pageSize": 0,
      "pageNumber": 0,
      "search": {
        "from": "",
        "to": "",
        "branchIds": [],
        "employeeIds": [],
        "employeeCode": 0,
        "employeeName": "",
        "branchId": 0,
        "administrationId": 0,
        "departmentId": 0,
        "shiftId": 0
      }
    },
    verb: 'POST'
  },
  print: true
}
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
export const Devices = {
  mainTitle: 'Devices',
  title: 'Devices',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  searchFields: [
    {
      label: 'Branch', type: 'select', backName: 'branchId', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    { label: 'Device Name', type: 'text', backName: 'machineNameEn' },
    { label: 'Device Serial', type: 'text', backName: 'machineSN' },
    {
      label: 'Device type', type: 'select', backName: 'type',
      options: [
        {
          "id": 1,
          "nameEn": "ZKTeco",
          "nameAr": "ZKTeco"
        },
        {
          "id": 2,
          "nameEn": "Timmy TM-f630",
          "nameAr": "Timmy TM-f630"
        }
      ], item: { value: 'id', label: 'nameAr' }
    }

  ],
  columns: [
    { name: 'Device ID', backName: 'id' },
    { name: 'Device Serial', backName: 'machineSN' },
    { name: 'Device Name', backName: 'machineNameEn' },
    { name: 'Branch', backName: 'branchName' },
    {
      name: 'Device Status', backName: 'status', enum: [
        { value: true, display: <span className='badge agree status'>Active</span> },
        { value: false, display: <span className='badge alert status'>Not Active</span> }
      ]
    } ,
    {
      name: 'Device type', backName: 'type', enum: [
        { value: 1, display: <span >ZKTeco</span> },
        { value: 2, display: <span >Timmy TM-f630</span> }
      ]
    }
  ],
  createEntityProps: [
    { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'machineNameAr' },
    { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'machineNameEn' },
    {
      label: 'Branch', type: 'select', validation: 'Required', style: 'col-lg-6', backName: 'branchId', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    { label: 'Serial Number', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'machineSN' },
    {
      label: 'Device type', type: 'select', backName: 'type',
      options: [
        {
          "id": 1,
          "nameEn": "ZKTeco",
          "nameAr": "ZKTeco"
        },
        {
          "id": 2,
          "nameEn": "Timmy TM-f630",
          "nameAr": "Timmy TM-f630"
        }
      ], item: { value: 'id', label: 'nameAr' }
    }
  ],
  updateEntityProps: [
    { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'machineNameAr' },
    { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'machineNameEn' },
    {
      label: 'Branch', type: 'select', validation: 'Required', style: 'col-lg-6', backName: 'branchId', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    },
    { label: 'Serial Number', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'machineSN' },
    {
      label: 'Device type', type: 'select', backName: 'type',
      options: [
        {
          "id": 1,
          "nameEn": "ZKTeco",
          "nameAr": "ZKTeco"
        },
        {
          "id": 2,
          "nameEn": "Timmy TM-f630",
          "nameAr": "Timmy TM-f630"
        }
      ], item: { value: 'id', label: 'nameAr' }
    }
  ],
  deleteMessage: 'Are you sure you want to Delete?',
  create: {
    url: API.Devices.create,
    body: {
      "branchId": '',
      "machineNameAr": '',
      "machineNameEn": '',
      "machineSN": ''
    },
    verb: 'POST'
  },
  read: {
    url: API.Devices.read,
    body: {
      pageSize: 10,
      pageNumber: 0,
      search: {
        "id": 0,
        "machineSN": "",
        "machineNameAr": "",
        "branchId": 0
      }
    },
    verb: 'POST'
  },
  update: {
    url: API.Devices.update,
    body: {
      "id": 0,
      "branchId": 0,
      "machineNameAr": "",
      "machineNameEn": "",
      "machineSN": ""
    },
    verb: 'POST'
  },
  delete: {
    url: API.Devices.delete,
    query: {
      branchMachineId: ''
    },
    verb: 'POST'
  }
}
export const SpecialWorkingDays = {
  mainTitle: 'Ramadan Working Times',
  title: 'Ramadan Working Times',
  path: [{ title: 'Dashboard', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: undefined,
  columns: [
    {
      name: 'ID',
      backName: 'id'
    },
    {
      name: 'Name',
      backName: 'nameEn'
    },
    {
      name: 'From',
      backName: 'dateFrom'
    },
    {
      name: 'To',
      backName: 'dateTo'
    },
    {
      name: 'Status',
      backName: 'status',
      enum: [
        { value: '1', display: <span className='badge warning status'>Not Started yet</span> },
        { value: '2', display: <span className='badge agree status'>Started</span> },
        { value: '3', display: <span className='badge alert status'>Ended</span> }
      ]
    }
  ],
  createEntityProps: [
    { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' },
    { label: 'From', type: 'date', validation: 'Required from', style: 'col-lg-6', backName: 'dateFrom' },
    { label: 'To', type: 'date', validation: 'Required to', style: 'col-lg-6', backName: 'dateTo' },

  ],
  updateEntityProps: [
    { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' },
    { label: 'From', type: 'date', validation: 'Required from', style: 'col-lg-6', backName: 'dateFrom' },
    { label: 'To', type: 'date', validation: 'Required to', style: 'col-lg-6', backName: 'dateTo' },
  ],
  deleteMessage: 'Are you sure you want to Delete?',
  create: {
    url: API.SpecialWorkingDays.create,
    body: {
      "nameEn": "",
      "nameAr": "",
      "dateFrom": "",
      "dateTo": ""
    },
    verb: 'POST'
  },
  read: {
    url: API.SpecialWorkingDays.read,
    body: {
      "pageSize": 10,
      "pageNumber": 0,
      "search": {
        "id": 0,
        "nameAr": "",
        "nameEn": ""
      }
    },
    verb: 'POST'
  },
  update: {
    url: API.SpecialWorkingDays.update,
    body: {
      "id": 0,
      "nameEn": "",
      "nameAr": "",
      "dateFrom": "",
      "dateTo": ""
    },
    verb: 'POST'
  },
  delete: {
    url: API.SpecialWorkingDays.delete,
    query: {
      durationRamadanWorId: ''
    },
    verb: 'POST'
  }
}

export const UserPermission = {
  // test: true, // To use testing local data instead of server data
  mainTitle: 'User Permissions',
  title: 'User Permissions',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  columns: [
    { name: 'ID', backName: 'id' },
    { name: 'Group Name', backName: 'nameEn' },
    {
      name: 'Permissions',
      backName: 'groupPermissions',
      link: { to: '/En/TAW/Main/UserPermission/Permissions/', routeValues: ['id', 'nameEn'], text: 'Permissions' }
    },
    {
      name: 'Users',
      backName: 'users',
      link: { to: '/En/TAW/Main/UserPermission/Users/', routeValues: ['id', 'nameEn'], text: 'Users' }
    }
  ],
  createEntityProps: [
    { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' }
  ],
  updateEntityProps: [
    { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' }
  ],
  deleteMessage: 'Are you sure you want to Delete this Group?',
  create: {
    url: API.UserPermission.create,
    body: {
      "nameAr": "",
      "nameEn": ""
    },
    verb: 'POST'
  },
  read: {
    url: API.UserPermission.read,
    body: {
      "pageSize": 100,
      "pageNumber": 0,
      "search": {
        "id": 0,
        "nameAr": "",
        "nameEn": ""
      }
    },
    verb: 'POST'
  },
  update: {
    url: API.UserPermission.update,
    body: {
      "id": 0,
      "nameAr": "",
      "nameEn": ""
    },
    verb: 'POST'
  },
  delete: {
    url: API.UserPermission.delete,
    query: {
      DeleteCustomGroupId: ''
    },
    verb: 'POST'
  }
}
export const GeneralSettings = {
  mainTitle: 'General Settings',
  title: 'General Settings',
  path: [{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }],
  createEntityProps: [
    { label: 'Reject duplicate transaction(minutes)', type: 'text',noFormCont: true, validation: '', style: '', backName: '' },
    { label: 'Number of Shifts in Report', type: 'select',noFormCont: true, validation: '', style: '', backName: '' },
    { label: 'Calculating overtime before shift start', type: 'text',noFormCont: true, validation: '', style: '', backName: '' },
    { label: ' calculating overtime after shift end', type: 'text',noFormCont: true, validation: '', style: '', backName: '' },
    { label: ' count The day as an absence after delay', type: 'checkbox', noFormCont: true, validation: '', style: '', backName: '' },
    { label: ' count the day as an absence after Leaving early', type: 'checkbox', noFormCont: true, validation: '', style: '', backName: '' },
    { label: 'Allow first trans. IN and Last trans. OUT', type: 'checkbox', noFormCont: true, validation: '', style: '', backName: '' }
  ],
  updateEntityProps: [
    { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' },
    {
      label: 'Manager', type: 'select', validation: '', style: 'col-lg-6', backName: 'managerId', url: API.Employees.read,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameEn' }
    }
  ],
  create: {
    url: API.Branches.create,
    body: {
      nameAr: '',
      nameEn: '',
      managerId: ''
    },
    verb: 'POST'
  },
  read: {
    url: API.Branches.read,
    body: {
      pageSize: 10,
      pageNumber: 0,
      search: {
      }
    },
    verb: 'POST'
  },
  update: {
    url: API.Branches.update,
    body: {
      id: '',
      nameAr: '',
      nameEn: '',
      managerId: ''
    },
    verb: 'POST'
  },
  delete: {
    url: API.Branches.delete,
    query: {
      branchId: ''
    },
    verb: 'POST'
  }
}