import React, { useState } from 'react';
import { Button, Popover, PopoverHeader, PopoverBody } from "reactstrap";

import 'popper.js';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import './TextInput.css';
import TimeField from 'react-simple-timefield';
// import DatePicker from 'react-date-picker';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment'
import MomentUtils from '@date-io/moment'
class TextInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            input: '',
            value: '',
            popoverOpen: false,
            popOverValue: props.value
        }
        this.togglePopover = this.togglePopover.bind(this);

    }


    onInputChange(e) {
        this.setState({ input: e.currentTarget.value, value: e.currentTarget.value });
        console.log(this.state.input);
    }
    togglePopover() {
        this.setState({ popoverOpen: !this.state.popoverOpen })
    }


    render() {
      require('moment/locale/en-au');
        const { popoverOpen } = this.state;
        const { type /* Default: text */, cols, rows, ar, target, className, placeholder, onChange, value, disabled, arDate, popover } = this.props
        if (popover) {
            return (
                <div>
                    <input id={target} type="" value={value} onClick={() => this.setState({ popOverValue: value })} className="popover-btn mb-1" />
                    <Popover
                        placement="bottom"
                        isOpen={popoverOpen}
                        target={target}
                        toggle={this.togglePopover}
                    >
                        <PopoverBody>
                            <div className={`d-flex ${ar ? "ar" : " "}`}>
                                <input disabled={disabled}
                                    type={type ? type : "text"}
                                    className={`TextInput${type ? " " + type : " text"}${className ? " " + className : ""}${disabled ? " disabled" : ""}
                                ${arDate ? ' ar-dateStyle' : null}`}
                                    placeholder={placeholder ? placeholder : "write..."}
                                    onChange={
                                        (e) => {
                                            this.setState({ popOverValue: e.currentTarget.value })
                                            onChange(e.currentTarget.value)
                                        }
                                    }
                                    accept={type === 'file' ? 'image/*' : undefined}
                                    value={this.state.popOverValue}
                                    max={type === 'date' ? '9999-12-31' : undefined}
                                    min={type === 'number' ? 0 : undefined}

                                />
                                <button type="button" class="btn btn-primary btn-popover" onClick={() => {
                                    onChange(this.state.popOverValue);
                                    this.setState({ popoverOpen: false });
                                }} >
                                    <FontAwesomeIcon icon={faCheck} color="#fff"
                                    ></FontAwesomeIcon>
                                </button>

                                <button type="button" class="btn btn-danger btn-popover" onClick={() => {
                                    this.setState({ popoverOpen: false });
                                }}  >
                                    <FontAwesomeIcon icon={faTimes} color="#fff"> </FontAwesomeIcon>
                                </button>
                            </div>
                        </PopoverBody>
                    </Popover>
                </div>
            )
        }
        else {
            if (type === 'time') {
                return (
                    <TimeField

                        value={value}
                        onChange={(e, value) => { onChange(value) }}
                        disabled={disabled}
                        style={{ width: '100%', textAlign: 'center' }}
                    />
                )
            }
            else if (type === 'date') {
                return (
                    //     <ModernDatepicker 
                    //     date={value} 
                    //     format={'YYYY-MM-DD'} 
                    //     showBorder        
                    //     onChange={(date) => onChange(date)}
                    //     placeholder={placeholder}
                    //   />

                    // <DatePicker
                    //     onChange={(date) => {
                    //         // date = date?.substring(0, 10);
                    //         console.log("date--->", date)
                    //         onChange(date);
                    //     }}
                    //     value={"2020-10-29"}
                    //     for='yyyy-MM-dd'
                    // />
                    // <DateInput
                    //     value={value}
                    //     dateFormat='YYYY-MM-DD'
                    //     disabled={false}
                    //     locale="en"
                    //     onChange={(date) => {
                    //         date = date?.substring(0,10);
                    //         onChange(date);
                    //     }}
                    //     placeholder={placeholder}
                    // />
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            id="date-picker-dialog"
                            format="yyyy-MM-DD"
                            variant="inline"
                            value={value ? 
                                moment(value).format("yyyy-MM-DD") 
                                : null}
                            onChange={(date) => {
                                if (date) {
                                    onChange(moment(date).format("yyyy-MM-DD"));
                                }
                                else {
                                    onChange(moment('').format("yyyy-MM-DD"));
                                }

                            }}
                            autoOk
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}

                        />
                    </MuiPickersUtilsProvider>

                    //  <DatePicker value={value} onChange={(date) => { onChange(date); }} />
                )
            }
            else if (type == 'textarea') {
                return (
                    <textarea
                        onChange={(e) => { onChange(e.currentTarget.value) }}
                        placeholder={placeholder ? placeholder : "write..."}
                        value={value}
                        className={`${className ? " " + className : ""}${disabled ? " disabled" : ""}
                     ${arDate ? ' ar-dateStyle' : null}`}
                        rows={rows}
                        cols={cols}
                    >
                    </textarea>
                )

            }
            else {
                return (

                    <input disabled={disabled}
                        type={type ? type : "text"}
                        className={`TextInput${type ? " " + type : " text"}${className ? " " + className : ""}${disabled ? " disabled" : ""}
                     ${arDate ? ' ar-dateStyle' : null}`}
                        placeholder={placeholder ? placeholder : "write..."}
                        onChange={
                            (e) => { onChange(e.currentTarget.value) }
                        }
                        accept={type === 'file' ? 'image/*' : undefined}
                        value={value}
                        max={type === 'date' ? '31-12-9999' : undefined}
                        min={type === 'number' ? 0 : undefined}
                    />
                )
            }
        }
    }

}
export default TextInput;