import React from 'react';
import './Filters.css';
import Button from './../Button/Button';
import ReactTooltip from "react-tooltip";
import Field from '../Field/Field';

const Filters = (props) => {
  if (props.Search) {
    props.SearchFields.push(
      <div className="form-group col d-flex justify-content-end mt-3">
        <Button
          className="btn btn-success btn-block search en"
          onClick={() => props.Search()}
        >
          Search
      </Button>
      </div>
);
  }
  return (
    <section className="Filters">
      <div className="Search--form-body">
        <div className="row w-100 align-items-end">
          {props.SearchFields}
        </div>
        <div className="right">
          <Button
            className="Button rotate"
            onClick={() => props.Clear()}
          >
            <i data-tip="Reset Filters" className="fa fa-repeat"></i>
          </Button>
          <ReactTooltip data-place="top" data-type="success" />
        </div>
      </div>
    </section>
  )
}


function hideFilters() {
  const filters = document.getElementsByClassName("Filters");
  const hideFiltersButton = document.querySelector("#hideFiltersButton");
  const showFiltersButton = document.querySelector("#showFiltersButton");
  for (let item of filters) {
    item.classList.add("d-none");
  }
  hideFiltersButton.classList.add("d-none");
  showFiltersButton.classList.remove("d-none");
}

function showFilters() {
  const filters = document.getElementsByClassName("Filters");
  const hideFiltersButton = document.querySelector("#hideFiltersButton");
  const showFiltersButton = document.querySelector("#showFiltersButton");
  for (let item of filters) {
    item.classList.remove("d-none");
  }
  hideFiltersButton.classList.remove("d-none");
  showFiltersButton.classList.add("d-none");
}

export default Filters;
export { hideFilters, showFilters }