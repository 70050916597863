import React from 'react';
import logo from './apex-logo-light-blue.png';
import company from '../../logo-white.png';
import './StartLayout.css';
import { Route } from "react-router-dom";
import LoginAr from '../LoginAr/LoginAr';
import RegisterAr from '../RegisterAr/RegisterAr';
import ChooseAppAr from '../ChooseAppAr/ChooseAppAr';
import ForgotPasswordAr from '../ForgotPasswordAr/ForgotPasswordAr';
import { Link } from "react-router-dom";
import { Dropdown } from 'react-bootstrap';

function StartLayoutAr(props) {
  return (
    <div className={""}>
      <div dir={'rtl'} className={"login-background"} style={{ width: '100%' }}>
        {/* Nav bar */}
        <nav className="StartLayout--">
          <div className="StartLayout--container">
            <img className="StartLayout--logo" src={logo} alt="LOGO" />
            {/* Choose Lang */}
            <Dropdown className="StartLayout--lang">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                العربية
               </Dropdown.Toggle>

              <Dropdown.Menu className="text-center">
                <Dropdown.Item href="#"><Link to='/En'>
                  English
                 </Link></Dropdown.Item>
                <Dropdown.Item href=""><Link to='/'>
                  العربية
                    </Link></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            
          </div>
        </nav>
          {/* Routes */}
        <section className="StartLayout-- ">
          {/* Write Company name */}
          {/* thair is two ways first component name 
          second : render{()=>{<compnent tag with  props > </compnent>}} */}

          <Route exact={true} path="/" component={ChooseAppAr} />
          {/* Login */}
          <Route path="/Ar/TAW/Start/Login/:companyName" render={({ match }) =>
            <LoginAr
              companyName={match.params.companyName}
            />
          }
          />
          {/* Forgot Password */}
          <Route path="/Ar/TAW/Start/ForgotPassword/:companyName" render={({ match }) =>
            <ForgotPasswordAr
              companyName={match.params.companyName}
            />
          }
          />
        </section>

      </div>

      {/* Footer */}
      <div className="bottom-outer">
        <div className="row w-100">
          <div className="col-md-5 text-left fs-10 text-mobile-center">
            جميع الحقوق محفوظة لمؤسسة طيف الألماس التجارية ©
      </div>
          <div className="col-md-2 d-flex justify-content-center text-mobile-center">
            <p className="m-0"> تواصل  معنا </p>
            <a class="fb" href="https://www.facebook.com"></a>
            <a class="tw" href="https://twitter.com"></a>
          </div>
          <div className="col-md-5 text-left d-flex justify-content-end fs-10 text-mobile-center">
            <a href="https://taif-alalmas.com/">
              <img className="company-logo" src={company} alt="LOGO" />
            </a>
            Powered by
          </div>

        </div>
      </div>
    </div>
  );
}

export default StartLayoutAr;