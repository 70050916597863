import React from 'react';
import './Table.css';

const Table = (props) => {
  return (
    <div className="Table--">
      {
        <table id={`${props.id? props.id : "my-table"}`} className="table2excel table table-striped custom-table mb-0 datatable dataTable no-footer">
         
     
            {props.totals ?
            <thead>{props.totals }</thead>
            :
            null}
          <thead>
            <tr className="TableHeaders">
              {props.TableHeaders}
            </tr>
          </thead>
          <tbody>

            {props.TableHeaders2 ? <tr> {props.TableHeaders2} </tr> : null}

            {props.data}
          </tbody>
        </table>
      }
    </div>
  )
}

export default Table;