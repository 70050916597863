import React from 'react';
import './MainHeaderAr.css';
import logo from './apex-logo-white.png';
import { Link } from 'react-router-dom';
import Cookie from "js-cookie";
import Lists from './../Lists/Lists';
import ar from '../../ar.jpg'
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH, faEllipsisV
} from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from 'react-bootstrap';
import us from '../../us.png';
import avatar from '../../avatar.png';

const MainHeaderAr = () => {
  let currentUrl = window.location.hash;
  currentUrl = currentUrl.replace('/Ar/', '/En/');

  return (
    <header className='MainHeader-- header ar'>
      <div className='right-header-sec'>
        <div className="header-right">
          <Link className='logo' to='/Ar/TAW/Main/Dashboard'>
            <img className='header--logo' src={logo} alt='LOGO' height={'60px'} />
          </Link>
        </div>
        <div className="page-title-box">
          <h3> TA SOFTWARE</h3>
        </div>
      </div>



      <ul className="nav user-menu">
        <li className="nav-item">
          <div className="top-nav-search">
            <a  className="responsive-search">
              <i className="fa fa-search"></i>
            </a>
            <form >
              <input className="form-control header-input text-right" type="text" placeholder="إبحث هنا " />
              <button className="btn" type="submit"><i className="fa fa-search"></i></button>
            </form>
          </div>
        </li>
        <li className="nav-item">
          {/* Change Language */}
          <Dropdown className="hover">
            <Dropdown.Toggle variant="link" className='nav-link dropdown-toggle' id="dropdown-basic">
              <img src={ar} alt="" width="30" height="20" style={{ margin: '0px 10px' }} />
              <span>العربية </span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="text-center">
              <Dropdown.Item href="#"><Link onClick={(e) => {
                e.preventDefault()
                let currentUrl = window.location.hash;
                currentUrl = currentUrl.replace('/Ar/', '/En/');
                window.location.hash = currentUrl;
              }} >
                <img src={us} alt="" width="30" height="30" style={{ margin: '0px 10px' }} />
                <span>English </span>
              </Link></Dropdown.Item>
              <Dropdown.Item href=""><Link onClick={(e) => {
                e.preventDefault()
                let currentUrl = window.location.hash;
                currentUrl = currentUrl.replace('/En/', '/Ar/');
                window.location.hash = currentUrl;
              }} >
                <img src={ar} alt="" width="30" height="20" style={{ margin: '0px 10px' }} />
                <span>العربية </span>
              </Link></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

        </li>
        <li className="nav-item">
          <a className="nav-link" style={{ position: 'relative' }}>
            <i className="fa fa-bell-o"></i>
            <span className="badge badge-pill" style={{ height: "16px", padding: "3px 5px" }}>0</span>
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" style={{ position: 'relative' }}>
            <i className="fa fa-comment-o"></i>
            <span className="badge badge-pill" style={{ height: "16px", padding: "3px 5px" }}>0</span>
          </a>
        </li>
        <li className="nav-item">
          <Dropdown className="hover">
            {/* Username */}
            <Dropdown.Toggle variant="link" className='nav-link dropdown-toggle' id="dropdown-basic">
              <span className="user-img">
                <img src={avatar} alt="" width="40" height="40" style={{ borderRadius: '50%' }} />
                <span className="status online"></span>
              </span>
              <a style={{ marginLeft: '5px', color: "#fff" }} href='#void' className='en' onClick={(e) => {
                e.preventDefault()
              }}>{Cookie.get('userName')}</a>
            </Dropdown.Toggle>

            <Dropdown.Menu className="text-center">
              <Dropdown.Item >
                <span className='en'>
                  <Link style={{ padding: '0px' }} to={'/Ar/TAW/Main/ChangePassword'}>
                    <span style={{ fontSize: "14px" }}>تغيير كلمة المرور</span>
                  </Link>
                </span>
              </Dropdown.Item>
              {/* Logout clears token and username cookies and redirect to the current company login page */}
              <Dropdown.Item >
                <span onClick={() => {
                  Cookie.set('token', '', { SameSite: 'Lax' });
                  Cookie.set('userName', '', { SameSite: 'Lax' });
                }} className='en'>
                  <Link style={{ padding: '0px' }} to={'/Ar/TAW/Start/Login/' + Cookie.get('companyName')}>
                    <i class="fa fa-sign-out" aria-hidden="true" ></i>
                    <span style={{ fontSize: "14px" }}>تسجيل الخروج</span>
                  </Link>
                </span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

        </li>

       
      </ul>
      <ul className="dropdown mobile-user-menu d-flex d-md-none">
        <li className="nav-item">
          <Dropdown className="hover">
            <Dropdown.Toggle variant="link" className='nav-link dropdown-toggle' id="dropdown-basic2">
              <img src={ar} alt="" width="20" height="20" style={{ margin: '0px 5px', zIndex: 60 }} />
              <span style={{ color: "#fff" }}>ع </span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="text-center">
              <Dropdown.Item href="#"><Link onClick={(e) => {
                e.preventDefault()
                let currentUrl = window.location.hash;
                currentUrl = currentUrl.replace('/Ar/', '/En/');
                window.location.hash = currentUrl;
              }} >
                <img src={us} alt="" width="20" height="20" style={{ margin: '0px 5px' }} />
                <span>English </span>
              </Link></Dropdown.Item>
              <Dropdown.Item href=""><Link onClick={(e) => {
                e.preventDefault()
                let currentUrl = window.location.hash;
                currentUrl = currentUrl.replace('/En/', '/Ar/');
                window.location.hash = currentUrl;
              }} >
                <img src={ar} alt="" width="20" height="20" style={{ margin: '0px 5px' }} />
                <span>العربية </span>
              </Link></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

        </li>
        <li className="nav-item">
          <Dropdown className="hover">
            <Dropdown.Toggle variant="link" className='nav-link dropdown-toggle' id="Mdropdown-basic">
              <FontAwesomeIcon className="mobile-icon" size={2} icon={faEllipsisV}></FontAwesomeIcon>
            </Dropdown.Toggle>

            <Dropdown.Menu className="text-center">
              <Dropdown.Item >
                <span onClick={() => {
                  Cookie.set('token', '', { SameSite: 'Lax' });
                  Cookie.set('userName', '', { SameSite: 'Lax' });
                }} className='en'>
                  <Link style={{ padding: '0px' }} to={'/Ar/TAW/Start/Login/' + Cookie.get('companyName')}>
                    <i className="fa fa-sign-out" aria-hidden="true" ></i>
                    <span style={{ fontSize: "14px" }}>تسجيل الخروج</span>
                  </Link>
                </span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

        </li>
      </ul>
    </header>
  )
}
export default MainHeaderAr;