import React from 'react';
import './Login.css';
import { Link, Redirect } from "react-router-dom";
import Cookie from "js-cookie";
import Loading from '../Loading/Loading';
import Overlay from '../Overlay/Overlay';
import Alert from '../Alert/Alert';
import Button from '../Button/Button';
import { ip } from '../../data/APIs';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyName: '',
      username: '',
      email: '',
      message: '',
      alertClass: 'success',
      loading: false,
      exist: false,
      checked: false
    }
  }
  
  componentDidMount() {
    this.Check();
    this.focusInput.focus();

    var inputFields = Array.from(document.getElementsByTagName("input")); // Array.from is to be able to deal with the NodeList as an array
    inputFields.forEach(inputField => {
      inputField.addEventListener("keyup", function (event) {
        if (event.keyCode === 13) {
          event.preventDefault();
          document.getElementById("submitButton").click();
        }
      });

    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.companyName !== this.props.companyName) {
      this.Check();
    }
  }

  ChangeState(event, name) {
    this.setState({ [name]: event.target.value });
  }

  Check() {
    this.setState({ loading: true });
    fetch(ip + '/api/CheckCompanyName?CompanyUniqueName=' + this.props.companyName, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'lang': 'ar'
      }
    })
      .then(response => response.json())
      .then((data) => {
        if (data.success) {
          this.setState({ exist: data.data, checked: true, loading: false });
        } else {
          console.error(data);
        }
      })
      .catch(error => console.error('Unable to Check.', error));
  }

  Submit() {
    let user = {
      companyName: this.props.companyName,
      userName: this.state.username,
      email: this.state.email,
    }
    this.setState({ loading: true });
    fetch(ip + '/api/ForgotPassword', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'lang': 'en'
      },
      body: JSON.stringify(user)
    })
      .then(response => response.json())
      .then((data) => {
        if (data.success && data.data !== null) {

          this.setState({ alertClass: 'success', message: data.message, loading: false });
        } else {
          this.setState({ alertClass: 'danger', message: data.message, loading: false });
        }
      })
      .catch(error => console.error('Unable to Login.', error));
  }

  render() {
    return (
      <section className="Login" >

        <Overlay visible={this.state.loading}>
          <Loading visible={this.state.loading} />
        </Overlay>

        <div className="StartLayout--container">
          <div className="text-right">
            <h2 className="LoginBoxTtAR"> Forget my password</h2>
          </div>
          <div className="Login--form row">
            {
              this.state.message ?
                <Alert className={"Alert " + (this.state.alertClass ? this.state.alertClass : 'danger')}>{this.state.message}</Alert>
                :
                null
            }

            <div className="Login--form-field col-md-4">
              <label htmlFor=""> User name </label>

              <input
                type="text"
                value={this.state.username}
                onChange={(e) => this.ChangeState(e, 'username')}
                ref={(input) => { this.focusInput = input; }}

              />
            </div>
            <div className="Login--form-field col-md-4">
              <label htmlFor=""> Email</label>
              <input
                type="email"
                value={this.state.email}
                onChange={(e) => this.ChangeState(e, 'email')}
              />

            </div>
            <div class="col-md-4 d-flex align-items-end" >
              <Button
                id="submitButton"
                className="Button square gradient"
                onClick={() => this.Submit()}
              >
                Send
            </Button>
            </div>

          </div>
        </div>
        {!this.state.exist && this.state.checked ? <Redirect to={"/"} /> : null}
      </section>
    );
  }
}

export default ForgotPassword;