/* eslint-disable eqeqeq */
import React from 'react';
import './BasicPage.css';
import { Link } from "react-router-dom";
import Table from './../Table/Table';
import Cookie from "js-cookie";
import Modal from './../Modal/Modal';
import PageHeader from '../PageHeader/PageHeader';
import Filters, { hideFilters, showFilters } from '../Filters/Filters';
import TableFooter from '../TableFooter/TableFooter';
import Loading from '../Loading/Loading';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import Exceptions from '../Exceptions/Exceptions';
import Overlay from '../Overlay/Overlay';
import ShiftEndNextDay from '../ShiftEndNextDay/ShiftEndNextDay';
import TextInput from '../TextInput/TextInput';
import Dropdown from '../Dropdown/Dropdown';
import SelectShift from '../SelectShift/SelectShift';
import PermissionTypes from '../PermissionTypes/PermissionTypes';
import Field from '../Field/Field';
import Button from './../Button/Button';
// import { upLayer, downLayer } from '../MainLayout/MainLayout';

// To use testing local data instead of server data
import { testBranches } from './../../data/testData/Branches';
import Alert from './../Alert/Alert';


class BasicPage extends React.Component {
  constructor(props) {
    super(props);


    let tempReadEntity = props.read.body;
    if (props.read.body && props.searchFields) {
      for (let field of props.searchFields) {
        if (field.backSearch) {
          tempReadEntity.search[field.backName] = '';
        }
      }
    }

    this.state = {
      createEntity: props.create.body,
      readEntity: props.read.body ? props.read.body : props.read.path,
      updateEntity: props.update.body,
      deleteEntity: props.delete.query ? props.delete.query : props.delete.body,
      createErrors: [],
      updateErrors: [],
      rowsNumber: 10,
      rowsTotal: 0,
      currentPage: 1,
      message: { text: '', style: '' },
      loading: false,
      add: false,
      edit: false,
      delete: false,
      search: false,
      data: [],
      modalUpdated: false,
      loadedPermissions: false,
      'Device type': [
        {
          "id": 1,
          "nameEn": "ZKTeco",
          "nameAr": "ZKTeco"
        },
        {
          "id": 2,
          "nameEn": "Timmy TM-F630",
          "nameAr": "Timmy TM-F630"
        }
      ]
    }
    // Get the Search Fields 'select elements' items from the Database when the page displayed for the first time
    if (props.searchFields) { // to prevent undefined error
      for (let field of props.searchFields) {
        if (field.type === 'select') {
          this.GetSelect(field.url, field.body, field.label);
        }
      }
    }

    // Get the Create & Update 'select elements' items from the Database when the page displayed for the first time
    for (let prop of props.createEntityProps) {
      // if the items already exist in the Search Field Don't Get it from the Database again
      if (prop.type === 'select' && props.searchFields.find(field => field.label === prop.label) === undefined) {
        this.GetSelect(prop.url, prop.body, prop.label);
        // selectShift Components don't have a Label
      } else if (prop.type === 'selectShift') {
        this.GetSelect(prop.url, prop.body, prop.label);
      }
    }
  }

  // Styling:
  // ========
  showModal(modalName) {
    // const sideNav = document.querySelector("#sideNav");
    const mainLayout = document.querySelector("#mainLayout");
    this.setState({ [modalName]: true });
    // sideNav.style.overflow = "visible";
    mainLayout.style.overflow = "visible";
  }

  hideModal(modalName) {
    // const sideNav = document.querySelector("#sideNav");
    const mainLayout = document.querySelector("#mainLayout");
    this.setState({ [modalName]: false });
    // sideNav.style.overflow = "auto";
    // mainLayout.style.overflow = "auto";

    // return the Modal elements to the default State
    if (modalName === 'add') {
      const entity = this.state.createEntity;
      for (let field of this.props.createEntityProps) {
        if (field.type === 'text' || field.type === 'email' || field.type === 'select' || field.type === 'date' || field.type === 'time' || field.type === 'password') {
          entity[field.backName] = '';
        }
        if (field.type === 'checkbox') {
          if (field.label === 'Active') {
            entity[field.backName] = true;
          } else {
            entity[field.backName] = false;
          }
        }
        if (field.type === 'shiftEndNextDay') {
          entity[field.backName[0]] = false;
          entity[field.backName[1]] = '';
        }
        if (field.type === 'exceptions') {
          let backNames = [];
          for (const backName of field.backName) {
            backNames.push(backName);
          }
          for (const backName of backNames) {
            entity[backName] = false;
          }
        }
        if (field.type === 'selectShift') {
          let backNames = [];
          for (const backName of field.backName) {
            backNames.push(backName);
          }
          for (const backName of backNames) {
            entity[backName] = '';
          }
        }
        if (field.type === 'permissionType') {
          let backNames = [];
          for (const backName of field.backName) {
            backNames.push(backName);
          }
          for (const backName of backNames) {
            entity[backName] = '';
          }
        }
      }
      this.setState({ createEntity: entity, createErrors: [] })
    } else if (modalName === 'search') {
      const entity = this.state.createEntity;
      entity[this.props.modalBackName] = [];
      this.setState({ createEntity: entity, createErrors: [] })
    } else if (modalName === 'edit') {
      const entity = this.state.updateEntity;
      for (let field of this.props.createEntityProps) {
        if (field.type === 'text' || field.type === 'email' || field.type === 'select' || field.type === 'date' || field.type === 'time' || field.type === 'password') {
          entity[field.backName] = '';
        }
        if (field.type === 'checkbox') {
          entity[field.backName] = true;
        }
        if (field.type === 'shiftEndNextDay') {
          entity[field.backName[0]] = false;
          entity[field.backName[1]] = '';
        }
      }
      this.setState({ updateEntity: entity, updateErrors: [] })
    }
  }
  // ========

  componentDidMount() {
    if (this.props.permissions !== null) {
      this.Clear()
    } else {
      let me = this;
      setTimeout(function () {
        me.componentDidMount();
      }, 1000);
    }

    // To rise Lists Component up:
    // const lists = document.querySelector("#lists");
    // lists.addEventListener("mouseenter", () => { downLayer(); });
    // lists.addEventListener("mouseleave", () => { upLayer(); });
  }

  // A method for changing the entity properties,
  // inputs:
  // state = Name of the entity, e.g: createEntity, updateEntity ... 
  // name = Name of the property, e.g: createEntity.nameAr, updateEntity.nameEn ... 
  // value = value of the property, e.g: createEntity.nameAr = 'أحمد', updateEntity.nameEn = 'Ahmad'... 
  ChangeEntityState(state, name, value) {
    let tempEntity = this.state[state];
    tempEntity[name] = value;
    this.setState({ [state]: tempEntity });
  }

  // A method for changing the state,
  // inputs:
  // key = Name of the state
  // value = value of the state
  ChangeState(key, value) {
    this.setState({ [key]: value });
  }

  // A method for Validating the user inputs before Creating or Updating,
  // inputs:
  // entity = the entity that will be sent to the Server for Creating or Updating, e.g: current createEntity.
  // output:
  // Array of errors if exist, otherwise an empty Array
  Validation(entity) {
    let errors = [];
    for (let prop of this.props.createEntityProps) {
      if (prop.validation) {
        if (prop.validation.includes('Required')) {
          if (!entity[prop.backName]) {
            errors = errors.concat(prop.label + ' field is Required.');
          }
        }
        if (prop.validation.includes('ConditionalReq')) {
          if (entity[prop.RequiredCondition] && !entity[prop.backName]) {
            errors = errors.concat(prop.label + ' field is Required.');
          }
        }
        if (prop.validation.includes('orReq')) {
          if (!entity[prop.RequiredCondition] && !entity[prop.backName]) {
            errors = errors.concat(prop.label + ' field is Required.');
          }
        }
        if (prop.validation.includes('from')) {
          let toProp = this.props.createEntityProps.find(prop => prop.validation.includes('to'));
          if ((Date.parse(entity[prop.backName]) > Date.parse(entity[toProp[4]]))) {
            errors = errors.concat(prop.label + ' field Should be less than or equal to "' + toProp[0] + '" field.');
          }
        }
        if (prop.validation.includes('to')) {
          let fromProp = this.props.createEntityProps.find(prop => prop.validation.includes('from'));

          if (Date.parse(entity[prop.backName]) < Date.parse(entity[fromProp[4]])) {
            errors = errors.concat(prop.label + ' field Should be greater than or equal to "' + fromProp[0] + '" field.');
          }
        }
      }

      if (prop.type === 'email') {
        let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (entity[prop.backName] && !entity[prop.backName].match(mailformat)) {
          errors = errors.concat(prop.label + ' field should be a valid email address.');
        }
      }
      if (prop.type === 'shiftEndNextDay') {
        if (entity[prop.backName[0]]) {
          if (!entity[prop.backName[1]]) {
            errors = errors.concat(prop.label[1] + ' field is Required.');
          }
        }
      }
      if (prop.type === 'selectShift') {
        if (!entity[prop.backName[0]]) {
          errors = errors.concat('Employee field is Required.');
        }
        if (!entity[prop.backName[1]]) {
          errors = errors.concat('Date field is Required.');
        }
        if (!entity[prop.backName[2]]) {
          errors = errors.concat('Selecting a Shift is Required.');
        }
        if (entity[prop.backName[2]] === 'open') {
          entity[prop.backName[2]] = '';
        }
      }
      if (prop.type === 'permissionType') {
        if (!entity[prop.backName[0]]) {
          errors = errors.concat('Permission Type field is Required.');
        } else {
          let timeIn;
          let timeOut;
          let shiftIn;
          let shiftOut;
          let time;
          switch (entity[prop.backName[0]]) {
            case 1:
              if (!entity[prop.backName[1]]) {
                errors = errors.concat('Time In field is Required.');
              } else {
                if (entity.periodShiftId) {
                  time = entity[prop.backName[1]].split(':');
                  timeIn = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).inTime.split(':');
                  shiftIn = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).outTime.split(':');
                  shiftOut = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  if (!(timeIn >= shiftIn && timeIn <= shiftOut)) {
                    errors = errors.concat('Permission Time In Value should be between Shift Time In and Shift Time Out.');
                  }
                }
              }
              if (!entity[prop.backName[2]]) {
                errors = errors.concat('Time Out field is Required.');
              } else {
                if (entity.periodShiftId) {
                  time = entity[prop.backName[2]].split(':');
                  timeOut = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).inTime.split(':');
                  shiftIn = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).outTime.split(':');
                  shiftOut = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  if (!(timeOut >= shiftIn && timeOut <= shiftOut)) {
                    errors = errors.concat('Permission Time Out Value should be between Shift Time In and Shift Time Out.');
                  }
                }
              }

              break;
            case 2:
              if (!entity[prop.backName[1]]) {
                errors = errors.concat('Time In field is Required.');
              } else {
                if (entity.periodShiftId) {
                  time = entity[prop.backName[1]].split(':');
                  timeIn = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).inTime.split(':');
                  shiftIn = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).outTime.split(':');
                  shiftOut = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  if (!(timeIn >= shiftIn && timeIn <= shiftOut)) {
                    errors = errors.concat('Permission Time In Value should be between Shift Time In and Shift Time Out.');
                  }
                }
              }
              break;
            case 3:
              if (!entity[prop.backName[2]]) {
                errors = errors.concat('Time Out field is Required.');
              } else {
                if (entity.periodShiftId) {
                  time = entity[prop.backName[2]].split(':');
                  timeOut = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).inTime.split(':');
                  shiftIn = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).outTime.split(':');
                  shiftOut = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  if (!(timeOut >= shiftIn && timeOut <= shiftOut)) {
                    errors = errors.concat('Permission Time Out Value should be between Shift Time In and Shift Time Out.');
                  }
                }
              }
              break;
            default:
              break;
          }
        }
      }
    }
    return errors;
  }

  // A method for making a fetch Request,
  // inputs:
  // entity = the entity that will be sent to the Server for Creating, Reading, Updating, or Deleting, e.g: current deleteEntity...
  // token = the JWT Barear Authorization token.
  // operation = the operation that will be done against the entity, e.g: create, read, update, or delete
  // then = the method that will be executed when we receive the response
  Fetch(entity, token, operation, then) {
    // for (const key in entity) {
    //   if (entity[key] === '') {
    //     entity[key] = null;
    //   }
    //   // if (!Number.isNaN(Number.parseInt(entity[key])) && typeof entity[key] !== "boolean") {
    //   //   entity[key] = Number.parseInt(entity[key]);
    //   // }
    // }
    // if (entity.search) {
    //   for (const key in entity.search) {
    //     if (entity.search[key] === '') {
    //       entity.search[key] = null;
    //     }
    //     // if (!Number.isNaN(Number.parseInt(entity.search[key])) && typeof entity.search[key] !== "boolean") {
    //     //   entity.search[key] = Number.parseInt(entity.search[key]);
    //     // }
    //   }
    // }

    let url = this.props[operation].path ?
      this.props[operation].url + '/' + Object.keys(entity).map((key) => entity[key]).join('/')
      :
      this.props[operation].query ?
        this.props[operation].url + '?' + Object.keys(entity).map((key) => key + '=' + entity[key]).join('&')
        :
        this.props[operation].url;

    let end;
    let start = Date.now();
    console.log('Start: ' + start);
    fetch(url, {
      method: this.props[operation].verb,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: this.props[operation].body ? JSON.stringify(entity) : null
    })
      .then(response => response.json())
      .then((response) => {
        end = Date.now();
        console.log('End: ' + end);
        console.log('Time: ' + (end - start));
        let me = this;
        then(response, me);
      })
      .catch(error => console.error('Unable to ' + operation + '.', error));
  }

  // A method for Creating a new entity in the Database,
  // inputs:
  // entity = the entity that will be sent to the Server for Creating (current createEntity)
  Create(entity) {
    // Trimming the 'text' user inputs '   ahmed   ' => 'ahmed'
    for (let prop of this.props.createEntityProps) {
      if (prop.type === 'text') {
        entity[prop.backName] = entity[prop.backName] ? entity[prop.backName].toString().trim() : '';
      }
    }

    let errors = this.Validation(entity);

    this.setState({ createErrors: errors });

    //if there is any validation error don't proceed
    if (errors.length === 0) {
      this.setState({ loading: true });
      let token = Cookie.get('token');
      let then = (response, me) => {
        if (response.status === 200 || response.success) {
          if (response.success) {
            if (me.props.useSearchModal) {
              me.hideModal('search');
            }
            me.hideModal('add');

            me.setState({ message: { text: response.message, style: 'success' }, loading: false });
            setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
            me.Read(me.state.readEntity);
          } else {
            if (me.props.useSearchModal) {
              me.hideModal('search');
            }
            me.hideModal('add');
            me.setState({ message: { text: response.message, style: 'danger' }, loading: false });
            setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
          }
        } else {
          if (response.message) {
            errors.push(response.message);
            me.setState({ createErrors: errors, loading: false });
          }
          console.error(response);
        }
      }
      this.Fetch(entity, token, 'create', then);
    }
  }

  // A method for Reading data from Database,
  // inputs:
  // entity = the entity that will be sent to the Server in the reaquest body (readEntity)
  Read(entity) {
    if (this.props.permissions?.data.find(permission => permission.nameEn === 'Get') || this.props.allPermissions) {

      this.setState({ loading: true });
      let token = Cookie.get('token');
      console.log(token);
      let then = (response, me) => {
        if (response.status === 200 || response.success) {
          if (response.success) {
            if (response.data.data) {
              me.setState(
                {
                  data: response.data.data.map((row) => {
                    for (const key in me.props.params) {
                      row[key] = me.props.params[key];
                    }
                    return row;
                  }),
                  rowsTotal: response.data.listCount,
                  loading: false,
                  modalUpdated: false
                }
              );
            } else {
              me.setState(
                {
                  data: response.data.map((row) => {
                    for (const key in me.props.params) {
                      row[key] = me.props.params[key];
                    }
                    return row;
                  }),
                  rowsTotal: response.data.listCount,
                  loading: false,
                  modalUpdated: false
                }
              );
            }
          } else {
            me.setState({ message: { text: response.message, style: 'danger' }, loading: false });
            setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
          }
        } else {
          if (response.message) {
            me.setState({ message: { text: response.message, style: 'danger' }, loading: false });
            setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
          }
          console.error(response);
        }
      }
      this.Fetch(entity, token, 'read', then);
    }

  }

  // A method for Updating an existing entity in the Database,
  // inputs:
  // entity = the entity that will be sent to the Server for Updating (current updateEntity)
  Update(entity) {
    // Trimming the 'text' user inputs '   ahmed   ' => 'ahmed'
    for (let prop of this.props.createEntityProps) {
      if (prop.type === 'text') {
        entity[prop.backName] = entity[prop.backName] ? entity[prop.backName].toString().trim() : '';
      }
    }

    let errors = this.Validation(entity);

    this.setState({ updateErrors: errors });

    // if there is any validation error don't proceed
    if (errors.length === 0) {
      this.setState({ loading: true });
      let token = Cookie.get('token');
      let then = (response, me) => {
        if (response.status === 200 || response.success) {
          if (response.success) {
            me.hideModal('edit');
            me.setState({ message: { text: response.message, style: 'success' }, loading: false });
            setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
            me.Read(me.state.readEntity);
          } else {
            me.hideModal('edit');
            me.setState({ message: { text: response.message, style: 'danger' }, loading: false });
            setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
          }
        } else {
          if (response.message) {
            errors.push(response.message);
            me.setState({ updateErrors: errors, loading: false });
          }
          console.error(response);
        }
      }
      this.Fetch(entity, token, 'update', then);
    }
  }

  // A method for Deleting an existing entity in the Database,
  // inputs:
  // entity = the entity that will be sent to the Server for Delete (current deleteEntity)
  Delete(entity) {
    this.setState({ loading: true });
    let token = Cookie.get('token');
    let then = (response, me) => {
      if (response.status === 200 || response.success) {
        if (response.success) {
          me.hideModal('delete');
          me.setState({ message: { text: response.message, style: 'success' }, loading: false });
          setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
          me.Read(me.state.readEntity);
        } else {
          me.hideModal('delete');
          me.setState({ message: { text: response.message, style: 'danger' }, loading: false });
          setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
        }
      } else {
        if (response.message) {
          me.hideModal('delete');
          me.setState({ message: { text: response.message, style: 'danger' }, loading: false });
          setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
        }
        console.error(response);
      }
    }
    this.Fetch(entity, token, 'delete', then);
  }

  // A method for getting the data of the selected row and putting it in the updateEntity,
  // inputs:
  // id = Selected row ID
  GetForUpdate(id) {
    let tempEntity = this.state.updateEntity;
    for (let prop in tempEntity) {
      tempEntity[prop] = this.state.data.find(item => item.id === id) ? this.state.data.find(item => item.id === id)[prop] === undefined ? tempEntity[prop] : this.state.data.find(item => item.id === id)[prop] : this.state.data.find(item => item[Object.keys(item)[0]] === id)[prop] === undefined ? tempEntity[prop] : this.state.data.find(item => item[Object.keys(item)[0]] === id)[prop];
    }
    this.setState({ updateEntity: tempEntity });
  }

  // A method for getting the id of the selected row and putting it in the deleteEntity,
  // inputs:
  // id = Selected row ID
  GetIdForDelete(id) {
    let tempEntity = this.state.deleteEntity;
    if (Array.isArray(tempEntity[Object.keys(tempEntity)[0]])) {
      tempEntity[Object.keys(tempEntity)[0]] = [id];
    } else {
      tempEntity[Object.keys(tempEntity)[0]] = id;
    }
    this.setState({ deleteEntity: tempEntity });
  }

  // A method for getting the select items from the Database,
  // inputs:
  // url = Web API Service url
  // body = Request body
  // name = the name of the state that the data will be stored in
  GetSelect(url, body, name) {
    let token = Cookie.get('token');
    fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then((response) => {
        if (response.success) {
          this.setState({ [name]: response.data.data });
        } else {
          console.error(response);
        }
      })
      .catch(error => console.error('Unable to Read.', error));
  }

  // A method for Resetting the Search fields values
  Clear() {
    let tempEntity = this.state.readEntity;

    for (let field of this.props.searchFields) {
      if (field.type === 'date') {
        tempEntity.search[field.backName] = '';
      } else if (!Number.isNaN(Number.parseInt(tempEntity.search[field.backName]))) {
        tempEntity.search[field.backName] = 0;
      } else {
        tempEntity.search[field.backName] = '';
      }
    }

    this.setState({ readEntity: tempEntity });
    this.Read(this.state.readEntity);

  }

  render() {
    let me = this;
    let tableRawData = this.state.data;

    // Getting only the required columns for display + the row id
    tableRawData = tableRawData.map((row) => {
      let tablerow = {};
      for (let column of this.props.columns) {
        tablerow[column['backName']] = row[column['backName']];
      }
      tablerow.id = row.id;

      return tablerow;
    });

    // if the tableRawData length changed because of the search, update the rowsTotal for Paging
    // if (tableRawData.length != this.state.rowsTotal) {
    //   this.setState({ rowsTotal: tableRawData.length })
    // }

    // Creating the Breadcrumb links
    let Path = this.props.path.map((folder) => <Link className="TreeNav--" to={folder.path} key={folder.title}>{folder.title}</Link>);

    // Creating the SearchFields
    let SearchFields
    if (this.props.searchFields) { // to prevent undefined error
      SearchFields = this.props.searchFields.map(
        (field) => {
          let items;
          switch (field.type) {
            case 'text':
              return (
                <React.Fragment key={field.label}>
                  <Field
                    title={`${field.label}:`}
                    titleSize={field.titleSize}
                    inputComponent={[
                      <TextInput
                        key={field.label}
                        placeholder={field.label}
                        type="text"
                        value={this.state.readEntity.search[field.backName]}
                        onChange={(value) => {
                          let tempEntity = this.state.readEntity;
                          tempEntity.search[field.backName] = value;
                          this.setState({ readEntity: tempEntity });
                        }}
                      />
                    ]}
                  />
                </React.Fragment>
              )
            case 'number':
              return (
                <React.Fragment key={field.label}>
                  <Field
                    title={`${field.label}:`}
                    titleSize={field.titleSize}
                    inputComponent={[
                      <TextInput
                        key={field.label}
                        placeholder={field.label}
                        type="number"
                        value={this.state.readEntity.search[field.backName]}
                        onChange={(value) => {
                          let tempEntity = this.state.readEntity;
                          tempEntity.search[field.backName] = value;
                          this.setState({ readEntity: tempEntity });
                        }}
                      />
                    ]}
                  />
                </React.Fragment>
              )
            case 'date':
              return (
                <React.Fragment key={field.label}>
                  <Field
                    title={`${field.label}:`}
                    titleSize={field.titleSize}
                    inputComponent={[
                      <TextInput
                        key={field.label}
                        placeholder={field.label}
                        type="date"
                        value={this.state.readEntity.search[field.backName]}
                        onChange={(value) => {
                          let tempEntity = this.state.readEntity;
                          tempEntity.search[field.backName] = value;
                          this.setState({ readEntity: tempEntity });
                        }}
                      />
                    ]}
                  />
                </React.Fragment>
              )
            case 'select':
              if (field.label === 'Management') {
                items = this.state[field.label] === undefined ?
                  []
                  :
                  this.state[field.label].filter(management => management.branchId === this.state.readEntity.search[this.props.searchFields.find(field => field.label === 'Branch').backName])
                    .map((item) => { return { label: item[field.item.label], value: item[field.item.value] } });
              } else if (field.label === 'Department') {
                items = this.state[field.label] === undefined ?
                  []
                  :
                  this.state[field.label].filter(department => department.administrationId === this.state.readEntity.search[this.props.searchFields.find(field => field.label === 'Management').backName])
                    .map((item) => { return { label: item[field.item.label], value: item[field.item.value] } });
              } else {
                items = this.state[field.label] === undefined ?
                  []
                  :
                  this.state[field.label].map((item) => { return { label: item[field.item.label], value: item[field.item.value] } });
              }

              return (
                <Field
                  key={field.label}
                  title={`${field.label}:`}
                  titleSize={field.titleSize}
                  inputComponent={[
                    <Dropdown
                      key={field.label}

                      placeholder={field.label}
                      value={items.find(item => item.value === this.state.readEntity.search[field.backName])}
                      options={items}
                      onChange={(value) => {
                        let tempEntity = this.state.readEntity;
                        tempEntity.search[field.backName] = value;
                        this.setState({ readEntity: tempEntity });
                      }}
                    />
                  ]}
                />
              )
            case 'staticSelect':
              return (
                <Field
                  key={field.label}
                  title={`${field.label}:`}
                  titleSize={field.titleSize}
                  inputComponent={[
                    <Dropdown
                      key={field.label}
                      placeholder={field.label}
                      value={field.items.find(item => item.value === this.state.readEntity.search[field.backName])}
                      options={field.items}
                      onChange={(value) => {
                        let tempEntity = this.state.readEntity;
                        tempEntity.search[field.backName] = value;
                        this.setState({ readEntity: tempEntity });
                      }}
                    />
                  ]}
                />
              )

            default:
              return (
                <Field
                  key={field.label}
                  title={`${field.label}:`}
                  titleSize={field.titleSize}
                  inputComponent={[
                    <TextInput
                      key={field.label}
                      placeholder={field.label}
                      type="text"
                      value={this.state.readEntity.search[field.backName]}
                      onChange={(value) => {
                        let tempEntity = this.state.readEntity;
                        tempEntity.search[field.backName] = value;
                        this.setState({ readEntity: tempEntity });
                      }}
                    />
                  ]}
                />
              )
          }
        }
      );

    }

    // Creating Table Headers
    let TableHeaders = this.props.columns.map((column) => <th key={column['name']}>{column['name']}</th>)
      .concat([me.props.noDelete && me.props.noEdit ? null : <th className="action" key={'Actions'} >Actions</th>]);
     
    // First get the specified page of data then displays it properly
    let TableData = tableRawData
      // .slice(((this.state.rowsNumber * this.state.currentPage) - this.state.rowsNumber), (this.state.rowsNumber * this.state.currentPage))
      .map(
        function (row, index) {
          return (
            <tr key={row.id}>
              {
                Object.keys(row)
                  .map((columnName, columnIndex) => {
                    // if the current property name is 'id' and there is no column with the backname 'id' 
                    if (columnName === 'id' && me.props.columns.find(column => column.backName === 'id') === undefined && !me.props.devices) {
                      // don't render a cell  
                      return null;
                    }
                    if (columnName === 'id' && me.props.devices) {
                      return <td> {index += 1} </td>;
                    }
                    return me.props.columns[columnIndex]['link'] ?
                      <td key={row[0] + ',' + columnIndex}>
                        <Link to={me.props.columns[columnIndex]['link']['to'].toString() + me.props.columns[columnIndex]['link']['routeValues'].map((value) => me.state.data.find(data => data[Object.keys(row)[0]] === row[Object.keys(row)[0]])[value]).join('/')}>
                          {
                            me.props.columns[columnIndex]['link']['icon'] ?
                              me.props.columns[columnIndex]['link']['icon']
                              :
                              me.props.columns[columnIndex]['link']['text'] ?
                                me.props.columns[columnIndex]['link']['text']
                                :
                                row[columnName]
                          }
                        </Link>
                      </td>
                      :
                      me.props.columns[columnIndex]['boolean'] ?
                        <td key={row[0] + ',' + columnIndex}>
                          {row[columnName] ? <span className='badge agree working-time'>Yes</span> : <span className='badge alert working-time'>No</span>}
                        </td>
                        :
                        me.props.columns[columnIndex]['enum'] ?
                          <td key={row[0] + ',' + columnIndex}>
                            {me.props.columns[columnIndex]['enum'].find((item) => item.value == row[columnName]) ? me.props.columns[columnIndex]['enum'].find((item) => item.value == row[columnName])['display'] : row[columnName]}
                          </td>
                          :
                          <td key={row[0] + ',' + columnIndex}>
                            {row[columnName]}
                          </td>;
                  })
                  .concat([me.props.noDelete && me.props.noEdit ? null :
                    <td className="td-action" key={row[0] + ',Actions'}>
                      {me.props.noDelete || (me.props.permissions ? !me.props.permissions.data.find(permission => permission.nameEn === 'Delete') : true) ?
                        me.props.allPermissions == true ?
                          <i
                            className="fa fa-trash-o"
                            onClick={(e) => {
                              me.showModal("delete");
                              //if there is an 'id' then return id, else return the value of the first property 
                              me.GetIdForDelete(row.id ? row.id : row[Object.keys(row)[0]]);
                            }}></i>
                          :
                          null
                        :
                        <i
                          className="fa fa-trash-o"
                          onClick={(e) => {
                            me.showModal("delete");
                            me.GetIdForDelete(row.id ? row.id : row[Object.keys(row)[0]]);
                          }}></i>}
                      {me.props.noEdit || (me.props.permissions ? !me.props.permissions.data.find(permission => permission.nameEn === 'Edite ') : true) ?
                        me.props.allPermissions == true ?
                          <i
                            className="fa fa-pencil"
                            onClick={(e) => {
                              me.showModal("edit");
                              //if there is an 'id' then return id, else return the value of the first property
                              me.GetForUpdate(row.id ? row.id : row[Object.keys(row)[0]]);
                            }}
                          >
                          </i>
                          :
                          null
                        :
                        me.props.routOut ?

                          <Link to={`/En/TAW/Main/EditEmployee/${row.id ? row.id : row[Object.keys(row)[0]]}`}
                          >
                            <i
                              className="fa fa-pencil" style={{ color: "var(--text-color)" }}

                            >
                            </i>
                          </Link>
                          :
                          me.props.routUnregistered ?
                            <Link to="/En/TAW/Main/AddUnsignEmployee">
                              <i
                                className="fa fa-pencil" style={{ color: "var(--text-color)" }}

                              >
                              </i>
                            </Link>
                            :
                            <i
                              className="fa fa-pencil"
                              onClick={(e) => {
                                me.showModal("edit");
                                me.GetForUpdate(row.id ? row.id : row[Object.keys(row)[0]]);
                              }}
                            >
                            </i>
                      }
                    </td>
                  ])
              }
            </tr>
          )
        }
      );

    // Creating Add Modal Fields
    let CreateEntityProperties = this.props.createEntityProps.map(
      (prop) => {
        let backNames;
        let values;
        let items;
        switch (prop.type) {
          case 'text':
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                // ===========
                title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={prop.label}
                    type="text"
                    value={this.state.createEntity[prop.backName]}
                    onChange={(value) => this.ChangeEntityState('createEntity', prop.backName, value)}
                  />
                ]}
              />
            );
          case 'number':
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                // ===========
                title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={prop.label}
                    type="number"
                    value={this.state.createEntity[prop.backName]}
                    onChange={(value) => this.ChangeEntityState('createEntity', prop.backName, value)}
                  />
                ]}
              />
            );
          case 'email':
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                // ===========
                title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={prop.label}
                    type="email"
                    value={this.state.createEntity[prop.backName]}
                    onChange={(value) => this.ChangeEntityState('createEntity', prop.backName, value)}
                  />
                ]}
              />
            );
          case 'date':
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                // ===========
                title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={prop.label}
                    type="date"
                    value={this.state.createEntity[prop.backName]}
                    onChange={(value) => this.ChangeEntityState('createEntity', prop.backName, value)}
                  />
                ]}
              />
            );
          case 'time':
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                noFormCont={prop.noFormCont}
                // ===========
                title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={prop.label}
                    type="time"
                    value={this.state.createEntity[prop.backName]?.substring(0, 5)}
                    onChange={(value) => this.ChangeEntityState('createEntity', prop.backName, value + ":00")}
                  />
                ]}
              />
            );
          case 'password':
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                // ===========
                title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={prop.label}
                    type="password"
                    value={this.state.createEntity[prop.backName]}
                    onChange={(value) => this.ChangeEntityState('createEntity', prop.backName, value)}
                  />
                ]}
              />
            );
          case 'select':
            if (prop.label === 'Management') {
              items = this.state[prop.label] === undefined ?
                []
                :
                this.state[prop.label].filter(management => management.branchId === this.state.createEntity[this.props.createEntityProps.find(prop => prop.label === 'Branch').backName]).map((item) => { return { label: item[prop.item.label], value: item[prop.item.value] } });
            } else if (prop.label === 'Department') {
              items = this.state[prop.label] === undefined ?
                []
                :
                this.state[prop.label].filter(department => department.administrationId === this.state.createEntity[this.props.createEntityProps.find(prop => prop.label === 'Management').backName]).map((item) => { return { label: item[prop.item.label], value: item[prop.item.value] } });
            } else if (prop.label === 'Employee') {
              items = this.state[prop.label] === undefined ?
                []
                :
                this.state[prop.label].map((item) => { return { label: item.employeeCode + ': ' + item[prop.item.label], value: item[prop.item.value] } });
            } else {
              items = this.state[prop.label] === undefined ?
                []
                :
                this.state[prop.label].map((item) => { return { label: item[prop.item.label], value: item[prop.item.value] } });
            }

            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                // ===========
                title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <Dropdown
                    key={prop.label}

                    placeholder={`Choose or type`}
                    value={items.find(item => item.value === this.state.createEntity[prop.backName])}
                    options={items}
                    onChange={(value) => this.ChangeEntityState('createEntity', prop.backName, value)}
                  />
                ]}
              />
            );
          case 'checkbox':
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                noFormCont={prop.noFormCont}
                // ===========
                title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <ToggleSwitch
                    value={this.state.createEntity[prop.backName]}
                    backName={prop.backName}
                    onChange={(name, value) => this.ChangeEntityState('createEntity', name, value)}
                    onText={'Yes'}
                    offText={'No'}
                    height={30}
                    width={70}
                    onHandleColor={this.props.checkboxgreen ? "#70c767" : " "}
                    onColor={this.props.checkboxgreen ? "#e1fcde" : " "}
                    // uncheckedIconWidth="80px"
                    // uncheckedIconMarginLeft="-38px"
                    checkedIconMarginLeft="10px"
                  />
                ]}
              />
            );
          case 'exceptions':
            backNames = [];
            for (const backName of prop.backName) {
              backNames.push(backName);
            }
            values = [];
            for (const backName of backNames) {
              values.push(this.state.createEntity[backName])
            }
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                // ===========
                title={prop.label}
                inputComponent={[
                  <Exceptions
                    values={values}
                    backNames={backNames}
                    onChange={(name, value) => this.ChangeEntityState('createEntity', name, value)}
                  />
                ]}
              />
            );
          case 'shiftEndNextDay':
            backNames = [];
            for (const backName of prop.backName) {
              backNames.push(backName);
            }
            values = [];
            for (const backName of backNames) {
              values.push(this.state.createEntity[backName])
            }
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                noFormCont={prop.noFormCont}
                // ===========
                title={''}
                inputComponent={[
                  <ShiftEndNextDay
                    onHandleColor={this.props.checkboxgreen ? "#70c767" : " "}
                    onColor={this.props.checkboxgreen ? "#e1fcde" : " "}
                    type={prop.inputType}
                    noFormCont={prop.noFormCont}
                    labels={prop.label}
                    values={values}
                    backNames={backNames}
                    onChange={(name, value) => this.ChangeEntityState('createEntity', name, value)}
                  />
                ]}
              />
            );
          case 'selectShift':
            backNames = [];
            for (const backName of prop.backName) {
              backNames.push(backName);
            }
            values = [];
            for (const backName of backNames) {
              values.push(this.state.createEntity[backName])
            }
            items = this.state[prop.label] === undefined ? [] : this.state[prop.label].map((item) => { return { label: item.employeeCode + ': ' + item[prop.item.label], value: item[prop.item.value] } });

            return (
              <Field
                // ===========
                style={prop.style, "col-lg-12"}
                titleSize={prop.titleSize}
                key={'selectShift'}
                // ===========
                title={prop.label}
                inputComponent={[
                  <SelectShift
                    items={items}
                    values={values}
                    backNames={backNames}
                    onChange={(name, value) => this.ChangeEntityState('createEntity', name, value)}
                    parent={this}
                  />
                ]}
              />
            );
          case 'permissionType':
            backNames = [];
            for (const backName of prop.backName) {
              backNames.push(backName);
            }
            values = [];
            for (const backName of backNames) {
              values.push(this.state.createEntity[backName])
            }

            items = this.state.createEntity.periodShiftId !== 'open' ?
              [
                { label: 'Temporary', value: 1 },
                { label: 'Late Attendance', value: 2 },
                { label: 'Early Leave', value: 3 },
                { label: 'All Day', value: 4 }
              ]
              :
              [
                { label: 'All Day', value: 4 }
              ];
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                key={'permissionType'}
                // ===========
                title={prop.label}
                inputComponent={[
                  <PermissionTypes
                    items={items}
                    values={values}
                    backNames={backNames}
                    onChange={(name, value) => this.ChangeEntityState('createEntity', name, value)}
                  />
                ]}
              />
            );
          default:
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                // ===========
                title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={prop.label}
                    type="text"
                    value={this.state.createEntity[prop.backName]}
                    onChange={(value) => this.ChangeEntityState('createEntity', prop.backName, value)}
                  />
                ]}
              />
            );
        }
      }
    );

    let UpdateEntityProperties;

    // if there is an Update operation in this page, Create Edit Modal Fields
    if (!this.props.noEdit) {
      UpdateEntityProperties = this.props.updateEntityProps.map(
        (prop) => {
          let backNames;
          let values;
          let items;
          switch (prop.type) {
            case 'text':
              return (
                <Field
                  // ===========
                  style={prop.style}
                  titleSize={prop.titleSize}
                  // ===========
                  title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                  inputComponent={[
                    <TextInput
                      key={prop.label}
                      placeholder={prop.label}
                      type="text"
                      value={this.state.updateEntity[prop.backName]}
                      onChange={(value) => this.ChangeEntityState('updateEntity', prop.backName, value)}
                    />
                  ]}
                />
              );
            case 'email':
              return (
                <Field
                  // ===========
                  style={prop.style}
                  titleSize={prop.titleSize}
                  // ===========
                  title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                  inputComponent={[
                    <TextInput
                      key={prop.label}
                      placeholder={prop.label}
                      type="email"
                      value={this.state.updateEntity[prop.backName]}
                      onChange={(value) => this.ChangeEntityState('updateEntity', prop.backName, value)}
                    />
                  ]}
                />
              );
            case 'number':
              return (
                <Field
                  // ===========
                  style={prop.style}
                  titleSize={prop.titleSize}
                  // ===========
                  title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                  inputComponent={[
                    <TextInput
                      key={prop.label}
                      placeholder={prop.label}
                      type="number"
                      value={this.state.createEntity[prop.backName]}
                      onChange={(value) => this.ChangeEntityState('updateEntity', prop.backName, value)}
                    />
                  ]}
                />
              );
            case 'date':
              return (
                <Field
                  // ===========
                  style={prop.style}
                  titleSize={prop.titleSize}
                  // ===========
                  title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                  inputComponent={[
                    <TextInput
                      key={prop.label}
                      placeholder={prop.label}
                      type="date"
                      value={this.state.updateEntity[prop.backName]}
                      onChange={(value) => this.ChangeEntityState('updateEntity', prop.backName, value)}
                    />
                  ]}
                />
              );
            case 'time':
              return (
                <Field
                  // ===========
                  style={prop.style}
                  titleSize={prop.titleSize}
                  noFormCont={prop.noFormCont}
                  // ===========
                  title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                  inputComponent={[
                    <TextInput
                      key={prop.label}
                      placeholder={prop.label}
                      type="time"
                      value={this.state.updateEntity[prop.backName]?.substring(0, 5)}
                      onChange={(value) => this.ChangeEntityState('updateEntity', prop.backName, value + ":00")}
                    />
                  ]}
                />
              );
            case 'password':
              return (
                <Field
                  // ===========
                  style={prop.style}
                  titleSize={prop.titleSize}
                  // ===========
                  title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                  inputComponent={[
                    <TextInput
                      key={prop.label}
                      placeholder={prop.label}
                      type="password"
                      value={this.state.updateEntity[prop.backName]}
                      onChange={(value) => this.ChangeEntityState('updateEntity', prop.backName, value)}
                    />
                  ]}
                />
              );
            case 'select':
              if (prop.label === 'Management') {
                items = this.state[prop.label] === undefined ?
                  []
                  :
                  this.state[prop.label].filter(management => management.branchId === this.state.updateEntity[this.props.updateEntityProps.find(prop => prop.label === 'Branch').backName]).map((item) => { return { label: item[prop.item.label], value: item[prop.item.value] } });
              } else if (prop.label === 'Department') {
                items = this.state[prop.label] === undefined ?
                  []
                  :
                  this.state[prop.label].filter(department => department.administrationId === this.state.updateEntity[this.props.updateEntityProps.find(prop => prop.label === 'Management').backName]).map((item) => { return { label: item[prop.item.label], value: item[prop.item.value] } });
              } else if (prop.label === 'Employee') {
                items = this.state[prop.label] === undefined ?
                  []
                  :
                  this.state[prop.label].map((item) => { return { label: item.employeeCode + ': ' + item[prop.item.label], value: item[prop.item.value] } });
              } else {
                items = this.state[prop.label] === undefined ?
                  []
                  :
                  this.state[prop.label].map((item) => { return { label: item[prop.item.label], value: item[prop.item.value] } });
              }

              return (
                <Field
                  // ===========
                  style={prop.style}
                  titleSize={prop.titleSize}
                  // ===========
                  title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                  inputComponent={[
                    <Dropdown
                      key={prop.label}

                      placeholder={`Choose or type`}
                      value={items.find(item => item.value === this.state.updateEntity[prop.backName])}
                      options={items}
                      onChange={(value) => this.ChangeEntityState('updateEntity', prop.backName, value)}
                    />
                  ]}
                />
              );
            case 'checkbox':
              return (
                <Field
                  // ===========
                  style={prop.style}
                  titleSize={prop.titleSize}
                  noFormCont={prop.noFormCont}
                  // ===========
                  title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                  inputComponent={[
                    <ToggleSwitch
                      value={this.state.updateEntity[prop.backName]}
                      backName={prop.backName}
                      onChange={(name, value) => this.ChangeEntityState('updateEntity', name, value)}
                      onText={'Yes'}
                      offText={'No'}
                      height={30}
                      width={70}
                      // uncheckedIconWidth="80px"
                      // uncheckedIconMarginLeft="-38px"
                      checkedIconMarginLeft="10px"
                    />
                  ]}
                />
              );
            case 'exceptions':
              backNames = [];
              for (const backName of prop.backName) {
                backNames.push(backName);
              }
              values = [];
              for (const backName of backNames) {
                values.push(this.state.updateEntity[backName])
              }
              return (
                <Field
                  // ===========
                  style={prop.style}
                  titleSize={prop.titleSize}
                  // ===========
                  title={prop.label}
                  inputComponent={[
                    <Exceptions
                      values={values}
                      backNames={backNames}
                      onChange={(name, value) => this.ChangeEntityState('updateEntity', name, value)}
                    />
                  ]}
                />
              );
            case 'shiftEndNextDay':
              backNames = [];
              for (const backName of prop.backName) {
                backNames.push(backName);
              }
              values = [];
              for (const backName of backNames) {
                values.push(this.state.updateEntity[backName])
              }
              return (
                <Field
                  // ===========
                  style={prop.style}
                  titleSize={prop.titleSize}
                  noFormCont={prop.noFormCont}
                  // ===========
                  title={''}
                  inputComponent={[
                    <ShiftEndNextDay
                      type={prop.inputType}
                      noFormCont={prop.noFormCont}
                      labels={prop.label}
                      values={values}
                      backNames={backNames}
                      onChange={(name, value) => this.ChangeEntityState('updateEntity', name, value)}
                    />
                  ]}
                />
              );
            case 'selectShift':
              backNames = [];
              for (const backName of prop.backName) {
                backNames.push(backName);
              }
              values = [];
              for (const backName of backNames) {
                values.push(this.state.updateEntity[backName])
              }
              items = this.state[prop.label] === undefined ? [] : this.state[prop.label].map((item) => { return { label: item.employeeCode + ': ' + item[prop.item.label], value: item[prop.item.value] } });

              return (
                <Field
                  // ===========
                  style={prop.style, "col-lg-12"}
                  titleSize={prop.titleSize}
                  key={'selectShift'}
                  // ===========
                  title={prop.label}
                  inputComponent={[
                    <SelectShift
                      items={items}
                      values={values}
                      backNames={backNames}
                      onChange={(name, value) => this.ChangeEntityState('updateEntity', name, value)}
                      parent={this}
                    />
                  ]}
                />
              );
            case 'permissionType':
              backNames = [];
              for (const backName of prop.backName) {
                backNames.push(backName);
              }
              values = [];
              for (const backName of backNames) {
                values.push(this.state.updateEntity[backName])
              }

              items = this.state.updateEntity.periodShiftId !== 'open' ?
                [
                  { label: 'Temporary', value: 1 },
                  { label: 'Late Attendance', value: 2 },
                  { label: 'Early Leave', value: 3 },
                  { label: 'All Day', value: 4 }
                ]
                :
                [
                  { label: 'All Day', value: 4 }
                ];

              return (
                <Field
                  // ===========
                  style={prop.style}
                  titleSize={prop.titleSize}
                  key={'permissionType'}
                  // ===========
                  title={prop.label}
                  inputComponent={[
                    <PermissionTypes
                      items={items}
                      values={values}
                      backNames={backNames}
                      onChange={(name, value) => this.ChangeEntityState('updateEntity', name, value)}
                    />
                  ]}
                />
              );
            default:
              return (
                <Field
                  // ===========
                  style={prop.style}
                  titleSize={prop.titleSize}
                  // ===========
                  title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                  inputComponent={[
                    <TextInput
                      key={prop.label}
                      placeholder={prop.label}
                      type="text"
                      value={this.state.updateEntity[prop.backName]}
                      onChange={(value) => this.ChangeEntityState('updateEntity', prop.backName, value)}
                    />
                  ]}
                />
              );
          }

        }
      );
    }


    return (
      <div className="BasicPage">
        {/* startOverlayComponents */}
        <Overlay visible={this.state.add}>
          <Modal
            title={this.props.title}
            action="add"
            visible={this.state.add}
            hideModal={(name) => this.hideModal(name)}
            EntityProperties={CreateEntityProperties}
            errors={this.state.createErrors}
            fetch={() => this.Create(this.state.createEntity)}
            removeModalTitleName={this.props.removeModalTitleName}
          />
        </Overlay>

        <Overlay visible={this.state.edit}>
          <Modal
            title={this.props.title}
            action="edit"
            visible={this.state.edit}
            hideModal={(name) => this.hideModal(name)}
            EntityProperties={UpdateEntityProperties}
            errors={this.state.updateErrors}
            fetch={() => this.Update(this.state.updateEntity)}
          />
        </Overlay>

        <Overlay visible={this.state.delete}>
          <Modal
            title={this.props.title}
            action="delete"
            visible={this.state.delete}
            hideModal={(name) => this.hideModal(name)}
            EntityProperties={[]}
            errors={[]}
            deleteMessage={this.props.deleteMessage}
            fetch={() => this.Delete(this.state.deleteEntity)}
          />
        </Overlay>

        <Overlay visible={this.state.search}>
          <Modal
            action="search"
            visible={this.state.search}
            hideModal={(name) => this.hideModal(name)}
            searchFields={this.props.modalSearchFields}
            EntityProperties={CreateEntityProperties}
            columns={this.props.modalColumns ? this.props.modalColumns : [
              { name: 'Code', backName: 'employeeCode' },
              { name: 'Name', backName: 'nameEn' }
            ]}
            errors={[]}
            parent={this}
            backName={this.props.modalBackName}
            read={this.props.modalRead}
            fetch={() => this.Create(this.state.createEntity)}
            updated={this.state.modalUpdated}
          />
        </Overlay>

        <Overlay visible={this.state.loading}>
          <Loading visible={this.state.loading} />
        </Overlay>
        {/* endOverlayComponents */}

        <div className="MainLayout--container">
          <PageHeader
            Path={Path}
            title={this.props.title}
            mainTitle={this.props.mainTitle}
            routOut={this.props.routOut}
            // =====================
            hideFilters={SearchFields ? () => hideFilters() : null}
            showFilters={SearchFields ? () => showFilters() : null}
            showModal={(name) => this.showModal(name)}

            noCreate={(this.props.noCreate || !this.props.permissions?.data.find(permission => permission.nameEn === 'Add') && !this.props.allPermissions) ? true : false}
            useSearchModal={this.props.useSearchModal ? true : false}
          // =====================
          />
          <div className="alerts">
            {
              this.state.message ?
                this.state.message['text'] ?
                  <Alert
                    className={"Alert " + this.state.message['style']}
                  >
                    {this.state.message['text']}
                  </Alert>
                  :
                  null
                :
                null
            }
          </div>
          {
            SearchFields ?
              <Filters
                SearchFields={SearchFields}
                Clear={() => this.Clear()}
                Search={() => this.Read(this.state.readEntity)}
              />
              :
              null
          }
          <TableFooter
            rowsNumber={this.state.readEntity.pageSize}
            rowsNumberChange={(value) => {
              value = value > 1 ? value : 1;
              this.ChangeEntityState('readEntity', 'pageSize', value);
              this.ChangeEntityState('readEntity', 'pageNumber', 0);
            }}
            total={this.state.rowsTotal}
            currentPage={this.state.readEntity.pageNumber + 1}
            currentPageChange={(value) => {
              this.ChangeEntityState('readEntity', 'pageNumber', Number(value) - 1);
              this.Read(this.state.readEntity);
            }}
          />
          <Table TableHeaders={TableHeaders} data={TableData} />

        </div>

      </div>
    );
  }
}

export default BasicPage;
