import React from 'react';
import logo from './apex-logo-light-blue.png';
import './StartLayout.css';
import { Route } from "react-router-dom";
import Login from '../Login/Login';
// import Register from '../Register/Register';
import ChooseApp from '../ChooseApp/ChooseApp';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import { Link } from "react-router-dom";
import { Dropdown } from 'react-bootstrap';
import company from '../../logo-white.png';

function StartLayout(props) {
  return (
    <div className={""}>
    <div style={{ width: '100%' }} className={"login-background"}>
      <nav className="StartLayout--">
        <div className="StartLayout--container">
          <img className="StartLayout--logo" src={logo} alt="LOGO" />
          <Dropdown className="StartLayout--lang">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              English
  </Dropdown.Toggle>
            <Dropdown.Menu className="text-center">
              <Dropdown.Item href="#"><Link to='/En'>
                English
          </Link></Dropdown.Item>
              <Dropdown.Item href=""><Link to='/'>
                العربية
          </Link></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {/* <Link to='/'>
            <button className="StartLayout--lang" >
              ع
          </button>
          </Link> */}
        </div>
      </nav>
      <section className="StartLayout-- en">
        <Route exact={true} path="/En" component={ChooseApp} />
        <Route path="/En/TAW/Start/Login/:companyName" render={({ match }) =>
          <Login
            companyName={match.params.companyName}
          />
        }
        />
        <Route path="/En/TAW/Start/ForgotPassword/:companyName" render={({ match }) =>
          <ForgotPassword
            companyName={match.params.companyName}
          />
        }
        />
        {/* <Route path="/En/TAW/Start/Register" component={Register} /> */}
      </section>
    
    </div>
      <div className="bottom-outer">
      <div className="row w-100">
        <div className="col-md-5 text-left pl-5 fs-10">
          © 2020 Taif Alalmas All Rights Reserved
    </div>
        <div className="col-md-2 d-flex justify-content-center">
          <p className="m-0">Contact Us</p>
          <a className="fb" href="https://www.facebook.com"></a>
          <a className="tw" href="https://twitter.com"></a>
        </div>
        <div className="col-md-5 text-right pr-5 fs-10">
          Powered by:
          <a href="https://taif-alalmas.com/">
            <img className="company-logo" src={company} alt="LOGO" />
          </a>
        </div>



      </div>
    </div>
    </div>
  );
}

export default StartLayout;