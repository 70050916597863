import React from 'react';
import './Button.css';


// we declared all component props by destructuring the props object
// Destructuring is a ES6 feature written in this way:
// const { property } = object;

// we often use the same naming of the prop like the tag name
// className={className} ==> tagName={prop}

// if you want to assign a chosen color to a specific className
// simply add your className directly to colors section in Button.scss file ( Button.className )

// if you need to put icon inside the Button,
// put it between <i className="fa"></i> tags to take the same styling

function Button({
  id,
  className,
  onClick,
  btnClr,
  children
}) {
  return (
    <button id={id} className={className} onClick={onClick} style={btnClr ? { backgroundColor: btnClr } : null}>
      <span>
        {children}
      </span>
    </button>
  )
}
export default Button;