import React from 'react';
import './TableFooter.css';
import { Link } from 'react-router-dom';
import TextInput from '../TextInput/TextInput';
import Field from '../Field/Field';
import Button from './../Button/Button';
import ReactTooltip from "react-tooltip";

const FooterAr = (props) => {
  let rowsNumber = props.rowsNumber ? props.rowsNumber : 0;
  let pagesNumber = (rowsNumber === 0 ? 0 : props.total / rowsNumber);
  let btns = [];
  let counter = 0;
  for (let i = 0; i < pagesNumber; i++) {
    btns.push([
      <Link
        key={i + 1}
        className={"PagesNav-- " + (props.currentPage === (i + 1) ? 'active' : '')}
        to="#"
        onClick={(e) => props.currentPageChange(e.currentTarget.innerHTML)} >
        {i + 1}
      </Link>
    ])
    counter++;
  }
  let from = props.currentPage === 1 ? 0 : props.currentPage - 2;
  let to = props.currentPage > pagesNumber ? btns.length : props.currentPage + 1;
  btns = btns.slice(from, to);
  if (btns.length > 0) {
    btns.unshift([
      <Link
        key="First"
        className={"PagesNav-- " + (props.currentPage === 1 ? "disabled" : '')}
        to="#"
        onClick={() => props.currentPageChange(1)}
      >
        <i className="fa fa-angle-right"></i><i className="fa fa-angle-right"></i>
      </Link>
    ]);
    btns.push([
      <Link
        key="Last"
        className={"PagesNav-- " + (props.currentPage === (counter) ? "disabled" : '')}
        to="#"
        onClick={() => props.currentPageChange(counter)}
      >
        <i className="fa fa-angle-left"></i><i className="fa fa-angle-left"></i>
      </Link>
    ]);
  }
  return (
    <footer className="TableFooter--">
      {/* 
      <div className="TableFooter--field number-rows">
        <label>No. of Rows :</label>
        <TextInput
          type="text"
          value={props.rowsNumber}
          onChange={(value) => props.rowsNumberChange('rowsNumber', value)}
        />
      </div>
       */}
      <div style={{ width: '75%' }}
      // style={{ maxWidth: '335px' }}
      >
        <Field
          title="عدد الصفوف:"
          style='col-lg-8'
          inputComponent={[
            <div>
            <TextInput
              type="text"
              value={props.rowsNumber}
              onChange={(value) => props.rowsNumberChange(value)}
            />
            <div className="right">
            <Button
              className="Button rotate"
              onClick={() => props.currentPageChange(1)}
            >
              <i data-tip="تحديث" className="fa fa-refresh"></i>
            </Button>
            <ReactTooltip data-place="left" data-type="success" />
          </div>
          </div>
          ]}
        />
        
      </div>

      <div className="TableFooter--field PagesNav--">
       

        <p className="paragraph" style={{ marginBottom: '0' }}>
          {/* ((this.state.rowsNumber * this.state.currentPage) - this.state.rowsNumber), (this.state.rowsNumber * this.state.currentPage) */}
          <span>{((props.rowsNumber * props.currentPage) - props.rowsNumber) + 1}</span>{/* <!-- the first row id or index --> */}
          -
          <span>{(props.rowsNumber * props.currentPage) < props.total ? (props.rowsNumber * props.currentPage) : props.total}</span>{/* <!-- the first row id or index --> */}
          من
          <span>{props.total}</span>{/* <!-- total no. of table --> */}
        </p>
        <nav className="PagesNav--">

          {btns}
          {/* <a className="PagesNav-- disabled" href="#void">&lt;&lt;</a> <!-- it takes ( disabled ) class --> */}
          {/* <a className="PagesNav-- active" href="#void">1</a> */}
          {/* <a className="PagesNav--" href="#void">2</a> <!-- it takes ( active ) class --> */}
          {/* <a className="PagesNav--" href="#void">3</a> */}
          {/* <a className="PagesNav--" href="#void">&gt;&gt;</a> */}
        </nav>
      </div>


    </footer>
  )
}
export default FooterAr;