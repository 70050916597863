import React from 'react';
import './MainLayout.css';

import DashBoard from '../DashBoard/DashBoard';
import BasicPage from '../BasicPage/BasicPage';
import WorkingHours from '../WorkingHours/WorkingHours';
import ReportPage from '../ReportPage/ReportPage';
import ProcedurePage from '../ProcedurePage/ProcedurePage';
import CompanyInfo from '../CompanyInfo/CompanyInfo';
import FormPage from '../FormPage/FormPage';
import PermissionsPage from '../PermissionsPage/PermissionsPage';
import ModalPage from '../ModalPage/ModalPage';
import MessagesSettingsEn from '../messagesSettingsEn/messagesSettingsEn'

import MainHeader from '../MainHeader/MainHeader';
import SideNav from '../SideNav/SideNav';

import { Route, Redirect } from 'react-router-dom';
import { API, ip } from './../../data/APIs';
import {
  Branches,
  // BranchesAr,
  Jobs,
  Projects,
  Nationalities,
  Tasks,
  WorkingTime,
  Employees,
  EmployeesGroups,
  VacationsTypes,
  OfficialHolidays,

  GetBranchTransactions,
  AddPermission,
  AddVacation,
  AddEditTransactions,

  EmployeesReport,
  WorkingTimeReport,
  DayStatusReport,
  DetailedAttendanceReport,
  TotalAttendanceReport,
  AbsenceReport,
  DelayReport,
  InCompleteTransReport,
  EarlyAttendanceReport,
  AttendanceSummaryReport,
  TotalAttendanceSummaryReport,
  OvertimeReport,
  WorkingTimeDetailsReport,
  BranchAttendanceReport,
  VacationsReport,
  PermissionsReport,
  DelayEarlyReport,
  TotalAbsenceReport,
  TotalDelayReport,

  Devices,
  SpecialWorkingDays,
  UserPermission,
  GeneralSettings
} from './../../data/PageProps';
import Cookie from "js-cookie";
import $ from 'jquery';
class MainLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SideNavIsOpen: false,
      SideNavWasClosed: false,
      activePage: '',
      loadedPermissions: false,
      permissions: []
    }
    if (!this.state.loadedPermissions) {
      let end;
      let start = Date.now();
      console.log('Start: ' + start);
      fetch(ip + '/api/CustomGroupPermission/GetAllTapsWithPages', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookie.get('token')}`
        },
        body: JSON.stringify({
          "pageSize": 100,
          "pageNumber": 0,
          "searchCriteria": "",
          "selectedId": [
            0
          ]
        })
      })
        .then(response => response.json())
        .then((response) => {
          end = Date.now();
          console.log('End: ' + end);
          console.log('Time: ' + (end - start));
          if (response.success && response.data !== null) {
            //save permissions in a cookie
            Cookie.set('permissions', response.data, { SameSite: 'Lax' });
            this.setState({ permissions: response.data, loadedPermissions: true });
          } else {
            console.error(response);
          }
        })
        .catch(error => console.error('Unable to get Permissions.', error));
    }

  }

  componentDidMount() {
    // const sideNav = document.querySelector('#sideNav');
    // const mainLayout = document.querySelector('#mainLayout');
    // sideNav.classList.remove('show-side-nav');
    // mainLayout.classList.remove('show-side-nav');
    // sideNav.classList.add('hide-side-nav');
    // mainLayout.classList.add('hide-side-nav');


    // const sideNav = document.querySelector('#sideNav');
    const mainLayout = document.querySelector('#mainLayout');
    if (document.getElementsByTagName('body')[0].offsetWidth > 991) {
      // sideNav.classList.add('show-side-nav');
      mainLayout.classList.add('show-side-nav');
      // sideNav.classList.remove('hide-side-nav');
      mainLayout.classList.remove('hide-side-nav');
      this.setState({ SideNavIsOpen: true });
      // console.log('after componentDidMount:', this.state.SideNavIsOpen);
      // sideNav.addEventListener('click', () => {
      //   hideSideNav();
      // })
    }
    if (document.getElementsByTagName('body')[0].offsetWidth < 991) {
      // sideNav.classList.remove('show-side-nav');
      mainLayout.classList.remove('show-side-nav');
      // sideNav.classList.add('hide-side-nav');
      mainLayout.classList.add('hide-side-nav');
      this.setState({ SideNavIsOpen: !(this.state.SideNavIsOpen) });
    }
    document.getElementsByTagName('body')[0].onresize = () => {
      if (document.getElementsByTagName('body')[0].offsetWidth > 991 && !(this.state.SideNavWasClosed)) {
        // if (sideNav.classList.contains('hide-side-nav')) {
        //   sideNav.classList.add('show-side-nav');
        //   mainLayout.classList.add('show-side-nav');
        //   sideNav.classList.remove('hide-side-nav');
        //   mainLayout.classList.remove('hide-side-nav');
        //   this.setState({ SideNavIsOpen: !(this.state.SideNavIsOpen) });
        // }
      }
      if (document.getElementsByTagName('body')[0].offsetWidth < 991 && !(this.state.SideNavWasClosed)) {
        // if (sideNav.classList.contains('show-side-nav')) {
        //   sideNav.classList.remove('show-side-nav');
        //   mainLayout.classList.remove('show-side-nav');
        //   sideNav.classList.add('hide-side-nav');
        //   mainLayout.classList.add('hide-side-nav');
        //   this.setState({ SideNavIsOpen: !(this.state.SideNavIsOpen) });
        // }
      }
    };
    if (!this.state.loadedPermissions) {
      fetch(ip + '/api/CustomGroupPermission/GetAllTapsWithPages', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookie.get('token')}`
        },
        body: JSON.stringify({
          "pageSize": 100,
          "pageNumber": 0,
          "searchCriteria": "",
          "selectedId": [
            0
          ]
        })
      })
        .then(response => response.json())
        .then((response) => {
          if (response.success && response.data !== null) {
            Cookie.set('permissions', response.data, { SameSite: 'Lax' });
            this.setState({ permissions: response.data, loadedPermissions: true });
          } else {
            console.error(response);
          }
        })
        .catch(error => console.error('Unable to get Permissions.', error));
    }

  }

  render() {
    // Styling:
    // ========
    const hideSideNav = () => {
      const sideNav = document.querySelector('#sideNav');
      sideNav.classList.remove('show-side-nav');
      sideNav.classList.add('hide-side-nav');
      const mainLayout = document.querySelector('#mainLayout');
      mainLayout.classList.toggle('show-side-nav');
      mainLayout.classList.toggle('hide-side-nav');
      this.setState({ SideNavIsOpen: false });
      this.setState({ SideNavWasClosed: true });
    }

    const toggleSideNav = () => {
      // const body = document.getElementsByTagName('body')[0];
      const sideNav = document.querySelector('#sideNav');
      sideNav.classList.toggle('show-side-nav');
      sideNav.classList.toggle('hide-side-nav');
      const mainLayout = document.querySelector('#mainLayout');
      mainLayout.classList.toggle('show-side-nav');
      mainLayout.classList.toggle('hide-side-nav');
      // const clock = document.querySelector('#clock');
      // clock.classList.toggle('hide-side-nav');
      const SideToggleButton = document.querySelector('#SideToggleButton');
      SideToggleButton.classList.toggle('hide-side-nav');
      if (this.state.SideNavIsOpen) {
        this.setState({ SideNavIsOpen: false });
        this.setState({ SideNavWasClosed: true });
      } else {
        this.setState({ SideNavIsOpen: true });
      }

      // if(this.state.SideNavIsOpen){
      //   body.addEventListener('click', () => {
      //     console.log('after click on body:',this.state.SideNavIsOpen);
      //     hideSideNav();
      //   })
      // }
    }
    if (window.innerHeight <= 500) {
      $("section.MainLayout--").css('min-height', window.innerHeight - 50)
    }
    if (window.innerHeight >= 500) {
      $("section.MainLayout--").css('min-height', document.innerHeight - 50)
    }
    const activatePage = (page) => {
      this.setState({ activePage: page });

    }
    // ========

    let mainLayout = this;

    return (
      <React.Fragment>
        {/* Website Master Page Header */}
        <MainHeader />
        <SideNav
          activatePage={activatePage}
          toggleSideNav={toggleSideNav}
          hideSideNav={hideSideNav}
          activePage={this.state.activePage}
          permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions : null}
        />
        {/* Master Page Body (Content Holder) */}
        <section id='mainLayout' className='MainLayout-- en'  >
          <Route path='/En/TAW/Main/Dashboard' render={() => <DashBoard lang='en' ></DashBoard>} />

          <Route path='/En/TAW/Main/Branches'
            render={
              function () {
                return (
                  <BasicPage
                    mainTitle={Branches.mainTitle}
                    title={Branches.title}
                    path={Branches.path}
                    searchFields={Branches.searchFields}
                    columns={Branches.columns}
                    createEntityProps={Branches.createEntityProps}
                    updateEntityProps={Branches.updateEntityProps}
                    deleteMessage={Branches.deleteMessage}
                    create={Branches.create}
                    read={Branches.read}
                    update={Branches.update}
                    delete={Branches.delete}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 1)?.data.find(page => page.id === 1) : null}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/Managements/:branchId/:branchName'
            render={
              function ({ match }) {
                return (
                  <BasicPage
                    mainTitle={`Managements in (${match.params.branchName})`}
                    title={match.params.branchName}
                    path={[
                      { title: 'Dashboard', path: '/En/TAW/Main/Dashboard' },
                      { title: 'Branches', path: '/En/TAW/Main/Branches' }
                    ]}
                    params={match.params}
                    searchFields={[
                      // { label: 'ID', type: 'number', backName: 'id' },
                      { label: 'Managements', type: 'text', backName: 'nameEn', }
                    ]}
                    columns={[
                      { name: 'ID', backName: 'id' },
                      { name: 'Management Name', backName: 'nameEn', link: { to: '/En/TAW/Main/Departments/', routeValues: ['branchId', 'branchName', 'id', 'nameEn'] } },
                      { name: 'Manager', backName: 'managerName' },
                      { name: 'Employees', backName: 'employeesCount' },
                      { name: 'Departments', backName: 'departmentCount' }
                    ]}
                    createEntityProps={[
                      { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
                      { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' },
                      {
                        label: 'Manager', type: 'select', validation: '', style: 'col-lg-6', backName: 'managerId', url: API.Employees.read,
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      }
                    ]}
                    updateEntityProps={[
                      { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
                      { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' },
                      {
                        label: 'Manager', type: 'select', validation: '', style: 'col-lg-6', backName: 'managerId', url: API.Employees.read,
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      }
                    ]}
                    deleteMessage='Are you sure you want to Delete?'
                    create={{
                      url: API.Directorates.create,
                      body: {
                        nameAr: '',
                        nameEn: '',
                        branchId: Number.parseInt(match.params.branchId),
                        managerId: ''
                      },
                      verb: 'POST'
                    }}
                    read={{
                      url: API.Directorates.read,
                      body: {
                        pageSize: 10,
                        pageNumber: 0,
                        search: {
                          branchId: Number.parseInt(match.params.branchId)
                        }
                      },
                      verb: 'POST'
                    }}
                    update={{
                      url: API.Directorates.update,
                      body: {
                        id: '',
                        nameAr: '',
                        nameEn: '',
                        managerId: '',
                        branchId: Number.parseInt(match.params.branchId)
                      },
                      verb: 'POST'
                    }}
                    delete={{
                      url: API.Directorates.delete,
                      query: {
                        branchId: ''
                      },
                      verb: 'POST'
                    }}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 1)?.data.find(page => page.id === 1) : null}

                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/Departments/:branchId/:branchName/:managementId/:managementName'
            render={
              function ({ match }) {
                return (
                  <BasicPage
                    mainTitle={`Departments in (${match.params.managementName})`}
                    title={match.params.managementName}
                    path={[
                      { title: 'Dashboard', path: '/En/TAW/Main/Dashboard' },
                      { title: 'Branches', path: '/En/TAW/Main/Branches' },
                      { title: match.params.branchName, path: '/En/TAW/Main/Managements/' + match.params.branchId + '/' + match.params.branchName }
                    ]}
                    params={match.params}
                    searchFields={[
                      // { label: 'ID', type: 'number', backName: 'id' },
                      { label: 'Departments', type: 'text', backName: 'nameEn', }
                    ]}
                    columns={[
                      { name: 'ID', backName: 'id' },
                      { name: 'Department Name', backName: 'nameEn' },
                      { name: 'Manager', backName: 'managerName' },
                      { name: 'Employees', backName: 'employeesCount' }
                    ]}
                    createEntityProps={[
                      { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
                      { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' },
                      {
                        label: 'Manager', type: 'select', validation: '', style: 'col-lg-6', backName: 'managerId', url: API.Employees.read,
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      }
                    ]}
                    updateEntityProps={[
                      { label: 'Arabic Name', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
                      { label: 'English Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' },
                      {
                        label: 'Manager', type: 'select', validation: '', style: 'col-lg-6', backName: 'managerId', url: API.Employees.read,
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      }
                    ]}
                    deleteMessage='Are you sure you want to Delete?'
                    create={{
                      url: API.Departments.create,
                      body: {
                        nameAr: '',
                        nameEn: '',
                        administrationId: Number.parseInt(match.params.managementId),
                        managerId: ''
                      },
                      verb: 'POST'
                    }}
                    read={{
                      url: API.Departments.read,
                      body: {
                        pageSize: 10,
                        pageNumber: 0,
                        search: {
                          administrationId: Number.parseInt(match.params.managementId)
                        }
                      },
                      verb: 'POST'
                    }}
                    update={{
                      url: API.Departments.update,
                      body: {
                        id: '',
                        nameAr: '',
                        nameEn: '',
                        managerId: '',
                        administrationId: Number.parseInt(match.params.managementId)
                      },
                      verb: 'POST'
                    }}
                    delete={{
                      url: API.Departments.delete,
                      query: {
                        branchId: ''
                      },
                      verb: 'POST'
                    }}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 1)?.data.find(page => page.id === 1) : null}

                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/Jobs'
            render={
              function () {
                return (
                  <BasicPage
                    mainTitle={Jobs.mainTitle}
                    title={Jobs.title}
                    path={Jobs.path}
                    searchFields={Jobs.searchFields}
                    columns={Jobs.columns}
                    createEntityProps={Jobs.createEntityProps}
                    updateEntityProps={Jobs.updateEntityProps}
                    deleteMessage={Jobs.deleteMessage}
                    create={Jobs.create}
                    read={Jobs.read}
                    update={Jobs.update}
                    delete={Jobs.delete}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 1)?.data.find(page => page.id === 2) : null}

                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/Projects'
            render={
              function () {
                return (
                  <BasicPage
                    mainTitle={Projects.mainTitle}
                    title={Projects.title}
                    path={Projects.path}
                    searchFields={Projects.searchFields}
                    columns={Projects.columns}
                    createEntityProps={Projects.createEntityProps}
                    updateEntityProps={Projects.updateEntityProps}
                    deleteMessage={Projects.deleteMessage}
                    create={Projects.create}
                    read={Projects.read}
                    update={Projects.update}
                    delete={Projects.delete}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 1)?.data.find(page => page.id === 3) : null}

                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/Nationalities'
            render={
              function () {
                return (
                  <BasicPage
                    mainTitle={Nationalities.mainTitle}
                    title={Nationalities.title}
                    path={Nationalities.path}
                    searchFields={Nationalities.searchFields}
                    columns={Nationalities.columns}
                    createEntityProps={Nationalities.createEntityProps}
                    updateEntityProps={Nationalities.updateEntityProps}
                    deleteMessage={Nationalities.deleteMessage}
                    create={Nationalities.create}
                    read={Nationalities.read}
                    update={Nationalities.update}
                    delete={Nationalities.delete}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 1)?.data.find(page => page.id === 4) : null}

                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/Tasks'
            render={
              function () {
                return (
                  <BasicPage
                    mainTitle={Tasks.mainTitle}
                    title={Tasks.title}
                    path={Tasks.path}
                    searchFields={Tasks.searchFields}
                    columns={Tasks.columns}
                    createEntityProps={Tasks.createEntityProps}
                    updateEntityProps={Tasks.updateEntityProps}
                    deleteMessage={Tasks.deleteMessage}
                    create={Tasks.create}
                    read={Tasks.read}
                    update={Tasks.update}
                    delete={Tasks.delete}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 1)?.data.find(page => page.id === 5) : null}

                  />
                )
              }
            }
          />
          <Route exact={true} path='/En/TAW/Main/WorkingTime'
            render={
              function () {
                return (
                  <BasicPage
                    mainTitle={WorkingTime.mainTitle}
                    title={WorkingTime.title}
                    path={WorkingTime.path}
                    searchFields={WorkingTime.searchFields}
                    columns={WorkingTime.columns}
                    createEntityProps={WorkingTime.createEntityProps}
                    updateEntityProps={WorkingTime.updateEntityProps}
                    deleteMessage={WorkingTime.deleteMessage}
                    create={WorkingTime.create}
                    read={WorkingTime.read}
                    update={WorkingTime.update}
                    delete={WorkingTime.delete}
                    checkboxgreen={true}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 1)?.data.find(page => page.id === 6) : null}

                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/WorkingTime/WorkingHours/:id/:open/:name'
            render={
              function ({ match }) {
                return (
                  <WorkingHours
                    mainTitle={`Working Hours of (${match.params.name})`}
                    title={match.params.name}
                    path={[
                      { title: 'Dashboard', path: '/En/TAW/Main/Dashboard' },
                      { title: 'Working Times', path: '/En/TAW/Main/WorkingTime' }
                    ]}
                    read={{
                      url: ip + '/api/Shift/GetShiftDetails',
                      path: {
                        shiftId: Number.parseInt(match.params.id),
                        shiftType: 1
                      },
                      verb: 'GET'
                    }}
                    update={match.params.open === 'true' ?
                      {
                        url: ip + '/api/Shift/UpdateOpenShiftDay',
                        body: {
                          "id": 0,
                          "dayId": 0,
                          "shiftId": Number.parseInt(match.params.id),
                          "totalDayHours": 0,
                          "shiftType": 1,
                          "isVacation": true
                        },
                        verb: 'POST'
                      }
                      :
                      {
                        url: ip + '/api/Shift/UpdateShiftDay',
                        body: {
                          "id": 0,
                          "dayId": 0,
                          "shiftId": Number.parseInt(match.params.id),
                          "shiftType": 1,
                          "isVacation": true,
                          "periodShiftParameterList": [
                            {
                              "seq": 1,
                              "inTime": "",
                              "outTime": "",
                              "startIn": "",
                              "endIn": "",
                              "startOut": "",
                              "endOut": "",
                              "availableEarlyTime": "",
                              "availableLateTime": ""
                            },
                            {
                              "seq": 2,
                              "inTime": "",
                              "outTime": "",
                              "startIn": "",
                              "endIn": "",
                              "startOut": "",
                              "endOut": "",
                              "availableEarlyTime": "",
                              "availableLateTime": ""
                            },
                            {
                              "seq": 3,
                              "inTime": "",
                              "outTime": "",
                              "startIn": "",
                              "endIn": "",
                              "startOut": "",
                              "endOut": "",
                              "availableEarlyTime": "",
                              "availableLateTime": ""
                            },
                            {
                              "seq": 4,
                              "inTime": "",
                              "outTime": "",
                              "startIn": "",
                              "endIn": "",
                              "startOut": "",
                              "endOut": "",
                              "availableEarlyTime": "",
                              "availableLateTime": ""
                            }
                          ]
                        },
                        verb: 'POST'
                      }}
                    open={match.params.open}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 1)?.data.find(page => page.id === 6) : null}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/Employees'
            render={
              function () {
                return (
                  <BasicPage
                    mainTitle={Employees.mainTitle}
                    title={Employees.title}
                    path={Employees.path}
                    searchFields={Employees.searchFields}
                    columns={Employees.columns}
                    createEntityProps={Employees.createEntityProps}
                    updateEntityProps={Employees.updateEntityProps}
                    deleteMessage={Employees.deleteMessage}
                    create={Employees.create}
                    read={Employees.read}
                    update={Employees.update}
                    delete={Employees.delete}
                    routOut={true}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 1)?.data.find(page => page.id === 7) : null}

                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/AddEmployee'
            render={
              function () {
                return (
                  <ModalPage
                    title={` Add Employee`}
                    path={[{ title: 'Dashboard ', path: '/En/TAW/Main/Dashboard' },
                    { title: 'Employees', path: '/En/TAW/Main/Employees' },
                    { title: ' Add Employee', path: '/En/TAW/Main/AddEmployee' }]}
                    EntityProps={Employees.createEntityProps}
                    read={undefined}
                    action={Employees.create}
                    routOut={true}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/AddUnsignEmployee'
            render={
              function () {
                return (
                  <ModalPage
                    title={` Add Employee`}
                    path={[{ title: 'Dashboard ', path: '/En/TAW/Main/Dashboard' },
                    { title: 'Employees Unsigned ', path: '/En/TAW/Main/UnregisteredEmployees' },
                    { title: ' Add Employee', path: '/En/TAW/Main/AddUnsignEmployee' }]}
                    EntityProps={Employees.createEntityProps}
                    read={undefined}
                    action={Employees.create}
                    routUnregistered={true}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/EditEmployee/:id'
            render={
              function ({ match }) {
                return (
                  <ModalPage
                    title={` Edit Employee`}
                    path={[{ title: 'Dashboard ', path: '/En/TAW/Main/Dashboard' },
                    { title: 'Employees', path: '/En/TAW/Main/Employees' },
                    { title: '  Edit Employee ', path: '/En/TAW/Main/EditEmployee/:id' }]}
                    EntityProps={Employees.updateEntityProps}
                    read={{
                      url: ip + '/api/Employee/GetEmployees',
                      path: {
                        employeeId: match.params.id
                      },
                      verb: 'GET'
                    }}
                    action={Employees.update}
                  />
                )
              }
            }
          />

          <Route path='/En/TAW/Main/emailEdites'
            render={
              function ({ match }) {
                return (
                  <ModalPage
                    title={`Add email information`}
                    path={[
                      { title: 'Dashbord ', path: '/En/TAW/Main/Dashboard' },
                      { title: 'GeneralSettings ', path: '/En/TAW/Main/GeneralSettings' },
                      { title: 'Email Edites', path: '/En/TAW/Main/emailEdites' }
                    ]}
                    pageName='emailEdites'
                    read={{
                      url: ip + '/api/GetEmailRequest',
                      verb: 'GET'
                    }}
                    type="generalSettings"
                    action={{
                      url: ip + '/api/UpdateRequest',
                      body: {
                        "id": '',
                        "email": "",
                        "password": ""
                      },
                      verb: 'POST'
                    }}
                    EntityProps={[
                      { label: 'Email', type: 'email', validation: '', style: 'col-lg-6', backName: 'email' },
                      { label: 'Password', type: 'text', validation: '', style: 'col-lg-6', backName: 'password' },
                      { label: 'Host name', type: 'text', validation: '', style: 'col-lg-6', backName: 'hostName' },
                      { label: 'Port number', type: 'text', validation: '', style: 'col-lg-6', backName: 'portNumber' }
                    ]}
                  />
                )
              }
            }
          />

          <Route path='/En/TAW/Main/smsEdites'
            render={
              function ({ match }) {
                return (
                  <ModalPage
                    title={`SMA edites`}
                    path={[
                      { title: 'Dashbord ', path: '/En/TAW/Main/Dashboard' },
                      { title: 'GeneralSettings ', path: '/En/TAW/Main/GeneralSettings' },
                      { title: 'SMA edites', path: '/En/TAW/Main/smsEdites' }
                    ]}
                    pageName='smsEdites'
                    read={{
                      url: ip + '/api/GetSMSRequest',
                      verb: 'GET'
                    }}
                    type="generalSettings"
                    action={{
                      url: ip + '/api/UpdateSMSRequest',
                      body: {
                        "id": '',
                        "userName": "",
                        "password": "",
                        "senderName": "",
                        "smsCompanyId": ""
                      },
                      verb: 'POST'
                    }}
                    EntityProps={[
                      {
                        label: 'Company name', type: 'select', validation: 'Required', style: 'col-lg-6', backName: 'smsCompanyId', url: ip + '/api/GetAllEmailRequestsDropDown',
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      },
                      { label: 'User name', type: 'text', validation: '', style: 'col-lg-6', backName: 'userName' },
                      { label: 'Password', type: 'text', validation: '', style: 'col-lg-6', backName: 'password' },
                      { label: 'Sender name', type: 'text', validation: '', style: 'col-lg-6', backName: 'senderName' },

                    ]}
                  />
                )
              }
            }
          />

          <Route path='/En/TAW/Main/emailBody'
            render={
              function ({ match }) {
                return (
                  <ModalPage
                    title={`Email body`}
                    path={[
                      { title: 'Dashbord ', path: '/En/TAW/Main/Dashboard' },
                      { title: 'GeneralSettings ', path: '/En/TAW/Main/GeneralSettings' },
                      { title: 'Edit email body', path: '/En/TAW/Main/emailBody' }
                    ]}
                    pageName='emailBody'
                    read={{
                      url: ip + '/api/GetEmailSubject',
                      verb: 'GET'
                    }}
                    type="generalSettings"
                    action={{
                      url: ip + '/api/UpdateEmailSubject',
                      body: {
                        "id": '',
                        "permissionsSubject": "",
                        "lateSubject": "",
                        "absentSubject": "",
                        "vocationSubject": ""
                      },
                      verb: 'POST'
                    }}
                    EntityProps={[

                      {
                        label: 'Permitions', type: 'textarea',
                        items: [{ label: 'Name', value: '{{EMPLOYEE_NAME}}' },
                        { label: 'Date', value: ' {{DATE}}' }], rows: "4", class: "form-control", 
                        validation: '', style: 'col-lg-12 my-2', backName: 'permissionsSubject', noFormCont: true,
                      },
                      {
                        label: 'Late', type: 'textarea', items: [{ label: 'Name', value: '{{EMPLOYEE_NAME}}' },
                        { label: 'Date', value: ' {{DATE}}' },
                        { label: 'Late', value: ' {{LATE}}' }], rows: "4", class: "form-control", validation: '', style: 'col-lg-12 my-2', backName: 'lateSubject', noFormCont: true,
                      },
                      {
                        label: 'Absent', type: 'textarea', items: [{ label: 'Name', value: '{{EMPLOYEE_NAME}}' },
                        { label: 'Date', value: ' {{DATE}}' }], rows: "4", class: "form-control", validation: '', style: 'col-lg-12 my-2', backName: 'absentSubject', noFormCont: true,
                      },
                      {
                        label: 'vacances', type: 'textarea', items: [{ label: 'Name', value: '{{EMPLOYEE_NAME}}' },
                        { label: 'Date', value: ' {{DATE}}' }], rows: "4", class: "form-control", validation: '', style: 'col-lg-12 my-2', backName: 'vocationSubject', noFormCont: true,
                      },

                    ]}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/smsBody'
            render={
              function ({ match }) {
                return (
                  <ModalPage
                    title={`Sms body`}
                    path={[
                      { title: 'Dashbord ', path: '/En/TAW/Main/Dashboard' },
                      { title: 'GeneralSettings ', path: '/En/TAW/Main/GeneralSettings' },
                      { title: 'update messages body', path: '/En/TAW/Main/smsBody' }
                    ]}
                    pageName='smsBody'
                    read={{
                      url: ip + '/api/GetSMSSubject',
                      verb: 'GET'
                    }}
                    type="generalSettings"
                    action={{
                      url: ip + '/api/UpdateSMSSubject',
                      body: {
                        "id": '',
                        "permissionsSubject": "",
                        "lateSubject": "",
                        "absentSubject": "",
                        "vocationSubject": ""
                      },
                      verb: 'POST'
                    }}
                    EntityProps={[

                      { label: 'Permitions', type: 'textarea', rows: "4", class: "form-control", validation: '', style: 'col-lg-12 my-2', backName: 'permissionsSubject', noFormCont: true, },
                      { label: 'Late', type: 'textarea', rows: "4", class: "form-control", validation: '', style: 'col-lg-12 my-2', backName: 'lateSubject', noFormCont: true, },
                      { label: 'Absent', type: 'textarea', rows: "4", class: "form-control", validation: '', style: 'col-lg-12 my-2', backName: 'absentSubject', noFormCont: true, },
                      { label: 'vacances', type: 'textarea', rows: "4", class: "form-control", validation: '', style: 'col-lg-12 my-2', backName: 'vocationSubject', noFormCont: true, },

                    ]}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/messagesSettings'
            render={
              function ({ }) {
                return (
                  <MessagesSettingsEn
                    mainTitle={`Messages Settings`}
                    title={'Messages Settings'}
                    path={[
                      { title: 'Dashbord ', path: '/En/TAW/Main/Dashboard' },
                      { title: 'GeneralSettings ', path: '/En/TAW/Main/GeneralSettings' },
                    ]}
                    params={[]}
                    columns={[
                      { name: '', backName: '', selection: true },
                      { name: 'Edits ', backName: ' ' },
                      { name: 'Email messages', backName: 'isAlertLateWithEmail', checkbox: true },
                      { name: 'SMS messages', backName: 'isAlertLateWithSMS', checkbox: true },
                      { name: 'Select employees', backName: ' ' },
                    ]}
                    createEntityProps={[]}
                    updateEntityProps={[
                      { label: 'Read', type: 'checkbox', noFormCont: true, validation: '', style: 'col-lg-6', backName: 'isAlertLateWithEmail' },
                      { label: 'Add', type: 'checkbox', noFormCont: true, validation: '', style: 'col-lg-6', backName: 'isAlertLateWithSMS' },
                    ]}

                    deleteMessage='Are you sure you want to Delete?'

                    read={{
                      url: ip + '/api/GetGeneralMessageSetting',
                      verb: 'GET'
                    }}

                    update={{
                      url: ip + '/api/UpdateGeneralMessageSetting',
                      body: {
                        "id": 0,
                        "isBlock": true,
                        "isLateTime": true,
                        "alertLateTime": "00:00",
                        "isAlertLateWithEmail": true,
                        "isAlertLateWithSMS": true,
                        "isAlertForManagerLateWithEmail": true,
                        "isAlertForManagerLateWithSMS": true,
                        "lateEmployees": [],
                        "isAlertAbsentWithEmail": true,
                        "isAlertAbsentWithSMS": true,
                        "isAlertForManagerAbsentWithEmail": true,
                        "isAlertForManagerAbsentWithSMS": true,
                        "absentEmployees": [],
                        "isAlertVocationWithEmail": true,
                        "isAlertVocationWithSMS": true,
                        "isAlertForManagerVocationWithEmail": true,
                        "isAlertForManagerVocationWithSMS": true,
                        "vocationEmployees": [],
                        "isAlertPermissionWithEmail": true,
                        "isAlertpermissionWithSMS": true,
                        "isAlertForManagerPermissionWithEmail": true,
                        "isAlertForManagerPermissionWithSMS": true,
                        "permissionEmployees": []
                      },
                      verb: 'POST'
                    }}

                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 4)?.data.find(page => page.id === 41) : null}
                  />
                )
              }
            }
          />

          <Route path='/En/TAW/Main/Settings/Employees/:id'
            render={
              function ({ match }) {
                return (
                  <BasicPage
                    mainTitle={match.params.id == 1 ? 'Alert late ' : match.params.id == 2 ? 'Alert Absent' : match.params.id == 3 ? 'Alert Permitions '
                      : 'Alert vacances '}
                    title={match.params.id == 1 ? 'Alert late ' : match.params.id == 2 ? 'Alert Absent' : match.params.id == 3 ? 'Alert Permitions '
                      : 'Alert vacances '}
                    path={[
                      { title: 'Dashbord', path: '/En/TAW/Main/Dashboard' },
                      { title: 'GeneralSettings ', path: '/En/TAW/Main/GeneralSettings' },
                      { title: 'Messages settings', path: '/En/TAW/Main/messagesSettings' },
                    ]}
                    searchFields={[
                      {
                        label: 'code', type: 'text', backName: 'employeeCode'
                      },
                      { label: 'Name', type: 'text', backName: 'nameEn' },
                      {
                        label: 'Branch', type: 'select', backName: 'branchId', url: API.Branches.dropdown,
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      },
                      {
                        label: 'Management', type: 'select', backName: 'adminstratId', url: API.Directorates.dropdown,
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      },
                      {
                        label: 'Department', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      },
                      {
                        label: 'Working Time', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      }
                    ]}
                    modalSearchFields={[
                      { label: 'code', type: 'text', backName: 'employeeCode' },
                      { label: 'Name', type: 'text', backName: 'nameEn' },
                      {
                        label: 'Branch', type: 'select', backName: 'branchId', url: ip + '/api/Branches/GetAllBranchsDropDown',
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      },
                      {
                        label: 'Management', type: 'select', backName: 'adminstratId', url: ip + '/api/Directorate/GetAllDirectorateDropDown',
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      },
                      {
                        label: 'Department', type: 'select', backName: 'departmentId', url: ip + '/api/Department/GetAllDepartmentDropDown',
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      },
                      {
                        label: 'Working Time', type: 'select', backName: 'shiftId', url: ip + '/api/Shift/GetAllShiftDropdown',
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      }
                    ]}
                    columns={[
                      {
                        name: 'code', backName: 'employeeCode'
                      },
                      { name: 'Name', backName: 'nameEn' },
                      { name: 'Branch', backName: 'branchName' },
                      { name: 'Working Time', backName: 'shiftName' }
                    ]}
                    createEntityProps={[]}
                    useSearchModal={true}
                    noEdit={true}
                    deleteMessage='Are you sure you want to Delete?'
                    //to get the modal data for search modal
                    modalRead={{
                      url: ip + '/api/GetLateEmployeesNotfoundGeneralSettingMessages',
                      body: {
                        "pageSize": 0,
                        "pageNumber": 0,
                        "type": match.params.id,
                        "search": {
                          "employeeCode": 0,
                          "nameEn": "",
                          "branchId": 0,
                          "adminstratId": 0,
                          "departmentId": 0,
                          "shiftId": 0
                        }
                      },
                      verb: 'POST'
                    }}
                    //the back
                    modalBackName={
                      match.params.id == 1 ? 'lateEmployees' : match.params.id == 2 ? 'absentEmployees' : match.params.id == 3 ? 'permissionEmployees'
                        : 'vocationEmployees'}
                    create={{
                      url: ip + "/api/AddGeneralSettingMessagesEmployees",
                      body: {
                        "type": match.params.id,
                        "lateEmployees": [],
                        "absentEmployees": [],
                        "vocationEmployees": [],
                        "permissionEmployees": []
                      },
                      verb: 'POST'
                    }}
                    read={{
                      url: ip + "/api/GetEmployeesGeneralSettingMessages",
                      body: {
                        "pageSize": 10,
                        "pageNumber": 0,
                        "type": match.params.id,
                        "search": {
                          "employeeCode": 0,
                          "nameEn": "",
                          "branchId": 0,
                          "adminstratId": 0,
                          "departmentId": 0,
                          "shiftId": 0
                        }
                      },
                      verb: 'POST'
                    }}
                    update={{
                      url: ip + "/api/AddGeneralSettingMessagesEmployees",
                      body: {
                        "type": match.params.id,
                        "lateEmployees": [],
                        "absentEmployees": [],
                        "vocationEmployees": [],
                        "permissionEmployees": []
                      },
                      verb: 'POST'
                    }}
                    delete={{
                      url: ip + "/api/RemoveGeneralSettingMessagesEmployees",
                      body: {
                        "id": []
                      }, verb: 'POST'
                    }}
                    addType={match.params.id}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 1)?.data.find(page => page.id === 8) : null}

                  />
                )
              }
            }
          />




          <Route path='/En/TAW/Main/autoTransaction'
            render={
              function ({ match }) {
                return (
                  <BasicPage
                    mainTitle={`Auto transaction settings`}
                    title={`Auto transaction settings  `}
                    path={[
                      { title: 'Dashboard ', path: '/En/TAW/Main/Dashboard' },
                      { title: 'General settings ', path: '/En/TAW/Main/GeneralSettings' },
                    ]}
                    // searchFields={[]}
                    searchFields={[{
                      label: 'Branch name', type: 'select', backName: 'branchId', url: API.Branches.dropdown,
                      body: {
                        "pageSize": 100,
                        "pageNumber": 0,
                        "searchCriteria": "",
                        "selectedId": [
                          0
                        ]
                      },
                      item: { value: 'id', label: 'nameEn' }
                    }]}
                    columns={[
                      { name: 'Branch name', backName: 'branchName' },
                      { name: 'Transfer time', backName: 'timeTransfer' },
                    ]}
                    createEntityProps={[
                      {
                        label: 'Branch name', type: 'select', backName: 'branchId', url: API.Branches.dropdown, style: 'col-lg-6',
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      },
                      { label: 'Transfer time', type: 'time', validation: 'Required', style: 'col-lg-6', backName: 'timeTransfer' },
                    ]}
                    updateEntityProps={[
                      {
                        label: 'Branch name', type: 'select', backName: 'branchId', url: API.Branches.dropdown, style: 'col-lg-6',
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      },
                      { label: 'Transfer time', type: 'time', validation: 'Required', style: 'col-lg-6', backName: 'timeTransfer' },
                    ]}
                    deleteMessage='Are you sure you want to Delete?'
                    create={{
                      url: ip + '/api/AddReadAndMoveSetting',
                      body: {
                        "branchId": 0,
                        "timeTransfer": ""
                      },
                      verb: 'POST'
                    }}
                    read={{
                      url: ip + '/api/GetAllReadAndMoveSetting',
                      body: {
                        "pageSize": 10,
                        "pageNumber": 0,
                        "search": {

                        }
                      },
                      verb: 'POST'
                    }}
                    update={{
                      url: ip + '/api/UpdateReadAndMoveSettingById',
                      body: {
                        "id": 0,
                        "branchId": 0,
                        "timeTransfer": ""
                      },
                      verb: 'POST'
                    }}
                    delete={{
                      url: ip + '/api/DeleteReadAndMoveSetting',
                      query: {
                        ReadAndMoveId: ''
                      },
                      verb: 'POST'
                    }}
                    allPermissions={true}

                  />

                )
              }
            }
          />

          <Route path='/En/TAW/Main/planEdites'
            render={
              function ({ match }) {
                return (
                  <ModalPage
                    title={`Plan settings`}
                    path={[
                      { title: 'Dashboard ', path: '/En/TAW/Main/Dashboard' },
                      { title: 'General Settings ', path: '/En/TAW/Main/GeneralSettings' },
                      { title: 'Plan settings', path: '/En/TAW/Main/planEdites' }
                    ]}
                    pageName='planEdites'
                    read={{
                      url: ip + '/api/GetCompanyBundleData',
                      verb: 'GET'
                    }}
                    type="generalSettings"
                    action={'renew'}
                    EntityProps={[
                      { label: 'Bundle name', type: 'info', validation: '', style: 'col-lg-6', backName: 'bundleName', disabled: true },
                      { label: 'Subscription days ', type: 'info', validation: '', style: 'col-lg-6', backName: 'subscriptionDays', disabled: true },
                      { label: 'Start date ', type: 'info', validation: '', style: 'col-lg-6', backName: 'startDate', disabled: true },
                      { label: 'End date', type: 'info', validation: '', style: 'col-lg-6', backName: 'endDate', disabled: true },

                    ]}
                  />
                )
              }
            }
          />
          <Route exact={true} path='/En/TAW/Main/EmployeesGroups'
            render={
              function () {
                return (
                  <BasicPage
                    mainTitle={EmployeesGroups.mainTitle}
                    title={EmployeesGroups.title}
                    path={EmployeesGroups.path}
                    searchFields={EmployeesGroups.searchFields}
                    columns={EmployeesGroups.columns}
                    createEntityProps={EmployeesGroups.createEntityProps}
                    updateEntityProps={EmployeesGroups.updateEntityProps}
                    deleteMessage={EmployeesGroups.deleteMessage}
                    create={EmployeesGroups.create}
                    read={EmployeesGroups.read}
                    update={EmployeesGroups.update}
                    delete={EmployeesGroups.delete}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 1)?.data.find(page => page.id === 8) : null}

                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/EmployeesGroups/Employees/:id/:name'
            render={
              function ({ match }) {
                return (
                  <BasicPage
                    mainTitle={match.params.name}
                    title={match.params.name}
                    path={[
                      { title: 'Dashboard', path: '/En/TAW/Main/Dashboard' },
                      { title: 'Employees Groups', path: '/En/TAW/Main/EmployeesGroups' }
                    ]}
                    searchFields={[
                      { label: 'ID (Code)', type: 'number', backName: 'employeeCode' },
                      { label: 'Name', type: 'text', backName: 'nameEn' },
                      {
                        label: 'Branch', type: 'select', backName: 'branchId', url: API.Branches.dropdown,
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      },
                      {
                        label: 'Management', type: 'select', backName: 'adminstratId', url: API.Directorates.dropdown,
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      },
                      {
                        label: 'Department', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      },
                      {
                        label: 'Working Time', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      }
                    ]}
                    modalSearchFields={[
                      { label: 'ID (Code)', type: 'number', backName: 'employeeCode' },
                      { label: 'Name', type: 'text', backName: 'nameEn' },
                      {
                        label: 'Branch', type: 'select', backName: 'branchId', url: ip + '/api/Branches/GetAllBranchsDropDown',
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      },
                      {
                        label: 'Management', type: 'select', backName: 'adminstratId', url: ip + '/api/Directorate/GetAllDirectorateDropDown',
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      },
                      {
                        label: 'Department', type: 'select', backName: 'departmentId', url: ip + '/api/Department/GetAllDepartmentDropDown',
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      },
                      {
                        label: 'Working Time', type: 'select', backName: 'shiftId', url: ip + '/api/Shift/GetAllShiftDropdown',
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      }
                    ]}
                    columns={[
                      { name: 'ID (Code)', backName: 'employeeCode' },
                      { name: 'Name', backName: 'nameEn' },
                      { name: 'Branch', backName: 'branchName' },
                      { name: 'Working Time', backName: 'shiftName' }
                    ]}
                    createEntityProps={[]}
                    useSearchModal={true}
                    noEdit={true}
                    deleteMessage='Are you sure you want to Delete?'
                    modalRead={{
                      url: ip + '/api/EmployeeGroupsDetails/SearchEmployeeForGroups',
                      body: {
                        "pageSize": 100,
                        "pageNumber": 0,
                        "search": {
                          "employeeCode": 0,
                          "nameEn": "",
                          "nameAr": "",
                          "branchId": 0,
                          "departmentId": 0,
                          "adminstratId": 0,
                          "jobId": 0,
                          "groupId": null,
                          "shiftId": 0
                        }
                      },
                      verb: 'POST'
                    }}
                    modalBackName='employeeId'
                    create={{
                      url: API.EmployeeGroupsDetails.create,
                      body: {
                        "employeeGroupsId": Number.parseInt(match.params.id),
                        "employeeId": []
                      },
                      verb: 'POST'
                    }}
                    read={{
                      url: API.EmployeeGroupsDetails.read,
                      body: {
                        "pageSize": 10,
                        "pageNumber": 0,
                        "search": {
                          "employeeCode": 0,
                          "nameEn": "",
                          "nameAr": "",
                          "branchId": 0,
                          "departmentId": 0,
                          "adminstratId": 0,
                          "jobId": 0,
                          "groupId": Number.parseInt(match.params.id),
                          "shiftId": 0
                        }
                      },
                      verb: 'POST'
                    }}
                    update={{
                      url: API.EmployeeGroupsDetails.update,
                      body: {
                        "employeeId": [
                          0
                        ]
                      },
                      verb: 'POST'
                    }}
                    delete={{
                      url: API.EmployeeGroupsDetails.delete,
                      body: {
                        "employeeId": []
                      },
                      verb: 'POST'
                    }}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 1)?.data.find(page => page.id === 8) : null}

                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/VacationsTypes'
            render={
              function () {
                return (
                  <BasicPage
                    mainTitle={VacationsTypes.mainTitle}
                    title={VacationsTypes.title}
                    path={VacationsTypes.path}
                    searchFields={VacationsTypes.searchFields}
                    columns={VacationsTypes.columns}
                    createEntityProps={VacationsTypes.createEntityProps}
                    updateEntityProps={VacationsTypes.updateEntityProps}
                    deleteMessage={VacationsTypes.deleteMessage}
                    create={VacationsTypes.create}
                    read={VacationsTypes.read}
                    update={VacationsTypes.update}
                    delete={VacationsTypes.delete}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 1)?.data.find(page => page.id === 9) : null}

                  />
                )
              }
            }
          />
          <Route exact={true} path='/En/TAW/Main/OfficialHolidays'
            render={
              function () {
                return (
                  <BasicPage
                    mainTitle={OfficialHolidays.mainTitle}
                    title={OfficialHolidays.title}
                    path={OfficialHolidays.path}
                    searchFields={OfficialHolidays.searchFields}
                    columns={OfficialHolidays.columns}
                    createEntityProps={OfficialHolidays.createEntityProps}
                    updateEntityProps={OfficialHolidays.updateEntityProps}
                    deleteMessage={OfficialHolidays.deleteMessage}
                    create={OfficialHolidays.create}
                    read={OfficialHolidays.read}
                    update={OfficialHolidays.update}
                    delete={OfficialHolidays.delete}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 1)?.data.find(page => page.id === 10) : null}

                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/OfficialHolidays/:id/:name'
            render={
              function ({ match }) {
                return (
                  <BasicPage
                    mainTitle={`Apply ${match.params.name} Holiday for:`}
                    title={match.params.name}
                    path={[
                      { title: 'Dashboard', path: '/En/TAW/Main/Dashboard' },
                      { title: 'Official Holidays', path: '/En/TAW/Main/OfficialHolidays' }
                    ]}
                    searchFields={[
                      { label: 'ID (Code)', type: 'number', backName: 'employeeCode' },
                      { label: 'Employees', type: 'text', backName: 'nameEn' },
                      {
                        label: 'Branch', type: 'select', backName: 'branchId', url: API.Branches.dropdown,
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      },
                      {
                        label: 'Management', type: 'select', backName: 'adminstratId', url: API.Directorates.dropdown,
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      },
                      {
                        label: 'Department', type: 'select', backName: 'departmentId', url: ip + '/api/Department/GetAllDepartmentDropDown',
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      },
                      {
                        label: 'Working Time', type: 'select', backName: 'shiftId', url: ip + '/api/Shift/GetAllShiftDropdown',
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      }
                    ]}
                    modalSearchFields={[
                      { label: 'ID (Code)', type: 'number', backName: 'employeeCode' },
                      { label: 'Name', type: 'text', backName: 'nameEn' },
                      {
                        label: 'Branch', type: 'select', backName: 'branchId', url: ip + '/api/Branches/GetAllBranchsDropDown',
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      },
                      {
                        label: 'Management', type: 'select', backName: 'adminstratId', url: ip + '/api/Directorate/GetAllDirectorateDropDown',
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      },
                      {
                        label: 'Department', type: 'select', backName: 'departmentId', url: ip + '/api/Department/GetAllDepartmentDropDown',
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      },
                      {
                        label: 'Working Time', type: 'select', backName: 'shiftId', url: ip + '/api/Shift/GetAllShiftDropdown',
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      }
                    ]}
                    columns={[
                      { name: 'ID (Code)', backName: 'employeeCode' },
                      { name: 'Employees', backName: 'nameEn' },
                      { name: 'Branch', backName: 'branchName' },
                      { name: 'Working Time', backName: 'shiftName' }
                    ]}
                    createEntityProps={[]}
                    useSearchModal={true}
                    noEdit={true}
                    deleteMessage='Are you sure you want to Delete?'
                    modalRead={{
                      url: ip + '/api/OfficialVacationDetails/SearchEmployeeNotInOfficialVacation',
                      body: {
                        "pageSize": 100,
                        "pageNumber": 0,
                        "search": {
                          "officialVacationId": Number.parseInt(match.params.id)
                        }
                      },
                      verb: 'POST'
                    }}
                    modalBackName='employeeId'
                    create={{
                      url: ip + '/api/OfficialVacationDetails/AddEmployeeOfficialVacation',
                      body: {
                        "employeeId": [],
                        "officialVacationId": Number.parseInt(match.params.id),
                        "isavailable": true
                      },
                      verb: 'POST'
                    }}
                    read={{
                      url: ip + '/api/OfficialVacationDetails/SearchEmployeeInOfficialVacation',
                      body: {
                        "pageSize": 10,
                        "pageNumber": 0,
                        "search": {
                          "officialVacationId": Number.parseInt(match.params.id)
                        }
                      },
                      verb: 'POST'
                    }}
                    update={{
                      url: '',
                      body: {},
                      verb: 'POST'
                    }}
                    delete={{
                      url: ip + '/api/OfficialVacationDetails/DeleteEmployeeOfficialVacationDetails',
                      body: {
                        "employeeId": [],
                        "officialVacationId": Number.parseInt(match.params.id),
                      },
                      verb: 'POST'
                    }}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 1)?.data.find(page => page.id === 10) : null}

                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/UnregisteredEmployees'
            render={
              function () {
                return (
                  <BasicPage
                    mainTitle='Unregistered Employees'
                    title='Unregistered Employees'
                    path={[{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }]}
                    searchFields={[
                      { label: 'Code', type: 'number', backName: 'employeeCode' }
                    ]}
                    columns={[
                      {
                        name: 'Code',
                        backName: 'employeeCode'
                      },
                      {
                        name: 'Name',
                        backName: 'employeeName'
                      }
                    ]}
                    createEntityProps={Employees.createEntityProps}
                    updateEntityProps={Employees.updateEntityProps}
                    deleteMessage='Are you sure you want to Delete?'
                    create={Employees.createUnrigestered}
                    read={{
                      url: ip + '/api/Transaction/ReadMachineTransactionsEmployeesNotFound',
                      body: {
                        "pageSize": 10,
                        "pageNumber": 0,
                        "search": {
                          "from": "",
                          "to": "",
                          "branchIds": [
                          ],
                          "employeeCode": 0
                        }
                      },
                      verb: 'POST'
                    }}
                    update={Employees.createUnrigestered}
                    delete={{
                      url: ip + '/api/Transaction/RemoveMachineTransactionForEmployeesNotFound',
                      query: {
                        machineTransactionId: ''
                      },
                      verb: 'POST'
                    }}
                    noCreate={true}
                    routUnregistered={true}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 1)?.data.find(page => page.id === 11) : null}

                  />
                )
              }
            }
          />













          <Route path='/En/TAW/Main/GetBranchTransactions'
            render={
              function () {
                return (
                  <ProcedurePage
                    mainTitle={GetBranchTransactions.mainTitle}
                    title={GetBranchTransactions.title}
                    path={GetBranchTransactions.path}
                    columns={GetBranchTransactions.columns}
                    actionEntityProps={GetBranchTransactions.actionEntityProps}
                    action={GetBranchTransactions.action}
                    btnTxt='Get Branch Transactions'
                    btnClr='#55ce63'
                    actionSearch={true}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 2)?.data.find(page => page.id === 12) : null}
                  />
                )
              }
            }
          />
          {/* <Route path='/En/TAW/Main/cancel'
          render={
            function () {
              return (
                <DashBoard>
                  API Not Found
                </DashBoard>
              )
            }
          }
        /> */}
          <Route path='/En/TAW/Main/DisplayTransactions'
            render={
              function () {
                return (
                  <ReportPage
                    mainTitle='Display Transactions'
                    title='Display Transactions'
                    path={[{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }]}
                    searchFields={
                      [
                        { label: 'Employee Code', type: 'text', backName: 'employeeCode' },
                        { label: 'Employee Name', type: 'text', backName: 'employeeName' },
                        {
                          label: 'Branch', type: 'select', backName: 'branchId', url: API.Branches.dropdown,
                          body: {
                            "pageSize": 100,
                            "pageNumber": 0,
                            "searchCriteria": "",
                            "selectedId": [
                              0
                            ]
                          },
                          item: { value: 'id', label: 'nameEn' }
                        },
                        {
                          label: 'Management', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
                          body: {
                            "pageSize": 100,
                            "pageNumber": 0,
                            "searchCriteria": "",
                            "selectedId": [
                              0
                            ]
                          },
                          item: { value: 'id', label: 'nameEn' }
                        },
                        {
                          label: 'Department', type: 'select', backName: 'departmentId', url: ip + '/api/Department/GetAllDepartmentDropDown',
                          body: {
                            "pageSize": 100,
                            "pageNumber": 0,
                            "searchCriteria": "",
                            "selectedId": [
                              0
                            ]
                          },
                          item: { value: 'id', label: 'nameEn' }
                        },
                        {
                          label: 'Job', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
                          body: {
                            "pageSize": 100,
                            "pageNumber": 0,
                            "searchCriteria": "",
                            "selectedId": [
                              0
                            ]
                          },
                          item: { value: 'id', label: 'nameEn' }
                        },
                        {
                          label: 'Group', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
                          body: {
                            "pageSize": 100,
                            "pageNumber": 0,
                            "searchCriteria": "",
                            "selectedId": [
                              0
                            ]
                          },
                          item: { value: 'id', label: 'nameEn' }
                        },
                        {
                          label: 'Working Time', type: 'select', backName: 'shiftId', url: ip + '/api/Shift/GetAllShiftDropdown',
                          body: {
                            "pageSize": 100,
                            "pageNumber": 0,
                            "searchCriteria": "",
                            "selectedId": [
                              0
                            ]
                          },
                          item: { value: 'id', label: 'nameEn' }
                        },
                        { label: 'Date', type: 'date', backName: 'date', backSearch: true }
                      ]
                    }
                    columns={[
                      { name: 'Employee Code', backName: 'employeeCode' },
                      { name: 'Employee Name', backName: 'employeeName' },
                      { name: 'Branch', backName: 'branchName' },
                      { name: 'Working Time', backName: 'shiftName' },
                      { name: 'Time In', backName: 'timeIn' },
                      { name: 'Time Out', backName: 'timeOut' },
                      { name: 'Manually Edited', backName: 'manual', boolean: true },
                      { name: 'Transferred', backName: 'isMoved', boolean: true }
                    ]}
                    read={{
                      url: ip + '/api/MovedTransaction/GetAllTransactions',
                      body: {
                        "search": {
                          "date": new Date().toJSON().slice(0, 10),
                          "branchIds": [],
                          "employeeIds": [],
                          "employeeName": ""
                        }
                      },
                      verb: 'POST'
                    }
                    }
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 2)?.data.find(page => page.id === 14) : null}
                    print={false}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/AddPermission'
            render={
              function () {
                return (
                  <BasicPage
                    mainTitle={AddPermission.mainTitle}
                    title={AddPermission.title}
                    path={AddPermission.path}
                    searchFields={AddPermission.searchFields}
                    columns={AddPermission.columns}
                    createEntityProps={AddPermission.createEntityProps}
                    updateEntityProps={AddPermission.updateEntityProps}
                    deleteMessage={AddPermission.deleteMessage}
                    create={AddPermission.create}
                    read={AddPermission.read}
                    update={AddPermission.update}
                    delete={AddPermission.delete}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 2)?.data.find(page => page.id === 17) : null}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/AddVacation'
            render={
              function () {
                return (
                  <BasicPage
                    mainTitle={AddVacation.mainTitle}
                    title={AddVacation.title}
                    path={AddVacation.path}
                    searchFields={AddVacation.searchFields}
                    columns={AddVacation.columns}
                    createEntityProps={AddVacation.createEntityProps}
                    updateEntityProps={AddVacation.updateEntityProps}
                    deleteMessage={AddVacation.deleteMessage}
                    create={AddVacation.create}
                    read={AddVacation.read}
                    update={AddVacation.update}
                    delete={AddVacation.delete}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 2)?.data.find(page => page.id === 18) : null}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/TransferTransactions'
            render={
              function () {
                return (
                  <ProcedurePage
                    title='Transfer Transactions'
                    mainTitle='Transfer Transactions'
                    path={[{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }]}
                    columns={[
                      {
                        name: 'Not Transferred Transactions',
                        backName: 'count'
                      }]}
                    modalSearchFields={[
                      { label: 'ID (Code)', type: 'number', backName: 'employeeCode' },
                      { label: 'Name', type: 'text', backName: 'nameEn' },
                      {
                        label: 'Branch', type: 'select', backName: 'branchId', url: ip + '/api/Branches/GetAllBranchsDropDown',
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      },
                      {
                        label: 'Management', type: 'select', backName: 'adminstratId', url: ip + '/api/Directorate/GetAllDirectorateDropDown',
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      },
                      {
                        label: 'Department', type: 'select', backName: 'departmentId', url: ip + '/api/Department/GetAllDepartmentDropDown',
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      },
                      {
                        label: 'Working Time', type: 'select', backName: 'shiftId', url: ip + '/api/Shift/GetAllShiftDropdown',
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      }
                    ]}
                    modalRead={{
                      url: ip + '/api/Employee/SearchAllEmployees',
                      body: {
                        "pageSize": 100,
                        "pageNumber": 0,
                        search: {
                          "employeeCode": 0,
                          "nameEn": "",
                          "nameAr": "",
                          "phoneNumber": "",
                          "placeStay": "",
                          "email": "",
                          "branchId": 0,
                          "departmentId": 0,
                          "adminstratId": 0,
                          "jobId": 0,
                          "projectId": 0,
                          "shiftId": 0,
                          "nationalitiesId": 0
                        }
                      },
                      verb: 'POST'
                    }}
                    modalBackName='employeeIds'
                    actionEntityProps={[
                      // ['Not Transferred Trans.:', 'readOnly', '', '', ''],
                      { label: 'From', type: 'date', validation: '', style: '', backName: 'from' },
                      { label: 'To', type: 'date', validation: '', style: '', backName: 'to' },
                      { label: 'Employees', type: 'searchModal', validation: '', style: '', backName: 'employeeIds' }
                    ]}
                    action={{
                      url: ip + '/api/MovedTransaction/MovedTransaction',
                      body: {
                        "employeeIds": [],
                        "from": "",
                        "to": ""
                      },
                      verb: 'POST'
                    }}
                    read={{
                      url: ip + '/api/MovedTransaction/GetMachineTransactions',
                      body: {
                        "search": {
                          "from": "",
                          "to": "",
                          "branchIds": [],
                          "employeeIds": [],
                          "employeeName": ""
                        }
                      },
                      verb: 'POST'
                    }}
                    btnTxt='Transfer Transactions'
                    btnClr='rgb(85, 206, 99)'
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 2)?.data.find(page => page.id === 13) : null}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/CancelTransferTransactions'
            render={
              function () {
                return (
                  <ProcedurePage
                    title='Cancel Transfer Transactions'
                    mainTitle='Cancel Transfer Transactions'
                    path={[{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }]}
                    columns={[]}
                    modalSearchFields={[
                      { label: 'ID (Code)', type: 'number', backName: 'employeeCode' },
                      { label: 'Name', type: 'text', backName: 'nameEn' },
                      {
                        label: 'Branch', type: 'select', backName: 'branchId', url: ip + '/api/Branches/GetAllBranchsDropDown',
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      },
                      {
                        label: 'Management', type: 'select', backName: 'adminstratId', url: ip + '/api/Directorate/GetAllDirectorateDropDown',
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      },
                      {
                        label: 'Department', type: 'select', backName: 'departmentId', url: ip + '/api/Department/GetAllDepartmentDropDown',
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      },
                      {
                        label: 'Working Time', type: 'select', backName: 'shiftId', url: ip + '/api/Shift/GetAllShiftDropdown',
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      }
                    ]}
                    modalRead={{
                      url: ip + '/api/Employee/SearchAllEmployees',
                      body: {
                        "pageSize": 100,
                        "pageNumber": 0,
                        search: {
                          "employeeCode": 0,
                          "nameEn": "",
                          "nameAr": "",
                          "phoneNumber": "",
                          "placeStay": "",
                          "email": "",
                          "branchId": 0,
                          "departmentId": 0,
                          "adminstratId": 0,
                          "jobId": 0,
                          "projectId": 0,
                          "shiftId": 0,
                          "nationalitiesId": 0
                        }
                      },
                      verb: 'POST'
                    }}
                    modalBackName='employeeIds'
                    actionEntityProps={[
                      { label: 'From', type: 'date', validation: '', style: '', backName: 'from' },
                      { label: 'To', type: 'date', validation: '', style: '', backName: 'to' },
                      { label: 'Employees', type: 'searchModal', validation: '', style: '', backName: 'employeeIds' }
                    ]}
                    action={{
                      url: ip + '/api/MovedTransaction/RemoveMovedTransaction',
                      body: {
                        "employeeIds": [],
                        "from": "",
                        "to": ""
                      },
                      verb: 'POST'
                    }}
                    btnTxt='Cancel Transfer Transactions'
                    btnClr='#F44336'
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 2)?.data.find(page => page.id === 15) : null}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/AddEditTransactions'
            render={
              function () {
                return (
                  <BasicPage
                    mainTitle={AddEditTransactions.mainTitle}
                    title={AddEditTransactions.title}
                    removeModalTitleName={true}
                    path={AddEditTransactions.path}
                    searchFields={AddEditTransactions.searchFields}
                    columns={AddEditTransactions.columns}
                    createEntityProps={AddEditTransactions.createEntityProps}
                    updateEntityProps={AddEditTransactions.updateEntityProps}
                    deleteMessage={AddEditTransactions.deleteMessage}
                    create={AddEditTransactions.create}
                    read={AddEditTransactions.read}
                    update={AddEditTransactions.update}
                    delete={AddEditTransactions.delete}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 2)?.data.find(page => page.id === 16) : null}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/CancelManuallyTrans'
            render={
              function () {
                return (
                  <ProcedurePage
                    title='Cancel Manual Transactions'
                    mainTitle='Cancel Manual Transactions'
                    path={[{ title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }]}
                    columns={[]}
                    modalSearchFields={[
                      { label: 'ID (Code)', type: 'number', backName: 'employeeCode' },
                      { label: 'Name', type: 'text', backName: 'nameEn' },
                      {
                        label: 'Branch', type: 'select', backName: 'branchId', url: ip + '/api/Branches/GetAllBranchsDropDown',
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      },
                      {
                        label: 'Management', type: 'select', backName: 'adminstratId', url: ip + '/api/Directorate/GetAllDirectorateDropDown',
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      },
                      {
                        label: 'Department', type: 'select', backName: 'departmentId', url: ip + '/api/Department/GetAllDepartmentDropDown',
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      },
                      {
                        label: 'Working Time', type: 'select', backName: 'shiftId', url: ip + '/api/Shift/GetAllShiftDropdown',
                        body: {
                          "pageSize": 100,
                          "pageNumber": 0,
                          "searchCriteria": "",
                          "selectedId": [
                            0
                          ]
                        },
                        item: { value: 'id', label: 'nameEn' }
                      }
                    ]}
                    modalRead={{
                      url: ip + '/api/Employee/SearchAllEmployees',
                      body: {
                        "pageSize": 100,
                        "pageNumber": 0,
                        search: {
                          "employeeCode": 0,
                          "nameEn": "",
                          "nameAr": "",
                          "phoneNumber": "",
                          "placeStay": "",
                          "email": "",
                          "branchId": 0,
                          "departmentId": 0,
                          "adminstratId": 0,
                          "jobId": 0,
                          "projectId": 0,
                          "shiftId": 0,
                          "nationalitiesId": 0
                        }
                      },
                      verb: 'POST'
                    }}
                    modalBackName='employeeIds'
                    actionEntityProps={[
                      { label: 'From', type: 'date', validation: '', style: '', backName: 'from' },
                      { label: 'To', type: 'date', validation: '', style: '', backName: 'to' },
                      { label: 'Employees', type: 'searchModal', validation: '', style: '', backName: 'employeeIds' }
                    ]}
                    action={{
                      url: ip + '/api/MovedTransaction/RemoveMovedTransaction',
                      body: {
                        "employeeIds": [],
                        "from": "",
                        "to": ""
                      },
                      verb: 'POST'
                    }}
                    btnTxt='Cancel Manual Transactions'
                    btnClr='#F44336'
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 2)?.data.find(page => page.id === 15) : null}
                  />
                )
              }
            }
          />
          {/* <Route path='/En/TAW/Main/DeleteEmployeesData'
          render={
            function () {
              return (
                <DashBoard>
                  API Not Found
                </DashBoard>
              )
            }
          }
        /> */}





















          <Route path='/En/TAW/Main/EmployeesReport'
            render={
              function () {
                return (
                  <ReportPage
                    mainTitle={EmployeesReport.mainTitle}
                    title={EmployeesReport.title}
                    path={EmployeesReport.path}
                    searchFields={EmployeesReport.searchFields}
                    preTableFields={EmployeesReport.preTableFields}
                    excel={true}
                    singleTable={true}
                    columns={EmployeesReport.columns}
                    read={EmployeesReport.read}
                    print={EmployeesReport.print}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 3)?.data.find(page => page.id === 19) : null}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/WorkingTimeReport'
            render={
              function () {
                return (
                  <ReportPage
                    mainTitle={WorkingTimeReport.mainTitle}
                    title={WorkingTimeReport.title}
                    path={WorkingTimeReport.path}
                    searchFields={WorkingTimeReport.searchFields}
                    preTableFields={WorkingTimeReport.preTableFields}
                    columns={WorkingTimeReport.columns}
                    totals={WorkingTimeReport.totals}
                    read={WorkingTimeReport.read}
                    print={WorkingTimeReport.print}
                    period={true}
                    WorkingTime={true}
                    excel={true}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 3)?.data.find(page => page.id === 20) : null}
                  />

                )
              }
            }
          />
          <Route path='/En/TAW/Main/DayStatusReport'
            render={
              function () {
                return (
                  <ReportPage
                    mainTitle={DayStatusReport.mainTitle}
                    title={DayStatusReport.title}
                    path={DayStatusReport.path}
                    searchFields={DayStatusReport.searchFields}
                    preTableFields={DayStatusReport.preTableFields}
                    columns={DayStatusReport.columns}
                    totals={DayStatusReport.totals}
                    read={DayStatusReport.read}
                    print={DayStatusReport.print}
                    period={DayStatusReport.period}
                    excel={true}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 3)?.data.find(page => page.id === 21) : null}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/DetailedAttendanceReport'
            render={
              function () {
                return (
                  <ReportPage
                    mainTitle={DetailedAttendanceReport.mainTitle}
                    title={DetailedAttendanceReport.title}
                    path={DetailedAttendanceReport.path}
                    searchFields={DetailedAttendanceReport.searchFields}
                    preTableFields={DetailedAttendanceReport.preTableFields}
                    columns={DetailedAttendanceReport.columns}
                    totals={DetailedAttendanceReport.totals}
                    read={DetailedAttendanceReport.read}
                    print={DetailedAttendanceReport.print}
                    period={DetailedAttendanceReport.period}
                    excel={true}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 3)?.data.find(page => page.id === 22) : null}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/TotalAttendanceReport'
            render={
              function () {
                return (
                  <ReportPage
                    mainTitle={TotalAttendanceReport.mainTitle}
                    title={TotalAttendanceReport.title}
                    path={TotalAttendanceReport.path}
                    searchFields={TotalAttendanceReport.searchFields}
                    preTableFields={TotalAttendanceReport.preTableFields}
                    columns={TotalAttendanceReport.columns}
                    totals={TotalAttendanceReport.totals}
                    read={TotalAttendanceReport.read}
                    print={TotalAttendanceReport.print}
                    period={TotalAttendanceReport.period}
                    excel={true}

                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 3)?.data.find(page => page.id === 23) : null}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/AbsenceReport'
            render={
              function () {
                return (
                  <ReportPage
                    mainTitle={AbsenceReport.mainTitle}
                    title={AbsenceReport.title}
                    path={AbsenceReport.path}
                    searchFields={AbsenceReport.searchFields}
                    columns={AbsenceReport.columns}
                    totals={AbsenceReport.totals}
                    read={AbsenceReport.read}
                    print={AbsenceReport.print}
                    singleTable={true}
                    excel={true}
                    period={true}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 3)?.data.find(page => page.id === 24) : null}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/DelayReport'
            render={
              function () {
                return (
                  <ReportPage
                    mainTitle={DelayReport.mainTitle}
                    title={DelayReport.title}
                    path={DelayReport.path}
                    searchFields={DelayReport.searchFields}
                    preTableFields={DelayReport.preTableFields}
                    columns={DelayReport.columns}
                    totals={DelayReport.totals}
                    read={DelayReport.read}
                    print={DelayReport.print}
                    period={DelayReport.period}
                    singleTable={true}
                    excel={true}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 3)?.data.find(page => page.id === 25) : null}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/InCompleteTransReport'
            render={
              function () {
                return (
                  <ReportPage
                    mainTitle={InCompleteTransReport.mainTitle}
                    title={InCompleteTransReport.title}
                    path={InCompleteTransReport.path}
                    searchFields={InCompleteTransReport.searchFields}
                    preTableFields={InCompleteTransReport.preTableFields}
                    columns={InCompleteTransReport.columns}
                    totals={InCompleteTransReport.totals}
                    read={InCompleteTransReport.read}
                    print={InCompleteTransReport.print}
                    period={InCompleteTransReport.period}
                    printPeriod={false}
                    excel={true}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 3)?.data.find(page => page.id === 26) : null}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/EarlyAttendanceReport'
            render={
              function () {
                return (
                  <ReportPage
                    mainTitle={EarlyAttendanceReport.mainTitle}
                    title={EarlyAttendanceReport.title}
                    path={EarlyAttendanceReport.path}
                    searchFields={EarlyAttendanceReport.searchFields}
                    preTableFields={EarlyAttendanceReport.preTableFields}
                    columns={EarlyAttendanceReport.columns}
                    totals={EarlyAttendanceReport.totals}
                    read={EarlyAttendanceReport.read}
                    print={EarlyAttendanceReport.print}
                    period={EarlyAttendanceReport.period}
                    excel={true}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 3)?.data.find(page => page.id === 27) : null}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/AttendanceSummaryReport'
            render={
              function () {
                return (

                  <ReportPage
                    mainTitle={AttendanceSummaryReport.mainTitle}
                    title={AttendanceSummaryReport.title}
                    path={AttendanceSummaryReport.path}
                    searchFields={AttendanceSummaryReport.searchFields}
                    preTableFields={AttendanceSummaryReport.preTableFields}
                    columns={AttendanceSummaryReport.columns}
                    totals={AttendanceSummaryReport.totals}
                    read={AttendanceSummaryReport.read}
                    print={AttendanceSummaryReport.print}
                    period={AttendanceSummaryReport.period}
                    colored={true}
                    singleTable={true}
                    excel={true}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 3)?.data.find(page => page.id === 28) : null}

                  />


                )
              }
            }
          />
          <Route path='/En/TAW/Main/TotalAttendanceSummaryReport'
            render={
              function () {
                return (
                  <ReportPage
                    mainTitle={TotalAttendanceSummaryReport.mainTitle}
                    title={TotalAttendanceSummaryReport.title}
                    path={TotalAttendanceSummaryReport.path}
                    searchFields={TotalAttendanceSummaryReport.searchFields}
                    preTableFields={TotalAttendanceSummaryReport.preTableFields}
                    columns={TotalAttendanceSummaryReport.columns}
                    totals={TotalAttendanceSummaryReport.totals}
                    read={TotalAttendanceSummaryReport.read}
                    print={TotalAttendanceSummaryReport.print}
                    period={TotalAttendanceSummaryReport.period}
                    printPeriod={true}
                    excel={true}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 3)?.data.find(page => page.id === 29) : null}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/OvertimeReport'
            render={
              function () {
                return (
                  <ReportPage
                    mainTitle={OvertimeReport.mainTitle}
                    title={OvertimeReport.title}
                    path={OvertimeReport.path}
                    searchFields={OvertimeReport.searchFields}
                    preTableFields={OvertimeReport.preTableFields}
                    columns={OvertimeReport.columns}
                    totals={OvertimeReport.totals}
                    read={OvertimeReport.read}
                    print={OvertimeReport.print}
                    period={OvertimeReport.period}
                    printPeriod={true}
                    overtime={true}
                    excel={true}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 3)?.data.find(page => page.id === 29) : null}

                  />

                )
              }
            }
          />
          <Route path='/En/TAW/Main/WorkingTimeDetailsReport'
            render={
              function () {
                return (

                  <ReportPage
                    mainTitle={WorkingTimeDetailsReport.mainTitle}
                    title={WorkingTimeDetailsReport.title}
                    path={WorkingTimeDetailsReport.path}
                    searchFields={WorkingTimeDetailsReport.searchFields}
                    preTableFields={WorkingTimeDetailsReport.preTableFields}
                    columns={WorkingTimeDetailsReport.columns}
                    totals={WorkingTimeDetailsReport.totals}
                    read={WorkingTimeDetailsReport.read}
                    print={WorkingTimeDetailsReport.print}
                    period={WorkingTimeDetailsReport.period}
                    printPeriod={true}
                    workingTimeDetails={true}
                    excel={true}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 3)?.data.find(page => page.id === 31) : null}
                  />

                )
              }
            }
          />
          <Route path='/En/TAW/Main/BranchAttendanceReport'
            render={
              function () {
                return (
                  <ReportPage
                    mainTitle={BranchAttendanceReport.mainTitle}
                    title={BranchAttendanceReport.title}
                    path={BranchAttendanceReport.path}
                    searchFields={BranchAttendanceReport.searchFields}
                    preTableFields={BranchAttendanceReport.preTableFields}
                    columns={BranchAttendanceReport.columns}
                    totals={BranchAttendanceReport.totals}
                    read={BranchAttendanceReport.read}
                    print={BranchAttendanceReport.print}
                    period={BranchAttendanceReport.period}
                    excel={true}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 3)?.data.find(page => page.id === 32) : null}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/VacationsReport'
            render={
              function () {
                return (
                  <ReportPage
                    mainTitle={VacationsReport.mainTitle}
                    title={VacationsReport.title}
                    path={VacationsReport.path}
                    searchFields={VacationsReport.searchFields}
                    preTableFields={VacationsReport.preTableFields}
                    columns={VacationsReport.columns}
                    totals={VacationsReport.totals}
                    read={VacationsReport.read}
                    print={VacationsReport.print}
                    period={VacationsReport.period}
                    excel={true}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 3)?.data.find(page => page.id === 33) : null}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/PermissionsReport'
            render={
              function () {
                return (
                  <ReportPage
                    mainTitle={PermissionsReport.mainTitle}
                    title={PermissionsReport.title}
                    path={PermissionsReport.path}
                    searchFields={PermissionsReport.searchFields}
                    preTableFields={PermissionsReport.preTableFields}
                    columns={PermissionsReport.columns}
                    totals={PermissionsReport.totals}
                    read={PermissionsReport.read}
                    print={PermissionsReport.print}
                    period={PermissionsReport.period}
                    excel={true}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 3)?.data.find(page => page.id === 34) : null}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/DelayEarlyReport'
            render={
              function () {
                return (
                  <ReportPage
                    mainTitle={DelayEarlyReport.mainTitle}
                    title={DelayEarlyReport.title}
                    path={DelayEarlyReport.path}
                    searchFields={DelayEarlyReport.searchFields}
                    preTableFields={DelayEarlyReport.preTableFields}
                    columns={DelayEarlyReport.columns}
                    totals={DelayEarlyReport.totals}
                    read={DelayEarlyReport.read}
                    print={DelayEarlyReport.print}
                    singleTable={true}
                    excel={true}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 3)?.data.find(page => page.id === 35) : null}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/TotalAbsenceReport'
            render={
              function () {
                return (
                  <ReportPage
                    mainTitle={TotalAbsenceReport.mainTitle}
                    title={TotalAbsenceReport.title}
                    path={TotalAbsenceReport.path}
                    searchFields={TotalAbsenceReport.searchFields}
                    preTableFields={TotalAbsenceReport.preTableFields}
                    columns={TotalAbsenceReport.columns}
                    totals={TotalAbsenceReport.totals}
                    read={TotalAbsenceReport.read}
                    print={TotalAbsenceReport.print}
                    period={TotalAbsenceReport.period}
                    printPeriod={true}
                    abcentFrom={true}
                    excel={true}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 3)?.data.find(page => page.id === 36) : null}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/TotalDelayReport'
            render={
              function () {
                return (
                  <ReportPage
                    mainTitle={TotalDelayReport.mainTitle}
                    title={TotalDelayReport.title}
                    path={TotalDelayReport.path}
                    searchFields={TotalDelayReport.searchFields}
                    preTableFields={TotalDelayReport.preTableFields}
                    columns={TotalDelayReport.columns}
                    totals={TotalDelayReport.totals}
                    read={TotalDelayReport.read}
                    print={TotalDelayReport.print}
                    period={TotalDelayReport.period}
                    delay={true}
                    excel={true}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 3)?.data.find(page => page.id === 37) : null}
                  />
                )
              }
            }
          />

















          <Route path='/En/TAW/Main/Devices'
            render={
              function () {
                return (
                  <BasicPage
                    mainTitle={Devices.mainTitle}
                    title={Devices.title}
                    path={Devices.path}
                    searchFields={Devices.searchFields}
                    columns={Devices.columns}
                    createEntityProps={Devices.createEntityProps}
                    updateEntityProps={Devices.updateEntityProps}
                    deleteMessage={Devices.deleteMessage}
                    create={Devices.create}
                    read={Devices.read}
                    update={Devices.update}
                    delete={Devices.delete}
                    devices={true}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 4)?.data.find(page => page.id === 38) : null}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/SpecialWorkingDays'
            render={
              function () {
                return (
                  <BasicPage
                    mainTitle={SpecialWorkingDays.mainTitle}
                    title={SpecialWorkingDays.title}
                    path={SpecialWorkingDays.path}
                    searchFields={SpecialWorkingDays.searchFields}
                    columns={SpecialWorkingDays.columns}
                    createEntityProps={SpecialWorkingDays.createEntityProps}
                    updateEntityProps={SpecialWorkingDays.updateEntityProps}
                    deleteMessage={SpecialWorkingDays.deleteMessage}
                    create={SpecialWorkingDays.create}
                    read={SpecialWorkingDays.read}
                    update={SpecialWorkingDays.update}
                    delete={SpecialWorkingDays.delete}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 4)?.data.find(page => page.id === 39) : null}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/UserPermission' exact={true}
            render={
              function () {
                return (
                  <BasicPage
                    mainTitle={UserPermission.mainTitle}
                    title={UserPermission.title}
                    path={UserPermission.path}
                    searchFields={UserPermission.searchFields}
                    columns={UserPermission.columns}
                    createEntityProps={UserPermission.createEntityProps}
                    updateEntityProps={UserPermission.updateEntityProps}
                    deleteMessage={UserPermission.deleteMessage}
                    create={UserPermission.create}
                    read={UserPermission.read}
                    update={UserPermission.update}
                    delete={UserPermission.delete}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 4)?.data.find(page => page.id === 40) : null}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/UserPermission/Permissions/:groupId/:groupName'
            render={
              function ({ match }) {
                return (
                  <PermissionsPage
                    mainTitle={`Permissions in (${match.params.groupName})`}
                    title={match.params.groupName}
                    path={[
                      { title: 'Dashboard', path: '/En/TAW/Main/Dashboard' },
                      { title: 'User Permissions', path: '/En/TAW/Main/UserPermission' }
                    ]}
                    params={match.params}
                    columns={[
                      { name: '', backName: '', selection: true },
                      { name: 'ID', backName: 'id' },
                      { name: 'Page Name', backName: 'nameEn' },
                      { name: 'View', backName: 'Get', checkbox: true },
                      { name: 'Add', backName: 'Add', checkbox: true },
                      { name: 'Edit', backName: 'Edite ', checkbox: true },
                      { name: 'Delete', backName: 'Delete', checkbox: true },
                      { name: 'Print', backName: 'Print', checkbox: true },
                    ]}
                    createEntityProps={[]}
                    updateEntityProps={[
                      { label: 'Read', type: 'checkbox', noFormCont: true, validation: '', style: 'col-lg-6', backName: 'active' },
                      { label: 'Add', type: 'checkbox', noFormCont: true, validation: '', style: 'col-lg-6', backName: 'active' },
                      { label: 'Edit', type: 'checkbox', noFormCont: true, validation: '', style: 'col-lg-6', backName: 'active' },
                      { label: 'Delete', type: 'checkbox', noFormCont: true, validation: '', style: 'col-lg-6', backName: 'active' },
                      { label: 'Print', type: 'checkbox', noFormCont: true, validation: '', style: 'col-lg-6', backName: 'active' },
                    ]}

                    deleteMessage='Are you sure you want to Delete?'
                    create={{
                      url: API.Directorates.create,
                      body: {
                        nameAr: '',
                        nameEn: '',
                        branchId: match.params.groupId,
                        managerId: ''
                      },
                      verb: 'POST'
                    }}
                    read={{
                      url: ip + '/api/CustomGroupPermission/GetAllPagesPermission',
                      body: {
                        "pageSize": 100,
                        "pageNumber": 0,
                        "searchCriteria": "",
                        "selectedId": [
                          0
                        ]
                      },
                      query: {
                        groupId: match.params.groupId
                      },
                      verb: 'POST'
                    }}
                    update={{
                      url: ip + '/api/AddGroupPermissions',
                      body: {
                        "groupId": match.params.groupId,
                        "pagePermissions": [
                          // {
                          //   "pageId": 0,
                          //   "permissionIds": [
                          //   ]
                          // }
                        ]
                      },
                      verb: 'POST'
                    }}
                    delete={{
                      url: API.Directorates.delete,
                      query: {
                        branchId: ''
                      },
                      verb: 'POST'
                    }}
                    noCreate={true}
                    noDelete={true}
                    noEdit={true}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 4)?.data.find(page => page.id === 40) : null}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/UserPermission/Users/:groupId/:groupName'
            render={
              function ({ match }) {
                return (
                  <BasicPage
                    mainTitle={`Users in (${match.params.groupName})`}
                    title={match.params.groupName}
                    path={[
                      { title: 'Dashboard', path: '/En/TAW/Main/Dashboard' },
                      { title: 'User Permissions', path: '/En/TAW/Main/UserPermission' }
                    ]}
                    columns={[
                      { name: 'Code', backName: 'userCode' },
                      { name: 'Name', backName: 'name' },
                      { name: 'UserName', backName: 'userName' }
                    ]}
                    modalColumns={[
                      { name: 'Code', backName: 'userCode' },
                      { name: 'Name', backName: 'name' },
                      { name: 'UserName', backName: 'userName' }
                    ]}
                    createEntityProps={[]}
                    useSearchModal={true}
                    noEdit={true}
                    deleteMessage='Are you sure you want to Delete?'
                    modalRead={{
                      url: ip + '/api/GetAllCompaniesUsersNotInGroup',
                      body: {
                        "pageSize": 100,
                        "pageNumber": 0,
                        "searchCriteria": "",
                        "selectedId": []
                      },
                      verb: 'POST'
                    }}
                    modalBackName='userIds'
                    create={{
                      url: ip + '/api/CustomGroupPermission/AddCustomUserGroup',
                      body: {
                        "groupId": match.params.groupId,
                        "userIds": []
                      },
                      verb: 'POST'
                    }}
                    read={{
                      url: ip + '/api/CustomGroupPermission/GetUsersForGroups',
                      body: {
                        "groupId": [match.params.groupId],
                        "pageSize": 100,
                        "pageNumber": 0,
                        "searchCriteria": "",
                        "selectedId": [
                          0
                        ]
                      },
                      verb: 'POST'
                    }}
                    update={{
                      url: API.EmployeeGroupsDetails.update,
                      body: {
                        "employeeId": [
                          0
                        ]
                      },
                      verb: 'POST'
                    }}
                    delete={{
                      url: ip + '/api/CustomGroupPermission/RemoveCustomUserGroup',
                      body: {
                        "userIds": [],
                        "groupId": match.params.groupId
                      },
                      verb: 'POST'
                    }}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 4)?.data.find(page => page.id === 40) : null}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/UsersAccounts'
            render={
              function () {
                return (
                  <BasicPage
                    mainTitle={`Users Accounts`}
                    title={`Users Accounts`}
                    path={[
                      { title: 'Dashboard', path: '/En/TAW/Main/Dashboard' }
                    ]}
                    // searchFields={[]}
                    columns={[
                      { name: 'Code', backName: 'userCode' },
                      { name: 'Name', backName: 'name' },
                      { name: 'UserName', backName: 'userName' }
                    ]}
                    createEntityProps={[
                      { label: 'UserName', type: 'text', validation: 'Required', style: 'col-lg-6', titleSize: 'mr-1', backName: 'userName' },
                      { label: 'Password', type: 'password', validation: 'Required', style: 'col-lg-6', titleSize: 'mr-1', backName: 'password' },
                      { label: 'Email', type: 'email', validation: 'Required', style: 'col-lg-6', titleSize: 'mr-1', backName: 'email' },
                      { label: 'Name', type: 'text', validation: 'Required', style: 'col-lg-6', titleSize: 'mr-1', backName: 'name' },
                      // { label: 'I Agree to Privacy Policy', type: 'checkbox', validation: '', style: '', backName: 'isOk' },
                    ]}
                    updateEntityProps={[
                      { label: 'UserName', type: 'text', validation: 'Required', style: 'col-lg-6', titleSize: 'mr-1', backName: 'userName' },
                      { label: 'Password', type: 'password', validation: 'Required', style: 'col-lg-6', titleSize: 'mr-1', backName: 'password' },
                      { label: 'Email', type: 'email', validation: 'Required', style: 'col-lg-6', titleSize: 'mr-1', backName: 'email' },
                      { label: 'Name', type: 'text', validation: 'Required', style: 'col-lg-6', titleSize: 'mr-1', backName: 'name' },
                      // { label: 'I Agree to Privacy Policy', type: 'checkbox', validation: '', style: '', backName: 'isOk' },
                    ]}
                    deleteMessage='Are you sure you want to Delete?'
                    create={{
                      url: ip + '/api/RegisterUsersForCompany',
                      body: {
                        "userName": "",
                        "email": "",
                        "password": "",
                        "name": "",
                        "isOk": true
                      },
                      verb: 'POST'
                    }}
                    read={{
                      url: ip + '/api/GetAllCompaniesUsers',
                      body: {
                        "pageSize": 10,
                        "pageNumber": 0,
                        "searchCriteria": "",
                        "selectedId": [
                          0
                        ]
                      },
                      verb: 'POST'
                    }}
                    update={{
                      url: ip + '/api/UpdateUserAccount',
                      body: {
                        "id": "",
                        "userName": "",
                        "email": "",
                        "password": "",
                        "name": "",
                        "isOk": true
                      },
                      verb: 'POST'
                    }}
                    delete={{
                      url: ip + '/api/RemoveUserAccount',
                      query: {
                        userId: ''
                      },
                      verb: 'POST'
                    }}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 4)?.data.find(page => page.id === 43) : null}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/CompanyInfo'
            render={
              function () {
                return (
                  <CompanyInfo
                    updateEntityProps={[
                      { label: 'Arabic Company Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'companyNameAr' },
                      { label: 'English Company Name', type: 'text', validation: '', style: 'col-lg-6', backName: 'companyNameEn' },
                      { label: 'Activity Arabic', type: 'text', validation: '', style: 'col-lg-6', backName: 'companyActivityAr' },
                      { label: 'Activity English', type: 'text', validation: '', style: 'col-lg-6', backName: 'companyActivityEn' },
                      { label: 'Address Arabic', type: 'text', validation: '', style: 'col-lg-6', backName: 'adressAr' },
                      { label: 'Address English', type: 'text', validation: '', style: 'col-lg-6', backName: 'adressEn' },
                      { label: 'Phone', type: 'text', validation: '', style: 'col-lg-6', backName: 'companyPhoneNumber' },
                      { label: 'Fax', type: 'text', validation: '', style: 'col-lg-6', backName: 'fax' },
                      { label: 'Website', type: 'text', validation: '', style: 'col-lg-6', backName: 'website' },
                      { label: 'Email', type: 'email', validation: '', style: 'col-lg-6', backName: 'email' },
                    ]}
                    read={{
                      url: ip + '/api/GetUserData',
                      verb: 'GET'
                    }}
                    update={{
                      url: ip + '/api/UpdatUser',
                      body: {
                        model: {
                          "id": "",
                          "email": "",
                          "companyNameAr": "",
                          "companyNameEn": "",
                          "adressAr": "",
                          "adressEn": "",
                          "companyActivityAr": "",
                          "companyActivityEn": "",
                          "fax": "",
                          "website": "",
                          "companyPhoneNumber": ""
                        },
                        imagePath: ''
                      },
                      verb: 'POST'
                    }}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 4)?.data.find(page => page.id === 42) : null}
                  />
                )
              }
            }
          />
          <Route path='/En/TAW/Main/GeneralSettings'
            render={
              function () {
                return (
                  <FormPage
                    title={`General Settings`}
                    updateEntityProps={[

                      { label: 'Time To Neglect Repeat Finger Print', type: 'number', validation: '', noFormCont: true, style: 'col-lg-11 mb-0 border border-bottom-0', titleSize: 'col-sm-6 py-2 border-right', backName: 'timeToNeglectRepeatFingerPrint' },
                      {
                        label: 'Number Of Seqance Which Will Show', type: 'staticSelect', validation: '', noFormCont: true, style: 'col-lg-11 mb-0 border border-bottom-0', titleSize: 'col-sm-6 py-2 border-right', backName: 'numberOfSeqanceWhichWillShow',


                        items: [
                          { label: 'One Shift', value: 1 },
                          { label: 'Two Shifts', value: 2 },
                          { label: 'Three Shifts', value: 3 },
                          { label: 'Four Shifts', value: 4 }
                        ]
                      },

                      { label: 'Extra Time Before Shift', type: 'number', validation: '', noFormCont: true, style: 'col-lg-11 mb-0 border border-bottom-0', titleSize: 'col-sm-6 py-2 border-right', backName: 'extraTimeBeforeShift' },
                      { label: 'Extra Time After Shift', type: 'number', validation: '', noFormCont: true, style: 'col-lg-11 mb-0 border border-bottom-0', titleSize: 'col-sm-6 py-2 border-right', backName: 'extraTimeAfterShift' },
                      { label: ['Is Time Absence After Delay', 'Time Absence After Delay'], type: 'shiftEndNextDay', noFormCont: true, dropdownAlign: ' border-right col-sm-6', validation: '', style: 'col-lg-11 mb-0 border border-bottom-0', titleSize: 'col-sm-6 py-2 border-right', backName: ['isTimeAbsenceAfterDelay', 'timeAbsenceAfterDelay'], inputType: 'number' },
                      { label: ['Is Time Absence After Early Leave', 'Time Absence After Early Leave'], type: 'shiftEndNextDay', noFormCont: true, dropdownAlign: ' border-right col-sm-6', validation: '', style: 'col-lg-11 mb-0 border border-bottom-0', titleSize: 'col-sm-6 py-2 border-right', backName: ['isTimeAbsenceAfterEarlyLeave', 'timeAbsenceAfterEarlyLeave'], inputType: 'number' },
                      { label: 'Is First Move Attend And Last Move Leave', type: 'checkbox', noFormCont: true, validation: '', style: 'col-lg-11 mb-0 border border-bottom-0', titleSize: 'col-sm-6 py-2 border-right', backName: 'isFirstMoveAttendAndLastMoveLeave' },
                      { label: 'Auto transaction settings', type: 'link', validation: '', to: '/En/TAW/Main/autoTransaction', noFormCont: true, name: 'Change info', style: 'col-lg-11 mb-0 border border-bottom-0', titleSize: 'col-sm-6 py-2 border-right', backName: '' },
                      // { label: 'Plan settings', type: 'link', validation: '', to: '/En/TAW/Main/planEdites', name: 'Change info', style: 'col-lg-11 mb-5 border h-auto', titleSize: 'col-sm-6 py-2 border-right', backName: '' },

                      { label: 'Plan settings', type: 'link', validation: '', to: '/En/TAW/Main/planEdites', noFormCont: true, name: 'Change info', style: 'col-lg-11 mb-0 border border-bottom-0', titleSize: 'col-sm-6 py-2 border-right', backName: '' },
                      { label: 'Email Settings', type: 'link', validation: '', to: '/En/TAW/Main/emailEdites', noFormCont: true, name: 'Change info', style: 'col-lg-11 mb-0 border border-bottom-0', titleSize: 'col-sm-6 py-2 border-right', backName: '' },
                      { label: 'SMS Settings ', type: 'link', validation: '', to: '/En/TAW/Main/smsEdites', noFormCont: true, name: 'Change info', style: 'col-lg-11 mb-0 border border-bottom-0', titleSize: 'col-sm-6 py-2 border-right', backName: '' },
                      { label: 'Add Email body', type: 'link', validation: '', to: '/En/TAW/Main/emailBody', noFormCont: true, name: 'Change info', style: 'col-lg-11 mb-0 border border-bottom-0', titleSize: 'col-sm-6 py-2 border-right', backName: '' },
                      { label: 'Add SMS body', type: 'link', validation: '', to: '/En/TAW/Main/smsBody', noFormCont: true, name: 'Change info', style: 'col-lg-11 mb-0 border border-bottom-0', titleSize: 'col-sm-6 py-2 border-right', backName: '' },
                      { label: 'Messages Settings', type: 'link', validation: '', to: '/En/TAW/Main/messagesSettings', noFormCont: true, name: 'Change info', style: 'col-lg-11 mb-5 border h-auto', titleSize: 'col-sm-6 py-2 border-right mt-0 ', backName: '' },


                      { label: 'Colored report settings', type: 'header', validation: '', style: 'col-lg-12', titleSize: 'col-12', backName: '' },
                      { label: 'Perimmision', type: 'color', validation: '', style: '', titleSize: '', backName: 'perimmisionColor' },
                      { label: 'Absent', type: 'color', validation: '', style: '', titleSize: '', backName: 'absentColor' },
                      { label: 'WeekEnd', type: 'color', validation: '', style: '', titleSize: '', backName: 'weekEndColor' },
                      { label: 'Work', type: 'color', validation: '', style: '', titleSize: '', backName: 'workColor' },
                      { label: 'Vacation', type: 'color', validation: '', style: '', titleSize: '', backName: 'vacationColor' },
                      { label: 'Late', type: 'color', validation: '', style: '', titleSize: '', backName: 'lateColor' },
                      { label: 'Early leave', type: 'color', validation: '', style: '', titleSize: '', backName: 'earlyLeaveColor' },
                      { label: 'Official vacation', type: 'color', validation: '', style: '', titleSize: '', backName: 'officialVacationColor' },

                    ]}
                    read={{
                      url: ip + '/api/GeneralSetting/GetGeneralSettings',
                      body: {
                        "pageSize": 10,
                        "pageNumber": 0,
                        "searchCriteria": "",
                        "selectedId": [
                          0
                        ]
                      },
                      verb: 'POST'
                    }}
                    update={{
                      url: ip + '/api/GeneralSetting/AddGeneralSetting',
                      body: {
                        "id": 0,
                        "timeToNeglectRepeatFingerPrint": "",
                        "numberOfSeqanceWhichWillShow": 0,
                        "extraTimeBeforeShift": "",
                        "extraTimeAfterShift": "",
                        "timeAbsenceAfterDelay": "",
                        "timeAbsenceAfterEarlyLeave": "",
                        "isFirstMoveAttendAndLastMoveLeave": false,
                        "isTimeAbsenceAfterDelay": false,
                        "isTimeAbsenceAfterEarlyLeave": false
                      },
                      verb: 'POST'
                    }}
                    permissions={mainLayout.state.loadedPermissions ? mainLayout.state.permissions.find(tab => tab.id === 4)?.data.find(page => page.id === 41) : null}
                  />
                )
              }
            }
          />

          <Route path='/En/TAW/Main/ChangePassword'
            render={
              function () {
                return (
                  <ModalPage
                    title={`Change Password`}
                    path={[{ title: 'Dashboard ', path: '/En/TAW/Main/Dashboard' }, { title: 'Change Password ', path: '/En/TAW/Main/ChangePassword' }]}
                    EntityProps={[
                      { label: 'Current Password', type: 'password', validation: 'Required', style: 'col-lg-12', backName: 'oldPassword' },
                      { label: 'New Password', type: 'password', validation: 'Required', style: 'col-lg-12', backName: 'newPassword' },
                      { label: 'Confirm Password', type: 'password', validation: 'Required Compare', style: 'col-lg-12', backName: 'confirmPassword' }]}
                    read={undefined}
                    action={{
                      url: ip + '/api/ChangePassword',
                      body: {
                        "companyName": Cookie.get('companyName'),
                        "userName": Cookie.get('userName'),
                        "oldPassword": "",
                        "newPassword": "",
                        "confirmPassword": ""
                      },
                      verb: 'POST'
                    }}
                    routOut={true}
                  />
                )
              }
            }
          />

          {!Cookie.get('token') ? <Redirect to="/" /> : null}
        </section>
      </React.Fragment>
    );
  }
}


function upLayer() {
  const mainLayout = document.querySelector("#mainLayout");
  mainLayout.classList.remove("down-layer");
}

function downLayer() {
  const mainLayout = document.querySelector("#mainLayout");
  mainLayout.classList.add("down-layer");
}


export default MainLayout;
export { upLayer, downLayer }