import React from 'react';
import './Exceptions.css';
import Field from '../Field/Field';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import TextInput from '../TextInput/TextInput';
import { Popover } from 'reactstrap';

class ShiftEndNextDay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: '',
      value: '',
      popoverOpen: false,
      popOverValue: ''
    }
    this.togglePopover = this.togglePopover.bind(this);

  }

  onInputChange(e) {
    this.setState({ input: e.currentTarget.value, value: e.currentTarget.value });
  }
  togglePopover() {
    this.setState({ popoverOpen: !this.state.popoverOpen })
  }

  activate(value) {
    if (!value) {
      this.props.onChange(this.props.backNames[1], '')
    }

  }

  render() {
    const { popoverOpen } = this.state;
    const { multi, placeholder, options, rtl, target, onChange, value, popover,titleSize } = this.props
    return (
      <div style={{ border: '0px' }}
        // className="Exceptions--"
        className="switch-row"
      >
        {/* <label className="Exceptions--label"> */}
        <Field
          title={[this.props.labels[0], null, ":"]}
          style={"m-0 p-0 col-lg-12"}
           noFormCont={true}
          titleSize={` ${this.props.titleSize ? this.props.titleSize : "col-sm-5"}${!this.props.values[0] ? this.props.dropdownAlign : ' '} `}
          inputComponent={[
            <div>
              <ToggleSwitch
                value={this.props.values[0]}
                backName={this.props.backNames[0]}
                onChange={(name, value) => {
                  this.props.onChange(this.props.backNames[0], value);
                  this.activate(value);
                }}
                onText={this.props.ar ? 'نعم' : 'Yes'}
                offText={this.props.ar ? 'لا' : 'No'}
                height={30}
                width={70}
                onHandleColor={this.props.onHandleColor ? "#70c767" : "#00c5fb"}
                onColor={this.props.onColor ? "#e1fcde" : "#ebf7fc"}
                // uncheckedIconWidth="80px"
                // uncheckedIconMarginLeft="-38px"
                checkedIconMarginLeft="10px"
              />


            </div>
          ]}
        />
        {/* <input
            id="checkbox"
            type="checkbox"
            checked={this.props.values[0]}
            onChange={(e) => {
              this.props.onChange(this.props.backNames[0], e.currentTarget.checked);
              this.activate(e.currentTarget.checked);
            }} />
          <span className="Exceptions--label">{this.props.labels[0]}</span> */}
        {/* </label> */}
        {popover ?
          <div
            style={!this.props.values[0] ? { display: 'none' } : {}}
          // className={"Exceptions--exception "}
          >

            <Field
              title={[this.props.labels[1], null, ":"]}
              style={"m-0 p-0 col-lg-12"}
              titleSize={`${this.props.titleSize ? this.props.titleSize : "col-sm-5"}`}
              inputComponent={[
                <TextInput
                  popover={true}
                  target={this.props.target}
                  key={this.props.labels[1]}
                  placeholder={this.props.labels[1]}
                  type={this.props.type}
                  value={this.props.values[1]}
                  onChange={(value) => this.props.onChange(this.props.backNames[1], value)}
                />
              ]}
            />
            {/* <span>{this.props.labels[1]}</span>
          <input
            type={this.props.type}
            value={this.props.values[1]}
            onChange={(e) => this.props.onChange(this.props.backNames[1], e.currentTarget.value)}
          /> */}
          </div>
          :
          <div
            style={!this.props.values[0] ? { display: 'none' } : {}}
          // className={"Exceptions--exception "}
          >

            <Field
              title={[this.props.labels[1], null, ":"]}
              style={"m-0 p-0 col-lg-12"}
              titleSize={`${this.props.titleSize ? this.props.titleSize : "col-sm-5"}`}
              // noFormCont={true}
              inputComponent={[
                <TextInput
                  key={this.props.labels[1]}
                  placeholder={this.props.labels[1]}
                  type={this.props.type}
                  value={this.props.values[1]}
                  onChange={(value) => this.props.onChange(this.props.backNames[1], value)}
                />
              ]}
            />
            {/* <span>{this.props.labels[1]}</span>
          <input
            type={this.props.type}
            value={this.props.values[1]}
            onChange={(e) => this.props.onChange(this.props.backNames[1], e.currentTarget.value)}
          /> */}
          </div>
        }
      </div>
    )
  }
}

export default ShiftEndNextDay;
