//export const ip = 'http://mainserver:807'
//export const ip = 'http://41.39.232.30:810'
//export const ip = 'http://web.ttechapps.info:810'
//export const ip = 'http://localhost:1435'
//export const ip = 'http://apex-program.com:810'
export const ip = 'https://apex-program.com:820'
//export const ip = 'http://192.168.1.30:810'
//export const adminIp ='https://localhost:44376'
//export const adminIp = 'http://mainserver:8085'
//export const adminIp ='https://localhost:44376'
//export const adminIp = 'http://41.39.232.30:8085'
export const adminIp = 'http://web.ttechapps.info:8070'

export const API = {
    Branches: {
        create: ip + '/api/Branches/AddBranch',
        read: ip + '/api/Branches/GetAllAndSearchBranchs',
        update: ip + '/api/Branches/UpdateBranch',
        delete: ip + '/api/Branches/DeletBranch',
        dropdown: ip + '/api/Branches/GetAllBranchsDropDown'
    },
    Directorates: {
        create: ip + '/api/Directorate/AddDirectorate',
        read: ip + '/api/Directorate/SearchGetAndAllDirectorates',
        update: ip + '/api/Directorate/UpdateDirectorates',
        delete: ip + '/api/Directorate/DeletDirectorates',
        dropdown: ip + '/api/Directorate/GetAllDirectorateDropDown'
    },
    Departments: {
        create: ip + '/api/Department/AddDepartment',
        read: ip + '/api/Department/SearchAdGetAllDepartment',
        update: ip + '/api/Department/UpdateDepartment',
        delete: ip + '/api/Department/DeleteDepartment',
        dropdown: ip + '/api/Department/GetAllDepartmentDropDown'
    },
    Jobs: {
        create: ip + '/api/Job/AddJob',
        read: ip + '/api/Job/SearchJobs',
        update: ip + '/api/Job/UpdateJob',
        delete: ip + '/api/Job/DeleteJob',
        dropdown: ip + '/api/Job/GetAllJobsDropDown'
    },
    Projects: {
        create: ip + '/api/Project/AddProject',
        read: ip + '/api/Project/SearchProjects',
        update: ip + '/api/Project/UpdateProject',
        delete: ip + '/api/Project/DeleteProject',
        dropdown: ip + '/api/Project/GetAllProjectsDropDown'
    },
    Nationalities: {
        create: ip + '/api/Nationalities/AddNationalities',
        read: ip + '/api/Nationalities/SearchNationalities',
        update: ip + '/api/Nationalities/UpdateNationalities',
        delete: ip + '/api/Nationalities/DeleteNationalities',
        dropdown: ip + '/api/Nationalities/GetAllNationalitiesDropDown'
    },
    Tasks: {
        create: ip + '/api/WorkingTasks/AddTasks',
        read: ip + '/api/WorkingTasks/SearchTasks',
        update: ip + '/api/WorkingTasks/UpdateTasks',
        delete: ip + '/api/WorkingTasks/DeleteTasks',
        dropdown: ip + '/api/WorkingTasks/GetAllTasksDropDown'
    },
    Shifts: { /* Working Time */
        create: ip + '/api/Shift/AddShift',
        update: ip + '/api/Shift/UpdateShift',
        delete: ip + '/api/Shift/DeleteShift',
        dropdown: ip + '/api/Shift/GetAllShiftDropdown',
        search: ip + '/api/Shift/SearchShifts'
    },
    Employees: {
        create: ip + '/api/Employee/AddEmployee',
        read: ip + '/api/Employee/GetAllEmployeesDropdownWithCode',
        update: ip + '/api/Employee/UpdateEmployee',
        delete: ip + '/api/Employee/DeleteEmployee',
        search: ip + '/api/Employee/SearchAllEmployees'
    },
    EmployeeGroupsDetails: {
        create: ip + '/api/EmployeeGroupsDetails/AddEmployeeForGroups',
        read: ip + '/api/EmployeeGroupsDetails/SearchEmployeeForGroups',
        // read: ip + '/api/EmployeeGroupsDetails/GetEmployeeGroup',
        update: ip + '/api/EmployeeGroupsDetails/UpdateEmployeeGroupsDetails',
        delete: ip + '/api/EmployeeGroupsDetails/UpdateEmployeeGroupsDetails'
    },
    VacationsTypes: {
        create: ip + '/api/VacationDays/AddVacationDay',
        read: ip + '/api/VacationDays/SearchVacationDays', //is it correct??
        update: ip + '/api/VacationDays/UpdateVacationDays',
        delete: ip + '/api/VacationDays/DeleteVacationDays',
        dropdown: ip + '/api/VacationDays/GetAllVacationDaysDropDown'
    },
    OfficialHolidays: {
        create: ip + '/api/OfficialHolidays/AddOfficialHolidays',
        // read:   ,
        update: ip + '/api/OfficialHolidays/UpdateOfficialHolidays',
        delete: ip + '/api/OfficialHolidays/DeleteOfficialHolidays',
        search: ip + '/api/OfficialHolidays/SearchOfficialHolidays'
    },
    // ====================================================================================================
    // ====================================================================================================
    // ====================================================================================================
    // ====================================================================================================
    // ====================================================================================================
    // ====================================================================================================
    // ====================================================================================================
    // ====================================================================================================
    AddPermission: {
        create: ip + '/api/AskPermission/AddAskPermission',
        // read:   ,
        update: ip + '/api/AskPermission/UpdateAskPermissions',
        delete: ip + '/api/AskPermission/DeleteAskPermissions',
        search: ip + '/api/AskPermission/SearchAskPermission'
    },
    AddVacation: {
        create: ip + '/api/AddVacation/AddEmployeeVacation',
        read: ip + '/api/AddVacation/SearchAddVavtionsForEmployee',
        update: ip + '/api/AddVacation/UpdateEmployeeVacation',
        delete: ip + '/api/AddVacation/DeleteEmployeeVacation'
    },
    AddEditTransactions: {
        create: ip + '/api/Transaction/AddTransaction',
        read: ip + '/api/Transaction/SearchTransaction',
        update: ip + '/api/Transaction/UpdateTransaction',
        delete: ip + '/api/Transaction/DeleteTransaction'
    },
    // ====================================================================================================
    // ====================================================================================================
    // ====================================================================================================
    // ====================================================================================================
    // ====================================================================================================
    // ====================================================================================================
    // ====================================================================================================
    // ====================================================================================================
    EmployeesReport: {
        read: ip + '/api/Employee/SearchEmployeeDetailsReport'
    },
    WorkingTimeReport: {
        read: ip + '/api/Shift/ShiftsReport'
    },
    DayStatusReport: {
        read: ip + '/api/MovedTransaction/DayStatusReportPrint'
    },
    DetailedAttendanceReport: {
        read: ip + '/api/MovedTransaction/AttendanceLeaveDetailsReport'
    },
    TotalAttendanceReport: {
        read: ip + '/api/MovedTransaction/TotalAttendanceAndLeaveReport'
    },
    AbsenceReport: {
        read: ip + '/api/MovedTransaction/GetAbsentReport'
    },
    DelayReport: {
        read: ip + '/api/MovedTransaction/DelayReport'
    },
    InCompleteTransReport: {
        read: ip + '/api/MovedTransaction/TransactionsNotCompleted'
    },
    EarlyAttendanceReport: {
        read: ip + '/api/MovedTransaction/EarlyAttendanceReport'
    },
    AttendanceSummaryReport: {
        read: ip + '/api/MovedTransaction/AttendanceReportSummary'
    },
    TotalAttendanceSummaryReport: {
        read: ip + '/api/MovedTransaction/AttendanceLeaveBranchesSummaryRport'
    },
    OvertimeReport: {
        read: ip + '/api/MovedTransaction/ExtraReport'
    },
    WorkingTimeDetailsReport: {
        read: ip + '/api/MovedTransaction/EmployeeWorkingDetailsReport'
    },
    BranchAttendanceReport: {
        read: ip + '/api/MovedTransaction/AttendanceLeaveBranchesDetailsRport'
    },
    VacationsReport: {
        read: ip + '/api/AddVacation/ReportAddVavtionsForEmployee'
    },
    PermissionsReport: {
        read: ip + '/api/AskPermission/ReportAskPermission'
    },
    DelayEarlyReport: {
        read: ip + '/api/MovedTransaction/LateAndLeaveEarlyReport'
    },
    TotalAbsenceReport: {
        read: ip + '/api/MovedTransaction/TotalAbsentForPeriodReport'
    },
    TotalDelayReport: {
        read: ip + '/api/MovedTransaction/TotalDelayForPeriodReport'
    },



    // ====================================================================================================
    // ====================================================================================================
    // ====================================================================================================
    // ====================================================================================================
    // ====================================================================================================
    // ====================================================================================================
    // ====================================================================================================
    // ====================================================================================================
    Devices: {
        create: ip + '/api/BrachMachine/AddBranchMachine',
        read: ip + '/api/BrachMachine/SearchAndGetAllBranchMachine',
        update: ip + '/api/BrachMachine/UpdateBranchMachine',
        delete: ip + '/api/BrachMachine/DeleteBranchMachine'
    },
    SpecialWorkingDays: {
        create: ip + '/api/DurationRamadanWork/AddDurationRamadanWork',
        read: ip + '/api/DurationRamadanWork/SearchAndGetAllDurationRamadanWork',
        update: ip + '/api/DurationRamadanWork/UpdateDurationRamadanWork',
        delete: ip + '/api/DurationRamadanWork/DeleteDurationRamadanWork'
    },
    UserPermission: {
        // ========================================================
        create: ip + '/api/CustomGroupPermission/AddCustomGroup',
        read: ip + '/api/CustomGroupPermission/SearchAndGetAllCustomGroup',
        update: ip + '/api/CustomGroupPermission/UpdateCustomGroup',
        delete: ip + '/api/CustomGroupPermission/DeleteCustomGroup'
        // ========================================================
    }
}