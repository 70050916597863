import React from 'react';
import './Alert.css';
import $ from 'jquery'
// we declared all component props by destructuring the props object
// Destructuring is a ES6 feature written in this way:
// const { property } = object;

// we often use the same naming of the prop like the tag name
// className={className} ==> tagName={prop}

const Alert =({className, style, children ,  iconPostion}) => {
  return (
    <div className={className} style={style}>
      <span className={`closeIcon ${iconPostion ? iconPostion : '' }`} onClick={() => { $('.Alert').addClass("d-none")}}>X</span>
      {children}
    </div>
  )
}
export default Alert;