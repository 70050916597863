/* eslint-disable eqeqeq */
import React from 'react';
import './ProcedurePage.css';
import { Link } from "react-router-dom";
import Table from './../Table/Table';
import Cookie from "js-cookie";
import ModalAr from './../ModalAr/ModalAr';
import PageHeaderAr from '../PageHeaderAr/PageHeaderAr';
import FiltersAr, { hideFilters, showFilters } from '../FiltersAr/FiltersAr';
import TableFooterAr from '../TableFooterAr/TableFooterAr';
import Loading from '../Loading/Loading';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import Exceptions from '../Exceptions/Exceptions';
import Overlay from '../Overlay/Overlay';
import ShiftEndNextDay from '../ShiftEndNextDay/ShiftEndNextDay';
import TextInput from '../TextInput/TextInput';
import Dropdown from '../Dropdown/Dropdown';
import SelectShift from '../SelectShift/SelectShift';
import PermissionTypes from '../PermissionTypes/PermissionTypes';
import Button from './../Button/Button';
import Field from './../Field/Field';
import { upLayer, downLayer } from '../MainLayout/MainLayout';
import Alert from './../Alert/Alert';
// for procedure pages
class ProcedurePageAr extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      //assign by value not by reference
      actionEntity: props.action.body,
      actionErrors: [],
      rowsNumber: 10,
      rowsTotal: 0,
      currentPage: 1,
      message: { text: '', style: '' },
      loading: false,
      search: false,
      data: [],
      modalUpdated: false,
      errors: []
    }

    // Get the Action 'select elements' items from the Database when the page displayed for the first time
    for (let prop of props.actionEntityProps) {
      if (prop.type === 'select' || prop.type === 'multiSelect' || prop.type === 'selectShift') {
        this.GetSelect(prop.url, prop.body, prop.label);
      }
    }
  }

  // Styling:
  // ========
  showModal(modalName) {
    const mainLayout = document.querySelector("#mainLayout");
    this.setState({ [modalName]: true });
    mainLayout.style.overflow = "visible";
  }

  hideModal(modalName) {
    const mainLayout = document.querySelector("#mainLayout");
    this.setState({ [modalName]: false });

    // return the Modal elements to the default State
    if (modalName === 'search') {
      const entity = this.state.actionEntity;
      entity[this.props.modalBackName] = [];
      this.setState({ actionEntity: entity, actionErrors: [] })
    }
  }

  // Without returning the Modal elements to the default State
  hideModalWithValues(modalName) {
    const mainLayout = document.querySelector("#mainLayout");
    this.setState({ [modalName]: false });
    //if the Page have a Read operation
    if (this.props.read) {
      this.Read({ search: this.state.actionEntity });
    }
  }

  componentDidMount() {
    //if the Page have a Read operation
    if (this.props.read) {
      if (this.props.permissions !== null) {
        this.Read({ search: this.state.actionEntity });
      } else {
        let me = this;
        setTimeout(function () {
          me.componentDidMount();
        }, 1000);
      }
    }

  }

  // A method for changing the entity properties,
  // inputs:
  // state = Name of the entity, e.g: createEntity, updateEntity ... 
  // name = Name of the property, e.g: createEntity.nameAr, updateEntity.nameEn ... 
  // value = value of the property, e.g: createEntity.nameAr = 'أحمد', updateEntity.nameEn = 'Ahmad'... 
  ChangeEntityState(state, name, value) {
    let tempEntity = this.state[state];
    tempEntity[name] = value;
    this.setState({ [state]: tempEntity });
  }

  // A method for changing the state,
  // inputs:
  // key = Name of the state
  // value = value of the state
  ChangeState(key, value) {
    this.setState({ [key]: value });
  }

  // A method for making a fetch Request,
  // inputs:
  // entity = the entity that will be sent to the Server for Creating, Reading, Updating, or Deleting, e.g: current deleteEntity...
  // token = the JWT Barear Authorization token.
  // operation = the operation that will be done against the entity, e.g: create, read, update, or delete
  // then = the method that will be executed when we receive the response
  Fetch(entity, token, operation, then) {

    //url bulding is explained in BasicPage
    fetch(
      this.props[operation].path ?
        this.props[operation].url + '/' + Object.keys(entity).map((key) => entity[key]).join('/')
        :
        this.props[operation].query ?
          this.props[operation].url + '?' + Object.keys(entity).map((key) => key + '=' + entity[key]).join('&')
          :
          this.props[operation].url, {
      method: this.props[operation].verb,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'lang': 'ar'
      },
      body: this.props[operation].body ? JSON.stringify(entity) : null
    })
      .then(response => response.json())
      .then((response) => {
        let me = this;
        then(response, me);
      })
      .catch(error => console.error('Unable to ' + operation + '.', error));
  }

  // A method for performing the Page Main Action,
  // inputs:
  // entity = the entity that will be sent to the Server (current actionEntity)
  Action(entity) {
    //if there is a 'get' permission
    let errors = this.Validation(entity);
    this.setState({ errors: errors });
    if (errors.length === 0) {
      if (this.props.permissions?.data.find(permission => permission.nameEn === 'Get')) {
        this.setState({ loading: true });
        let token = Cookie.get('token');
        console.log(Cookie.get('token'));
        let then = (response, me) => {
          console.log(response);
          if (response.status === 200 || response.success) {
            if (response.success) {
              // if there is a read operation in this page
              if (this.props.read) {
                me.setState(
                  {
                    loading: false,
                    modalUpdated: false,
                    message: { text: response.message, style: 'success' }
                  }
                );
                setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
                //Read data after making action
                this.Read({ search: this.state.actionEntity });
              } else if (!this.props.action.readAction && !this.props.action.ReadMachine) { // if it is not a read action (an action that only expects a response of whether the operation is successful or not)
                me.setState(
                  {
                    loading: false,
                    modalUpdated: false,
                    message: { text: response.message, style: 'success' }
                  }
                );
                setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
              } else {
                // if it is a read action (expects data from the server)
                me.setState(
                  {
                    data: response.data.list.map((row) => {
                      for (const key in me.props.params) {
                        row[key] = me.props.params[key];
                      }
                      return row;
                    }),
                    rowsTotal: response.data.listCount,
                    loading: false,
                    modalUpdated: false,
                    message: { text: response.message, style: 'success' }
                  }
                );
                setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
              }

            } else { // if not succeeded
              me.setState({ message: { text: response.message, style: 'danger' }, loading: false });
              setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
            }
          } else { // if response.status !== 200
            if (response.message) {
              me.setState({ message: { text: response.message, style: 'danger' }, loading: false });
              setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
            }
            console.error(response);
          }
        }

        this.Fetch(entity, token, 'action', then);
      }
    }
  }

  // A method for Reading data from Database,
  // inputs:
  // entity = the entity that will be sent to the Server in the reaquest body (readEntity)
  Read(entity) {
    // if there is a 'get' permission
    if (this.props.permissions?.data.find(permission => permission.nameEn === 'Get')) {
      this.setState({ loading: true });
      let token = Cookie.get('token');
      console.log(token);
      let then = (response, me) => {
        if (response.status === 200 || response.success) {
          if (response.success) {
            me.setState(
              {
                data: [response.data],
                rowsTotal: 1,
                loading: false,
                modalUpdated: false
              }
            );
          } else {
            me.setState({ message: { text: response.message, style: 'danger' }, loading: false });
            setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
          }
        } else {
          if (response.message) {
            me.setState({ message: { text: response.message, style: 'danger' }, loading: false });
            setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
          }
          console.error(response);
        }
      }
      this.Fetch(entity, token, 'read', then);
    }
  }

  // A method for getting the select items from the Database,
  // inputs:
  // url = Web API Service url
  // body = Request body
  // name = the name of the state that the data will be stored in
  GetSelect(url, body, name) {
    let token = Cookie.get('token');
    fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then((response) => {
        if (response.success) {
          this.setState({ [name]: response.data.data });
        } else {
          console.error(response.message);
        }
      })
      .catch(error => console.error('Unable to perform Action.', error));
  }

  // A method for Resetting the Action fields values
  Clear() {
    let tempEntity = this.state.actionEntity;
    for (let field of this.props.actionEntityProps) {
      if (field.type === 'text' || field.type === 'email' || field.type === 'select' || field.type === 'date' || field.type === 'time' || field.type === 'password') {
        tempEntity[field.backName] = '';
      }
      if (field.type === 'multiSelect') {
        tempEntity[field.backName] = [];
      }
      if (field.type === 'searchModal') {
        tempEntity[field.backName] = [];
      }
    }
    this.setState({ actionEntity: tempEntity });
    // if there is a read operation in this page then read data again after clearing filters
    if (this.props.read) {
      this.Read({ search: this.state.actionEntity });
    }
  }

  // A method for Validating the user inputs before Creating or Updating,
  // inputs:
  // entity = the entity that will be sent to the Server for Creating or Updating, e.g: current createEntity.
  // output:
  // Array of errors if exist, otherwise an empty Array

  Validation(entity) {
    let errors = [];
    for (let prop of this.props.actionEntityProps) {
      if (prop.validation) {
        if (prop.validation.includes('Required')) {
          if (!entity[prop.backName]) {
            errors = errors.concat(prop.label + ' حقل إجباري.');
          }
        }
        //Depends on property of RequiredCondition
        if (prop.validation.includes('ConditionalReq')) {
          if (entity[prop.RequiredCondition] && !entity[prop.backName]) {
            errors = errors.concat(prop.label + ' حقل إجباري.');
          }
        }
        //Depends on property of RequiredCondition
        if (prop.validation.includes('orReq')) {
          if (!entity[prop.RequiredCondition] && !entity[prop.backName]) {
            errors = errors.concat(prop.label + ' حقل إجباري.');
          }
        }
        if (prop.validation.includes('from')) {
          let toProp = this.props.createEntityProps.find(prop => prop.validation.includes('to'));
          if ((Date.parse(entity[prop.backName]) > Date.parse(entity[toProp[4]]))) {
            errors = errors.concat(prop.label + ' يجب أن يكون أقل من أو يساوي "' + toProp[0] + '".');
          }
        }
        if (prop.validation.includes('to')) {
          let fromProp = this.props.createEntityProps.find(prop => prop.validation.includes('from'));

          if (Date.parse(entity[prop.backName]) < Date.parse(entity[fromProp[4]])) {
            errors = errors.concat(prop.label + ' يجب أن يكون أكبر من أو يساوي "' + fromProp[0] + '".');
          }
        }
      }

      if (prop.type === 'email') {
        let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (entity[prop.backName] && !entity[prop.backName].match(mailformat)) {
          errors = errors.concat(prop.label + ' يجب أن يكون بريد إلكتروني صحيح.');
        }
      }
      if (prop.type === 'shiftEndNextDay') {
        //if checkbox checked
        if (entity[prop.backName[0]]) {
          if (!entity[prop.backName[1]]) {
            errors = errors.concat(prop.label[1] + ' حقل إجباري.');
          }
        }
      }
      if (prop.type === 'selectShift') {
        //Employee select is required
        if (!entity[prop.backName[0]]) {
          errors = errors.concat('الموظف حقل إجباري.');
        }
        //Date select is required
        if (!entity[prop.backName[1]]) {
          errors = errors.concat('التاريخ حقل إجباري.');
        }
        //prop.backName[2]
        //shift select is required
        if (!entity.periodShiftId && !entity.periodShiftFourthId
          && !entity.periodShiftSecondId && !entity.periodShiftThirdId) {

          errors = errors.concat('اختيار الوردية إجباري.');
        }
        //if open working time there are no shifts to select
        if (entity[prop.backName[2]] === 'open') {
          entity[prop.backName[2]] = '';
        }
      }
      if (prop.type === 'permissionType') {
        //permission type select is required
        if (!entity[prop.backName[0]]) {
          errors = errors.concat('نوع الإذن حقل إجباري.');
        } else {
          let timeIn;
          let timeOut;
          let shiftIn;
          let shiftOut;
          let time;
          //permission type
          switch (entity[prop.backName[0]]) {
            //if temporary
            case 1:
              if (!entity[prop.backName[1]]) {
                errors = errors.concat('وقت الحضور حقل إجباري.');
              } else {
                if (entity.periodShiftId) {
                  //split the time entered by the user to get hours and minuts in an array
                  time = entity[prop.backName[1]].split(':');
                  //Create a date object of the entered time
                  timeIn = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  //split the inTime of the shift to get hours and minuts in an array
                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).inTime.split(':');
                  //Create a date object of inTime of the shift
                  shiftIn = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));
                  //split the outTime of the shift to get hours and minuts in an array
                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).outTime.split(':');
                  //Create a date object of outTime of the shift
                  shiftOut = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  //check if entered time is between shift inTime and outTime
                  if (!(timeIn >= shiftIn && timeIn <= shiftOut)) {
                    errors = errors.concat('يجب أن يكون وقت حضور الإذن داخل وقت الوردية المحددة.');
                  }
                }
              }
              if (!entity[prop.backName[2]]) {
                errors = errors.concat('وقت الانصراف حقل إجباري.');
              } else {
                if (entity.periodShiftId) {
                  time = entity[prop.backName[2]].split(':');
                  timeOut = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).inTime.split(':');
                  shiftIn = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).outTime.split(':');
                  shiftOut = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  if (!(timeOut >= shiftIn && timeOut <= shiftOut)) {
                    errors = errors.concat('يجب أن يكون وقت انصراف الإذن داخل وقت الوردية المحددة.');
                  }
                }
              }

              break;
            case 2:
              if (!entity[prop.backName[1]]) {
                errors = errors.concat('وقت الحضور حقل إجباري.');
              } else {
                if (entity.periodShiftId) {
                  time = entity[prop.backName[1]].split(':');
                  timeIn = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).inTime.split(':');
                  shiftIn = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).outTime.split(':');
                  shiftOut = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  if (!(timeIn >= shiftIn && timeIn <= shiftOut)) {
                    errors = errors.concat('يجب أن يكون وقت حضور الإذن داخل وقت الوردية المحددة.');
                  }
                }
              }
              break;
            case 3:
              if (!entity[prop.backName[2]]) {
                errors = errors.concat('وقت الانصراف حقل إجباري.');
              } else {
                if (entity.periodShiftId) {
                  time = entity[prop.backName[2]].split(':');
                  timeOut = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).inTime.split(':');
                  shiftIn = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).outTime.split(':');
                  shiftOut = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  if (!(timeOut >= shiftIn && timeOut <= shiftOut)) {
                    errors = errors.concat('يجب أن يكون وقت انصراف الإذن داخل وقت الوردية المحددة.');
                  }
                }
              }
              break;
            case 4:
              errors = errors.filter((error) => error !== 'اختيار الوردية إجباري.');
              break;
            default:
              break;
          }
        }
      }
    }
    return errors;
  }
  render() {
    let me = this;
    // Getting only the required columns for display + the row id
    let tableRawData = this.state.data.map((row) => {
      let tablerow = {};
      for (let column of this.props.columns) {
        tablerow[column['backName']] = row[column['backName']];
      }

      return tablerow;
    });

    // if the tableRawData lenght changed because of the search, update the rowsTotal for Paging
    if (tableRawData.length != this.state.rowsTotal) {
      this.setState({ rowsTotal: tableRawData.length })
    }

    // Creating the Breadcrumb links
    let Path = this.props.path.map((folder) => <Link className="TreeNav--" to={folder.path} key={folder.title}>{folder.title}</Link>);

    // Creating Table Headers
    let TableHeaders = this.props.columns.map((column) => <th key={column['name']}>{column['name']}</th>);

    // First get the specified page of data then displays it properly
    let TableData = tableRawData
      // Client side pagination
      .slice(((this.state.rowsNumber * this.state.currentPage) - this.state.rowsNumber), (this.state.rowsNumber * this.state.currentPage))
      .map(
        function (row) {
          return (
            <tr key={row[Object.keys(row)[0]].toString()}>
              {
                Object.keys(row)
                  .map((columnName, columnIndex) =>
                    //Displaying data in table is explained in BasicPage
                    me.props.columns[columnIndex]['link'] ?
                      <td key={row[0] + ',' + columnIndex}>
                        <Link to={me.props.columns[columnIndex]['link']['to'].toString() + me.props.columns[columnIndex]['link']['routeValues'].map((value) => me.state.data.find(data => data[Object.keys(row)[0]] === row[Object.keys(row)[0]])[value]).join('/')}>
                          {
                            me.props.columns[columnIndex]['link']['icon'] ?
                              me.props.columns[columnIndex]['link']['icon']
                              :
                              me.props.columns[columnIndex]['link']['text'] ?
                                me.props.columns[columnIndex]['link']['text']
                                :
                                row[columnName]
                          }
                        </Link>
                      </td>
                      :
                      me.props.columns[columnIndex]['boolean'] ?
                        <td key={row[0] + ',' + columnIndex}>
                          {row[columnName] ? <span className='badge agree working-time'>Yes</span> : <span className='badge alert working-time'>No</span>}
                        </td>
                        :
                        me.props.columns[columnIndex]['enum'] ?
                          <td key={row[0] + ',' + columnIndex}>
                            {me.props.columns[columnIndex]['enum'].find((item) => item.value == row[columnName]) ? me.props.columns[columnIndex]['enum'].find((item) => item.value == row[columnName])['display'] : row[columnName]}
                          </td>
                          :
                          <td key={row[0] + ',' + columnIndex}>
                            {row[columnName]}
                          </td>)
              }
            </tr>
          )
        }
      );

    // Creating Action Fields
    let ActionEntityProperties = this.props.actionEntityProps.map(
      (prop) => {
        let backNames;
        let values;
        let items;
        switch (prop.type) {
          case 'text':
            return (

              <Field
                title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                titleSize={prop.titleSize}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={`Word or some of it`}
                    type="text"
                    value={this.state.actionEntity[prop.backName]}
                    onChange={(value) => this.ChangeEntityState('actionEntity', prop.backName, value)}
                  />
                ]}
              />
            );
          case 'email':
            return (

              <Field
                title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                titleSize={prop.titleSize}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={`Word or some of it`}
                    type="email"
                    value={this.state.actionEntity[prop.backName]}
                    onChange={(value) => this.ChangeEntityState('actionEntity', prop.backName, value)}
                  />
                ]}
              />
            );
          case 'date':
            return (

              <Field
                title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                titleSize={prop.titleSize}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    arDate={true}
                    placeholder={prop.label}
                    type="date"
                    value={this.state.actionEntity[prop.backName]}
                    onChange={(value) => {
                      this.ChangeEntityState('actionEntity', prop.backName, value);
                      //Read data again if there is a read operation in this page
                      if (this.props.read) {
                        this.Read({ search: this.state.actionEntity });
                      }
                    }}
                  />
                ]}
              />
            );
          case 'time':
            return (

              <Field
                title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                titleSize={prop.titleSize}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={`Word or some of it`}
                    type="time"
                    //Hours and minuts only
                    value={this.state.actionEntity[prop.backName]?.substring(0, 5)}
                    onChange={(value) => this.ChangeEntityState('actionEntity', prop.backName, value + ":00")}
                  />
                ]}
              />
            );
          case 'password':
            return (

              <Field
                title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                titleSize={prop.titleSize}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={`Word or some of it`}
                    type="password"
                    value={this.state.actionEntity[prop.backName]}
                    onChange={(value) => this.ChangeEntityState('actionEntity', prop.backName, value)}
                  />
                ]}
              />
            );
          case 'select':
            items = this.state[prop.label] === undefined ?
              []
              :
              this.state[prop.label].map((item) => { return { label: item[prop.item.label], value: item[prop.item.value] } });
            return (

              <Field
                key={prop.label}
                titleSize={prop.titleSize}
                title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <Dropdown
                    rtl={true}
                    key={prop.label}
                    placeholder={prop.label}
                    value={items.find(item => item.value === this.state.actionEntity[prop.backName])}
                    options={items}
                    onChange={(value) => this.ChangeEntityState('actionEntity', prop.backName, value)}
                  />
                ]}
              />
            );
          case 'multiSelect':
            items = this.state[prop.label] === undefined ?
              []
              :
              this.state[prop.label].map((item) => { return { label: item[prop.item.label], value: item[prop.item.value] } });
            return (

              <Field
                key={prop.label}
                titleSize={prop.titleSize}
                title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <Dropdown
                    rtl={true}
                    key={prop.label}
                    placeholder={prop.label}
                    multi={true}
                    //value={items.filter(item => this.state.actionEntity[prop.backName]?.includes(item.value))}
                    options={items}
                    //onChange={(value) => this.ChangeEntityState('actionEntity', prop.backName, value)}
                    value={prop.backSearch ?
                      items?.find(item => item.value === this.state.actionEntity[prop.backName]) :
                      (prop.innerSearch ?
                        items.find(item => item.value === this.state.actionEntity[prop.backName + ',inner']) :
                        items.find(item => item.value === this.state.actionEntity[prop.backName]))}

                    onChange={(value) => {
                      if (prop.backSearch) {
                        let tempEntity = this.state.actionEntity;
                        tempEntity[prop.backName] = value;

                        this.setState({ actionEntity: tempEntity });
                      } else if (prop.innerSearch) {
                        this.ChangeEntityState('actionEntity', prop.backName + ',inner', value)
                      } else {
                        this.ChangeEntityState('actionEntity', prop.backName, value)
                      }
                    }}
                  />
                ]}
              />
            );
          case 'checkbox':
            return (

              <Field
                key={prop.label}
                titleSize={prop.titleSize}
                inputComponent={[
                  <ToggleSwitch
                    value={this.state.actionEntity[prop.backName]}
                    backName={prop.backName}
                    onChange={(name, value) => this.ChangeEntityState('actionEntity', name, value)}
                    onText={prop.label}
                    offText={'Not ' + prop.label}
                    height={30}
                    width={110}
                    uncheckedIconWidth="72px"
                    uncheckedIconMarginLeft="-35px"
                    checkedIconMarginLeft="30px"
                  />
                ]}
              />
            );
          case 'exceptions':
            backNames = [];
            for (const backName of prop.backName) {
              backNames.push(backName);
            }
            values = [];
            for (const backName of backNames) {
              values.push(this.state.actionEntity[backName])
            }
            return (

              <Field
                key={prop.label}
                title={prop.label}
                titleSize={prop.titleSize}
                inputComponent={[
                  <Exceptions values={values} backNames={backNames} onChange={(name, value) => this.ChangeEntityState('actionEntity', name, value)} />
                ]}
              />
            );
          case 'shiftEndNextDay':
            backNames = [];
            for (const backName of prop.backName) {
              backNames.push(backName);
            }
            values = [];
            for (const backName of backNames) {
              values.push(this.state.actionEntity[backName])
            }
            return (

              <Field
                key={prop.label}
                title={prop.label}
                titleSize={prop.titleSize}
                inputComponent={[
                  <ShiftEndNextDay values={values} backNames={backNames} onChange={(name, value) => this.ChangeEntityState('actionEntity', name, value)} />
                ]}
              />
            );
          case 'selectShift':
            backNames = [];
            for (const backName of prop.backName) {
              backNames.push(backName);
            }
            values = [];
            for (const backName of backNames) {
              values.push(this.state.actionEntity[backName])
            }
            items = this.state[prop.label] === undefined ?
              []
              :
              this.state[prop.label].map((item) => { return { label: item[prop.item.label], value: item[prop.item.value] } });
            return (

              <Field
                key={prop.label}
                title={prop.label}
                titleSize={prop.titleSize}
                inputComponent={[
                  <SelectShift
                    items={items}
                    values={values}
                    backNames={backNames}
                    onChange={(name, value) => this.ChangeEntityState('actionEntity', name, value)}
                  />
                ]}
              />
            );
          case 'permissionType':
            backNames = [];
            for (const backName of prop.backName) {
              backNames.push(backName);
            }
            values = [];
            for (const backName of backNames) {
              values.push(this.state.actionEntity[backName])
            }
            items = [
              { label: 'Temporary', value: 1 },
              { label: 'Late Attendance', value: 2 },
              { label: 'Early Leave', value: 3 },
              { label: 'All Day', value: 4 }
            ];
            return (

              <Field
                key={prop.label}
                title={prop.label}
                titleSize={prop.titleSize}
                inputComponent={[
                  <PermissionTypes
                    items={items}
                    values={values}
                    backNames={backNames}
                    onChange={(name, value) => this.ChangeEntityState('actionEntity', name, value)}
                  />
                ]}
              />
            );
          case 'searchModal':
            return (
              // a button to show search modal
              <Field
                // key={prop.label}
                titleSize={prop.titleSize}
                inputComponent={[
                  <Button
                    className="btn add flashing"
                    onClick={() => this.showModal('search')}
                  >
                    <i className="fa">+</i>{prop.label}
                  </Button>
                ]}
              />
            );
          case 'readOnly':
            return (
              <Field
                title={prop.label}
                titleSize={prop.titleSize}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={` `}
                    disabled
                    type="text"
                    value={this.state.actionEntity[prop.backName]}
                    onChange={(value) => this.ChangeEntityState('actionEntity', prop.backName, value)}
                  />
                ]}
                long
              />
            );
          default:
            return (

              <Field
                title={[prop.label, prop.validation === 'Required' ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                titleSize={prop.titleSize}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={`Word or some of it`}
                    type="text"
                    value={this.state.actionEntity[prop.backName]}
                    onChange={(value) => this.ChangeEntityState('actionEntity', prop.backName, value)}
                  />
                ]}
              />
            );
        }
      }
    );

    return (
      <div className="ProcedurePage">
        {/* startOverlayComponents */}

        {/* Search Modal explained in Basic Page */}
        <Overlay visible={this.state.search}>
          <ModalAr
            action="search"
            visible={this.state.search}
            hideModal={(name) => this.hideModal(name)}
            searchFields={this.props.modalSearchFields}
            EntityProperties={ActionEntityProperties}
            columns={[
              { name: 'الكود', backName: 'employeeCode' },
              { name: 'الإسم', backName: 'nameAr' }
            ]}
            errors={[]}
            parent={this}
            backName={this.props.modalBackName}
            read={this.props.modalRead}
            fetch={() => this.hideModalWithValues('search')}
            updated={this.state.modalUpdated}
          />
        </Overlay>

        <Overlay visible={this.state.loading}>
          <Loading visible={this.state.loading} />
        </Overlay>
        {/* endOverlayComponents */}


        <div className="MainLayout--container">
          <PageHeaderAr
            Path={Path}
            title={this.props.title}
            mainTitle={this.props.mainTitle}
            // =================
            hideFilters={() => hideFilters()}
            showFilters={() => showFilters()}
            //if readaction put the action entity in an object with a property called 'search' {search: actionEntity}
            Action={() => this.props.action.readAction ?
              this.Action({ search: this.state.actionEntity }) : this.Action(this.state.actionEntity)}

            showModal={(name) => this.showModal(name)}
            report={this.props.report}
            procedure={this.props.permissions?.data.find(permission => permission.nameEn === 'Get') ? true : false}
            noCreate={!this.props.permissions?.data.find(permission => permission.nameEn === 'Get') ? true : false}
            btnTxt={this.props.btnTxt}
            btnClr={this.props.btnClr}
            useSearchModal={this.props.useSearchModal ? true : false}
          // =================
          />
          <div className="alerts">
            {
              this.state.errors ?
                this.state.errors.length > 0 ?
                  (
                    <Alert className="Alert danger">
                      <ul>
                        {this.state.errors.map((error) => <li key={error}>{error}</li>)}
                      </ul>
                    </Alert>
                  )
                  :
                  null
                :
                null
            }
            {
              this.state.message ?
                this.state.message['text'] ?
                  <Alert
                    className={"Alert " + this.state.message['style']}
                  >
                    {this.state.message['text']}
                  </Alert>
                  :
                  null
                :
                null
            }
          </div>
          <FiltersAr
            SearchFields={ActionEntityProperties}
            Clear={() => this.Clear()}
          />
          {this.props.columns.length > 0 ?
            <>
              {/* if the column name is 'not transferred transactions' that means that it's a table with only one row and one cell containing the number of not transferred transactions, so we don't nead pagination for it  */}
              {this.props.columns[0].name !== 'الحركات الغير مرحلة' ?
                <TableFooterAr
                  rowsNumber={this.state.rowsNumber}
                  rowsNumberChange={(value) => {
                    value = value > 1 ? value : 1;
                    this.ChangeState('rowsNumber', value);
                    this.setState({ currentPage: 1 });
                  }}
                  total={this.state.rowsTotal}
                  currentPage={this.state.currentPage}
                  currentPageChange={(value) => this.setState({ currentPage: Number(value) })}
                />

                :
                null}
              <Table TableHeaders={TableHeaders} data={TableData} />
            </>
            :
            null
          }
        </div>

      </div>
    );
  }
}

export default ProcedurePageAr;
