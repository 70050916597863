import React from 'react';
import './CompanyInfo.css';
import Button from '../Button/Button';
import Cookie from "js-cookie";
import Field from '../Field/Field';
import TextInput from '../TextInput/TextInput';
import Dropdown from '../Dropdown/Dropdown';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import Overlay from '../Overlay/Overlay';
import Loading from '../Loading/Loading';
import Alert from './../Alert/Alert';
import { ip } from './../../data/APIs';

class CompanyInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      readEntity: props.read.body ? props.read.body : props.read.path,
      updateEntity: props.update.body,
      updateErrors: [],
      message: { text: '', style: '' },
      loading: false,
      data: [],
    }
    this.fileInput = React.createRef();
  }

  componentDidMount() {
    if (this.props.permissions !== null) {
      this.Read(this.state.readEntity);
    } else {
      let me = this;
      setTimeout(function () {
        me.componentDidMount();
      }, 1000);
    }
    
  }

  // A method for changing the entity properties,
  // inputs:
  // state = Name of the entity, e.g: createEntity, updateEntity ... 
  // name = Name of the property, e.g: createEntity.nameAr, updateEntity.nameEn ... 
  // value = value of the property, e.g: createEntity.nameAr = 'أحمد', updateEntity.nameEn = 'Ahmad'... 
  ChangeEntityState(state, name, value) {
    let tempEntity = this.state[state];
    tempEntity[name] = value;
    this.setState({ [state]: tempEntity });
  }

  ChangeEntityStateModel(state, name, value) {
    let tempEntity = this.state[state];
    tempEntity.model[name] = value;
    this.setState({ [state]: tempEntity });
  }

  ChangeFileState(state, name, value) {
    let tempEntity = this.state[state];
    tempEntity[name] = value;
    this.setState({ [state]: tempEntity });
  }
  // A method for Validating the user inputs before Creating or Updating,
  // inputs:
  // entity = the entity that will be sent to the Server for Creating or Updating, e.g: current createEntity.
  // output:
  // Array of errors if exist, otherwise an empty Array
  Validation(entity) {
    let errors = [];
    for (let prop of this.props.updateEntityProps) {
      if (prop.validation) {
        if (prop.validation.includes('Required')) {
          if (!entity[prop.backName]) {
            errors = errors.concat(prop.label + ' field is Required.');
          }
        }
        if (prop.validation.includes('ConditionalReq')) {
          if (entity[prop.RequiredCondition] && !entity[prop.backName]) {
            errors = errors.concat(prop.label + ' field is Required.');
          }
        }
        if (prop.validation.includes('orReq')) {
          if (!entity[prop.RequiredCondition] && !entity[prop.backName]) {
            errors = errors.concat(prop.label + ' field is Required.');
          }
        }
        if (prop.validation.includes('from')) {
          let toProp = this.props.updateEntityProps.find(prop => prop.validation.includes('to'));
          if ((Date.parse(entity[prop.backName]) > Date.parse(entity[toProp[4]]))) {
            errors = errors.concat(prop.label + ' field Should be less than or equal to "' + toProp[0] + '" field.');
          }
        }
        if (prop.validation.includes('to')) {
          let fromProp = this.props.updateEntityProps.find(prop => prop.validation.includes('from'));

          if (Date.parse(entity[prop.backName]) < Date.parse(entity[fromProp[4]])) {
            errors = errors.concat(prop.label + ' field Should be greater than or equal to "' + fromProp[0] + '" field.');
          }
        }
      }

      if (prop.type === 'email') {
        let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (entity[prop.backName] && !entity[prop.backName].match(mailformat)) {
          errors = errors.concat(prop.label + ' field should be a valid email address.');
        }
      }
      if (prop.type === 'shiftEndNextDay') {
        if (entity[prop.backName[0]]) {
          if (!entity[prop.backName[1]]) {
            errors = errors.concat('Day end time field is Required.');
          }
        }
      }
      if (prop.type === 'selectShift') {
        if (!entity[prop.backName[0]]) {
          errors = errors.concat('Employee field is Required.');
        }
        if (!entity[prop.backName[1]]) {
          errors = errors.concat('Date field is Required.');
        }
        if (!entity[prop.backName[2]]) {
          errors = errors.concat('Selecting a Shift is Required.');
        }
        if (entity[prop.backName[2]] === 'open') {
          entity[prop.backName[2]] = '';
        }
      }
      if (prop.type === 'permissionType') {
        if (!entity[prop.backName[0]]) {
          errors = errors.concat('Permission Type field is Required.');
        } else {
          let timeIn;
          let timeOut;
          let shiftIn;
          let shiftOut;
          let time;
          switch (entity[prop.backName[0]]) {
            case 1:
              if (!entity[prop.backName[1]]) {
                errors = errors.concat('Time In field is Required.');
              } else {
                if (entity.periodShiftId) {
                  time = entity[prop.backName[1]].split(':');
                  timeIn = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).inTime.split(':');
                  shiftIn = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).outTime.split(':');
                  shiftOut = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  if (!(timeIn >= shiftIn && timeIn <= shiftOut)) {
                    errors = errors.concat('Permission Time In Value should be between Shift Time In and Shift Time Out.');
                  }
                }
              }
              if (!entity[prop.backName[2]]) {
                errors = errors.concat('Time Out field is Required.');
              } else {
                if (entity.periodShiftId) {
                  time = entity[prop.backName[2]].split(':');
                  timeOut = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).inTime.split(':');
                  shiftIn = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).outTime.split(':');
                  shiftOut = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  if (!(timeOut >= shiftIn && timeOut <= shiftOut)) {
                    errors = errors.concat('Permission Time Out Value should be between Shift Time In and Shift Time Out.');
                  }
                }
              }

              break;
            case 2:
              if (!entity[prop.backName[1]]) {
                errors = errors.concat('Time In field is Required.');
              }
              break;
            case 3:
              if (!entity[prop.backName[2]]) {
                errors = errors.concat('Time Out field is Required.');
              }
              break;
            default:
              break;
          }
        }
      }
    }
    return errors;
  }

  // A method for making a fetch Request,
  // inputs:
  // entity = the entity that will be sent to the Server for Creating, Reading, Updating, or Deleting, e.g: current deleteEntity...
  // token = the JWT Barear Authorization token.
  // operation = the operation that will be done against the entity, e.g: create, read, update, or delete
  // then = the method that will be executed when we receive the response
  Fetch(entity, token, operation, then) {
    let url = this.props[operation].path ?
      this.props[operation].url + '/' + Object.keys(entity).map((key) => entity[key]).join('/')
      :
      this.props[operation].query ?
        this.props[operation].url + '?' + Object.keys(entity).map((key) => key + '=' + entity[key]).join('&')
        :
        this.props[operation].url;

    let end;
    let start = Date.now();
    console.log('Start: ' + start);
    var formdata = new FormData();
    if (operation === 'update') {
      formdata.append("model", JSON.stringify(entity.model));
      if (this.fileInput.current.files[0]) {
        formdata.append("imagePath", this.fileInput.current.files[0], this.fileInput.current.files[0].name);
      }

    }

    fetch(url, {
      method: this.props[operation].verb,
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: operation === 'read' ? null : formdata,
      redirect: 'follow'

    })
      .then(response => response.json())
      .then((response) => {
        end = Date.now();
        console.log('End: ' + end);
        console.log('Time: ' + (end - start));
        let me = this;
        then(response, me);
      })
      .catch(error => console.error('Unable to ' + operation + '.', error));
  }

  // A method for Reading data from Database,
  // inputs:
  // entity = the entity that will be sent to the Server in the reaquest body (readEntity)
  Read(entity) {
    if (this.props.permissions?.data.find(permission => permission.nameEn === 'Get')) {
      this.setState({ loading: true });
      let token = Cookie.get('token');
      console.log(token);
      let then = (response, me) => {
        if (response.status === 200 || response.success) {
          if (response.success) {
            if (response.data.data) {
              me.setState(
                {
                  data: response.data.data.map((row) => {
                    for (const key in me.props.params) {
                      row[key] = me.props.params[key];
                    }
                    return row;
                  }),
                  rowsTotal: response.data.listCount,
                  loading: false,
                  modalUpdated: false
                }
              );
            } else {
              let tempEntity = Object.assign({}, response.data);
              delete tempEntity.image;
              me.setState(
                {
                  data: response.data,
                  updateEntity: { model: tempEntity, imagePath: '' },
                  rowsTotal: response.data.listCount,
                  loading: false,
                  modalUpdated: false
                }
              );
            }
          } else {
            me.setState({ message: { text: response.message, style: 'danger' }, loading: false });
            setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
          }
        } else {
          if (response.message) {
            me.setState({ message: { text: response.message, style: 'danger' }, loading: false });
            setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
          }
          console.error(response);
        }
      }
      this.Fetch(entity, token, 'read', then);
    }
  }
  // A method for Updating an existing entity in the Database,
  // inputs:
  // entity = the entity that will be sent to the Server for Updating (current updateEntity)
  Update(entity) {
    // Trimming the 'text' user inputs '   ahmed   ' => 'ahmed'
    for (let prop of this.props.updateEntityProps) {
      if (prop.type === 'text') {
        entity.model[prop.backName] = entity.model[prop.backName] ? entity.model[prop.backName].toString().trim() : '';
      }
    }

    let errors = this.Validation(entity.model);

    this.setState({ updateErrors: errors });

    // if there is any validation error don't proceed
    if (errors.length === 0) {
      this.setState({ loading: true });
      let token = Cookie.get('token');
      let then = (response, me) => {
        if (response.status === 200 || response.success) {
          if (response.success) {
            me.setState({ message: { text: response.message, style: 'success' }, loading: false });
            setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
            me.Read(me.state.readEntity);
          } else {
            me.setState({ message: { text: response.message, style: 'danger' }, loading: false });
            setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
          }
        } else {
          if (response.message) {
            errors.push(response.message);
            me.setState({ updateErrors: errors, loading: false });
          }
          console.error(response);
        }
      }
      this.Fetch(entity, token, 'update', then);
    }
  }

  render() {

    let UpdateEntityProperties = this.props.updateEntityProps.map(
      (prop) => {
        let backNames;
        let values;
        let items;
        switch (prop.type) {
          case 'text':
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                // ===========
                title={[prop.label, prop.validation === 'Required' ? <span  style={{color:"red"}}>*</span>  : null, ":"]}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={prop.label}
                    type="text"
                    value={this.state.updateEntity.model[prop.backName]}
                    onChange={(value) => this.ChangeEntityStateModel('updateEntity', prop.backName, value)}
                  />
                ]}
              />
            );
          case 'email':
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                // ===========
                title={[prop.label, prop.validation === 'Required' ? <span  style={{color:"red"}}>*</span>  : null, ":"]}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={prop.label}
                    type="email"
                    value={this.state.updateEntity.model[prop.backName]}
                    onChange={(value) => this.ChangeEntityStateModel('updateEntity', prop.backName, value)}
                  />
                ]}
              />
            );
          case 'date':
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                // ===========
                title={[prop.label, prop.validation === 'Required' ? <span  style={{color:"red"}}>*</span>  : null, ":"]}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={prop.label}
                    type="date"
                    value={this.state.updateEntity.model[prop.backName]}
                    onChange={(value) => this.ChangeEntityStateModel('updateEntity', prop.backName, value)}
                  />
                ]}
              />
            );
          case 'time':
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                // ===========
                title={[prop.label, prop.validation === 'Required' ? <span  style={{color:"red"}}>*</span>  : null, ":"]}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={prop.label}
                    type="time"
                    value={this.state.updateEntity.model[prop.backName]?.substring(0, 5)}
                    onChange={(value) => this.ChangeEntityStateModel('updateEntity', prop.backName, value+":00")}
                  />
                ]}
              />
            );
          case 'password':
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                // ===========
                title={[prop.label, prop.validation === 'Required' ? <span  style={{color:"red"}}>*</span>  : null, ":"]}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={prop.label}
                    type="password"
                    value={this.state.updateEntity.model[prop.backName]}
                    onChange={(value) => this.ChangeEntityStateModel('updateEntity', prop.backName, value)}
                  />
                ]}
              />
            );
          case 'file':
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                // ===========
                title={[prop.label, prop.validation === 'Required' ? <span  style={{color:"red"}}>*</span>  : null, ":"]}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={prop.label}
                    type="file"
                    value={this.state.updateEntity[prop.backName]}
                    onChange={(value) => this.ChangeEntityState('updateEntity', prop.backName, value)}
                  />
                ]}
              />
            );
          case 'select':
            if (prop.label === 'Management') {
              items = this.state[prop.label] === undefined ?
                []
                :
                this.state[prop.label].filter(management => management.branchId === this.state.updateEntity.model[this.props.updateEntityProps.find(prop => prop.label === 'Branch').backName]).map((item) => { return { label: item[prop.item.label], value: item[prop.item.value] } });
            } else if (prop.label === 'Department') {
              items = this.state[prop.label] === undefined ?
                []
                :
                this.state[prop.label].filter(department => department.administrationId === this.state.updateEntity.model[this.props.updateEntityProps.find(prop => prop.label === 'Management').backName]).map((item) => { return { label: item[prop.item.label], value: item[prop.item.value] } });
            } else if (prop.label === 'Employee') {
              items = this.state[prop.label] === undefined ?
                []
                :
                this.state[prop.label].map((item) => { return { label: item.employeeCode + ': ' + item[prop.item.label], value: item[prop.item.value] } });
            } else {
              items = this.state[prop.label] === undefined ?
                []
                :
                this.state[prop.label].map((item) => { return { label: item[prop.item.label], value: item[prop.item.value] } });
            }

            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                // ===========
                title={[prop.label, prop.validation === 'Required' ? <span  style={{color:"red"}}>*</span>  : null, ":"]}
                inputComponent={[
                  <Dropdown
                    key={prop.label}
                    placeholder={`Choose or type`}
                    value={items.find(item => item.value === this.state.updateEntity.model[prop.backName])}
                    options={items}
                    onChange={(value) => this.ChangeEntityStateModel('updateEntity', prop.backName, value)}
                  />
                ]}
              />
            );
          case 'checkbox':
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                // ===========
                title={[prop.label, prop.validation === 'Required' ? <span  style={{color:"red"}}>*</span>  : null, ":"]}
                inputComponent={[
                  <ToggleSwitch
                    value={this.state.updateEntity.model[prop.backName]}
                    backName={prop.backName}
                    onChange={(name, value) => this.ChangeEntityStateModel('updateEntity', name, value)}
                    onText={'Yes'}
                    offText={'No'}
                    height={30}
                    width={70}
                    // uncheckedIconWidth="80px"
                    // uncheckedIconMarginLeft="-38px"
                    checkedIconMarginLeft="10px"
                  />
                ]}
              />
            );

          default:
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                // ===========
                title={[prop.label, prop.validation === 'Required' ? <span  style={{color:"red"}}>*</span>  : null, ":"]}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={prop.label}
                    type="text"
                    value={this.state.updateEntity.model[prop.backName]}
                    onChange={(value) => this.ChangeEntityStateModel('updateEntity', prop.backName, value)}
                  />
                ]}
              />
            );
        }

      }
    );

    return (
      <section className="CompanyInfo--dialog en">
        <Overlay visible={this.state.loading}>
          <Loading visible={this.state.loading} />
        </Overlay>
        <div className="StartLayout--container">
          <h4>CompanyInfo</h4>
          <div className="CompanyInfo--form">
            {
              this.state.updateErrors ?
                this.state.updateErrors.length > 0 ?
                  (
                    <Alert
                      className="Alert danger"
                      style={{
                        // marginTop: "-20px",
                        // marginBottom: "20px"
                      }}
                    >
                      <ul>
                        {this.state.updateErrors.map((error) => <li key={error}>{error}</li>)}
                      </ul>
                    </Alert>
                  )
                  :
                  null
                :
                null
            }
            {
              this.state.message ?
                this.state.message['text'] ?
                  <Alert
                    className={"Alert " + this.state.message['style']}
                  >
                    {this.state.message['text']}
                  </Alert>
                  :
                  null
                :
                null
            }
            {UpdateEntityProperties}
            <Field
              title={['Logo', null, ":"]}
              style={ 'col-lg-6'}
              inputComponent={[
                <input
                  name='imagePath'
                  type='file'
                  ref={this.fileInput}
                  className={`TextInput file`}
                  onChange={(e) => { this.ChangeEntityState('updateEntity', 'imagePath', this.fileInput.current.files[0]) }}
                  accept={'image/*'}
                />
              ]}
            />
            <Field
              title={['Current Logo', null, ":"]}
              style={ 'col-lg-6'}
              noFormCont={true}
              inputComponent={[
                <img style={{ borderRadius: '13%' }} src={ip + '/' + this.state.data.image} alt="Current Logo" width='50px'></img>
              ]}
            />
            {(!this.props.permissions?.data.find(permission => permission.nameEn === 'Edite ')) ?
              null
              :
              <Button
                id="submitButton"
                className="btn add square gradient"
                onClick={() => this.Update(this.state.updateEntity)}
              >
                Save
            </Button>
            }
          </div>
        </div>
      </section>
    )
  }
}

export default CompanyInfo;