/* eslint-disable eqeqeq */
import React from 'react';
import './ReportPage.css';
import { Link } from "react-router-dom";
import Table from '../Table/Table';
import Cookie from "js-cookie";
import PageHeaderAr from '../PageHeaderAr/PageHeaderAr';
import FiltersAr, { hideFilters, showFilters } from '../FiltersAr/FiltersAr';
import Loading from '../Loading/Loading';
import Overlay from '../Overlay/Overlay';
import TextInput from '../TextInput/TextInput';
import Dropdown from '../Dropdown/Dropdown';
import Field from '../Field/Field';
import DataHeader from './../DataHeader/DataHeader';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import 'html2canvas';
import font from '../../Amiri-Regular-normal';
import Alert from './../Alert/Alert';
import { ip } from './../../data/APIs';
import Button from './../Button/Button';
import axios from 'axios';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import TableFooterAr from '../TableFooterAr/TableFooterAr'
class ReportPageAr extends React.Component {
  constructor(props) {
    super(props);

    // Create an Empty Search Entity when the page displayed for the first time
    let tempSearchEntity = {};
    for (let field of props.searchFields) {
      //'dateFromTo' type is no longer used now because we moved the search by date to the server side and removed it from the client side
      if (field.type === 'dateFromTo') {
        tempSearchEntity[field.backName + ',from'] = '';
        tempSearchEntity[field.backName + ',to'] = '';
      } else if (field.innerSearch) { // innerSearch is to search in the 'data' property of every row in 'this.state.data', so we add ',inner' to it's name in the searchEntity to recognize it. e.g searchEntity = {'nameAr,inner': ''}
        tempSearchEntity[field.backName + ',inner'] = '';
      }
      else {
        tempSearchEntity[field.backName] = '';
      }
    }

    let tempReadEntity = props.read.body

    this.state = {
      readEntity: props.read.body ? tempReadEntity : props.read.path,
      searchEntity: tempSearchEntity,
      rowsNumber: 10,
      rowsTotal: 0,
      currentPage: 1,
      message: { text: '', style: '' },
      loading: false,
      search: false,
      data: [],
      modalUpdated: false,
      //Company info for Print
      companyInfo: null,
      columns: props.columns.slice(),
      comLogo: null,
      generalSettings: null,
      totals: props.totals?.slice()

    }
    // Get the Search Fields 'select elements' items from the Database when the page displayed for the first time  
    for (let field of props.searchFields) {
      if (field.type === 'select' || field.type === 'multiSelect') {
        this.GetSelect(field.url, field.body, field.label);
      }
    }
  }

  // Styling:
  // ========
  showModal(modalName) {
    const mainLayout = document.querySelector("#mainLayout");
    this.setState({ [modalName]: true });
    mainLayout.style.overflow = "visible";
  }

  hideModal(modalName) {
    const mainLayout = document.querySelector("#mainLayout");
    this.setState({ [modalName]: false });
  }
  // ========

  componentDidMount() {
    // Read Data from the Database and Display it
    if (this.props.permissions !== null) {
      this.Clear();
      this.GetCompanyInfo();
      this.GetGeneralSettings();

    } else {
      let me = this;
      setTimeout(function () {
        me.componentDidMount();
      }, 1000);
    }

  }

  // A method for changing the entity properties,
  // inputs:
  // state = Name of the entity, e.g: createEntity, updateEntity ... 
  // name = Name of the property, e.g: createEntity.nameAr, updateEntity.nameEn ... 
  // value = value of the property, e.g: createEntity.nameAr = 'أحمد', updateEntity.nameEn = 'Ahmad'... 
  ChangeEntityState(state, name, value) {
    let tempEntity = this.state[state];
    tempEntity[name] = value;
    this.setState({ [state]: tempEntity });
  }

  // A method for changing the state,
  // inputs:
  // key = Name of the state
  // value = value of the state
  ChangeState(key, value) {
    this.setState({ [key]: value });
  }

  // A method for making a fetch Request,
  // inputs:
  // entity = the entity that will be sent to the Server for Creating, Reading, Updating, or Deleting, e.g: current deleteEntity...
  // token = the JWT Bearer Authorization token.
  // operation = the operation that will be done against the entity, e.g: create, read, update, or delete
  // then = the method that will be executed when we receive the response
  Fetch(entity, token, operation, then) {

    let url = this.props[operation].path ?
      this.props[operation].url + '/' + Object.keys(entity).map((key) => entity[key]).join('/')
      :
      this.props[operation].query ?
        this.props[operation].url + '?' + Object.keys(entity).map((key) => key + '=' + entity[key]).join('&')
        :
        this.props[operation].url;

    let end;
    let start = Date.now();
    console.log('Start: ' + start);
    fetch(url, {
      method: this.props[operation].verb,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'lang': 'ar'
      },
      body: this.props[operation].body ? JSON.stringify(entity) : null
    })
      .then(response => response.json())
      .then((response) => {
        end = Date.now();
        console.log('End: ' + end);
        console.log('Time: ' + (end - start));
        let me = this;
        then(response, me);
      })
      .catch(error => console.error('Unable to ' + operation + '.', error));
  }

  // A method for Reading data from Database,
  // inputs:
  // entity = the entity that will be sent to the Server in the request body (readEntity)
  Read(entity) {
    if (this.props.permissions?.data.find(permission => permission.nameEn === 'Get')) {
      this.setState({ loading: true });
      let token = Cookie.get('token');
      console.log(token);
      let then = (response, me) => {
        if (response.status === 200 || response.success) {
          if (response.success) {
            //if response.data ia an array not an object
            if (Array.isArray(response.data)) {
              // ====================================
              me.setState(
                {
                  data: response.data.map((row) => {
                    for (const key in me.props.params) {
                      row[key] = me.props.params[key];
                    }
                    return row;
                  }),
                  rowsTotal: response.data.listCount,
                  loading: false,
                  modalUpdated: false
                }
              );
            } else { // if response.data is not an array, it is an object
              // ====================================
              me.setState(
                {
                  data: response.data.data.map((row) => {
                    for (const key in me.props.params) {
                      row[key] = me.props.params[key];
                    }
                    return row;
                  }),
                  rowsTotal: response.data.listCount,
                  loading: false,
                  modalUpdated: false
                }
              );
            }
          } else { // not success
            me.setState({ message: { text: response.message, style: 'danger' }, loading: false });
            setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
          }
        } else {  // response.status !== 200
          if (response.message) {
            me.setState({ message: { text: response.message, style: 'danger' }, loading: false });
            setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
          }
          console.error(response);
        }
      }
      this.Fetch(entity, token, 'read', then);
    }
  }

  //Get Company Info for print
  GetCompanyInfo() {
    let token = Cookie.get('token');
    let then = (response, me) => {
      if (response.status === 200 || response.success) {
        if (response.success) {
          me.setState(
            {
              companyInfo: response.data,
            }
          );
          //if there is an image path
          if (response.data.image) {
            //convert image path to Base64 to show it in the report
            this.ImageUrlToBase64(ip + '/' + response.data.image, function (myBase64) {
              me.Logo = myBase64;
              me.setState({ comLogo: myBase64 })
            });
          }
        } else { // not success
          me.setState({ message: { text: response.message, style: 'danger' } });
          setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
        }
      } else { // response.status !== 200
        if (response.message) {
          me.setState({ message: { text: response.message, style: 'danger' } });
          setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
        }
        console.error(response);
      }
    }
    fetch(ip + '/api/GetUserData', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'lang': 'ar'
      },
    })
      .then(response => response.json())
      .then((response) => {
        let me = this;
        then(response, me);
      })
      .catch(error => console.error('Unable to Get Company Info.', error));
  }

  GetGeneralSettings() {
    let token = Cookie.get('token');
    let then = (response, me) => {
      if (response.status === 200 || response.success) {
        if (response.success) {
          me.setState(
            {
              generalSettings: response.data,
            }
          );
        } else {
          me.setState({ message: { text: response.message, style: 'danger' } });
          setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
        }
      } else {
        if (response.message) {
          me.setState({ message: { text: response.message, style: 'danger' } });
          setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
        }
        console.error(response);
      }
    }
    fetch(ip + '/api/GeneralSetting/GetGeneralSettings', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'lang': 'ar'
      },
      body: JSON.stringify({
        "pageSize": 10,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      })
    })
      .then(response => response.json())
      .then((response) => {
        let me = this;
        then(response, me);
      })
      .catch(error => console.error('Unable to Get generalSettings Info.', error));
  }

  // Declare printing variables
  head = []
  body = []
  hasArabicCodepoints(s) {
    return /[\u0600-\u06FF]/.test(s);
  }
  // Create table for Print()
  CreateTable(tableHead, tableRows) {

    //Columns Names for Attendance and leave 
    this.head = tableHead.map((column) =>
      column.backName == "timeOut1" ?
        " انصراف "
        :
        column.backName == "timeIn1" ?
          " حضور "
          :
          column.backName == "timeOut2" ?
            " انصراف "
            :
            column.backName == "timeIn2" ?
              " حضور "
              :
              column.backName == "timeOut3" ?
                " انصراف "
                :
                column.backName == "timeIn3" ?
                  " حضور "
                  :
                  column.backName == "timeOut4" ?
                    " انصراف "
                    :
                    column.backName == "timeIn4" ?
                      " حضور "
                      :
                      column.name);

    //Display table data properly (Same Logic as displaying data in tables)
    this.body = tableRows?.map(
      (row) => {
        let temp = this.state.columns.map((column) =>
          column.boolean ?
            row[column.backName] === true ?
              column.yes
              :
              column.no
            :
            column.enum ? column.enum.find((item) => item.value == row[column.backName]) ?
              column.enum.find((item) => item.value == row[column.backName])['display']
              :
              row[column.backName]
              :
              row[column.backName]);
        //Reverse to make it rigt to left
        temp.reverse();
        return temp;
      });
    //Reverse to make it rigt to left
    this.head.reverse();
  }

  //A function to convert image url to base64
  //takes the url of the image and a callback functiob to call when image is loaded
  ImageUrlToBase64(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  //Create and Download a PDF file of the Page Report
  Print() {
    //print only if company info is loaded
    if (this.state.companyInfo) {
      let companyInfo = this.state.companyInfo
      //if any property in companyInfo evaluates to false, then set it to ''
      for (const key in companyInfo) {
        if (!companyInfo[key]) {
          companyInfo[key] = '';
        }
      }

      let me = this;
      //Make the page orientation = portrait if this is the workingTimeDetails report, otherwise it is landscape
      var doc = new jsPDF(this.props.workingTimeDetails ? 'portrait' : 'landscape');
      //Add Amirir Font for Arabic Text
      doc.addFileToVFS('Amiri-Regular-normal.ttf', font);
      doc.addFont('Amiri-Regular-normal.ttf', 'Amiri-Regular', 'normal');
      doc.setFont('Amiri-Regular'); // set font

      // You can use html:
      // autoTable(doc, { html: '#my-table', theme: 'grid', headStyles: { fillColor: 180 } });

      // Or JavaScript:

      // check if we want the multi tables view or the single one
      if (this.props.preTableFields || this.props.period && !this.props.singleTable) {
        let tablesData = this.state.data.slice()
        // The following filtering should be generic
        // =====================================================================
        // Filtering data according to the Search Fields values
        for (let prop in this.state.searchEntity) {
          if (this.state.searchEntity[prop]) {
            //',from' and ',to' is for 'dateFromTo' searchField type which is no longer used
            if (prop.toString().endsWith(',from') || prop.toString().endsWith(',to')) {
              if (prop.toString().endsWith(',from')) {
                let backName = prop.split(',')[0];
                tablesData = tablesData.filter(row => {
                  if (row[backName] != null) {
                    let rowDate = new Date(row[backName]);
                    let searchDate = new Date(this.state.searchEntity[prop])
                    return rowDate >= searchDate;
                  } else {
                    return false;
                  }
                });
              } else if (prop.toString().endsWith(',to')) {
                let backName = prop.split(',')[0];
                tablesData = tablesData.filter(row => {
                  if (row[backName] != null) {
                    let rowDate = new Date(row[backName]);
                    let searchDate = new Date(this.state.searchEntity[prop])
                    return rowDate <= searchDate;
                  } else {
                    return false;
                  }
                });
              }
            } else if (prop.toString().endsWith(',inner')) { // ',inner' is for backSearch
              let backName = prop.split(',')[0]; // get the backname without ',inner'
              tablesData = tablesData.map(row => {
                let newRow = Object.assign({}, row); //assign by value
                newRow.data = row.data.filter(innerRow => { //search into the inner 'data' of each row
                  //get rows which the current column contains the entered word (case insensetive)
                  if (innerRow[backName] != null) {
                    return innerRow[backName].toString().toLowerCase().includes(this.state.searchEntity[prop].toString().toLowerCase())
                  } else {
                    return false;
                  }
                }).slice();
                return newRow;
              });
            } else {
              tablesData = tablesData.filter(row => {
                if (row[prop] != null) { //get rows which the current column contains the entered word (case insensetive)
                  return row[prop].toString().toLowerCase().includes(this.state.searchEntity[prop].toString().toLowerCase())

                } else {
                  return ''.toLowerCase().includes(this.state.searchEntity[prop].toString().toLowerCase())
                }
              });
            }
          }
        }

        //Period Section
        let period = "";
        if (this.props.period && tablesData && this.props.printPeriod) {
          this.props.abcentFrom ? period += "الغياب خلال الفترة " : this.props.delay ? period += `التأخير خلال الفترة` : period += ` الفترة  `;
          period += `من:  ${tablesData[0]?.from ? tablesData[0].from : " - "}`;
          period += `  إلى: ${tablesData[0]?.to ? tablesData[0].to : " - "}`;
        }

        let headers = [];
        if (this.props.overtime) {
          if (this.state.data.length > 0) {
            for (let x = 0; x < this.state.data.length; x++) {
              let header1 = [], header2 = [];
              for (let j = 0; j < this.state.data[x].data.length; j++) {
                header1 = []; header2 = [];
                header1.push(
                  'كود الموظف'
                )
                header1.push(
                  'إسم الموظف'
                )
                header2.push(
                  ''
                )
                header2.push(
                  ''
                )
                for (let i = 0; i < this.state.data[x].data[j].data.length; i++) {
                  header1.push(
                    this.state.data[x].data[j].data[i].date
                  )
                  header2.push(
                    this.state.data[x].data[j].data[i].day
                  )
                }
              }
              header1.push(
                'اجمالي الساعات'
              )
              header2.push(
                ''
              )
              header1.reverse();
              header2.reverse();
              headers.push({ header1: header1, header2: header2 })
            }

          }
        }
        let printyIndex = 0;
        for (let i = 0; i < tablesData.length; i++) {

          if (tablesData[i].data != false) { // Not to Print empty tables
            // Print preTableData
            let preTableFields = []

            if (this.props.preTableFields || this.props.period) {
              // eslint-disable-next-line array-callback-return
              if (this.props.preTableFields) {
                let rev = false;
                let engBranch = '';
                this.props.preTableFields.map((preTableField) => {
                  if (preTableField.backName == 'branchName') {
                    tablesData[i][preTableField.backName] = tablesData[i][preTableField.backName].replace(')', " ' ")
                    tablesData[i][preTableField.backName] = tablesData[i][preTableField.backName].replace('(', " ' ")
                    rev = this.hasArabicCodepoints(tablesData[i][preTableField.backName])

                  }
                  if (!rev && preTableField.backName == 'branchName') {
                    engBranch = (tablesData[i][preTableField.backName] ?
                      tablesData[i][preTableField.backName] : ' ') + ':' + preTableField.name
                  }
                  // isShiftOpen
                  if (preTableField.backName == 'isShiftOpen') {
                    preTableFields.push(preTableField.name + ': ' + (tablesData[i][preTableField.backName] ?
                      "مفتوح" : 'عادي'));
                  }
                  else {
                    preTableFields.push(preTableField.name + (tablesData[i][preTableField.backName] ?
                      ' : ' + tablesData[i][preTableField.backName] : ' '));
                  }

                })

                preTableFields.push(period);
                if (!rev) {
                  preTableFields.push(engBranch);

                }
              }
              else {
                preTableFields.push(period);
              }

            }
            let totals = [];

            if (this.props.totals) {
              totals = this.state.totals.map((column) => {
                return (
                  column.backName ?
                    tablesData[i][column.backName]
                    :
                    null
                )
              })
              let counter = 0;
              for (const cell of totals) {
                if (cell) {
                  break;
                }
                counter++;
                totals.shift();
              }
              totals.unshift({ content: ': الإجماليات', colSpan: counter, styles: { halign: 'right' } });
              totals.reverse();
            }
            let head1 = [];
            this.state.columns.map((row) => {
              if (row.backName == 'timeOut1') {
                head1.unshift({ content: "الوردية الاولي", colSpan: 2, styles: { halign: 'center' } })
              } else if (row.backName == 'timeOut2') {
                head1.unshift({ content: "الوردية الثانية", colSpan: 2, styles: { halign: 'center' } })
              } else if (row.backName == 'timeOut3') {
                head1.unshift({ content: "الوردية الثالثة", colSpan: 2, styles: { halign: 'center' } })
              } else if (row.backName == 'timeOut4') {
                head1.unshift({ content: "الوردية الرابعة", colSpan: 2, styles: { halign: 'center' } })
              } else if (row.backName == 'timeIn1') {

              } else if (row.backName == 'timeIn2') {

              } else if (row.backName == 'timeIn3') {

              } else if (row.backName == 'timeIn4') {

              } else {
                head1.unshift({ content: "", styles: { halign: 'center' } })
              }
            });

            let OverTableData = [];
            // creating table data
            if (this.props.overtime) {
              OverTableData = tablesData[i].data.map((raw) => {
                let arr = [];

                for (const column of this.state.columns) {
                  if (column.backName == 'date') {
                    let data = raw.data.map((col) =>
                      col.extraMinutes ? col.extraMinutes : 0
                    );
                    for (const i of data) {
                      arr.unshift(i);
                    }
                  } else {
                    arr.unshift(raw[column.backName] ? raw[column.backName] : ' - ');
                  }
                }
                return arr;
              })
            }

            let DetailsTableData = [];
            // creating table data
            if (this.props.workingTimeDetails) {
              for (const column of this.state.columns) {
                DetailsTableData.push([
                  {
                    content:
                      (tablesData[i].data[0][column.backName] ?
                        tablesData[i].data[0][column.backName] : ' - ')
                  },
                  column.name,
                ]);
              }

            }
            this.CreateTable(this.state.columns, tablesData[i].data)

            var printHead1 = head1.some(h => h.content != "");
            var prevTable = doc.lastAutoTable.finalY;
            var printed = false;
            autoTable(doc, {
              head: this.props.totals ?
                (printHead1 ?
                  [totals, head1, this.head]
                  :
                  [totals, this.head])
                :
                (printHead1 ?
                  [head1, this.head]
                  :
                  headers.length > 0 ?
                    [headers[i].header1, headers[i].header2]
                    :
                    DetailsTableData.length > 0 ?
                      []
                      :
                      [this.head]),
              body: OverTableData.length > 0 ? OverTableData : DetailsTableData.length > 0 ? DetailsTableData : this.body,
              theme: 'grid',
              headStyles: { font: 'Amiri-Regular', fillColor: 230, textColor: 5, cellPadding: 1 },
              bodyStyles: { font: 'Amiri-Regular', overflow: 'linebreak', lineColor: 100, textColor: 10, cellPadding: this.props.DetailedReport ? 0 : 1 },
              styles: { cellWidth: 'auto', halign: "center" },
              startY: i === 0 ? undefined : this.props.DetailedReport ? printyIndex : (prevTable + 12),
              pageBreak: this.props.workingTimeDetails && i !== 0 ? 'always' : undefined,
              margin: { top: this.props.workingTimeDetails ? 50 : 40, bottom: this.props.DetailedReport ? 15 : 25 },
              showHead: 'firstPage',
              didDrawPage: function (data) {

                // Header
                doc.setFontSize(10)
                doc.setDrawColor(35);
                // doc.setTextColor(10)
                doc.rect(data.settings.margin.left, 10, doc.internal.pageSize.width - (data.settings.margin.right + data.settings.margin.left), 15)

                if (me.Logo && me.Logo != 'data:') {
                  doc.addImage(me.Logo, 'JPEG', data.settings.margin.left + 5, 13, 10, 10)
                }

                //Arabic Company Name 
                doc.text(companyInfo.companyNameAr, (doc.internal.pageSize.width - data.settings.margin.right) - 5, 15, { align: 'right' })
                //Arabic Company Activity 
                doc.text(companyInfo.companyActivityAr, (doc.internal.pageSize.width - data.settings.margin.right) - 5, 22, { align: 'right' })

                //English Company Name 
                // doc.text(companyInfo.companyNameEn, data.settings.margin.left + 5, 15)
                //English Company Activity 
                // doc.text(companyInfo.companyActivityEn, data.settings.margin.left + 5, 22)

                doc.setFontSize(20)

                //Report Title
                doc.text(me.props.title, data.settings.margin.left + me.props.workingTimeDetails ? 75 : 100, 20)

                doc.setFontSize(10)

                if (!printed) {
                  for (let index = preTableFields.length - 1; index >= 0; index--) {
                    if (preTableFields[index].trim() == '') {
                      preTableFields.splice(index, 1);
                    }
                  }

                  if (me.props.workingTimeDetails) {
                    let i = 0;
                    for (let index = 0; index < preTableFields.length; index++) {
                      doc.text(preTableFields[index], doc.internal.pageSize.width - data.settings.margin.right - i, index <= 2 ? data.settings.margin.top - 15 : index <= 5 ? data.settings.margin.top - 10 : data.settings.margin.top - 5, { align: 'right' });
                      i += 70;
                      if (index == 2) {
                        i = 0;
                      }
                      if (index == 5) {
                        i = 0;
                      }
                    }
                    printed = true;
                  } else if ((data.settings.startY + 40) > doc.internal.pageSize.height) {
                    let i = 0;
                    let lines = preTableFields.length / 4;
                    if (lines <= 1) {
                      for (let index = 0; index < preTableFields.length; index++) {
                        doc.text(preTableFields[index], doc.internal.pageSize.width - data.settings.margin.right - i, data.settings.margin.top - 5, { align: 'right' });
                        i += 70;
                      }
                    } else if (lines <= 2) {
                      for (let index = 0; index < preTableFields.length; index++) {
                        doc.text(preTableFields[index], doc.internal.pageSize.width - data.settings.margin.right - i, index <= 3 ? data.settings.margin.top - 8 : data.settings.margin.top - 3, { align: 'right' });
                        i += 70;
                        if (index == 3) {
                          i = 0;
                        }
                      }
                    }
                    printed = true;
                  } else {
                    let i = 0;
                    let lines = preTableFields.length / 4;
                    if (lines <= 1) {
                      for (let index = 0; index < preTableFields.length; index++) {
                        doc.text(preTableFields[index], doc.internal.pageSize.width - data.settings.margin.right - i, data.settings.startY - 5, { align: 'right' });
                        i += 70;
                      }
                    } else if (lines <= 2) {
                      for (let index = 0; index < preTableFields.length; index++) {
                        doc.text(preTableFields[index], doc.internal.pageSize.width - data.settings.margin.right - i, index <= 3 ? data.settings.startY - 8 : data.settings.startY - 3, { align: 'right' });
                        i += 70;
                        if (index == 3) {
                          i = 0;
                        }
                      }
                    }

                    printed = true;

                  }
                }

                // Footer
                doc.setFontSize(10)

                doc.line(data.settings.margin.left,
                  (doc.internal.pageSize.height - data.settings.margin.bottom) + 5,
                  doc.internal.pageSize.width - data.settings.margin.right,
                  (doc.internal.pageSize.height - data.settings.margin.bottom) + 5)

                // Date & Time
                let d = new Date();

                doc.text(d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' ' + d.getHours() + ':' + (d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()), (doc.internal.pageSize.width - (data.settings.margin.right + data.settings.margin.left)) - 10, doc.internal.pageSize.height - 12)

                // English Address
                doc.text(companyInfo.adressEn, data.settings.margin.left, doc.internal.pageSize.height - 5)

                // Arabic Address
                doc.text(companyInfo.adressAr, data.settings.margin.left + 250, doc.internal.pageSize.height - 5, { align: 'right' })

                // Fax
                doc.text('Fax: ' + companyInfo.fax, data.settings.margin.left + 135, doc.internal.pageSize.height - 5)

                // Phone
                doc.text('Phone: ' + companyInfo.companyPhoneNumber, data.settings.margin.left + 85, doc.internal.pageSize.height - 5)

                var str = 'Page ' + doc.internal.getNumberOfPages()
                // Total page number plugin only available in jspdf v1.0+
                // if (typeof doc.putTotalPages === 'function') {
                //   str = str + ' of ' + totalPagesExp
                // }

                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                var pageSize = doc.internal.pageSize
                var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()

                // Page Number
                doc.text(str, (doc.internal.pageSize.width - data.settings.margin.right) - 13, pageHeight - 5)
                printyIndex += doc.internal.pageSize.height;
              },
              didDrawCell: this.props.workingTimeDetails ? (data) => {
                if (data.column.index === 0) {
                  if (data.row.index < 4) {
                    doc.text('يوم', data.cell.x + 2, data.cell.y + 4);
                  } else {
                    doc.text('ساعة', data.cell.x + 2, data.cell.y + 4);
                  }
                }
              } : undefined
            });

          }
        }
      }
      else if (this.props.singleTable) {
        let tableData = this.state.data.slice();
        let period = '';

        if (this.props.period && tableData.length > 0) {
          let to, from;
          if (tableData[0].date) {
            to = tableData[tableData.length - 1].date;
            from = tableData[0].date;
            for (let prop of tableData) {
              if (prop.date <= from) {
                from = prop.date
              }
              if (prop.date >= to) {
                to = prop.date
              }
            }
          }

          if (tableData[0]?.from && tableData[0]?.to) {
            to = tableData[0].to;
            from = tableData[0].from;
          }
          period += " ";
          period += " ";
          period += " ";
          this.props.abcentFrom ? period += "الغياب خلال الفترة " : this.props.delay ? period += `التأخير خلال الفترة` : period += ` الفترة  `;
          period += `من:  ${tableData ? from : " - "}`;
          period += `  إلى : ${tableData ? to : " - "}`;
          period += " ";
        }
        let coloredHeaders = [];
        if (this.props.colored) {
          if (this.state.data.length > 0) {
            coloredHeaders.unshift(
              'كود الموظف'
            )
            coloredHeaders.unshift(
              'إسم الموظف'
            )
            for (let x = 0; x < this.state.data[0].data.length; x++) {
              coloredHeaders.unshift(
                this.state.data[0].data[x].date
              )
            }
            coloredHeaders.unshift(
              'أيام العمل'
            )
          }
          else {
            coloredHeaders.unshift(
              'كود الموظف '
            )
            coloredHeaders.unshift(
              'إسم الموظف'
            )
            coloredHeaders.unshift(
              'الايام'
            )
            coloredHeaders.unshift(
              'أيام العمل'
            )
          }
        }
        let coloredTableData = [];
        if (this.props.colored) {
          coloredTableData = this.state.data.map((raw) => {
            let arr = [];
            for (const column of this.state.columns) {
              if (column.backName == 'data') {
                for (const col of raw.data) {
                  arr.unshift(col.timeIn ? col.timeIn : 0);
                }
              } else {
                arr.unshift(raw[column.backName] ? raw[column.backName] : ' - ');
              }
            }

            return arr;
          })
        }
        let head1 = [];
        this.state.columns.map((row) => {
          if (row.backName == 'timeOut1') {
            head1.unshift({ content: "الوردية الاولي", colSpan: 2, styles: { halign: 'center' } })
          } else if (row.backName == 'timeOut2') {
            head1.unshift({ content: "الوردية الثانية", colSpan: 2, styles: { halign: 'center' } })
          } else if (row.backName == 'timeOut3') {
            head1.unshift({ content: "الوردية الثالثة", colSpan: 2, styles: { halign: 'center' } })
          } else if (row.backName == 'timeOut4') {
            head1.unshift({ content: "الوردية الرابعة", colSpan: 2, styles: { halign: 'center' } })
          } else if (row.backName == 'timeIn1') {

          } else if (row.backName == 'timeIn2') {

          } else if (row.backName == 'timeIn3') {

          } else if (row.backName == 'timeIn4') {

          } else {
            head1.unshift({ content: "", styles: { halign: 'center' } })
          }
        });
        // for single table:
        this.CreateTable(this.state.columns, this.state.data)
        var printHead1 = head1.some(h => h.content != "");

        autoTable(doc, {
          head: this.props.colored ? [coloredHeaders] : (printHead1 ?
            [head1, this.head] : [this.head]),
          body: coloredTableData.length > 0 ? coloredTableData : this.body,
          theme: 'grid',
          headStyles: { font: 'Amiri-Regular', fillColor: 240, textColor: 10 },
          bodyStyles: { font: 'Amiri-Regular', overflow: 'linebreak', lineColor: 100 },
          styles: { cellWidth: 'auto', halign: "center" },
          didDrawPage: function (data) {
            // Header
            doc.setFontSize(10)
            // doc.setTextColor(40)
            doc.rect(data.settings.margin.left, 10, doc.internal.pageSize.width - (data.settings.margin.right + data.settings.margin.left), 15)

            if (me.Logo && me.Logo != 'data:') {
              doc.addImage(me.Logo, 'JPEG', data.settings.margin.left + 5, 13, 10, 10)
            }

            //Arabic Company Name 
            doc.text(companyInfo.companyNameAr, (doc.internal.pageSize.width - data.settings.margin.right) - 5, 15, { align: 'right' })
            //Arabic Company Activity 
            doc.text(companyInfo.companyActivityAr, (doc.internal.pageSize.width - data.settings.margin.right) - 5, 22, { align: 'right' })

            //English Company Name 
            // doc.text(companyInfo.companyNameEn, data.settings.margin.left + 5, 15)
            //English Company Activity 
            // doc.text(companyInfo.companyActivityEn, data.settings.margin.left + 5, 22)

            doc.setFontSize(20)
            doc.setDrawColor(0, 0, 0);

            //Report Title
            doc.text(me.props.title, data.settings.margin.left + 100, 20)

            doc.setFontSize(10)
            if (!printed) {
              doc.text(period, data.settings.margin.left + 130, data.settings.margin.top - 5, { align: 'center' })
              printed = true;
            }


            // Footer
            doc.setFontSize(10)

            doc.line(data.settings.margin.left, (doc.internal.pageSize.height - data.settings.margin.bottom) + 11, doc.internal.pageSize.width - data.settings.margin.right, (doc.internal.pageSize.height - data.settings.margin.bottom) + 11)

            // Date & Time
            let d = new Date();

            doc.text(d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' ' + d.getHours() + ':' + (d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()), (doc.internal.pageSize.width - (data.settings.margin.right + data.settings.margin.left)) - 10, doc.internal.pageSize.height - 15)

            // English Address
            doc.text(companyInfo.adressEn, data.settings.margin.left, doc.internal.pageSize.height - 10)

            // Arabic Address
            doc.text(companyInfo.adressAr, data.settings.margin.left + 250, doc.internal.pageSize.height - 10, { align: 'right' })

            // Fax
            doc.text('Fax: ' + companyInfo.fax, data.settings.margin.left + 135, doc.internal.pageSize.height - 10)

            // Phone
            doc.text('Phone: ' + companyInfo.companyPhoneNumber, data.settings.margin.left + 85, doc.internal.pageSize.height - 10)

            var str = 'Page ' + doc.internal.getNumberOfPages()
            // Total page number plugin only available in jspdf v1.0+
            // if (typeof doc.putTotalPages === 'function') {
            //   str = str + ' of ' + totalPagesExp
            // }

            // jsPDF 1.4+ uses getWidth, <1.4 uses .width
            var pageSize = doc.internal.pageSize
            var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()

            // Page Number
            doc.text(str, (doc.internal.pageSize.width - data.settings.margin.right) - 13, pageHeight - 10)
          },
          margin: { top: 40, bottom: 25 },
        });
      }
      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(doc.internal.getNumberOfPages().toString())
      }
      doc.save(this.props.title + '.pdf');

    }
  }
  // A method for getting the select items from the Database,
  // inputs:
  // url = Web API Service url
  // body = Request body
  // name = the name of the state that the data will be stored in
  GetSelect(url, body, name) {
    let token = Cookie.get('token');
    this.setState({ loading: true })
    fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then((response) => {
        if (response.success) {
          this.setState({ [name]: response.data.data, loading: false });
        } else {
          console.error(response.message);
        }
      })
      .catch(error => console.error('Unable to Read.', error));
  }

  // A method for Resetting the Search fields values
  Clear() {
    let tempSearchEntity = {};
    let tempEntity = this.state.readEntity;
    let backSearch = false;

    for (let field of this.props.searchFields) {
      if (field.backSearch && field.type != 'multiSelect') {
        tempEntity.search[field.backName] = '';
        backSearch = true
      }
      if (field.type == 'multiSelect') {
        tempEntity[field.backName] = [];
        backSearch = true
      }
      else {
        tempSearchEntity[field.backName] = '';
      }
    }
    if (backSearch) {
      this.setState({ readEntity: tempEntity, searchEntity: tempSearchEntity });
      // this.Read(this.state.readEntity);
    } else {
      this.setState({ searchEntity: tempSearchEntity });

    }
  }

  render() {
    let
      me = this,
      tableRawData = this.state.data.slice(),
      TableData,
      tables = [],
      totals,
      tablesData,
      preTableFields,
      from, to, headers = [], coloredHeaders = []
      ;

    if (tableRawData.length > 0) {
      to = tableRawData[tableRawData.length - 1].date;
      from = tableRawData[0].date;
      for (let prop of tableRawData) {
        if (prop.date <= from) {
          from = prop.date
        }
        if (prop.date >= to) {
          to = prop.date
        }
      }
    }
    if (this.props.overtime) {
      if (this.state.data.length > 0) {
        for (let x = 0; x < this.state.data.length; x++) {
          let header1 = [], header2 = [];
          for (let j = 0; j < this.state.data[x].data.length; j++) {
            header1 = []; header2 = [];
            header1.push(
              <th key={'كود الموظف'}> كود الموظف </th>
            )
            header1.push(
              <th key={'إسم الموظف'}> إسم الموظف </th>
            )
            header2.push(
              <td></td>
            )
            header2.push(
              <td></td>
            )
            for (let i = 0; i < this.state.data[x].data[j].data.length; i++) {
              header1.push(
                <th> {this.state.data[x].data[j].data[i].date}</th>
              )
              header2.push(
                <td > {this.state.data[x].data[j].data[i].day}</td>
              )
            }
          }
          header1.push(
            <th key={'اجمالي الساعات'}> اجمالي الساعات  </th>
          )
          header2.push(
            <td> </td>
          )
          headers.push({ header1: header1, header2: header2 })
        }

      }
    }
    if (this.props.colored) {
      if (this.state.data.length > 0) {
        from = this.state.data[0].from;
        to = this.state.data[0].to;
        coloredHeaders.push(
          <th key={'كود الموظف'}> كود الموظف </th>
        )
        coloredHeaders.push(
          <th key={'إسم الموظف'}> إسم الموظف </th>
        )
        for (let x = 0; x < this.state.data[0].data.length; x++) {
          coloredHeaders.push(
            <th className="text-center"> {this.state.data[0].data[x].date}</th>
          )
        }
        coloredHeaders.push(
          <th key={'أيام العمل'}>  أيام العمل  </th>
        )
      }
      else {
        coloredHeaders.push(
          <th key={'كود الموظف'}> كود الموظف </th>
        )
        coloredHeaders.push(
          <th key={'إسم الموظف'}> إسم الموظف </th>
        )
        coloredHeaders.push(
          <th > الايام </th>
        )
        coloredHeaders.push(
          <th key={'أيام العمل'}>  أيام العمل  </th>
        )
      }
    }

    if (this.state.data.length > 0) {
      //Check how many shifts should we show according to the general settings
      if (this.state.data[0].sequanceNumber) {
        let i = 0;
        switch (this.state.data[0].sequanceNumber) {
          case 1:
            //Remove the rest of the shifts
            i = this.state.columns.indexOf(this.state.columns.filter(c => c.backName === 'timeIn2')[0]);
            if (i != -1) {
              if (this.state.totals) {
                this.state.totals.splice(this.state.totals.indexOf(this.state.totals.filter(c => c.name === this.state.columns[i].name)[0]), 1);
              }
              this.state.columns.splice(i, 1);
            }
            i = this.state.columns.indexOf(this.state.columns.filter(c => c.backName === 'timeOut2')[0]);
            if (i != -1) {
              if (this.state.totals) {
                this.state.totals.splice(this.state.totals.indexOf(this.state.totals.filter(c => c.name === this.state.columns[i].name)[0]), 1);
              }
              this.state.columns.splice(i, 1);
            }
            i = this.state.columns.indexOf(this.state.columns.filter(c => c.backName === 'timeIn3')[0]);
            if (i != -1) {
              if (this.state.totals) {
                this.state.totals.splice(this.state.totals.indexOf(this.state.totals.filter(c => c.name === this.state.columns[i].name)[0]), 1);
              }
              this.state.columns.splice(i, 1);
            }
            i = this.state.columns.indexOf(this.state.columns.filter(c => c.backName === 'timeOut3')[0]);
            if (i != -1) {
              if (this.state.totals) {
                this.state.totals.splice(this.state.totals.indexOf(this.state.totals.filter(c => c.name === this.state.columns[i].name)[0]), 1);
              }
              this.state.columns.splice(i, 1);
            }
            i = this.state.columns.indexOf(this.state.columns.filter(c => c.backName === 'timeIn4')[0]);
            if (i != -1) {
              if (this.state.totals) {
                this.state.totals.splice(this.state.totals.indexOf(this.state.totals.filter(c => c.name === this.state.columns[i].name)[0]), 1);
              }
              this.state.columns.splice(i, 1);
            }
            i = this.state.columns.indexOf(this.state.columns.filter(c => c.backName === 'timeOut4')[0]);
            if (i != -1) {
              if (this.state.totals) {
                this.state.totals.splice(this.state.totals.indexOf(this.state.totals.filter(c => c.name === this.state.columns[i].name)[0]), 1);
              }
              this.state.columns.splice(i, 1);
            }
            break;
          case 2:
            //Remove the rest of the shifts
            i = this.state.columns.indexOf(this.state.columns.filter(c => c.backName === 'timeIn3')[0]);
            if (i != -1) {
              if (this.state.totals) {
                this.state.totals.splice(this.state.totals.indexOf(this.state.totals.filter(c => c.name === this.state.columns[i].name)[0]), 1);
              }
              this.state.columns.splice(i, 1);
            }
            i = this.state.columns.indexOf(this.state.columns.filter(c => c.backName === 'timeOut3')[0]);
            if (i != -1) {
              if (this.state.totals) {
                this.state.totals.splice(this.state.totals.indexOf(this.state.totals.filter(c => c.name === this.state.columns[i].name)[0]), 1);
              }
              this.state.columns.splice(i, 1);
            }
            i = this.state.columns.indexOf(this.state.columns.filter(c => c.backName === 'timeIn4')[0]);
            if (i != -1) {
              if (this.state.totals) {
                this.state.totals.splice(this.state.totals.indexOf(this.state.totals.filter(c => c.name === this.state.columns[i].name)[0]), 1);
              }
              this.state.columns.splice(i, 1);
            }
            i = this.state.columns.indexOf(this.state.columns.filter(c => c.backName === 'timeOut4')[0]);
            if (i != -1) {
              if (this.state.totals) {
                this.state.totals.splice(this.state.totals.indexOf(this.state.totals.filter(c => c.name === this.state.columns[i].name)[0]), 1);
              }
              this.state.columns.splice(i, 1);
            }
            break;
          case 3:
            //Remove the rest of the shifts
            i = this.state.columns.indexOf(this.state.columns.filter(c => c.backName === 'timeIn4')[0]);
            if (i != -1) {
              if (this.state.totals) {
                this.state.totals.splice(this.state.totals.indexOf(this.state.totals.filter(c => c.name === this.state.columns[i].name)[0]), 1);
              }
              this.state.columns.splice(i, 1);
            }
            i = this.state.columns.indexOf(this.state.columns.filter(c => c.backName === 'timeOut4')[0]);
            if (i != -1) {
              if (this.state.totals) {
                this.state.totals.splice(this.state.totals.indexOf(this.state.totals.filter(c => c.name === this.state.columns[i].name)[0]), 1);
              }
              this.state.columns.splice(i, 1);
            }
            break;
          default:
            break;
        }
      }
    }

    // Filtering data according to the Search Fields values
    for (let prop in this.state.searchEntity) {
      if (this.state.searchEntity[prop]) {
        if (!prop.toString().endsWith(',from') && !prop.toString().endsWith(',to')) {
          tableRawData = tableRawData.filter(row => {
            if (row[prop] != null) {
              return row[prop].toString().toLowerCase().includes(this.state.searchEntity[prop].toString().toLowerCase())

            } else {
              return ''.toLowerCase().includes(this.state.searchEntity[prop].toString().toLowerCase())
            }
          });
        } else {
          if (prop.toString().endsWith(',from')) {
            let backName = prop.split(',')[0];
            tableRawData = tableRawData.filter(row => {
              if (row[backName] != null) {
                let rowDate = new Date(row[backName]);
                let searchDate = new Date(this.state.searchEntity[prop])
                return rowDate >= searchDate;
              } else {
                return false;
              }
            });
          } else if (prop.toString().endsWith(',to')) {
            let backName = prop.split(',')[0];
            tableRawData = tableRawData.filter(row => {
              if (row[backName] != null) {
                let rowDate = new Date(row[backName]);
                let searchDate = new Date(this.state.searchEntity[prop])
                return rowDate <= searchDate;
              } else {
                return false;
              }
            });
          }
        }
      }
    }

    // Getting only the required columns for display + the row id
    tableRawData = tableRawData.map((row) => {
      let tablerow = {};
      for (let column of this.state.columns) {
        tablerow[column['backName']] = row[column['backName']];
      }
      tablerow.id = row.id;

      return tablerow;
    });

    // if the tableRawData length changed because of the search, update the rowsTotal for Paging
    if (tableRawData.length != this.state.rowsTotal) {
      this.setState({ rowsTotal: tableRawData.length })
    }

    // Creating the Breadcrumb links
    let Path = this.props.path.map((folder) => <Link className="TreeNav--" to={folder.path} key={folder.title}>{folder.title}</Link>);

    // Creating the SearchFields
    let SearchFields = this.props.searchFields.map(
      (field) => {
        let items;
        switch (field.type) {
          case 'text':
            return (
              <React.Fragment key={field.label}>
                <Field
                  title={`${field.label}:`}
                  titleSize={field.titleSize}
                  inputComponent={[
                    <TextInput
                      key={field.label}
                      placeholder={field.label}
                      type="text"
                      value={field.backSearch ? this.state.readEntity.search[field.backName] : (field.innerSearch ? this.state.searchEntity[field.backName + ',inner'] : this.state.searchEntity[field.backName])}
                      onChange={(value) => {
                        if (field.backSearch) {
                          let tempEntity = this.state.readEntity;
                          tempEntity.search[field.backName] = value;
                          this.setState({ readEntity: tempEntity });
                        } else if (field.innerSearch) {
                          this.ChangeEntityState('searchEntity', field.backName + ',inner', value)
                        } else {
                          this.ChangeEntityState('searchEntity', field.backName, value)
                        }

                      }}
                    />
                  ]}
                />
              </React.Fragment>
            )
          case 'date':
            return (
              <React.Fragment key={field.label}>
                <Field
                  title={`${field.label}:`}
                  titleSize={field.titleSize}
                  inputComponent={[
                    <TextInput
                      key={field.label}
                      arDate={true}
                      placeholder={field.label}
                      type="date"
                      value={field.backSearch ? this.state.readEntity.search[field.backName] : this.state.searchEntity[field.backName]}
                      onChange={(value) => {
                        if (field.backSearch) {
                          let tempEntity = this.state.readEntity;
                          tempEntity.search[field.backName] = value;
                          this.setState({ readEntity: tempEntity });
                        } else {
                          this.ChangeEntityState('searchEntity', field.backName, value)
                        }

                      }}
                    />
                  ]}
                />
              </React.Fragment>
            )
          case 'select':
            if (field.backSearch) {
              if (field.label === 'الإدارة') {
                items = this.state[field.label] === undefined ?
                  []
                  :
                  this.state[field.label].filter(management => management.branchIds=== this.state.readEntity.search[this.props.searchFields.find(field => field.label === 'الفرع').backName])
                    .map((item) => { return { label: item[field.item.label], value: item[field.item.value] } });
              } else if (field.label === 'القسم') {
                items = this.state[field.label] === undefined ?
                  []
                  :
                  this.state[field.label].filter(department => department.administrationId === this.state.readEntity.search[this.props.searchFields.find(field => field.label === 'الإدارة').backName])
                    .map((item) => { return { label: item[field.item.label], value: item[field.item.value] } });
              } else {
                items = this.state[field.label] === undefined ?
                  []
                  :
                  this.state[field.label].map((item) => { return { label: item[field.item.label], value: item[field.item.value] } });
              }
            } else {
              if (field.label === 'الإدارة') {
                if (field.innerSearch) {
                  items = this.state[field.label] === undefined ?
                    []
                    :
                    this.state[field.label].filter(management => management.branchIds=== this.state.searchEntity[this.props.searchFields.find(field => field.label === 'الفرع').backName + ',inner'])
                      .map((item) => { return { label: item[field.item.label], value: item[field.item.value] } });
                } else {
                  items = this.state[field.label] === undefined ?
                    []
                    :
                    this.state[field.label].filter(management => management.branchIds=== this.state.searchEntity[this.props.searchFields.find(field => field.label === 'الفرع').backName])
                      .map((item) => { return { label: item[field.item.label], value: item[field.item.value] } });
                }
              } else if (field.label === 'القسم') {
                if (field.innerSearch) {
                  items = this.state[field.label] === undefined ?
                    []
                    :
                    this.state[field.label].filter(department => department.administrationId === this.state.searchEntity[this.props.searchFields.find(field => field.label === 'الإدارة').backName + ',inner'])
                      .map((item) => { return { label: item[field.item.label], value: item[field.item.value] } });
                } else {
                  items = this.state[field.label] === undefined ?
                    []
                    :
                    this.state[field.label].filter(department => department.administrationId === this.state.searchEntity[this.props.searchFields.find(field => field.label === 'الإدارة').backName])
                      .map((item) => { return { label: item[field.item.label], value: item[field.item.value] } });
                }
              } else {
                items = this.state[field.label] === undefined ?
                  []
                  :
                  this.state[field.label].map((item) => { return { label: item[field.item.label], value: item[field.item.value] } });
              }
            }


            return (
              <Field
                key={field.label}
                title={`${field.label}:`}
                titleSize={field.titleSize}
                inputComponent={[
                  <Dropdown
                    rtl={true}
                    key={field.label}
                    placeholder={field.label}
                    options={items}
                    value={field.backSearch ? items.find(item => item.value === this.state.readEntity.search[field.backName]) : (field.innerSearch ? items.find(item => item.value === this.state.searchEntity[field.backName + ',inner']) : items.find(item => item.value === this.state.searchEntity[field.backName]))}
                    onChange={(value) => {
                      if (field.backSearch) {
                        let tempEntity = this.state.readEntity;
                        tempEntity.search[field.backName] = value;
                        this.setState({ readEntity: tempEntity });
                      } else if (field.innerSearch) {
                        this.ChangeEntityState('searchEntity', field.backName + ',inner', value)
                      } else {
                        this.ChangeEntityState('searchEntity', field.backName, value)
                      }
                    }}
                  />
                ]}
              />
            )
          case 'multiSelect':
            items = this.state[field.label] === undefined ?
              []
              :
              this.state[field.label].map((item) => {
                return {
                  label: item[field.item.label],
                  value: item[field.item.value]
                }
              });
            return (
              <Field
                key={field.label}
                title={`${field.label}:`}
                titleSize={field.titleSize}
                inputComponent={[
                  <Dropdown
                    rtl={true}
                    multi={true}
                    key={field.label}
                    placeholder={field.label}
                    options={items}
                    value={field.backSearch ?
                      items?.find(item => item.value === this.state.readEntity.search[field.backName]) :
                      (field.innerSearch ?
                        items.find(item => item.value === this.state.searchEntity[field.backName + ',inner']) :
                        items.find(item => item.value === this.state.searchEntity[field.backName]))}

                    onChange={(value) => {
                      if (field.backSearch) {
                        let tempEntity = this.state.readEntity;
                        tempEntity.search[field.backName] = value;

                        this.setState({ readEntity: tempEntity });
                      } else if (field.innerSearch) {
                        this.ChangeEntityState('searchEntity', field.backName + ',inner', value)
                      } else {
                        this.ChangeEntityState('searchEntity', field.backName, value)
                      }
                    }}
                  />
                ]}
              />
            );
          case 'staticSelect':
            return (
              <Field
                key={field.label}
                title={`${field.label}:`}
                titleSize={field.titleSize}
                inputComponent={[
                  <Dropdown
                    rtl={true}
                    key={field.label}
                    placeholder={field.label}
                    value={field.backSearch ? field.items.find(item => item.value === this.state.readEntity.search[field.backName]) : (field.innerSearch ? field.items.find(item => item.value === this.state.searchEntity[field.backName + ',inner']) : field.items.find(item => item.value === this.state.searchEntity[field.backName]))}
                    options={field.items}
                    onChange={(value) => {
                      if (field.backSearch) {
                        let tempEntity = this.state.readEntity;
                        tempEntity.search[field.backName] = value;
                        this.setState({ readEntity: tempEntity });
                      } else if (field.innerSearch) {
                        this.ChangeEntityState('searchEntity', field.backName + ',inner', value)
                      } else {
                        this.ChangeEntityState('searchEntity', field.backName, value)
                      }
                    }}
                  />
                ]}
              />
            )
          case 'dateFromTo':
            return (
              <React.Fragment key={'FromTo'}>
                <Field
                  title={`من:`}
                  titleSize={field.titleSize}
                  inputComponent={[
                    <TextInput
                      key={'From'}
                      placeholder={`Pick or type`}
                      type="date"
                      value={this.state.searchEntity[field.backName + ',from']}
                      onChange={(value) => this.ChangeEntityState('searchEntity', field.backName + ',from', value)}
                    />
                  ]}
                />
                <Field
                  title={`إلى:`}
                  titleSize={field.titleSize}
                  inputComponent={[
                    <TextInput
                      key={'To'}
                      placeholder={`Pick or type`}
                      type="date"
                      value={this.state.searchEntity[field.backName + ',to']}
                      onChange={(value) => this.ChangeEntityState('searchEntity', field.backName + ',to', value)}
                    />
                  ]}
                />
              </React.Fragment>
            )
          default:
            return (
              <Field
                key={field.label}
                title={`${field.label}:`}
                titleSize={field.titleSize}
                inputComponent={[
                  <TextInput
                    key={field.label}
                    placeholder={field.label}
                    type="text"
                    value={this.state.searchEntity[field.backName]}
                    onChange={(value) => this.ChangeEntityState('searchEntity', field.backName, value)}
                  />
                ]}
              />
            )
        }
      }
    );

    //if there is backSearch fields, then add the search button
    if (this.props.searchFields.some(field => field.backSearch === true)) {
      SearchFields.push(
        <div className="form-group col d-flex justify-content-end mt-3">
          <Button
            className="btn btn-success btn-block search ar"
            onClick={() => this.Read(this.state.readEntity)}
          >
            بحث
          </Button>
        </div>

      );
    }

    // Creating Table Headers
    let TableHeaders = this.state.columns.map((column, i) =>
      column.backName == 'timeIn1' ?
        <th colspan="2">الوردية الأولى </th>
        :
        column['backName'] == 'timeIn2' ?
          <th colspan="2">الوردية الثانية </th>
          :
          column['backName'] == 'timeIn3' ?
            <th colspan="2" >الوردية الثالثة </th>
            :
            column['backName'] == 'timeIn4' ?
              <th colspan="2">الوردية الربعة </th>
              :
              column['backName'] == 'timeOut1' ?
                undefined
                :
                column['backName'] == 'timeOut2' ?
                  undefined
                  :
                  column['backName'] == 'timeOut3' ?
                    undefined
                    :
                    column['backName'] == 'timeOut4' ?
                      undefined
                      :
                      <th key={column['backName']}> {column['name']} </th>
    );

    let TableHeaders2 = this.state.columns.some(c => c.backName == 'timeIn1') ? this.state.columns.map((column, i) =>
      column.backName == 'timeIn1' ?
        <td key={column['backName']} className="p-1"> <span class="badge agree p-5px" >حضور</span> </td>
        :
        column['backName'] == 'timeIn2' ?
          <td key={column['backName']} className="p-1"> <span class="badge agree p-5px">حضور</span> </td>
          :
          column['backName'] == 'timeIn3' ?
            <td key={column['backName']} className="p-1"> <span class="badge agree p-5px">حضور</span> </td>
            :
            column['backName'] == 'timeIn4' ?
              <td key={column['backName']} className="p-1"> <span class="badge agree p-5px">حضور</span> </td>
              :
              column['backName'] == 'timeOut1' ?
                <td key={column['backName']} className="p-1"> <span class="badge alert p-5px">انصراف</span> </td>
                :
                column['backName'] == 'timeOut2' ?
                  <td key={column['backName']} className="p-1"> <span class="badge alert p-5px">انصراف</span> </td>
                  :
                  column['backName'] == 'timeOut3' ?
                    <td key={column['backName']} className="p-1"> <span class="badge alert p-5px">انصراف</span> </td>
                    :
                    column['backName'] == 'timeOut4' ?
                      <td key={column['backName']} className="p-1"> <span class="badge alert p-5px">انصراف</span> </td>
                      :
                      <td ></td>

    ) : null;

    // check if we want the multi tables view or the single one
    let counter = 0;
    for (let i = 0; i < this.state.data.length; i++) {
      if (this.state.data[i].data) {
        counter = counter + 1
      }
    }

    if (counter > 0 && !this.props.colored) { // for multi tables:
      tablesData = this.state.data.slice()

      // The following filtering should be generic
      // =====================================================================
      // Filtering data according to the Search Fields values
      for (let prop in this.state.searchEntity) {
        if (this.state.searchEntity[prop]) {
          if (prop.toString().endsWith(',from') || prop.toString().endsWith(',to')) {
            if (prop.toString().endsWith(',from')) {
              let backName = prop.split(',')[0];
              tablesData = tablesData.filter(row => {
                if (row[backName] != null) {
                  let rowDate = new Date(row[backName]);
                  let searchDate = new Date(this.state.searchEntity[prop])
                  return rowDate >= searchDate;
                } else {
                  return false;
                }
              });
            } else if (prop.toString().endsWith(',to')) {
              let backName = prop.split(',')[0];
              tablesData = tablesData.filter(row => {
                if (row[backName] != null) {
                  let rowDate = new Date(row[backName]);
                  let searchDate = new Date(this.state.searchEntity[prop])
                  return rowDate <= searchDate;
                } else {
                  return false;
                }
              });
            }
          } else if (prop.toString().endsWith(',inner')) {
            let backName = prop.split(',')[0];
            tablesData = tablesData.map(row => {
              let newRow = Object.assign({}, row);
              newRow.data = row.data.filter(innerRow => {
                if (innerRow[backName] != null) {
                  return innerRow[backName].toString().toLowerCase().includes(this.state.searchEntity[prop].toString().toLowerCase())
                } else {
                  return false;
                }
              }).slice();
              return newRow;
            });
          } else {
            tablesData = tablesData.filter(row => {
              if (row[prop] != null) {
                return row[prop].toString().toLowerCase().includes(this.state.searchEntity[prop].toString().toLowerCase())

              } else {
                return ''.toLowerCase().includes(this.state.searchEntity[prop].toString().toLowerCase())
              }
            });
          }
        }
      }
      // =====================================================================

      // creating every table data component
      for (let i = 0; i < tablesData.length; i++) {
        // creating data located before every table
        if (this.props.preTableFields) {

          preTableFields = this.props.preTableFields.map((preTableField, j) => {
            return (
              <div style={{ margin: '0 5px', whiteSpace: 'nowrap' }} className={`d-flex`}>
                {j == 0 ? null :
                  this.props.preTableFields.length > 3 ?
                    null :
                    <p className='mb-0'> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </p>
                }
                <p className='mb-0'> {preTableField.name} &nbsp; : &nbsp;</p>
                {preTableField.backName == 'isShiftOpen' ?
                  <p className='mb-0'>{tablesData[i][preTableField.backName] ? "مفتوح" : ' عادي'}</p>


                  :
                  <p className='mb-0'>{tablesData[i][preTableField.backName] ? tablesData[i][preTableField.backName] : ' -'}</p>

                }
              </div>
            )
          })
        }
        let OverTableData = [];
        // creating table data
        if (this.props.overtime) {
          OverTableData = tablesData[i].data.map((raw) => {
            return (
              <tr key={raw.id}>
                {
                  this.state.columns.map((column) => {
                    return (

                      column.backName == 'date' ?
                        raw.data.map((col) =>
                          <td> {col.extraMinutes ? col.extraMinutes : 0}</td>
                        )
                        :
                        <td key={column.backName} >
                          {raw[column.backName] ? raw[column.backName] : ' - '}
                        </td>
                    )
                  })
                }
              </tr>
            )
          })
        }

        let DetailsTableData = [];
        // creating table data
        if (this.props.workingTimeDetails) {
          DetailsTableData = tablesData[i].data.map((raw) => {
            return this.state.columns.map((column) => {
              return (
                <tr>
                  <td style={{ width: '25%' }}>
                    {column.name}
                  </td>
                  <td>
                    {raw[column.backName] ? raw[column.backName] : ' - '}
                  </td>
                </tr>

              )
            });
          })
        }

        TableData = tablesData[i].data.map((raw) => {
          return (
            <tr key={raw.id}>
              {
                this.state.columns.map((column) => {
                  return (
                    column.boolean ?
                      <td key={column.backName}>
                        {
                          raw[column.backName] === true ?
                            <span className='badge alert working-time'>{column.yes}</span>
                            :
                            <span className='badge agree working-time'>{column.no}</span>
                        }
                      </td>
                      : column.enum ?
                        <td key={column.backName}>
                          {
                            column.enum.find((item) => item.value == raw[column.backName]) ?
                              column.enum.find((item) => item.value == raw[column.backName])['display']
                              :
                              raw[column.backName]
                          }
                        </td>
                        :

                        <td key={column.backName}>
                          {raw[column.backName]}
                        </td>
                  )
                })
              }
            </tr>
          )
        })

        // creating totals data if existing
        if (this.props.totals) {
          totals = [
            <tr className="totals">
              {
                this.state.totals.map((column, index) => {
                  return (
                    column.backName ?
                      <th className="total">{tablesData[i][column.backName]}</th>
                      :
                      index === 1 ?
                        <th className="empty">الإجمالي </th>
                        :
                        <th className="empty"></th>
                  )
                })
              }
            </tr>
          ]
        }
        // Don't Draw line after the last table
        let hr
        if (i === (tablesData.length - 1)) {
          hr = ""
        } else {
          hr = <hr></hr>
        }
        // collecting all data components
        if (TableData != false) { // Not to display empty tables
          tables[i] = [
            <div className="MainLayout--container">
              {
                this.props.period ?
                  <div className={`period row mt-2 ${tablesData[0]?.from && tablesData[0]?.to ? " justify-content-center" : ' justify-content-center'}`} style={{ textAlign: 'right', padding: '0px 10px' }}>
                    <div className={`d-flex ${this.props.preTableFields?.length == 0 ? "col-md-8" : ''} ${this.props.preTableFields?.length > 3 ? "justify-content-between col-md-12" : " "}`}> {preTableFields}
                      <span>&nbsp; &nbsp; &nbsp; &nbsp; </span>
                    </div>
                    {tablesData[0]?.from && tablesData[0]?.to ?
                      <p className={`mb-0 text-center col-md-4 ${this.props.preTableFields?.length > 3 ? "col-md-12" : " "}`} >
                        {this.props.abcentFrom ?
                          "الغياب خلال الفترة"
                          :
                          this.props.delay ? "التأخير خلال الفترة "
                            : "الفترة"}
                        <span>من :  </span>
                        <span> {tablesData[0]?.from} </span>
                        <span>&nbsp; </span>
                        <span>  إلى :</span>
                        <span> {tablesData[0]?.to} </span>
                      </p> : null
                    }

                  </div>
                  :
                  null
              }

              {this.props.overtime ?

                <Table TableHeaders={headers[i].header1} data={OverTableData} totals={totals} TableHeaders2={headers[i].header2} />

                :
                this.props.workingTimeDetails ?
                  <Table TableHeaders={[]} data={DetailsTableData} totals={[]} TableHeaders2={[]} />
                  :
                  <Table TableHeaders={TableHeaders} data={TableData} totals={totals} TableHeaders2={TableHeaders2} />

              }

            </div>,
            hr
          ]
        }
      }
    } else { // for single table:
      // First get the specified page of data then displays it properly
      let coloredTableData = [];
      if (this.props.colored) {
        coloredTableData = tableRawData.map((raw) => {
          return (
            <tr key={raw.id}>
              {
                this.state.columns.map((column) => {
                  return (
                    column.backName === 'data' ?
                      raw.data.map((col, i) =>
                        <td className={col.flag} style={{ background: col.flag }}> {col.timeIn ? col.timeIn : 0}</td>
                      )
                      :
                      <td  >
                        {raw[column.backName] ? raw[column.backName] : ' - '}
                      </td>
                  )
                })
              }
            </tr>
          )
        })
      }
      TableData = tableRawData.map(
        function (row) {
          return (
            <tr key={row.id}>
              {
                Object.keys(row)
                  .map((columnName, columnIndex) => {
                    if (columnName === 'id' && me.state.columns.find(column => column.backName === 'id') === undefined) {
                      return null;
                    }
                    return me.state.columns[columnIndex]['link'] ?
                      <td key={row[0] + ',' + columnIndex}>
                        <Link to={me.state.columns[columnIndex]['link']['to'].toString() + me.state.columns[columnIndex]['link']['routeValues'].map((value) => me.state.data.find(data => data[Object.keys(row)[0]] === row[Object.keys(row)[0]])[value]).join('/')}>
                          {
                            me.state.columns[columnIndex]['link']['icon'] ?
                              me.state.columns[columnIndex]['link']['icon']
                              :
                              me.state.columns[columnIndex]['link']['text'] ?
                                me.state.columns[columnIndex]['link']['text']
                                :
                                row[columnName]
                          }
                        </Link>
                      </td>
                      :
                      me.state.columns[columnIndex]['boolean'] ?
                        <td key={row[0] + ',' + columnIndex}>
                          {row[columnName] ? <span className='badge agree working-time'>نعم</span> : <span className='badge alert working-time'>لا</span>}
                        </td>
                        :
                        me.state.columns[columnIndex]['enum'] ?
                          <td key={row[0] + ',' + columnIndex}>
                            {me.state.columns[columnIndex]['enum'].find((item) => item.value == row[columnName]) ? me.state.columns[columnIndex]['enum'].find((item) => item.value == row[columnName])['display'] : row[columnName]}
                          </td>
                          :
                          <td key={row[0] + ',' + columnIndex}>
                            {row[columnName]}
                          </td>;
                  })
              }
            </tr>
          )
        }
      );
      tables = [
        <div className="MainLayout--container">
          {this.props.colored ?
            <div className="row justify-content-center colors">
              <div className="col mt-2"><div className="text-center" style={{ background: this.state.generalSettings?.data[0].perimmisionColor }}> اذن </div></div>
              <div className="col mt-2"><div className="text-center" style={{ background: this.state.generalSettings?.data[0].absentColor }}> غياب </div></div>
              <div className="col mt-2"><div className="text-center" style={{ background: this.state.generalSettings?.data[0].weekEndColor }}> عطلة اسبوعية </div></div>
              <div className="col mt-2"><div className="text-center" style={{ background: this.state.generalSettings?.data[0].workColor }}> مداوم </div></div>
              <div className="col mt-2"><div className="text-center" style={{ background: this.state.generalSettings?.data[0].vacationColor }}> اجازة </div></div>
              <div className="col mt-2"><div className="text-center" style={{ background: this.state.generalSettings?.data[0].earlyLeaveColor }}> انصراف مبكر </div></div>
              <div className="col mt-2"><div className="text-center" style={{ background: this.state.generalSettings?.data[0].lateColor }}> تأخير </div></div>
              <div className="col mt-2"><div className="text-center" style={{ background: this.state.generalSettings?.data[0].officialVacationColor }}> عطلة رسمية </div></div>
            </div>

            :
            null
          }
          {
            this.props.period ?

              <div className="period d-flex justify-content-center mt-2" style={{ textAlign: 'right', padding: '0px 10px' }}>
                {tableRawData.length > 0 ?
                  <p className='mb-0 text-center'>
                    {this.props.abcentFrom ?
                      "الغياب خلال الفترة "
                      :
                      this.props.delay ? " التأخير خلال الفترة  "
                        : " الفترة "}
                    <span >من:  </span>
                    <span> {from} </span>
                    <span>&nbsp; </span>
                    <span >  إلى:</span>
                    <span> {to} </span>
                  </p> : null}

              </div>
              :
              null
          }
          {this.props.colored ?
            <Table TableHeaders={coloredHeaders} data={coloredTableData} TableHeaders2={TableHeaders2} />
            :
            <Table TableHeaders={TableHeaders} data={TableData} TableHeaders2={TableHeaders2} />
          }
        </div>
      ]
    }

    return (
      <div className="ReportPage">
        {/* startOverlayComponents */}
        <Overlay visible={this.state.loading}>
          <Loading visible={this.state.loading} />
        </Overlay>
        {/* endOverlayComponents */}

        <div className="MainLayout--container">



          <PageHeaderAr
            excel={this.props.excel}
            Path={Path}
            excelData={tablesData ? tablesData.slice() : tableRawData}
            excelHeader={this.props.columns?.slice()}
            singleTable={this.props.singleTable}
            overtime={this.props.overtime}
            colored={this.props.colored}
            period={this.props.period}
            preTableFields={this.props.preTableFields?.slice()}
            title={this.props.title}
            mainTitle={this.props.mainTitle}
            hideFilters={() => hideFilters()}
            showFilters={() => showFilters()}
            printPDF={() => this.Print()}
            showModal={(name) => this.showModal(name)}
            //check for print permission
            print={this.props.print && this.props.permissions?.data.find(permission => permission.nameEn === 'Print') ? true : false}
            report={true}
            useSearchModal={this.props.useSearchModal ? true : false}
          />


          <div className="alerts">
            {
              this.state.message ?
                this.state.message['text'] ?
                  <Alert
                    className={"Alert " + this.state.message['style']}
                  >
                    {this.state.message['text']}
                  </Alert>
                  :
                  null
                :
                null
            }
          </div>
          <FiltersAr
            SearchFields={SearchFields}
            hideFilters={() => hideFilters()}
            showFilters={() => showFilters()}
            Clear={() => this.Clear()}
          />


          {/* <TableFooterAr
            // number of rows per page
            rowsNumber={this.state.readEntity.pageSize}
            // on change the value of the rows number input
            rowsNumberChange={(value) => {
              // if the value is less than 1, then make it 1 (can't be 0 or less)
              value = value > 1 ? value : 1;
              //assign the value to the pageSize property in readEntity
              this.ChangeEntityState('readEntity', 'pageSize', value);
              //return to the first page
              this.ChangeEntityState('readEntity', 'pageNumber', 0);
            }}
            //Total number of rows in all pages
            total={this.state.rowsTotal}
            //selected page
            currentPage={this.state.readEntity.pageNumber + 1}
            //on selecting a page to show+
            currentPageChange={(value) => {
              //in back-end the pages are numbered starting from 0 not from 1 so we convert the value to a number if it is a string and subtract it by 1
              this.ChangeEntityState('readEntity', 'pageNumber', Number(value) - 1);
              //get the new page from back-end
              this.Read(this.state.readEntity);
            }}
          /> */}
        </div>

        {

          tables
        }

      </div>
    );
  }
}

export default ReportPageAr;
