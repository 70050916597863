import { API, ip } from './APIs';
import React from 'react';

export const Branches = {
  mainTitle: 'الفروع',
  title: 'الفروع',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    // { label: 'الرقم', type: 'number', backName: 'id' },
    { label: 'الفروع', type: 'text', backName: 'nameAr' }
  ],
  columns: [
    // {
    //   name: 'الرقم',
    //   backName: 'id'
    // },
    {
      name: 'الفروع',
      backName: 'nameAr',
      link: { to: '/Ar/TAW/Main/Managements/', routeValues: ['id', 'nameAr'] }
    },
    {
      name: 'المدير',
      backName: 'managerName'
    },
    {
      name: 'الموظفون',
      backName: 'employeesCount'
    },
    {
      name: 'الإدارات',
      backName: 'administrationsCount'
    }
  ],
  createEntityProps: [
    { label: 'الإسم العربي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'الإسم الإنجليزي', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' },
    {
      label: 'المدير', type: 'select', validation: '', style: 'col-lg-6', backName: 'managerId', url: API.Employees.read,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    }
  ],
  updateEntityProps: [
    { label: 'الإسم العربي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'الإسم الإنجليزي', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' },
    {
      label: 'المدير', type: 'select', validation: '', style: 'col-lg-6', backName: 'managerId', url: API.Employees.read,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    }
  ],
  deleteMessage: 'All Related Managements and Departments will be Deleted, Are you sure you want to Delete this Branch?',
  create: {
    url: API.Branches.create,
    body: {
      nameAr: '',
      nameEn: '',
      managerId: ''
    },
    verb: 'POST'
  },
  read: {
    url: API.Branches.read,
    body: {
      pageSize: 10,
      pageNumber: 0,
      search: {
        "id": '',
        "nameEn": "",
        "nameAr": ""
      }
    },
    verb: 'POST'
  },
  update: {
    url: API.Branches.update,
    body: {
      id: '',
      nameAr: '',
      nameEn: '',
      managerId: ''
    },
    verb: 'POST'
  },
  delete: {
    url: API.Branches.delete,
    query: {
      branchId: ''
    },
    verb: 'POST'
  }
}
export const BranchesAr = {
  mainTitle: 'الفروع',
  title: 'الفروع',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    // { label: 'الرقم', type: 'number', backName: 'id' },
    { label: 'الفرع', type: 'text', backName: 'nameAr' }
  ],
  columns: [
    // {
    //   name: 'الرقم',
    //   backName: 'id'
    // },
    {
      name: 'الفرع',
      backName: 'nameAr',
      link: { to: '/Ar/TAW/Main/Managements/', routeValues: ['id', 'nameAr'] }
    },
    {
      name: 'المدير',
      backName: 'managerName'
    },
    {
      name: 'الموظفون',
      backName: 'employeesCount'
    },
    {
      name: 'الإدارات',
      backName: 'administrationsCount'
    }
  ],
  createEntityProps: [
    { label: 'الإسم العربي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'الإسم الإنجليزي', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' },
    {
      label: 'المدير', type: 'select', validation: '', style: '', backName: 'managerId', url: API.Employees.read,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    }
  ],
  updateEntityProps: [
    { label: 'الإسم العربي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'الإسم الإنجليزي', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' },
    {
      label: 'المدير', type: 'select', validation: '', style: '', backName: 'managerId', url: API.Employees.read,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    }
  ],
  deleteMessage: 'All Related Managements and Departments will be Deleted, Are you sure you want to Delete this Branch?',
  create: {
    url: API.Branches.create,
    body: {
      nameAr: '',
      nameEn: '',
      managerId: ''
    },
    verb: 'POST'
  },
  read: {
    url: API.Branches.read,
    body: {
      pageSize: 10,
      pageNumber: 0,
      search: {
      }
    },
    verb: 'POST'
  },
  update: {
    url: API.Branches.update,
    body: {
      id: '',
      nameAr: '',
      nameEn: '',
      managerId: ''
    },
    verb: 'POST'
  },
  delete: {
    url: API.Branches.delete,
    query: {
      branchId: ''
    },
    verb: 'POST'
  }
}
export const Jobs = {
  mainTitle: 'الوظائف',
  title: 'الوظائف',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    // { label: 'الرقم', type: 'number', backName: 'id' },
    { label: 'الإسم', type: 'text', backName: 'nameAr' }
  ],
  columns: [
    // {
    //   name: 'الرقم',
    //   backName: 'id'
    // },
    {
      name: 'الإسم',
      backName: 'nameAr'
    }
  ],
  createEntityProps: [
    { label: 'الإسم العربي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'الإسم الإنجليزي', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' }
  ],
  updateEntityProps: [
    { label: 'الإسم العربي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'الإسم الإنجليزي', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' }
  ],
  deleteMessage: 'هل تريد حذف هذا العنصر؟',
  create: {
    url: API.Jobs.create,
    body: {
      nameAr: '',
      nameEn: ''
    },
    verb: 'POST'
  },
  read: {
    url: API.Jobs.read,
    body: {
      pageSize: 10,
      pageNumber: 0,
      search: {
        "id": '',
        "nameAr": "",
        "nameEn": ""
      }
    },
    verb: 'POST'
  },
  update: {
    url: API.Jobs.update,
    body: {
      id: '',
      nameAr: '',
      nameEn: ''
    },
    verb: 'POST'
  },
  delete: {
    url: API.Jobs.delete,
    query: {
      jobId: ''
    },
    verb: 'POST'
  }
}
export const Projects = {
  mainTitle: 'المشاريع',
  title: 'المشاريع',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    // { label: 'الرقم', type: 'number', backName: 'id' },
    { label: 'الإسم', type: 'text', backName: 'nameAr' }
  ],
  columns: [
    // {
    //   name: 'الرقم',
    //   backName: 'id'
    // },
    {
      name: 'الإسم',
      backName: 'nameAr'
    }
  ],
  createEntityProps: [
    { label: 'الإسم العربي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'الإسم الإنجليزي', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' }
  ],
  updateEntityProps: [
    { label: 'الإسم العربي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'الإسم الإنجليزي', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' }
  ],
  deleteMessage: 'هل تريد حذف هذا العنصر؟',
  create: {
    url: API.Projects.create,
    body: {
      nameAr: '',
      nameEn: ''
    },
    verb: 'POST'
  },
  read: {
    url: API.Projects.read,
    body: {
      pageSize: 10,
      pageNumber: 0,
      search: {
        "id": '',
        "nameAr": "",
        "nameEn": ""
      }
    },
    verb: 'POST'
  },
  update: {
    url: API.Projects.update,
    body: {
      id: '',
      nameAr: '',
      nameEn: ''
    },
    verb: 'POST'
  },
  delete: {
    url: API.Projects.delete,
    query: {
      projectId: ''
    },
    verb: 'POST'
  }
}
export const Nationalities = {
  mainTitle: 'الجنسيات',
  title: 'الجنسيات',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    // { label: 'الرقم', type: 'number', backName: 'id' },
    { label: 'الإسم', type: 'text', backName: 'nameAr' }
  ],
  columns: [
    // {
    //   name: 'الرقم',
    //   backName: 'id'
    // },
    {
      name: 'الإسم',
      backName: 'nameAr'
    }
  ],
  createEntityProps: [
    { label: 'الإسم العربي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'الإسم الإنجليزي', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' }
  ],
  updateEntityProps: [
    { label: 'الإسم العربي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'الإسم الإنجليزي', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' }
  ],
  deleteMessage: 'هل تريد حذف هذا العنصر؟',
  create: {
    url: API.Nationalities.create,
    body: {
      nameAr: '',
      nameEn: ''
    },
    verb: 'POST'
  },
  read: {
    url: API.Nationalities.read,
    body: {
      pageSize: 10,
      pageNumber: 0,
      search: {
        "id": '',
        "nameAr": "",
        "nameEn": ""
      }
    },
    verb: 'POST'
  },
  update: {
    url: API.Nationalities.update,
    body: {
      id: '',
      nameAr: '',
      nameEn: ''
    },
    verb: 'POST'
  },
  delete: {
    url: API.Nationalities.delete,
    query: {
      jobId: ''
    },
    verb: 'POST'
  }
}
export const Tasks = {
  mainTitle: 'المهام',
  title: 'المهام',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    // { label: 'الرقم', type: 'number', backName: 'id' },
    { label: 'الإسم', type: 'text', backName: 'nameAr' }
  ],
  columns: [
    // {
    //   name: 'الرقم',
    //   backName: 'id'
    // },
    {
      name: 'الإسم',
      backName: 'nameAr'
    }
  ],
  createEntityProps: [
    { label: 'الإسم العربي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'الإسم الإنجليزي', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' }
  ],
  updateEntityProps: [
    { label: 'الإسم العربي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'الإسم الإنجليزي', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' }
  ],
  deleteMessage: 'هل تريد حذف هذا العنصر؟',
  create: {
    url: API.Tasks.create,
    body: {
      nameAr: '',
      nameEn: ''
    },
    verb: 'POST'
  },
  read: {
    url: API.Tasks.read,
    body: {
      pageSize: 10,
      pageNumber: 0,
      search: {
        "id": '',
        "nameAr": "",
        "nameEn": ""
      }
    },
    verb: 'POST'
  },
  update: {
    url: API.Tasks.update,
    body: {
      id: '',
      nameAr: '',
      nameEn: ''
    },
    verb: 'POST'
  },
  delete: {
    url: API.Tasks.delete,
    query: {
      jobId: ''
    },
    verb: 'POST'
  }
}
export const WorkingTime = {
  mainTitle: 'أوقات العمل',
  title: 'أوقات العمل',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    // { label: 'الرقم', type: 'number', backName: 'id' },
    { label: 'الإسم', type: 'text', backName: 'nameAr' }
  ],
  columns: [
    // { name: 'الرقم', backName: 'id' },
    { name: 'الإسم', backName: 'nameAr', link: { to: '/Ar/TAW/Main/WorkingTime/WorkingHours/', routeValues: ['id', 'isShiftOpen', 'nameAr'] } },
    { name: 'إجمالي الساعات', backName: 'totalHours' },
    { name: 'وقت انتهاء اليوم', backName: 'dayEndTime' },
    { name: 'دوام مفتوح', backName: 'isShiftOpen', boolean: true },
  ],
  createEntityProps: [
    { label: 'الإسم العربي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'الإسم الإنجليزي', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' },
    { label: 'دوام مفتوح', type: 'checkbox', validation: '', style: 'col-lg-6', noFormCont: true, backName: 'isShiftOpen' },
    { label: ['الدوام ينتهي اليوم التالي', 'وقت انتهاء اليوم'], type: 'shiftEndNextDay', validation: 'col-lg-6', titleSize: 'col-sm-6 ', style: 'col-lg-6', backName: ['isEndNextDay', 'dayEndTime'], inputType: 'time' }
  ],
  updateEntityProps: [
    { label: 'الإسم العربي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'الإسم الإنجليزي', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' },
    { label: 'دوام مفتوح', type: 'checkbox', validation: '', style: 'col-lg-6', noFormCont: true, backName: 'isShiftOpen' },
    { label: ['الدوام ينتهي اليوم التالي', 'وقت انتهاء اليوم'], type: 'shiftEndNextDay', validation: '', titleSize: 'col-sm-6 ', style: 'col-lg-6', backName: ['isEndNextDay', 'dayEndTime'], inputType: 'time' }
  ],
  deleteMessage: 'هل تريد حذف هذا العنصر؟',
  create: {
    url: API.Shifts.create,
    body: {
      "nameEn": "",
      "nameAr": "",
      "isShiftOpen": false,
      "isEndNextDay": false,
      "dayEndTime": ""
    },
    verb: 'POST'
  },
  read: {
    url: API.Shifts.search,
    body: {
      "pageSize": 10,
      "pageNumber": 0,
      "search": {
        "id": '',
        "nameAr": "",
        "nameEn": ""
      }
    },
    verb: 'POST'
  },
  update: {
    url: API.Shifts.update,
    body: {
      id: '',
      "nameEn": "",
      "nameAr": "",
      "isShiftOpen": false,
      "isEndNextDay": false,
      "dayEndTime": ""
    },
    verb: 'POST'
  },
  delete: {
    url: API.Shifts.delete,
    query: {
      hoursId: ''
    },
    verb: 'POST'
  }
}
export const Employees = {
  mainTitle: 'الموظفون',
  title: 'الموظفون',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'الكود', type: 'number', backName: 'employeeCode' },
    { label: 'الاسم', type: 'text', backName: 'nameAr' },
    {
      label: 'الفرع', type: 'select', backName: 'branchId', url: API.Branches.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الإدارة', type: 'select', backName: 'adminstratId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },

      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'القسم', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },

      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الدوام', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    }
  ],
  columns: [
    { name: 'الكود', backName: 'employeeCode' },
    { name: 'الاسم', backName: 'nameAr' },
    { name: 'الفرع', backName: 'branchName' },
    { name: 'الدوام', backName: 'shiftName' },
    { name: 'نشط', backName: 'active', boolean: true },


  ],
  createEntityProps: [
    { label: 'الكود', type: 'number', validation: 'Required', style: 'col-lg-5 pl-0 ml-5', backName: 'employeeCode' },
    { label: 'نشط', type: 'checkbox', validation: '', style: 'col-lg-6', noFormCont: true, backName: 'active' },
    { label: 'الإسم العربي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'الإسم الإنجليزي', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' },
    {
      label: 'الفرع', type: 'select', validation: 'Required', style: 'col-lg-6', backName: 'branchId', url: API.Branches.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الإدارة', type: 'select', validation: '', style: 'col-lg-6', backName: 'adminstratId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },

      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'القسم', type: 'select', validation: '', style: 'col-lg-6', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },

      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الدوام', type: 'select', validation: 'Required', style: 'col-lg-6', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الوظيفة', type: 'select', validation: '', style: 'col-lg-6', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'المجموعة', type: 'select', validation: '', style: 'col-lg-6', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },

      item: { value: 'id', label: 'nameAr' }
    },

    { label: 'رقم الهوية', type: 'text', validation: '', style: 'col-lg-6', backName: 'nationalityNumber' },
    { label: 'البريد الإلكتروني', type: 'email', validation: '', style: 'col-lg-6', backName: 'email' },
    {
      label: 'الجنسية', type: 'select', validation: '', style: 'col-lg-6', backName: 'nationalitiesId', url: ip + '/api/Nationalities/GetAllNationalitiesDropDown',
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'المهمة', type: 'select', validation: '', style: 'col-lg-6', backName: 'workTasksId', url: ip + '/api/WorkingTasks/GetAllTasksDropDown',
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'المشروع', type: 'select', validation: '', style: 'col-lg-6', backName: 'projectId', url: ip + '/api/Project/GetAllProjectsDropDown',
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    { label: 'رقم الهاتف', type: 'text', validation: '', style: 'col-lg-6', backName: 'phoneNumber' },
    {
      label: '', type: 'exceptions', validation: '', style: 'col-lg-12', backName: [
        'provideDelayDiscountingOvertime',
        'extraTimeBeforeWorking',
        'extraTimeAfterWorking',
        'addWorkingHoursOnHoliday',
        'logOutWithoutFingerprint']
    }
  ],
  updateEntityProps: [
    { label: 'الكود', type: 'number', validation: 'Required', style: 'col-lg-5 pl-0 ml-5', backName: 'employeeCode' },
    { label: 'نشط', type: 'checkbox', validation: '', style: 'col-lg-6', noFormCont: true, backName: 'active' },
    { label: 'الإسم العربي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'الإسم الإنجليزي', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' },
    {
      label: 'الفرع', type: 'select', validation: 'Required', style: 'col-lg-6', backName: 'branchId', url: API.Branches.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الإدارة', type: 'select', validation: '', style: 'col-lg-6', backName: 'adminstratId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'القسم', type: 'select', validation: '', style: 'col-lg-6', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },

      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الدوام', type: 'select', validation: 'Required', style: 'col-lg-6', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الوظيفة', type: 'select', validation: '', style: 'col-lg-6', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'المجموعة', type: 'select', validation: '', style: 'col-lg-6', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },

      item: { value: 'id', label: 'nameAr' }
    },
    { label: 'رقم الهوية', type: 'text', validation: '', style: 'col-lg-6', backName: 'nationalityNumber' },
    { label: 'البريد الإلكتروني', type: 'email', validation: '', style: 'col-lg-6', backName: 'email' },
    {
      label: 'الجنسية', type: 'select', validation: '', style: 'col-lg-6', backName: 'nationalitiesId', url: ip + '/api/Nationalities/GetAllNationalitiesDropDown',
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'المهمة', type: 'select', validation: '', style: 'col-lg-6', backName: 'workTasksId', url: ip + '/api/WorkingTasks/GetAllTasksDropDown',
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'المشروع', type: 'select', validation: '', style: 'col-lg-6', backName: 'projectId', url: ip + '/api/Project/GetAllProjectsDropDown',
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    { label: 'رقم الهاتف', type: 'text', validation: '', style: 'col-lg-6', backName: 'phoneNumber' },
    {
      label: '', type: 'exceptions', validation: '', style: 'col-lg-12', backName: [
        'provideDelayDiscountingOvertime',
        'extraTimeBeforeWorking',
        'extraTimeAfterWorking',
        'addWorkingHoursOnHoliday',
        'logOutWithoutFingerprint']
    }
  ],
  deleteMessage: 'هل تريد حذف هذا العنصر؟',
  create: {
    url: ip + '/api/Employee/AddEmployee',
    body: {
      "employeeCode": '',
      "nameEn": '',
      "nameAr": '',
      "phoneNumber": '',
      "placeStay": '',
      "nationalityNumber": '',
      "email": '',
      "branchId": '',
      "departmentId": '',
      "adminstratId": '',
      "jobId": '',
      "groupId": '',
      "projectId": '',
      "nationalitiesId": '',
      "workTasksId": '',
      "shiftId": '',
      "extraTimeAfterWorking": false,
      "extraTimeBeforeWorking": false,
      "addWorkingHoursOnHoliday": false,
      "logOutWithoutFingerprint": false,
      "provideDelayDiscountingOvertime": false,
      "active": true
    },
    verb: 'POST'
  },
  createUnrigestered: {
    url: ip + '/api/Employee/AddEmployee',
    body: {
      "employeeCode": '',
      "nameEn": '',
      "nameAr": '',
      "phoneNumber": '',
      "placeStay": '',
      "nationalityNumber": '',
      "email": '',
      "branchId": '',
      "departmentId": '',
      "adminstratId": '',
      "jobId": '',
      "groupId": '',
      "projectId": '',
      "nationalitiesId": '',
      "workTasksId": '',
      "shiftId": '',
      "extraTimeAfterWorking": false,
      "extraTimeBeforeWorking": false,
      "addWorkingHoursOnHoliday": false,
      "logOutWithoutFingerprint": false,
      "provideDelayDiscountingOvertime": false,
      "active": true
    },
    verb: 'POST'
  },
  read: {
    url: API.Employees.search,
    body: {
      pageSize: 10,
      pageNumber: 0,
      search: {
        "employeeCode": '',
        "nameEn": "",
        "nameAr": "",
        "phoneNumber": "",
        "placeStay": "",
        "email": "",
        "branchId": 0,
        "departmentId": 0,
        "adminstratId": 0,
        "jobId": 0,
        "projectId": 0,
        "shiftId": 0,
        "nationalitiesId": 0
      }
    },
    verb: 'POST'
  },
  update: {
    url: ip + '/api/Employee/UpdateEmployee',
    body: {
      "id": '',
      "employeeCode": '',
      "nameEn": '',
      "nameAr": '',
      "phoneNumber": '',
      "placeStay": '',
      "nationalityNumber": '',
      "email": '',
      "branchId": '',
      "departmentId": '',
      "adminstratId": '',
      "jobId": '',
      "groupId": '',
      "projectId": '',
      "nationalitiesId": '',
      "workTasksId": '',
      "shiftId": '',
      "extraTimeAfterWorking": false,
      "extraTimeBeforeWorking": false,
      "addWorkingHoursOnHoliday": false,
      "logOutWithoutFingerprint": false,
      "provideDelayDiscountingOvertime": false,
      "active": false
    },
    verb: 'POST'
  },
  delete: {
    url: ip + '/api/Employee/DeleteEmployee',
    query: {
      jobId: ''
    },
    verb: 'POST'
  }
}
export const EmployeesGroups = {
  mainTitle: 'مجموعات الموظفين',
  title: 'مجموعات الموظفين',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    // { label: 'الرقم', type: 'number', backName: 'id' },
    { label: 'الإسم', type: 'text', backName: 'nameAr' },
  ],
  columns: [
    // {
    //   name: 'الرقم',
    //   backName: 'id'
    // },
    {
      name: 'الإسم',
      backName: 'nameAr'
    },
    {
      name: 'الموظفون',
      backName: '',
      link: { to: '/Ar/TAW/Main/EmployeesGroups/Employees/', routeValues: ['id', 'nameAr'], text: 'الموظفون' }
    },
  ],
  createEntityProps: [
    { label: 'الإسم العربي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'الإسم الإنجليزي', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' }
  ],
  updateEntityProps: [
    { label: 'الإسم العربي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'الإسم الإنجليزي', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' }
  ],
  deleteMessage: 'هل تريد حذف هذا العنصر؟',
  create: {
    url: ip + '/api/EmployeeGroups/AddEmployeeGroups',
    body: {
      nameAr: '',
      nameEn: ''
    },
    verb: 'POST'
  },
  read: {
    url: ip + '/api/EmployeeGroups/SearchAndGetAllEmployeeGroups',
    body: {
      pageSize: 10,
      pageNumber: 0,
      search: {
        "id": '',
        "nameAr": "",
        "nameEn": ""
      }
    },
    verb: 'POST'
  },
  update: {
    url: ip + '/api/EmployeeGroups/UpdateEmployeeGroups',
    body: {
      id: '',
      nameAr: '',
      nameEn: ''
    },
    verb: 'POST'
  },
  delete: {
    url: ip + '/api/EmployeeGroups/DeleteEmployeeGroups',
    query: {
      EmployeeGroupId: ''
    },
    verb: 'POST'
  }
}
export const VacationsTypes = {
  mainTitle: 'أنواع الأجازات',
  title: 'أنواع الأجازات',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    // { label: 'الرقم', type: 'number', backName: 'id' },
    { label: 'الإسم', type: 'text', backName: 'nameAr' }
  ],
  columns: [
    // {
    //   name: 'الرقم',
    //   backName: 'id'
    // },
    {
      name: 'الإسم',
      backName: 'nameAr'
    }
  ],
  createEntityProps: [
    { label: 'الإسم العربي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'الإسم الإنجليزي', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' }
  ],
  updateEntityProps: [
    { label: 'الإسم العربي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'الإسم الإنجليزي', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' }
  ],
  deleteMessage: 'هل تريد حذف هذا العنصر؟',
  create: {
    url: API.VacationsTypes.create,
    body: {
      nameAr: '',
      nameEn: ''
    },
    verb: 'POST'
  },
  read: {
    url: API.VacationsTypes.read,
    body: {
      pageSize: 10,
      pageNumber: 0,
      search: {
        "id": '',
        "nameAr": "",
        "nameEn": ""
      }
    },
    verb: 'POST'
  },
  update: {
    url: API.VacationsTypes.update,
    body: {
      id: '',
      nameAr: '',
      nameEn: ''
    },
    verb: 'POST'
  },
  delete: {
    url: API.VacationsTypes.delete,
    query: {
      jobId: ''
    },
    verb: 'POST'
  }
}
export const OfficialHolidays = {
  mainTitle: 'العطلات الرسمية',
  title: 'العطلات الرسمية',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    //  // { label: 'الرقم', type: 'number', backName: 'id' },
    { label: 'الإسم', type: 'text', backName: 'nameAr' },
    { label: 'من', type: 'date', backName: 'from', backSearch: true },
    { label: 'إلى', type: 'date', backName: 'to', backSearch: true },
  ],
  columns: [
    // { name: 'الرقم', backName: 'id' },
    { name: 'الإسم', backName: 'nameAr', link: { to: '/Ar/TAW/Main/OfficialHolidays/', routeValues: ['id', 'nameAr'] } },
    { name: 'من', backName: 'from' },
    { name: 'إلى', backName: 'to' },
    { name: 'المدة', backName: 'officialVacationTime' },
    {
      name: 'الحالة', backName: 'status',
      enum: [
        { value: '1', display: <span className='badge warning status'>لم تبدأ بعد</span> },
        { value: '2', display: <span className='badge agree status'>بدأت</span> },
        { value: '3', display: <span className='badge alert status'>انتهت</span> }
      ]
    }
  ],
  createEntityProps: [
    { label: 'الإسم العربي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'الإسم الإنجليزي', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' },
    { label: 'من', type: 'date', validation: 'Required from', style: 'col-lg-6', backName: 'from' },
    { label: 'إلى', type: 'date', validation: 'Required to', style: 'col-lg-6', backName: 'to' }
  ],
  updateEntityProps: [
    { label: 'الإسم العربي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'الإسم الإنجليزي', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' },
    { label: 'من', type: 'date', validation: 'Required from', style: 'col-lg-6', backName: 'from' },
    { label: 'إلى', type: 'date', validation: 'Required to', style: 'col-lg-6', backName: 'to' }
  ],
  deleteMessage: 'هل تريد حذف هذا العنصر؟',
  create: {
    url: ip + '/api/OfficialHolidays/AddOfficialHolidays',
    body: {
      "nameEn": "",
      "nameAr": "",
      "from": "",
      "to": ""
    },
    verb: 'POST'
  },
  read: {
    url: ip + '/api/OfficialHolidays/SearchOfficialHolidays',
    body: {
      pageSize: 10,
      pageNumber: 0,
      search: {
        "id": '',
        "nameAr": "",
        "nameEn": ""
      }
    },
    verb: 'POST'
  },
  update: {
    url: ip + '/api/OfficialHolidays/UpdateOfficialHolidays',
    body: {
      "id": '',
      "nameEn": "",
      "nameAr": "",
      "from": "",
      "to": ""
    },
    verb: 'POST'
  },
  delete: {
    url: ip + '/api/OfficialHolidays/DeleteOfficialHolidays',
    query: {
      officialHolidaysId: ''
    },
    verb: 'POST'
  }
}
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
export const GetBranchTransactions = {
  mainTitle: 'سحب حركات الفروع',
  title: 'سحب حركات الفروع',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  columns: [
    {
      name: 'الحركات',
      backName: 'value'
    }
  ],
  actionEntityProps: [
    { label: 'من', type: 'date', validation: '', style: '', backName: 'from' },
    { label: 'إلى', type: 'date', validation: '', style: '', backName: 'to' },
    {
      label: 'الفروع', type: 'multiSelect', validation: '', style: '', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, backSearch: true
    }
  ],
  action: {
    url: ip + '/api/Transaction/ReadMachineTransaction',
    body: {
      "from": "",
      "to": "",
      "branchIds": [],
      "employeeCode": 0
    },
    verb: 'POST',
    readAction: true
  }
}

export const AddPermission = {
  mainTitle: 'إضافة إذن',
  title: 'إضافة إذن',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'كود الموظف', type: 'text', backName: 'employeeCode' },
    { label: 'إسم الموظف', type: 'text', backName: 'employeeName' },
    {
      label: 'الفرع', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, backSearch: true
    },
    {
      label: 'الإدارة', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },

      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'القسم', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الوظيفة', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'المجموعة', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },

      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الدوام', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'نوع الإذن', type: 'staticSelect', backName: 'permissionType',
      items: [
        { label: 'مؤقت', value: 1 },
        { label: 'حضور متأخر', value: 2 },
        { label: 'انصراف مبكر', value: 3 },
        { label: 'يوم كامل', value: 4 }
      ]
    },
    { label: 'من', type: 'date', backName: 'from', backSearch: true },
    { label: 'إلى', type: 'date', backName: 'to', backSearch: true }
  ],
  columns: [
    {
      name: 'كود الموظف',
      backName: 'employeeCode'
    },
    {
      name: 'إسم الموظف',
      backName: 'employeeName'
    },
    {
      name: 'التاريخ',
      backName: 'permissionDate'
    },
    {
      name: 'الفرع',
      backName: 'branchName'
    },
    {
      name: 'الدوام',
      backName: 'shiftName'
    },
    {
      name: 'نوع الإذن',
      backName: 'permissionType',
      enum: [{ value: '1', display: 'مؤقت' }, { value: '2', display: 'حضور متأخر' }, { value: '3', display: 'انصراف مبكر' }, { value: '4', display: 'يوم كامل' }]
    },
    {
      name: 'الوردية',
      backName: 'sequence',
      enum:
        [
          { value: '1', display: 'الأولى' },
          { value: '2', display: 'الثانية' },
          { value: '3', display: 'الثالثة' },
          { value: '4', display: 'الرابعة' }

        ]
    },
    {
      name: 'وقت الحضور',
      backName: 'timingOfEnty'
    },
    {
      name: 'وقت الانصراف',
      backName: 'timingOfExit'
    }
  ],
  createEntityProps: [
    {
      label: '', type: 'selectShift', validation: '', style: 'col-lg-6',
      backName: ['employeeId', 'permissionDate', { 'periodShiftId': '', 'periodShiftSecondId': '', 'periodShiftThirdId': '', 'periodShiftFourthId': '' }], url: API.Employees.read,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    { label: '', type: 'permissionType', validation: '', style: 'col-lg-6', backName: ['permissionType', 'timingOfEnty', 'timingOfExit'] },
    { label: 'ملاحظات', type: 'text', validation: '', style: 'col-lg-6', backName: 'notes' }
  ],
  updateEntityProps: [
    {
      label: '', type: 'selectShift', validation: '', style: 'col-lg-6', backName: ['employeeId', 'permissionDate', { 'periodShiftId': '', 'periodShiftSecondId': '', 'periodShiftThirdId': '', 'periodShiftFourthId': '' }], url: API.Employees.read,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    { label: '', type: 'permissionType', validation: '', style: 'col-lg-6', backName: ['permissionType', 'timingOfEnty', 'timingOfExit'] },
    { label: 'ملاحظات', type: 'text', validation: '', style: 'col-lg-6', backName: 'notes' }
  ],
  deleteMessage: 'هل تريد حذف هذا العنصر؟',
  create: {
    url: API.AddPermission.create,
    body: {
      "employeeId": '',
      "permissionType": '',
      "periodShiftId": '',
      "periodShiftSecondId": '',
      "periodShiftThirdId": '',
      "periodShiftFourthId": '',
      "permissionDate": '',
      "timingOfEnty": '',
      "timingOfExit": '',
      "notes": ""
    },
    verb: 'POST'
  },
  read: {
    url: API.AddPermission.search,
    body: {
      pageSize: 10,
      pageNumber: 0,
      search: {
        "id": '',
        "employeeId": 0,
        "employeeCode": '',
        "permissionType": 0,
        "branchName": "",
        "employeeName": "",
        "administrationName": "",
        "departmentName": "",
        "jobName": "",
        "groupName": "",
        "shiftName": "",
        "timingOfEnty": "",
        "timingOfExit": "",
        "from": "",
        "to": "",
        "branchIds": []
      }
    },
    verb: 'POST'
  },
  update: {
    url: API.AddPermission.update,
    body: {
      "id": '',
      "employeeId": '',
      "permissionType": '',
      "periodShiftId": '',
      "periodShiftSecondId": '',
      "periodShiftThirdId": '',
      "periodShiftFourthId": '',
      "permissionDate": '',
      "timingOfEnty": '',
      "timingOfExit": '',
      "notes": ""
    },
    verb: 'POST'
  },
  delete: {
    url: API.AddPermission.delete,
    query: {
      jobId: ''
    },
    verb: 'POST'
  }
}
export const AddVacation = {
  mainTitle: 'إضافة أجازة',
  title: 'إضافة أجازة',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'كود الموظف', type: 'text', backName: 'employeeNumber' },
    { label: 'إسم الموظف', type: 'text', backName: 'employeeName' },
    {
      label: 'الفرع', type: 'select', backName: 'branchId', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الإدارة', type: 'select', backName: 'adminstratId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'القسم', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الوظيفة', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'المجموعة', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الدوام', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'نوع الأجازة', type: 'select', backName: 'vocationId', url: API.VacationsTypes.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    { label: 'من', type: 'date', backName: 'from', backSearch: true },
    { label: 'إلى', type: 'date', backName: 'to', backSearch: true }
  ],
  columns: [
    {
      name: 'كود الموظف',
      backName: 'employeeNumber'
    },
    {
      name: 'إسم الموظف',
      backName: 'employeeName'
    },
    {
      name: 'الفرع',
      backName: 'branchName'
    },
    {
      name: 'نوع الأجازة',
      backName: 'vacationType'
    },
    {
      name: 'من',
      backName: 'dateFrom'
    },
    {
      name: 'إلى',
      backName: 'dateTo'
    },
    {
      name: 'المدة',
      backName: 'daysNumber'
    },
    {
      name: 'ملاحظات',
      backName: 'description'
    },
  ],
  createEntityProps: [
    {
      label: 'الموظف', type: 'select', validation: 'Required', style: 'col-lg-6', backName: 'employeeId', url: API.Employees.read,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'نوع الأجازة', type: 'select', validation: 'Required', style: 'col-lg-6', backName: 'vocationId', url: API.VacationsTypes.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    { label: 'من', type: 'date', validation: 'Required from', style: 'col-lg-6', backName: 'dateFrom' },
    { label: 'إلى', type: 'date', validation: 'Required to', style: 'col-lg-6', backName: 'dateTo' },
    { label: 'الملاحظات', type: 'text', validation: '', style: 'col-lg-12', backName: 'description' },
  ],
  updateEntityProps: [
    {
      label: 'الموظف', type: 'select', validation: 'Required', style: 'col-lg-6', backName: 'employeeId', url: API.Employees.read,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'نوع الأجازة', type: 'select', validation: 'Required', style: 'col-lg-6', backName: 'vocationId', url: API.VacationsTypes.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    { label: 'من', type: 'date', validation: 'Required from', style: 'col-lg-6', backName: 'dateFrom' },
    { label: 'إلى', type: 'date', validation: 'Required to', style: 'col-lg-6', backName: 'dateTo' },
    { label: 'الملاحظات', type: 'text', validation: '', style: 'col-lg-12', backName: 'description' },
  ],
  deleteMessage: 'هل تريد حذف هذا العنصر؟',
  create: {
    url: API.AddVacation.create,
    body: {
      "employeeId": 0,
      "vocationId": 0,
      "description": "",
      "dateFrom": "",
      "dateTo": ""
    },
    verb: 'POST'
  },
  read: {
    url: API.AddVacation.read,
    body: {
      pageSize: 10,
      pageNumber: 0,
      search: {
        "id": '',
        "employeeId": 0,
        "branchId": 0,
        "administrationId": 0,
        "departmentId": 0,
        "jobId": 0,
        "groupId": 0,
        "shiftId": 0,
        "from": "",
        "to": ""
      }
    },
    verb: 'POST'
  },
  update: {
    url: API.AddVacation.update,
    body: {
      "id": 0,
      "employeeId": 0,
      "vocationId": 0,
      "description": "",
      "dateFrom": "",
      "dateTo": ""
    },
    verb: 'POST'
  },
  delete: {
    url: API.AddVacation.delete,
    query: {
      employeevacationId: ''
    },
    verb: 'POST'
  }
}
export const TransferTransactions = {
  mainTitle: 'Transfer الحركات',
  title: 'Transfer الحركات',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  columns: [
    { name: 'الكود', backName: 'employeeCode' },
    { name: 'الموظفون', backName: 'nameAr' },
    { name: 'الفرع', backName: 'branchName' }
  ],
  read: {
    url: API.Employees.search,
    body: {
      pageSize: 100,
      pageNumber: 0,
      search: {
        "employeeCode": '',
        "nameEn": "",
        "nameAr": "",
        "phoneNumber": "",
        "placeStay": "",
        "email": "",
        "branchId": 0,
        "departmentId": 0,
        "adminstratId": 0,
        "jobId": 0,
        "projectId": 0,
        "shiftId": 0,
        "nationalitiesId": 0
      }
    },
    verb: 'POST'
  },
  actionEntityProps: [
    { label: 'من', type: 'date', validation: '', style: '', backName: 'from' },
    { label: 'إلى', type: 'date', validation: '', style: '', backName: 'to' },
    { label: 'الموظفون', type: 'searchModal', validation: '', style: '', backName: 'employeeIds' }
  ],
  btnText: 'سحب حركات الفروع',

}

export const CancelManualTransactions = {
  mainTitle: 'إلغاء الحركات المعدلة يدوياً',
  title: 'إلغاء الحركات المعدلة يدوياً',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  columns: [
    { name: 'الكود', backName: 'employeeCode' },
    { name: 'الموظفون', backName: 'nameAr' },
    { name: 'الفرع', backName: 'branchName' }
  ],
  read: {
    url: API.Employees.search,
    body: {
      pageSize: 100,
      pageNumber: 0,
      search: {
        "employeeCode": '',
        "nameEn": "",
        "nameAr": "",
        "phoneNumber": "",
        "placeStay": "",
        "email": "",
        "branchId": 0,
        "departmentId": 0,
        "adminstratId": 0,
        "jobId": 0,
        "projectId": 0,
        "shiftId": 0,
        "nationalitiesId": 0
      }
    },
    verb: 'POST'
  },
  actionEntityProps: [
    { label: 'من', type: 'date', validation: '', style: '', backName: 'from' },
    { label: 'إلى', type: 'date', validation: '', style: '', backName: 'to' },
    { label: 'الموظفون', type: 'searchModal', validation: '', style: '', backName: 'employeeIds' }
  ],
  btnText: 'سحب حركات الفروع',

}
export const AddEditTransactions = {
  mainTitle: 'إضافة و تعديل حركات',
  title: 'إضافة و تعديل حركات',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'كود الموظف', type: 'number', backName: 'employeeCode' },
    { label: 'إسم الموظف', type: 'text', backName: 'employeeName' },
    {
      // label: 'الفرع', type: 'select', backName: 'branchIdIn', url: API.Branches.dropdown,
      label: 'الفرع', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,

      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, backSearch: true
    },
    {
      label: 'الإدارة', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'القسم', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الدوام', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    { label: 'من', type: 'date', backName: 'from', backSearch: true },
    { label: 'إلى', type: 'date', backName: 'to', backSearch: true },
  ],
  columns: [
    // {
    //   name: 'الرقم',
    //   backName: 'id'
    // },
    {
      name: 'كود الموظف',
      backName: 'employeeCode'
    },
    {
      name: 'إسم الموظف',
      backName: 'employeeName'
    },
    {
      name: 'التاريخ',
      backName: 'date'
    },
    {
      name: 'وقت الحضور',
      backName: 'attendanceTime'
    },
    {
      name: 'وقت الانصراف',
      backName: 'leaveTime'
    },
  ],
  createEntityProps: [
    {
      label: 'الموظف', type: 'select', validation: 'Required', style: 'col-lg-6', backName: 'employeeId', url: API.Employees.read,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    { label: 'التاريخ', type: 'date', validation: 'Required', style: 'col-lg-6', backName: 'date' },
    { label: 'وقت الحضور', type: 'time', validation: 'orReq', noFormCont: true, RequiredCondition: 'leaveTime', style: 'col-lg-6', backName: 'attendanceTime' },

    {
      label: 'فرع الحضور', type: 'select', validation: 'ConditionalReq', RequiredCondition: 'attendanceTime', style: 'col-lg-6', backName: 'branchIdIn', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    { label: 'وقت الانصراف', type: 'time', validation: 'orReq', noFormCont: true, RequiredCondition: 'attendanceTime', style: 'col-lg-6', backName: 'leaveTime' },

    {
      label: 'فرع الانصراف', type: 'select', validation: 'ConditionalReq', RequiredCondition: 'leaveTime', style: 'col-lg-6', backName: 'branchIdOut', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    { label: 'كلمة مرور المسؤول', type: 'password', validation: 'Required', style: 'col-lg-6', backName: 'password' }
  ],
  updateEntityProps: [
    {
      label: 'الموظف', type: 'select', validation: 'Required', style: 'col-lg-6', backName: 'employeeId', url: API.Employees.read,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    { label: 'التاريخ', type: 'date', validation: 'Required', style: 'col-lg-6', backName: 'date' },
    { label: 'وقت الحضور', type: 'time', validation: '', style: 'col-lg-6', noFormCont: true, backName: 'attendanceTime' },

    {
      label: 'فرع الحضور', type: 'select', validation: 'Required', style: 'col-lg-6', backName: 'branchIdIn', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    { label: 'وقت الانصراف', type: 'time', validation: '', noFormCont: true, style: 'col-lg-6', backName: 'leaveTime' },

    {
      label: 'فرع الانصراف', type: 'select', validation: 'Required', style: 'col-lg-6', backName: 'branchIdOut', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    { label: 'كلمة مرور المسؤول', type: 'password', validation: 'Required', style: 'col-lg-6', backName: 'password' }
  ],
  deleteMessage: 'هل تريد حذف هذا العنصر؟',
  create: {
    url: API.AddEditTransactions.create,
    body: {
      employeeId: '',
      branchIdIn: '',
      branchIdOut: '',
      password: '',
      date: '',
      attendanceTime: '',
      leaveTime: ''
    },
    verb: 'POST'
  },
  read: {
    url: API.AddEditTransactions.read,
    body: {
      pageSize: 10,
      pageNumber: 0,
      search: {
        "from": "",
        "to": "",
        "branchIds": [
        ],
        "employeeIds": [
        ],
        "employeeCode": '',
        "employeeName": "",
        "branchId": 0,
        "administrationId": 0,
        "departmentId": 0,
        "shiftId": 0
      }
    },
    verb: 'POST'
  },
  update: {
    url: API.AddEditTransactions.update,
    body: {
      id: '',
      employeeId: '',
      branchIdIn: '',
      branchIdOut: '',
      password: '',
      date: '',
      attendanceTime: '',
      leaveTime: ''
    },
    verb: 'POST'
  },
  delete: {
    url: API.AddEditTransactions.delete,
    query: {
      transactionId: ''
    },
    verb: 'POST'
  }
}

// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
export const EmployeesReport = {

  mainTitle: 'تقرير الموظفين',
  title: 'تقرير الموظفين',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'كود الموظف', type: 'text', backName: 'employeeCode', backSearch: true },
    { label: 'إسم الموظف', type: 'text', backName: 'nameAr', backSearch: true },
    {
      label: 'الفرع', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, backSearch: true
    },
    {
      label: 'الإدارة', type: 'select', backName: 'adminstratId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'القسم', type: 'select', backName: 'departmentId', url: ip + '/api/Department/GetAllDepartmentDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, backSearch: true
    },
    {
      label: 'الوظيفة', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, backSearch: true
    },
    {
      label: 'المجموعة', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الدوام', type: 'select', backName: 'shiftId', url: ip + '/api/Shift/GetAllShiftDropdown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, backSearch: true
    }
  ],
  columns: [
    { name: 'كود الموظف', backName: 'employeeCode' },
    { name: 'إسم الموظف', backName: 'nameAr' },
    { name: 'الفرع', backName: 'branchName' },
    { name: 'الإدارة', backName: 'administrationName' },
    { name: 'القسم', backName: 'departmentName' },
    { name: 'الوظيفة', backName: 'jobName' },
    { name: 'المجموعة', backName: 'groupName' },
    { name: 'الدوام', backName: 'shiftName' }
  ],
  read: {
    url: API.EmployeesReport.read,
    body: {
      pageSize: 100,
      pageNumber: 0,
      "search": {
        "employeeCode": '',
        "nameEn": "",
        "nameAr": "",
        "phoneNumber": "",
        "placeStay": "",
        "email": "",
        "branchIds": [],
        "departmentId": 0,
        "adminstratId": 0,
        "jobId": 0,
        "projectId": 0,
        "shiftId": 0,
        "nationalitiesId": 0,
        "groupId": 0,
      }
    },
    verb: 'POST'
  },
  print: true
}
export const WorkingTimeReport = {
  mainTitle: ' تقرير اوقات الدوام',
  title: ' تقرير اوقات الدوام',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'الإسم', type: 'text', backName: 'nameAr' },
    {
      label: 'النوع', type: 'staticSelect', backName: 'isShiftOpen',
      items: [
        { label: 'عادي', value: false },
        { label: 'مفتوح', value: true },
        { label: 'الكل', value: null },
      ], backSearch: true
    }
  ],
  // workingTimeReport: true,
  preTableFields: [
    { name: 'رقم الدوام', backName: 'id' },
    { name: 'اسم الدوام', backName: 'nameAr' },
    { name: 'إجمالي الساعات', backName: 'totalHours' },
    { name: 'نوع  الدوام', backName: 'isShiftOpen' }
  ],
  columns: [
    {
      name: 'اليوم', backName: 'dayId', enum: [
        { value: '1', display: 'الإثنين' },
        { value: '2', display: 'الثلاثاء' },
        { value: '3', display: 'الأربعاء' },
        { value: '4', display: 'الخميس' },
        { value: '5', display: 'الجمعة' },
        { value: '6', display: 'السبت' },
        { value: '7', display: 'الأحد' }
      ]
    },
    { name: 'حضور وردية 1', backName: 'timeIn1' },
    { name: 'انصراف وردية 1', backName: 'timeOut1' },
    { name: 'حضور وردية 2', backName: 'timeIn2' },
    { name: 'انصراف وردية 2', backName: 'timeOut2' },
    { name: 'حضور وردية 3', backName: 'timeIn3' },
    { name: 'انصراف وردية 3', backName: 'timeOut3' },
    { name: 'حضور وردية 4', backName: 'timeIn4' },
    { name: 'انصراف وردية 4', backName: 'timeOut4' },
    { name: 'اجمالي الساعات  ', backName: 'totalDayHours' },
    {
      name: 'حالة اليوم',
      backName: 'isVacation',
      boolean: true,
      yes: 'عطلة',
      no: 'عمل'
    }
  ],
  read: {
    url: API.WorkingTimeReport.read,
    body: {
      "pageSize": 100,
      "pageNumber": 0,
      "search": {
        "nameAr": "",
        "nameEn": "",
        "isShiftOpen": null
      }
    },
    verb: 'POST'
  },
  print: true
}
export const DayStatusReport = {
  mainTitle: 'تقرير حالة اليوم',
  title: 'تقرير حالة اليوم',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'كود الموظف', type: 'text', backName: 'employeeCode', innerSearch: true },
    { label: 'إسم الموظف', type: 'text', backName: 'employeeName', innerSearch: true },
    {
      label: 'الفرع', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, backSearch: true
    },
    {
      label: 'الإدارة', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'القسم', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الوظيفة', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    {
      label: 'المجموعة', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    {
      label: 'الدوام', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    { label: 'من', type: 'date', backName: 'from', backSearch: true },
    { label: 'إلى', type: 'date', backName: 'to', backSearch: true },
  ],

  preTableFields: [
    { name: 'الفرع', backName: 'branchName' },
    { name: 'اليوم', backName: 'day' },
    { name: 'التاريخ', backName: 'date' }
  ],
  period: true,
  columns: [
    { name: 'كود الموظف', backName: 'employeeCode' },
    { name: 'إسم الموظف', backName: 'employeeName' },
    { name: 'الدوام', backName: 'shiftName' },
    { name: 'حضور وردية 1', backName: 'timeIn1' },
    { name: 'انصراف وردية 1', backName: 'timeOut1' },
    { name: 'حضور وردية 2', backName: 'timeIn2' },
    { name: 'انصراف وردية 2', backName: 'timeOut2' },
    { name: 'حضور وردية 3', backName: 'timeIn3' },
    { name: 'انصراف وردية 3', backName: 'timeOut3' },
    { name: 'حضور وردية 4', backName: 'timeIn4' },
    { name: 'انصراف وردية 4', backName: 'timeOut4' },
    { name: 'الدوام', backName: 'totalHourDay' },
    { name: 'التأخير', backName: 'hourDayDelayTime' },
    { name: 'الإضافي', backName: 'hourDayOverTime' },
    { name: 'ساعات العمل', backName: 'totalWorkingHours' },
    { name: 'حالة اليوم', backName: 'dayStatus' }
  ],
  read: {
    url: API.DayStatusReport.read,
    body: {
      "search": {
        "from": "",
        "to": "",
        "branchIds": [],
        "employeeIds": [
        ],
        "employeeName": ""
      }
    },
    verb: 'POST'
  },
  print: true
}
export const DetailedAttendanceReport = {
  mainTitle: 'تقرير حضور و انصراف التفصيلي',
  title: 'تقرير حضور و انصراف التفصيلي',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'كود الموظف', type: 'text', backName: 'employeeCode' },
    { label: 'إسم الموظف', type: 'text', backName: 'employeeName' },
    {
      label: 'الفرع', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, backSearch: true
    },
    {
      label: 'الإدارة', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'القسم', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الوظيفة', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'المجموعة', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الدوام', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    { label: 'من', type: 'date', backName: 'from', backSearch: true },
    { label: 'إلى', type: 'date', backName: 'to', backSearch: true }
  ],
  preTableFields: [
    { name: 'كود الموظف', backName: 'employeeCode', style: "col-lg-2" },
    { name: 'إسم الموظف', backName: 'employeeName', style: "col-lg-2" },
    { name: 'الفرع', backName: 'branchName', style: "col-lg-2 " },
    { name: 'الوظيفة', backName: 'jobName', style: "col-lg-2 " },
    { name: 'الدوام', backName: 'shiftName', style: "col-lg-2  " }
  ],
  period: true,
  columns: [
    { name: 'اليوم', backName: 'day' },
    { name: 'التاريخ', backName: 'date' },
    { name: 'حضور وردية 1', backName: 'timeIn1' },
    { name: 'انصراف وردية 1', backName: 'timeOut1' },
    { name: 'حضور وردية 2', backName: 'timeIn2' },
    { name: 'انصراف وردية 2', backName: 'timeOut2' },
    { name: 'حضور وردية 3', backName: 'timeIn3' },
    { name: 'انصراف وردية 3', backName: 'timeOut3' },
    { name: 'حضور وردية 4', backName: 'timeIn4' },
    { name: 'انصراف وردية 4', backName: 'timeOut4' },
    { name: 'الدوام', backName: 'totalHourDay' },
    { name: 'التأخير', backName: 'hourDayDelayTime' },
    { name: 'الإضافي', backName: 'hourDayOverTime' },
    { name: 'ساعات العمل', backName: 'totalWorkingHours' },
    { name: 'حالة اليوم', backName: 'dayStatus' }
  ],
  totals: [
    { name: 'اليوم', backName: '' },
    { name: 'التاريخ', backName: '' },
    { name: 'حضور وردية 1', backName: '' },
    { name: 'انصراف وردية 1', backName: '' },
    { name: 'حضور وردية 2', backName: '' },
    { name: 'انصراف وردية 2', backName: '' },
    { name: 'حضور وردية 3', backName: '' },
    { name: 'انصراف وردية 3', backName: '' },
    { name: 'حضور وردية 4', backName: '' },
    { name: 'انصراف وردية 4', backName: '' },
    { name: 'ساعات العمل', backName: 'totalWorkHour' },
    { name: 'ساعات التأخير', backName: 'totalHourDayDelayTime' },
    { name: 'ساعات الإضافي', backName: 'totalHourDayOverTime' },
    { name: 'مجموع ساعات العمل', backName: 'totalWorkingHoursDays' },
    { name: 'حالة اليوم', backName: '' }
  ],
  read: {
    url: API.DetailedAttendanceReport.read,
    body: {
      "search": {
        "from": "",
        "to": "",
        "branchIds": [],
        "employeeIds": [],
        "employeeName": ""
      }
    },
    verb: 'POST'
  },
  print: true
}
export const TotalAttendanceReport = {
  mainTitle: 'تقرير حضور و انصراف الإجمالي',
  title: 'تقرير حضور و انصراف الإجمالي',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'كود الموظف', type: 'text', backName: 'employeeCode', innerSearch: true },
    { label: 'إسم الموظف', type: 'text', backName: 'employeeName', innerSearch: true },
    {
      label: 'الفرع', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, backSearch: true
    },
    {
      label: 'الإدارة', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    {
      label: 'القسم', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    {
      label: 'الوظيفة', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    {
      label: 'المجموعة', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    {
      label: 'الدوام', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    { label: 'من', type: 'date', backName: 'from', backSearch: true },
    { label: 'إلى', type: 'date', backName: 'to', backSearch: true }
  ],
  period: true,
  columns: [
    { name: 'كود الموظف', backName: 'employeeCode' },
    { name: 'إسم الموظف', backName: 'employeeName' },
    { name: 'الفرع', backName: 'branchName' },
    { name: 'الوظيفة', backName: 'jobName' },
    { name: 'الدوام', backName: 'shiftName' },
    { name: 'ساعات الدوام', backName: 'shiftHours' },
    { name: 'التأخير', backName: 'lateHours' },
    { name: 'الغياب', backName: 'absentHours' },
    { name: 'الأجازات', backName: 'vacationsDays' },
    { name: 'الإضافي', backName: 'extraHours' },
    { name: 'ساعات العمل الفعلية', backName: 'workingHours' }
  ],
  read: {
    url: API.TotalAttendanceReport.read,
    body: {
      "search": {
        "from": "",
        "to": "",
        "branchIds": [],
        "employeeIds": [
        ],
        "employeeName": ""
      }
    },
    verb: 'POST'
  },
  print: true
}
export const AbsenceReport = {
  mainTitle: 'تقرير الغياب',
  title: 'تقرير الغياب',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'كود الموظف', type: 'text', backName: 'employeeCode' },
    { label: 'إسم الموظف', type: 'text', backName: 'employeeName' },
    {
      label: 'الفرع', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, backSearch: true
    },
    {
      label: 'الإدارة', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' },
    },
    {
      label: 'القسم', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الوظيفة', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'المجموعة', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الدوام', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    { label: 'من', type: 'date', backName: 'from', backSearch: true },
    { label: 'إلى', type: 'date', backName: 'to', backSearch: true }
  ],
  columns: [
    { name: 'اليوم', backName: 'day' },
    { name: 'التاريخ', backName: 'date' },
    { name: 'كود الموظف', backName: 'employeeCode' },
    { name: 'إسم الموظف', backName: 'employeeName' },
    { name: 'الفرع', backName: 'branchName' },
    { name: 'الإدارة', backName: 'administrationName' },
    { name: 'القسم', backName: 'departmentName' },
    { name: 'الوظيفة', backName: 'jobName' },
    { name: 'الدوام', backName: 'shiftName' }
  ],
  read: {
    url: API.AbsenceReport.read,
    body: {
      "search": {
        "from": "",
        "to": "",
        "branchIds": [],
        "employeeIds": [
        ],
        "employeeName": ""
      }
    },
    verb: 'POST'
  },
  print: true,
  period: true
}
export const DelayReport = {
  mainTitle: 'تقرير التأخير ',
  title: '  تقرير التأخير',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'كود الموظف', type: 'text', backName: 'employeeCode' },
    { label: 'إسم الموظف', type: 'text', backName: 'employeeName' },
    {
      label: 'الفرع', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, backSearch: true
    },
    {
      label: 'الإدارة', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'القسم', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الوظيفة', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'المجموعة', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الدوام', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    { label: 'من', type: 'date', backName: 'from', backSearch: true },
    { label: 'إلى', type: 'date', backName: 'to', backSearch: true }
  ],
  period: true,
  columns: [
    { name: 'اليوم', backName: 'day' },
    { name: 'التاريخ', backName: 'date' },
    { name: 'كود الموظف', backName: 'employeeCode' },
    { name: 'إسم الموظف', backName: 'employeeName' },
    { name: 'الفرع', backName: 'branchName' },
    { name: 'الدوام', backName: 'shiftName' },
    { name: 'ساعات التأخير', backName: 'lateHours' }
  ],
  read: {
    url: API.DelayReport.read,
    body: {
      "pageSize": 1000,
      "pageNumber": 0,
      "search": {
        "from": "",
        "to": "",
        "branchIds": [],
        "employeeIds": [],
        "employeeCode": '',
        "employeeName": "",
        "branchId": 0,
        "administrationId": 0,
        "departmentId": 0,
        "shiftId": 0
      }
    },
    verb: 'POST'
  },
  print: true
}
export const InCompleteTransReport = {
  mainTitle: 'تقرير الحركات الغير مكتملة',
  title: 'تقرير الحركات الغير مكتملة',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'كود الموظف', type: 'text', backName: 'employeeCode', innerSearch: true },
    { label: 'إسم الموظف', type: 'text', backName: 'employeeName', innerSearch: true },
    {
      label: 'الفرع', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, backSearch: true
    },
    {
      label: 'الإدارة', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    {
      label: 'القسم', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    {
      label: 'الوظيفة', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    {
      label: 'المجموعة', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    {
      label: 'الدوام', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    { label: 'من', type: 'date', backName: 'from', backSearch: true },
    { label: 'إلى', type: 'date', backName: 'to', backSearch: true }
  ],
  preTableFields: [
    { name: 'اليوم', backName: 'day' },
    { name: 'التاريخ', backName: 'date' }
  ],
  columns: [
    { name: 'كود الموظف', backName: 'employeeCode' },
    { name: 'إسم الموظف', backName: 'employeeName' },
    { name: 'الفرع', backName: 'branchName' },
    { name: 'الدوام', backName: 'shiftName' },
    { name: 'الوردية', backName: 'seq' },
    { name: 'وقت الحضور', backName: 'timeIn' },
    { name: 'وقت الانصراف ', backName: 'timeOut' }
  ],
  read: {
    url: API.InCompleteTransReport.read,
    body: {
      "pageSize": 0,
      "pageNumber": 0,
      "search": {
        "from": "",
        "to": "",
        "branchIds": [],
        "employeeIds": [],
        "employeeCode": '',
        "employeeName": "",
        "branchId": 0,
        "administrationId": 0,
        "departmentId": 0,
        "shiftId": 0
      }
    },
    verb: 'POST'
  },
  print: true,
  period: true
}
export const EarlyAttendanceReport = {
  mainTitle: '  تقرير الحضور المبكر ',
  title: ' تقرير الحضور المبكر  ',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'كود الموظف', type: 'text', backName: 'employeeCode', innerSearch: true },
    { label: 'إسم الموظف', type: 'text', backName: 'employeeName', innerSearch: true },
    {
      label: 'الفرع', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, backSearch: true
    },
    {
      label: 'الإدارة', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    {
      label: 'القسم', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    {
      label: 'الوظيفة', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    {
      label: 'المجموعة', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    {
      label: 'الدوام', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    { label: 'من', type: 'date', backName: 'from', backSearch: true },
    { label: 'إلى', type: 'date', backName: 'to', backSearch: true }
  ],
  preTableFields: [
    { name: 'اليوم', backName: 'day' },
    { name: 'التاريخ', backName: 'date' }
  ],
  columns: [
    { name: 'كود الموظف', backName: 'employeeCode' },
    { name: 'إسم الموظف', backName: 'employeeName' },
    { name: 'الفرع', backName: 'branchName' },
    { name: 'الدوام', backName: 'shiftName' },
    { name: 'الوردية', backName: 'seq' },
    { name: 'وقت الحضور', backName: 'timeIn' },
    { name: 'وقت الوردية', backName: 'timeInShift' }
  ],
  read: {
    url: API.EarlyAttendanceReport.read,
    body: {
      "pageSize": 0,
      "pageNumber": 0,
      "search": {
        "from": "",
        "to": "",
        "branchIds": [],
        "employeeIds": [],
        "employeeCode": 0,
        "employeeName": "",
        "administrationId": 0,
        "departmentId": 0,
        "shiftId": 0
      }
    },
    verb: 'POST'
  },
  print: true,
  period: true
}
export const AttendanceSummaryReport = {
  mainTitle: 'ملخص تقرير الحضور',
  title: 'ملخص تقرير الحضور',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'كود الموظف', type: 'text', backName: 'employeeCode' },
    { label: 'إسم الموظف', type: 'text', backName: 'employeeName' },
    {
      label: 'الفرع', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, backSearch: true
    },
    {
      label: 'الإدارة', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, backSearch: true
    },
    {
      label: 'القسم', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الوظيفة', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'المجموعة', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الدوام', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    { label: 'من', type: 'date', backName: 'from', backSearch: true },
    { label: 'إلى', type: 'date', backName: 'to', backSearch: true }
  ],
  period: true,
  columns: [
    { name: 'كود الموظف', backName: 'employeeCode' },
    { name: 'إسم الموظف', backName: 'employeeName' },
    { name: 'التاريخ', backName: 'data' },
    { name: 'أيام العمل', backName: 'workingDays' }
  ],
  read: {
    url: API.AttendanceSummaryReport.read,
    body: {
      "pageSize": 0,
      "pageNumber": 0,
      "search": {
        "from": "",
        "to": "",
        "branchIds": [],
        "employeeIds": [],
        "employeeCode": 0,
        "employeeName": "",
        "branchId": 0,
        "administrationId": 0,
        "departmentId": 0,
        "shiftId": 0
      }
    },
    verb: 'POST'
  },
  print: true,
  // preTableFields: [
  //   { name: 'كود الموظف', backName: 'employeeCode' },
  // ],
}
export const TotalAttendanceSummaryReport = {
  mainTitle: ' ملخص تقرير الحضور الكلي للفروع',
  title: ' ملخص تقرير الحضور الكلي للفروع',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'كود الموظف', type: 'text', backName: 'employeeCode', innerSearch: true },
    { label: 'إسم الموظف', type: 'text', backName: 'employeeName', innerSearch: true },
    {
      label: 'الفرع', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, backSearch: true
    },
    {
      label: 'الإدارة', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    {
      label: 'القسم', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    {
      label: 'الوظيفة', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    {
      label: 'المجموعة', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    {
      label: 'الدوام', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    { label: 'من', type: 'date', backName: 'from', backSearch: true },
    { label: 'إلى', type: 'date', backName: 'to', backSearch: true }
  ],
  preTableFields: [
    { name: 'الفرع', backName: 'branchName' }
  ],
  period: true,
  columns: [
    { name: 'كود الموظف', backName: 'employeeCode' },
    { name: 'إسم الموظف', backName: 'employeeName' },
    { name: 'الوظيفة', backName: 'jobName' },
    { name: 'الدوام', backName: 'shiftName' },
    { name: 'س.ع الافتراضيه', backName: 'shiftHours' },
    { name: 'التأخير', backName: 'lateHours' },
    { name: '  الانصراف مبكر', backName: 'earlyLeave' },
    { name: 'الغياب', backName: 'absentHours' },
    { name: 'الأجازات', backName: 'vacationsDays' },
    { name: 'الإضافي', backName: 'extraHours' },
    { name: 'س.ع الفعلية ', backName: 'workingHours' },
    { name: 'النسبة  ', backName: 'rate' }
  ],
  read: {
    url: API.TotalAttendanceSummaryReport.read,
    body: {
      "pageSize": 0,
      "pageNumber": 0,
      "search": {
        "from": "",
        "to": "",
        "branchIds": [],
        "employeeIds": [],
        "employeeCode": 0,
        "employeeName": "",
        "branchId": 0,
        "administrationId": 0,
        "departmentId": 0,
        "shiftId": 0
      }
    },
    verb: 'POST'
  },
  print: true
}
export const OvertimeReport = {
  mainTitle: 'تقرير الاضافي',
  title: 'تقرير الاضافي',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'كود الموظف', type: 'text', backName: 'employeeCode', innerSearch: true },
    { label: 'إسم الموظف', type: 'text', backName: 'employeeName', innerSearch: true },
    {
      label: 'الفرع', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown, innerSearch: true,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, backSearch: true
    },
    {
      label: 'الإدارة', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    {
      label: 'القسم', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    {
      label: 'الوظيفة', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    {
      label: 'المجموعة', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    {
      label: 'الدوام', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    { label: 'من', type: 'date', backName: 'from', backSearch: true },
    { label: 'إلى', type: 'date', backName: 'to', backSearch: true }
  ],
  preTableFields: [
    { name: 'الفرع', backName: 'branchName' }
  ],
  period: true,
  columns: [
    { name: 'كود الموظف', backName: 'employeeCode' },
    { name: 'إسم الموظف', backName: 'employeeName' },
    { name: 'التاريخ', backName: 'date' },
    { name: 'اجمالي الساعات', backName: 'totalExtraHours' }
  ],
  read: {
    url: API.OvertimeReport.read,
    body: {
      "pageSize": 0,
      "pageNumber": 0,
      "search": {
        "from": "",
        "to": "",
        "branchIds": [],
        "employeeIds": [],
        "employeeCode": 0,
        "employeeName": "",
        "branchId": 0,
        "administrationId": 0,
        "departmentId": 0,
        "shiftId": 0
      }
    },
    verb: 'POST'
  },
  print: true
}
export const WorkingTimeDetailsReport = {
  mainTitle: 'تقرير دوام الموظفين تفصيلي',
  title: 'تقرير دوام الموظفين تفصيلي',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'كود الموظف', type: 'text', backName: 'employeeCode' },
    { label: 'إسم الموظف', type: 'text', backName: 'employeeName' },
    {
      label: 'الفرع', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, backSearch: true
    },
    {
      label: 'الإدارة', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'القسم', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الوظيفة', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'المجموعة', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الدوام', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    { label: 'من', type: 'date', backName: 'from', backSearch: true },
    { label: 'إلى', type: 'date', backName: 'to', backSearch: true }
  ],
  preTableFields: [
    { name: 'كود الموظف', backName: 'employeeCode' },
    { name: 'إسم الموظف', backName: 'employeeName' },
    { name: 'الفرع', backName: 'branchName' },
    { name: 'الإدارة', backName: 'administrationName' },
    { name: 'القسم', backName: 'departmentName' },
    { name: 'الوظيفة', backName: 'jobName' },
    { name: 'الدوام', backName: 'shiftName' }
  ],
  period: true,
  columns: [
    { name: 'عدد أيام الفترة', backName: 'shiftDays' },
    { name: 'عدد أيام الدوام خلال الفترة', backName: 'attendanceDaysPeriod' },
    { name: 'عدد أيام الأجازات', backName: 'vacationsDays' },
    { name: 'عدد أيام الغياب', backName: 'absentDays' },
    { name: 'عدد ساعات الدوام', backName: 'shiftHours' },
    { name: 'عدد ساعات الحضور الفعلي', backName: 'workingHours' },
    { name: 'عدد ساعات التأخير', backName: 'lateHours' },
    { name: 'عدد ساعات الإضافي', backName: 'extraHours' },
    { name: 'عدد ساعات الإضافي أيام الأجازات', backName: 'extraHoursInVactions' }
  ],
  read: {
    url: API.WorkingTimeDetailsReport.read,
    body: {
      "pageSize": 1000,
      "pageNumber": 0,
      "search": {
        "from": "",
        "to": "",
        "branchIds": [],
        "employeeIds": [
        ],
        "employeeCode": 0,
        "employeeName": "",
        "branchId": 0,
        "administrationId": 0,
        "departmentId": 0,
        "shiftId": 0
      }
    },
    verb: 'POST'
  },
  print: true
}
export const BranchAttendanceReport = {
  mainTitle: 'الحضور والانصراف بالفروع',
  title: 'الحضور والانصراف بالفروع',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'كود الموظف', type: 'text', backName: 'employeeCode' },
    { label: 'إسم الموظف', type: 'text', backName: 'employeeName' },
    {
      label: 'الفرع', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, backSearch: true
    },
    {
      label: 'الإدارة', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'القسم', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الوظيفة', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'المجموعة', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, backSearch: true
    },
    {
      label: 'الدوام', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 1000,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    { label: 'من', type: 'date', backName: 'from', backSearch: true },
    { label: 'إلى', type: 'date', backName: 'to', backSearch: true }
  ],
  preTableFields: [
    { name: 'كود الموظف', backName: 'employeeCode' },
    { name: 'إسم الموظف', backName: 'employeeName' },
    { name: 'الفرع', backName: 'branchName' },
    { name: 'الوظيفة', backName: 'JobName' },
    { name: 'الدوام', backName: 'shiftName' }
  ],
  period: true,
  columns: [
    { name: 'اليوم', backName: 'day' },
    { name: 'التاريخ', backName: 'date' },
    { name: 'الدوام', backName: 'shiftName' },
    { name: 'حضور وردية 1', backName: 'timeIn1' },
    { name: 'انصراف وردية 1', backName: 'timeOut1' },
    { name: 'حضور وردية 2', backName: 'timeIn2' },
    { name: 'انصراف وردية 2', backName: 'timeOut2' },
    { name: 'حضور وردية 3', backName: 'timeIn3' },
    { name: 'انصراف وردية 3', backName: 'timeOut3' },
    { name: 'حضور وردية 4', backName: 'timeIn4' },
    { name: 'انصراف وردية 4', backName: 'timeOut4' },
    { name: 'الفرع', backName: 'branchName' }
  ],
  read: {
    url: API.BranchAttendanceReport.read,
    body: {
      "pageSize": 0,
      "pageNumber": 0,
      "search": {
        "from": "",
        "to": "",
        "branchIds": [],
        "employeeIds": [],
        "employeeCode": 0,
        "employeeName": "",
        "branchId": 0,
        "administrationId": 0,
        "departmentId": 0,
        "shiftId": 0,
        "groupId": 0,
      }
    },
    verb: 'POST'
  },
  print: true
}
export const VacationsReport = {
  mainTitle: 'اجازات الموظفين ',
  title: 'اجازات الموظفين ',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'كود الموظف', type: 'text', backName: 'employeeCode' },
    { label: 'إسم الموظف', type: 'text', backName: 'employeeName' },
    {
      label: 'الفرع', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, backSearch: true
    },
    {
      label: 'الإدارة', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'القسم', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الوظيفة', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'المجموعة', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الدوام', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    { label: 'من', type: 'date', backName: 'from', backSearch: true },
    { label: 'إلى', type: 'date', backName: 'to', backSearch: true },
    {
      label: 'نوع الأجازة', type: 'select', backName: '', url: API.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    }
  ],
  preTableFields: [
    { name: 'كود الموظف', backName: 'employeeCode' },
    { name: 'إسم الموظف', backName: 'employeeName' },
    { name: 'الفرع', backName: 'branchName' },
    { name: 'الوظيفة', backName: 'JobName' },
    { name: 'الدوام', backName: 'shiftName' }
  ],
  period: true,
  columns: [
    { name: 'نوع الأجازة', backName: 'vacationType' },
    { name: 'تاريخ البداية', backName: 'dateFrom' },
    { name: 'تاريخ النهاية', backName: 'dateTo' },
    { name: 'المدة', backName: 'daysNumber' },
    { name: 'ملاحظات', backName: 'description' }
  ],
  totals: [
    { name: 'نوع الأجازة', backName: '' },
    { name: 'Starts', backName: '' },
    { name: 'Ends', backName: '' },
    { name: 'Period', backName: 'totalOfVacations' },
    { name: 'ملاحظات', backName: '' }
  ],

  read: {
    url: API.VacationsReport.read,
    body: {
      "pageSize": 100,
      "pageNumber": 0,
      "search": {}
    },
    verb: 'POST'
  },
  print: true
}
export const PermissionsReport = {
  mainTitle: 'تقرير اذونات الموظفين',
  title: 'تقرير اذونات الموظفين',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'كود الموظف', type: 'text', backName: 'employeeCode' },
    { label: 'إسم الموظف', type: 'text', backName: 'employeeName' },
    {
      label: 'الفرع', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, backSearch: true
    },
    {
      label: 'الإدارة', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'القسم', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الوظيفة', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'المجموعة', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الدوام', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    { label: 'من', type: 'date', backName: 'from', backSearch: true },
    { label: 'إلى', type: 'date', backName: 'to', backSearch: true },
    {
      label: 'نوع الأذن', type: 'select', backName: '', url: API.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    }
  ],
  preTableFields: [
    { name: 'كود الموظف', backName: 'employeeCode' },
    { name: 'إسم الموظف', backName: 'employeeName' },
    { name: 'الفرع', backName: 'branchName' },
    { name: 'الوظيفة', backName: 'JobName' },
    { name: 'الدوام', backName: 'shiftName' }
  ],
  period: true,
  columns: [
    { name: 'اليوم', backName: 'day' },
    { name: 'التاريخ', backName: 'permissionDate' },
    {
      name: 'نوع الإذن',
      backName: 'permissionType',
      enum: [
        { value: '1', display: 'مؤقت' },
        { value: '2', display: 'حضور متأخر' },
        { value: '3', display: 'انصراف مبكر' },
        { value: '4', display: 'يوم كامل' }
      ]
    },
    {
      name: 'الوردية',
      backName: 'sequence',
      enum: [
        { value: '1', display: 'الأولى' },
        { value: '2', display: 'الثانية' },
        { value: '3', display: 'الثالثة' },
        { value: '4', display: 'الرابعة' }
      ]
    },
    { name: 'وقت الحضور', backName: 'timingOfEnty' },
    { name: 'وقت الانصراف', backName: 'timingOfExit' },
    { name: 'ملاحظات', backName: 'notes' }
  ],
  read: {
    url: API.PermissionsReport.read,
    body: {
      "pageSize": 100,
      "pageNumber": 0,
      "search": {
        "id": 0,
        "employeeId": 0,
        "employeeCode": "",
        "permissionType": 0,
        "branchIds": [],
        "employeeName": "",
        "administrationName": "",
        "departmentName": "",
        "jobName": "",
        "groupName": "",
        "shiftName": "",
        "timingOfEnty": "",
        "timingOfExit": "",
        "from": "",
        "to": ""
      }
    },
    verb: 'POST'
  },
  print: true
}
export const DelayEarlyReport = {
  mainTitle: 'التاخير والانصراف المبكر',
  title: 'التاخير والانصراف المبكر',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'كود الموظف', type: 'text', backName: 'employeeCode' },
    { label: 'إسم الموظف', type: 'text', backName: 'employeeName' },
    {
      label: 'الفرع', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, backSearch: true
    },
    {
      label: 'الإدارة', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'القسم', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'الوظيفة', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    {
      label: 'المجموعة', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, backSearch: true
    },
    {
      label: 'الدوام', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    { label: 'من', type: 'date', backName: 'from', backSearch: true },
    { label: 'إلى', type: 'date', backName: 'to', backSearch: true }
  ],
  columns: [
    { name: 'كود الموظف', backName: 'employeeCode' },
    { name: 'إسم الموظف', backName: 'employeeName' },
    { name: 'التاريخ', backName: 'date' },
    { name: 'الحضور', backName: 'timeIn1' },
    { name: 'الانصراف', backName: 'timeOut1' },
    { name: 'الحضور', backName: 'timeIn2' },
    { name: 'الانصراف', backName: 'timeOut2' },
    { name: 'الحضور', backName: 'timeIn3' },
    { name: 'الانصراف', backName: 'timeOut3' },
    { name: 'الحضور', backName: 'timeIn4' },
    { name: 'الانصراف', backName: 'timeOut4' },
    { name: 'التأخير', backName: 'hourDayDelayTime' },
    { name: 'انصراف مبكر', backName: 'earlyLeave' },
    { name: 'الغياب', backName: 'isAbsent', boolean: true }
  ],
  read: {
    url: API.DelayEarlyReport.read,
    body: {
      "pageSize": 100,
      "pageNumber": 0,
      "search": {
        "from": "",
        "to": "",
        "branchIds": [],
        "employeeIds": [],
        "employeeCode": 0,
        "employeeName": "",
        "branchId": 0,
        "administrationId": 0,
        "departmentId": 0,
        "shiftId": 0,
        "groupId": 0,
      }
    },
    verb: 'POST'
  },
  print: true
}
export const TotalAbsenceReport = {
  mainTitle: ' اجمالي الغياب',
  title: ' اجمالي الغياب',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'كود الموظف', type: 'text', backName: 'employeeCode', innerSearch: true },
    { label: 'إسم الموظف', type: 'text', backName: 'employeeName', innerSearch: true },
    {
      label: 'الفرع', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, backSearch: true
    },
    {
      label: 'الإدارة', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    {
      label: 'القسم', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    {
      label: 'الوظيفة', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    {
      label: 'المجموعة', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    {
      label: 'الدوام', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    { label: 'من', type: 'date', backName: 'from', backSearch: true },
    { label: 'إلى', type: 'date', backName: 'to', backSearch: true }
  ],
  period: true,
  columns: [
    { name: 'كود الموظف', backName: 'employeeCode' },
    { name: 'إسم الموظف', backName: 'employeeName' },
    { name: 'الفرع', backName: 'branchName' },
    { name: 'الوظيفة', backName: 'jobName' },
    { name: 'ساعة العمل ', backName: 'shiftName' },
    { name: 'إجمالي الغياب  ', backName: 'absentDays' }
  ],
  read: {
    url: API.TotalAbsenceReport.read,
    body: {
      "pageSize": 100,
      "pageNumber": 0,
      "search": {
        "from": "",
        "to": "",
        "branchIds": [],
        "employeeIds": [],
        "employeeCode": 0,
        "employeeName": "",
        "branchId": 0,
        "administrationId": 0,
        "departmentId": 0,
        "shiftId": 0,
        "groupId": 0,
      }
    },
    verb: 'POST'
  },
  print: true
}
export const TotalDelayReport = {
  mainTitle: 'تقرير اجمالي التأخير ',
  title: 'تقرير اجمالي التأخير ',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    { label: 'كود الموظف', type: 'text', backName: 'employeeCode', innerSearch: true },
    { label: 'إسم الموظف', type: 'text', backName: 'employeeName', innerSearch: true },
    {
      label: 'الفرع', type: 'multiSelect', backName: 'branchIds', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, backSearch: true
    },
    {
      label: 'الإدارة', type: 'select', backName: 'administrationId', url: API.Directorates.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    {
      label: 'القسم', type: 'select', backName: 'departmentId', url: API.Departments.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    {
      label: 'الوظيفة', type: 'select', backName: 'jobId', url: API.Jobs.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    {
      label: 'المجموعة', type: 'select', backName: 'groupId', url: ip + '/api/EmployeeGroups/GetAllEmployeeGroupsDropDown',
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    {
      label: 'الدوام', type: 'select', backName: 'shiftId', url: API.Shifts.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }, innerSearch: true
    },
    { label: 'من', type: 'date', backName: 'from', backSearch: true },
    { label: 'إلى', type: 'date', backName: 'to', backSearch: true }
  ],
  period: true,
  columns: [
    { name: 'كود الموظف', backName: 'employeeCode' },
    { name: 'إسم الموظف', backName: 'employeeName' },
    { name: 'الفرع', backName: 'branchName' },
    { name: 'الوظيفة', backName: 'jobName' },
    { name: ' الدوام', backName: 'shiftName' },
    { name: 'إجمالي التأخير  ', backName: 'lateHours' }
  ],
  read: {
    url: API.TotalDelayReport.read,
    body: {
      "pageSize": 0,
      "pageNumber": 0,
      "search": {
        "from": "",
        "to": "",
        "branchIds": [],
        "employeeIds": [],
        "employeeCode": 0,
        "employeeName": "",
        "branchId": 0,
        "administrationId": 0,
        "departmentId": 0,
        "shiftId": 0,
        "groupId": 0,
      }
    },
    verb: 'POST'
  },
  print: true
}
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
// ====================================================================================================
export const Devices = {
  mainTitle: 'الأجهزة',
  title: 'الأجهزة',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: [
    {
      label: 'الفرع', type: 'select', backName: 'branchId', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    { label: 'إسم الجهاز', type: 'text', backName: 'machineNameAr' },
    { label: 'الرقم التسلسلي', type: 'text', backName: 'machineSN' },
    {
      label: 'نوع الجهاز', type: 'select', backName: 'type',
      options: [
        {
          "id": 1,
          "nameEn": "ZKTeco",
          "nameAr": "ZKTeco"
        },
        {
          "id": 2,
          "nameEn": "Timmy TM-f630",
          "nameAr": "Timmy TM-f630"
        }
      ], item: { value: 'id', label: 'nameAr' }
    }
  ],
  columns: [
    { name: 'رقم الجهاز', backName: 'id' },
    { name: 'الرقم التسلسلي', backName: 'machineSN' },
    { name: 'إسم الجهاز', backName: 'machineNameAr' },
    { name: 'الفرع', backName: 'branchName' },
    {
      name: 'حالة الجهاز', backName: 'status', enum: [
        { value: true, display: <span className='badge agree status'>Active</span> },
        { value: false, display: <span className='badge alert status'>Not Active</span> }
      ]
    },
    {
      name: 'نوع الجهاز', backName: 'type', enum: [
        { value: 1, display: <span >ZKTeco</span> },
        { value: 2, display: <span >Timmy TM-f630</span> }
      ]
    }
  ],
  createEntityProps: [
    { label: 'الإسم العربي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'machineNameAr' },
    { label: 'الإسم الإنجليزي', type: 'text', validation: '', style: 'col-lg-6', backName: 'machineNameEn' },
    {
      label: 'الفرع', type: 'select', validation: 'Required', style: 'col-lg-6', backName: 'branchId', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    { label: 'الرقم التسلسلي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'machineSN' },
    {
      label: 'نوع الجهاز', type: 'select', backName: 'type', validation: 'Required',
      options: [
        {
          "id": 1,
          "nameEn": "ZKTeco",
          "nameAr": "ZKTeco"
        },
        {
          "id": 2,
          "nameEn": "Timmy TM-f630",
          "nameAr": "Timmy TM-f630"
        }
      ], item: { value: 'id', label: 'nameAr' }
    }
  ],
  updateEntityProps: [
    { label: 'الإسم العربي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'machineNameAr' },
    { label: 'الإسم الإنجليزي', type: 'text', validation: '', style: 'col-lg-6', backName: 'machineNameEn' },
    {
      label: 'الفرع', type: 'select', validation: 'Required', style: 'col-lg-6', backName: 'branchId', url: API.Branches.dropdown,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    },
    { label: 'الرقم التسلسلي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'machineSN' },
    {
      label: 'نوع الجهاز', type: 'select', backName: 'type', validation: 'Required',
      options: [
        {
          "id": 1,
          "nameEn": "ZKTeco",
          "nameAr": "ZKTeco"
        },
        {
          "id": 2,
          "nameEn": "Timmy TM-f630",
          "nameAr": "Timmy TM-f630"
        }
      ], item: { value: 'id', label: 'nameAr' }
    }
  ],
  deleteMessage: 'هل تريد حذف هذا العنصر؟',
  create: {
    url: API.Devices.create,
    body: {
      "branchId": '',
      "machineNameAr": '',
      "machineNameEn": '',
      "machineSN": ''
    },
    verb: 'POST'
  },
  read: {
    url: API.Devices.read,
    body: {
      pageSize: 10,
      pageNumber: 0,
      search: {
        "id": '',
        "machineSN": "",
        "machineNameAr": "",
        "branchId": 0,
        "type": 0
      }
    },
    verb: 'POST'
  },
  update: {
    url: API.Devices.update,
    body: {
      "id": 0,
      "branchId": 0,
      "machineNameAr": "",
      "machineNameEn": "",
      "machineSN": "",
      "type": 0
    },
    verb: 'POST'
  },
  delete: {
    url: API.Devices.delete,
    query: {
      branchMachineId: ''
    },
    verb: 'POST'
  }
}
export const SpecialWorkingDays = {
  mainTitle: 'مواعيد رمضان',
  title: 'مواعيد رمضان',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  searchFields: undefined,
  columns: [
    // {
    //   name: 'الرقم',
    //   backName: 'id'
    // },
    {
      name: 'الإسم',
      backName: 'nameAr'
    },
    {
      name: 'من',
      backName: 'dateFrom'
    },
    {
      name: 'إلى',
      backName: 'dateTo'
    },
    {
      name: 'الحالة',
      backName: 'status',
      enum: [
        { value: '1', display: <span className='badge warning status'>لم تبدأ بعد</span> },
        { value: '2', display: <span className='badge agree status'>بدأت</span> },
        { value: '3', display: <span className='badge alert status'>انتهت</span> }
      ]
    }
  ],
  createEntityProps: [
    { label: 'الإسم العربي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'الإسم الإنجليزي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameEn' },
    { label: 'من', type: 'date', validation: 'Required from', style: 'col-lg-6', backName: 'dateFrom' },
    { label: 'إلى', type: 'date', validation: 'Required to', style: 'col-lg-6', backName: 'dateTo' },

  ],
  updateEntityProps: [
    { label: 'الإسم العربي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'الإسم الإنجليزي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameEn' },
    { label: 'من', type: 'date', validation: 'Required from', style: 'col-lg-6', backName: 'dateFrom' },
    { label: 'إلى', type: 'date', validation: 'Required to', style: 'col-lg-6', backName: 'dateTo' },
  ],
  deleteMessage: 'هل تريد حذف هذا العنصر؟',
  create: {
    url: API.SpecialWorkingDays.create,
    body: {
      "nameEn": "",
      "nameAr": "",
      "dateFrom": "",
      "dateTo": ""
    },
    verb: 'POST'
  },
  read: {
    url: API.SpecialWorkingDays.read,
    body: {
      "pageSize": 10,
      "pageNumber": 0,
      "search": {
        "id": 0,
        "nameAr": "",
        "nameEn": ""
      }
    },
    verb: 'POST'
  },
  update: {
    url: API.SpecialWorkingDays.update,
    body: {
      "id": 0,
      "nameEn": "",
      "nameAr": "",
      "dateFrom": "",
      "dateTo": ""
    },
    verb: 'POST'
  },
  delete: {
    url: API.SpecialWorkingDays.delete,
    query: {
      durationRamadanWorId: ''
    },
    verb: 'POST'
  }
}
export const UserPermission = {
  // test: true, // To use testing local data instead of server data
  mainTitle: 'صلاحيات المستخدمين',
  title: 'صلاحيات المستخدمين',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  columns: [
    // { name: 'الرقم', backName: 'id' },
    { name: 'اسم المجموعة', backName: 'nameAr' },
    {
      name: 'الصلاحيات',
      backName: 'groupPermissions',
      link: { to: '/Ar/TAW/Main/UserPermission/Permissions/', routeValues: ['id', 'nameAr'], text: 'الصلاحيات' }
    },
    {
      name: 'المستخدمين',
      backName: 'users',
      link: { to: '/Ar/TAW/Main/UserPermission/Users/', routeValues: ['id', 'nameAr'], text: 'المستخدمين' }
    }
  ],
  createEntityProps: [
    { label: 'الإسم العربي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'الإسم الإنجليزي', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' }
  ],
  updateEntityProps: [
    { label: 'الإسم العربي', type: 'text', validation: 'Required', style: 'col-lg-6', backName: 'nameAr' },
    { label: 'الإسم الإنجليزي', type: 'text', validation: '', style: 'col-lg-6', backName: 'nameEn' }
  ],
  deleteMessage: 'هل تريد حذف هذا العنصر؟',
  create: {
    url: API.UserPermission.create,
    body: {
      "nameAr": "",
      "nameEn": ""
    },
    verb: 'POST'
  },
  read: {
    url: API.UserPermission.read,
    body: {
      "pageSize": 10,
      "pageNumber": 0,
      "search": {
        "id": '',
        "nameAr": "",
        "nameEn": ""
      }
    },
    verb: 'POST'
  },
  update: {
    url: API.UserPermission.update,
    body: {
      "id": 0,
      "nameAr": "",
      "nameEn": ""
    },
    verb: 'POST'
  },
  delete: {
    url: API.UserPermission.delete,
    query: {
      DeleteCustomGroupId: ''
    },
    verb: 'POST'
  }
}
export const GeneralSettings = {
  mainTitle: 'General Settings',
  title: 'General Settings',
  path: [{ title: 'الرئيسية', path: '/Ar/TAW/Main/Dashboard' }],
  createEntityProps: [
    { label: 'Reject duplicate transaction(minutes)', type: 'text', validation: '', style: '', backName: '' },
    { label: 'Number of Shifts in Report', type: 'select', validation: '', style: '', backName: '' },
    { label: 'Calculating overtime before shift start', type: 'text', validation: '', style: '', backName: '' },
    { label: ' calculating overtime after shift end', type: 'text', validation: '', style: '', backName: '' },
    { label: ' count The day as an absence after delay', type: 'checkbox', noFormCont: true, validation: '', style: '', backName: '' },
    { label: ' count the day as an absence after Leaving early', type: 'checkbox', noFormCont: true, validation: '', style: '', backName: '' },
    { label: 'Allow first trans. IN and Last trans. OUT', type: 'checkbox', noFormCont: true, validation: '', style: '', backName: '' }
  ],
  updateEntityProps: [
    { label: 'الإسم العربي', type: 'text', validation: 'Required', style: '', backName: 'nameAr' },
    { label: 'الإسم الإنجليزي', type: 'text', validation: '', style: '', backName: 'nameEn' },
    {
      label: 'المدير', type: 'select', validation: '', style: '', backName: 'managerId', url: API.Employees.read,
      body: {
        "pageSize": 100,
        "pageNumber": 0,
        "searchCriteria": "",
        "selectedId": [
          0
        ]
      },
      item: { value: 'id', label: 'nameAr' }
    }
  ],
  create: {
    url: API.Branches.create,
    body: {
      nameAr: '',
      nameEn: '',
      managerId: ''
    },
    verb: 'POST'
  },
  read: {
    url: API.Branches.read,
    body: {
      pageSize: 100,
      pageNumber: 0,
      search: {

      }
    },
    verb: 'POST'
  },
  update: {
    url: API.Branches.update,
    body: {
      id: '',
      nameAr: '',
      nameEn: '',
      managerId: ''
    },
    verb: 'POST'
  },
  delete: {
    url: API.Branches.delete,
    query: {
      branchId: ''
    },
    verb: 'POST'
  }
}
