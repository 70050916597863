import React from 'react';
import { HashRouter as Router, Route } from "react-router-dom";

import StartLayout from './../StartLayout/StartLayout';
import MainLayout from '../MainLayout/MainLayout';
import StartLayoutAr from './../StartLayoutAr/StartLayoutAr';
import MainLayoutAr from '../MainLayoutAr/MainLayoutAr';
import Idle from 'react-idle'
import Cookie from "js-cookie";


class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    logOut() {
        window.location.assign("/");
        Cookie.set('token', '', { SameSite: 'Lax' })
        Cookie.set('userName', '', { SameSite: 'Lax' })
    }
    render() {
        return (
            <Idle
                timeout={3600000}  
                render={({ idle }) =>
                    <div>
                        {idle
                            ?
                            this.logOut()
                            :
                            <Router>
                                {/* handling the empty path */}
                                <Route exact={true} path="/" component={StartLayoutAr} />
                                <Route exact={true} path="/En" component={StartLayout} />
                                {/* handling the path */}
                                <Route path="/Ar/TAW/Start" component={StartLayoutAr} />
                                <Route path="/En/TAW/Start" component={StartLayout} />
                                {/* for other components  */}
                                <Route path="/Ar/TAW/Main" component={MainLayoutAr} />
                                <Route path="/En/TAW/Main" component={MainLayout} />
                            </Router>
                        }
                    </div>
                }
            />


        );
    }

}

export default App;