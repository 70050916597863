import React from 'react';
import './Loading.css';
import OriginalComponent from 'react-spinners/ClockLoader';

// Full documentation of the OriginalComponent at:
// https://www.npmjs.com/package/react-spinners.
// https://www.davidhu.io/react-spinners/.

// We declare the original component as OriginalComponent 
// To decrease confusion with that one we will use in our project.

// We declared all component props by destructuring the props object
// Destructuring is a ES6 feature written in this way:
// const { property } = object;.

// Each prop type will be mentioned beside it.

// if prop describes a tagName , then prop will take the same naming as following:
// className={className} ==> tagName={prop}.

class Loading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      visible // boolean
    } = this.props;
    return (
      <div>
        {
          visible ?
          (
            <OriginalComponent
            // #00c5fb
              color={"#fff"}
              size = {80}
              // css={overrideFadeLoader}
            />
          )
          :
          null
        }
      </div>
    );
  }
}
export default Loading;