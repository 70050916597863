import React from 'react';
import OriginalComponent from 'react-dropdown-select';
import './Dropdown.css';
import { Button, PopoverHeader, PopoverBody } from "reactstrap";
import { Popover } from "reactstrap";
import 'popper.js';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
// Full documentation of the OriginalComponent at:
// https://sanusart.github.io/react-dropdown-select/.

// We declare the original component as OriginalComponent 
// To decrease confusion with that one we will use in our project.

// We declared all component props by destructuring the props object
// Destructuring is a ES6 feature written in this way:
// const { property } = object;.

// Each prop type will be mentioned beside it.

// if prop describes a tagName , then prop will take the same naming as following:
// className={className} ==> tagName={prop}.

class Dropdown extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          input: '',
          value: '',
          popoverOpen: false,
          popOverValue: ''
      }
      this.togglePopover = this.togglePopover.bind(this);

  }
  
  onInputChange(e) {
    this.setState({ input: e.currentTarget.value, value: e.currentTarget.value });
}
togglePopover() {
    this.setState({ popoverOpen: !this.state.popoverOpen })
}

render() {
    const { popoverOpen } = this.state;
    const {  multi,placeholder,ar,  options, rtl, target, onChange, value, popover,addStyle } = this.props
  if (popover) {
    return (

      <div>
        <input id={target}  type="" value={value ? value.label : '' }  placeholder={placeholder}  className="popover-btn" />
        <Popover
          placement="bottom"
          isOpen={popoverOpen}
          target={target}
          toggle={this.togglePopover}
        >
          <PopoverBody>
          <div className={`d-flex ${ar? "ar": " "}`}>
              <OriginalComponent
                direction={rtl ? 'rtl' : 'ltr'}
                className="Dropdown"
                placeholder={placeholder}
                // multi
                clearable
                options={options}
                values={value ? [value] : []}
                onChange={(value) => {onChange(value[0] ? value[0].value : '')
                console.log(value[0].value)
                this.setState({ popOverValue: value[0].value })
                this.setState({value : value[0].label})  
              }}
                noDataLabel={rtl ? 'لا توجد بيانات' : 'No Data'}
              />
              <button type="button" class="btn btn-primary btn-popover" onClick={() => {
                // onChange(this.state.popOverValue);
                this.setState({ value: this.state.popOverValue })
                this.setState({ popoverOpen: false });
              }} >
                <FontAwesomeIcon icon={faCheck} color="#fff"
                ></FontAwesomeIcon>
              </button>

              <button type="button" class="btn btn-danger btn-popover" onClick={() => {
                this.setState({ popoverOpen: false });
              }}  >
                <FontAwesomeIcon icon={faTimes} color="#fff"> </FontAwesomeIcon>
              </button>
            </div>
          </PopoverBody>
        </Popover>
      </div>
    )
  }
  else {
  if (multi) {
    return (
      <OriginalComponent
        direction={rtl ? 'rtl' : 'ltr'}
        className="Dropdown"
        placeholder={placeholder}
        multi
        clearable
        options={options}
        values={value}
        onChange={(value) => onChange(value.map(item => item.value))}
        noDataLabel={rtl ? 'لا توجد بيانات' : 'No Data'}
      />
    )
  }
  else{
  return (
    <OriginalComponent
      direction={rtl ? 'rtl' : 'ltr'}
      className={`Dropdown ${addStyle ? addStyle :''}`}
      placeholder={placeholder}
      // multi
      clearable
      options={options}
      values={value ? [value] : []}
      onChange={(value) => onChange(value[0] ? value[0].value : '')}
      noDataLabel={rtl ? 'لا توجد بيانات' : 'No Data'}
    />
  )
}
}
}
}
export default Dropdown;