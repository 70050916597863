import React from 'react';
import './Overlay.css';

const Overlay = ({ children, id, className, visible }) => {
  return (
    <div>
      {
        <div id={id} className={`Overlay${className ? " " + className : ""}${visible ? " show" : ""}`}>
          {children}
        </div>
      }
    </div>
  )
}

export default Overlay;