import React from 'react';
import './MainHeader.css';
import logo from './apex-logo-white.png';
import { Link } from 'react-router-dom';
import Cookie from "js-cookie";
import Lists from './../Lists/Lists';
import img from '../../ar.jpg'
import ReactTooltip from 'react-tooltip';
import { Dropdown } from 'react-bootstrap';
import us from '../../us.png';
import avatar from '../../avatar.png';
import ar from '../../ar.jpg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,faEllipsisV
} from "@fortawesome/free-solid-svg-icons";
const MainHeader = () => {
  return (
    <header className='MainHeader-- header en'>
      {/* 
        <div className='header--side-toggle-button'>
        </div>
        */}

      {/* <Lists /> */}
      <div className='right-header-sec'>
        <div className="header-left">
          <Link className='logo' to='/Ar/TAW/Main/Dashboard'>
            <img className='header--logo' src={logo} alt='LOGO' height={'60px'} />
          </Link>
        </div>
        <div className="page-title-box">
          <h3> TA SOFTWARE</h3>
        </div>
      </div>




      <ul className="nav user-menu">
        <li className="nav-item">
          <div className="top-nav-search">
            <a  className="responsive-search">
              <i className="fa fa-search"></i>
            </a>
            <form>
              <input className="form-control header-input text-left" type="text" placeholder=" Search here " />
              <button className="btn" type="submit"><i className="fa fa-search"></i></button>
            </form>
          </div>
        </li>
        <li className="nav-item">
          <Dropdown className="hover">
            <Dropdown.Toggle variant="link" className='nav-link dropdown-toggle' id="dropdown-basic">
              <img src={us} alt="" width="30" height="20" style={{ margin: '0px 10px' }} />
              <span>English </span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="text-center">
              <Dropdown.Item href="#"><Link onClick={(e) => {
                e.preventDefault()
                let currentUrl = window.location.hash;
                currentUrl = currentUrl.replace('/Ar/', '/En/');
                window.location.hash = currentUrl;
              }} >
                <img src={us} alt="" width="30" height="30" style={{ margin: '0px 10px' }} />
                <span>English </span>
              </Link></Dropdown.Item>
              <Dropdown.Item href=""><Link onClick={(e) => {
                e.preventDefault()
                let currentUrl = window.location.hash;
                currentUrl = currentUrl.replace('/En/', '/Ar/');
                window.location.hash = currentUrl;
              }} >
                <img src={ar} alt="" width="30" height="20" style={{ margin: '0px 10px' }} />
                <span>العربية </span>
              </Link></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

        </li>
        <li className="nav-item">
          <a  class="nav-link" style={{ position: 'relative' }}>
            <i class="fa fa-bell-o"></i>
            <span class="badge badge-pill" style={{height:"16px",padding: "3px 5px"}}>0</span>
          </a>
        </li>
        <li className="nav-item">
          <a  class="nav-link" style={{ position: 'relative' }}>
            <i class="fa fa-comment-o"></i>
            <span class="badge badge-pill" style={{height:"16px",padding: "3px 5px"}}>0</span>
          </a>
        </li>
        <li className="nav-item">
          <Dropdown className="hover">
            <Dropdown.Toggle variant="link" className='nav-link dropdown-toggle' id="dropdown-basic">
              <span className="user-img">
                <img src={avatar} alt="" width="40" height="40" style={{ borderRadius: '50%' }} />
                <span className="status online"></span>
              </span>
              <a style={{ marginLeft: '5px', color: "#fff" }} href='#void' className='en' onClick={(e) => {
                e.preventDefault()
              }}>{Cookie.get('userName')}</a>
            </Dropdown.Toggle>

            <Dropdown.Menu className="text-center">
            <Dropdown.Item >
                <span className='en'>
                  <Link style={{ padding: '0px' }} to={'/En/TAW/Main/ChangePassword'}>
                    <span style={{ fontSize: "14px" }}>Change Password</span>
                  </Link>
                </span>
              </Dropdown.Item>
              <Dropdown.Item >
                <span onClick={() => {
                  Cookie.set('token', '', { SameSite: 'Lax' });
                  Cookie.set('userName', '', { SameSite: 'Lax' });
                }} className='en'>
                  <Link style={{ padding: '0px' }} to={'/En/TAW/Start/Login/' + Cookie.get('companyName')}>
                    <i className="fa fa-sign-out" aria-hidden="true" ></i>
                    <span style={{ fontSize: "14px" }}> LogOut </span>
                  </Link>
                </span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </li>
      </ul>
      <ul className="dropdown mobile-user-menu d-flex d-md-none">
         <li class="nav-item">
          <Dropdown className="hover">
            <Dropdown.Toggle variant="link" className='nav-link dropdown-toggle' id="dropdown-basic2">
              <img src={us} alt="" width="20" height="20" style={{ margin: '0px 5px' ,zIndex:60}} />
              <span style={{color:"#fff"}}>en </span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="text-center">
              <Dropdown.Item href="#"><Link onClick={(e) => {
                e.preventDefault()
                let currentUrl = window.location.hash;
                currentUrl = currentUrl.replace('/Ar/', '/En/');
                window.location.hash = currentUrl;
              }} >
                <img src={us} alt="" width="20" height="20" style={{ margin: '0px 5px' }} />
                <span>English </span>
              </Link></Dropdown.Item>
              <Dropdown.Item href=""><Link onClick={(e) => {
                e.preventDefault()
                let currentUrl = window.location.hash;
                currentUrl = currentUrl.replace('/En/', '/Ar/');
                window.location.hash = currentUrl;
              }} >
                <img src={ar} alt="" width="20" height="20" style={{ margin: '0px 5px' }} />
                <span>العربية </span>
              </Link></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

        </li>
      <li class="nav-item">
          <Dropdown className="hover">
            <Dropdown.Toggle variant="link" className='nav-link dropdown-toggle' id="Mdropdown-basic">
              <FontAwesomeIcon className="mobile-icon" size={2} icon={faEllipsisV}></FontAwesomeIcon>
            </Dropdown.Toggle>

            <Dropdown.Menu className="text-center">
              <Dropdown.Item >
                <span onClick={() => {
                  Cookie.set('token', '', { SameSite: 'Lax' });
                  Cookie.set('userName', '', { SameSite: 'Lax' });
                }} className='en'>
                  <Link style={{ padding: '0px' }} to={'/En/TAW/Start/Login/' + Cookie.get('companyName')}>
                    <i class="fa fa-sign-out" aria-hidden="true" ></i>
                    <span style={{ fontSize: "14px" }}> Log out</span>
                  </Link>
                </span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

        </li>
      </ul>
      {/* 
        <div className='header--ellipsis'>
          <button><i className='fa fa-ellipsis-v'></i></button>
          <ul>
            <li className='header--user-name'>
              <a href='#void'>{Cookie.get('userName')}</a>
            </li>
            <li>
              <Link to='/TAW/Start/Login'>Log out</Link>
            </li>
            <li>
              <a style={{display:"inline-block", fontSize: "15px", marginBottom: "5px"}} href='#void'>ع</a>
            </li>
          </ul>
        </div>
 */}
      {/* <div className="right-lists">
          <ul>
            <li style={{padding : '0px'}}>
              <a  style={{padding : '0px'}} href='#void' onClick={(e) => { e.preventDefault() }}>
                {Cookie.get('userName')}
              </a>
            </li>
            <li  style={{padding : '0px'}} data-tip='' data-for='logOut' >
              <span onClick={() => {
                Cookie.set('token', '', { SameSite: 'Lax' });
                Cookie.set('userName', '', { SameSite: 'Lax' });
              }}>

                <Link style={{ padding: '0px' }} to={'/En/TAW/Start/Login/' + Cookie.get('companyName')}> <i className="fa fa-sign-out" aria-hidden="true" ></i></Link>
              </span>
            </li>
           
             <ReactTooltip id='logOut'>Log out </ReactTooltip>
            <li>
              {/* style={{display:"inline-block", fontSize: "15px", marginBottom: "5px"}}  */}
      {/* <Link to='/En/TAW/Start/Login'>ع</Link> */}

      {/* <a className="arabic" href='#void' onClick={(e) => {
                e.preventDefault()
                let currentUrl = window.location.hash;
                currentUrl = currentUrl.replace('/En/', '/Ar/');
                window.location.hash = currentUrl;
              }}>

                <img src={img} style={{ height: '25px', width: '25px', borderRadius: '50%' }}/>
              </a>
            </li>
          </ul>
        </div>  */}
    </header>
  )
}
export default MainHeader;