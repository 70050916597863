/* eslint-disable eqeqeq */
import React from 'react';
import './TableSpan.css';

const TableSpan = (props) => {
  let Days = ['Mon.', 'Tue.', 'Wed.', 'Thu.', 'Fri.', 'Sat', 'Sun'];
  let tableData = props.data.map((data) => {
    let row = [];
    row.push(Days[data.dayId - 1]);
    if (props.open !== 'true') {
      for (let index = 0; index < 4; index++) {
        row.push(
          data.periodShiftTimes.find((shift) => (shift.seq - 1) === index) ?
            data.periodShiftTimes.find((shift) => (shift.seq - 1) === index).inTime
            :
            '00:00:00'
        );
        row.push(
          data.periodShiftTimes.find((shift) => (shift.seq - 1) === index) ?
            data.periodShiftTimes.find((shift) => (shift.seq - 1) === index).outTime
            :
            '00:00:00'
        );
      }
    }
    row.push(data.totalDayHours);
    row.push(data.isVacation ? 'Weekend' : 'Work');
    return row;
  }).slice();

  let tableRows = tableData.map((row) =>
    <tr key={row[0]}>
      {row.map((data, i) => <td key={i}>{data}</td>)}
      <td className="td-action">
        {
          props.noEdit ?
            null
            :
            <i
              className="fa fa-pencil"
              onClick={(e) => props.onEditClick(e)}
            >
            </i>
        }
      </td>
    </tr>
  );

  return (
    <div className="TableSpan--">
      <table>
        <thead>{
          props.open !== 'true' ?
            <>
              <tr>
                <th>Day</th>
                <th colSpan="2">First Shift</th>
                <th colSpan="2">Second Shift</th>
                <th colSpan="2">Third Shift</th>
                <th colSpan="2">Fourth Shift</th>
                <th>Day Working Hrs</th>
                <th>Day Status</th>
                <th className="action">Actions</th>
              </tr>
              <tr>
                <td></td>
                <td><span className="badge agree">Enter</span></td>
                <td><span className="badge alert">Leave</span></td>
                <td><span className="badge agree">Enter</span></td>
                <td><span className="badge alert">Leave</span></td>
                <td><span className="badge agree">Enter</span></td>
                <td><span className="badge alert">Leave</span></td>
                <td><span className="badge agree">Enter</span></td>
                <td><span className="badge alert">Leave</span></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </>
            :
            <tr>
              <th>Day</th>
              <th>Working Hours</th>
              <th>Day Status</th>
              <th className="action">Actions</th>
            </tr>
        }
        </thead>
        <tbody>
          {tableRows}
        </tbody>
      </table>
    </div>
  )
}

export default TableSpan;