/* eslint-disable eqeqeq */
import React from 'react';
import './Modal.css';
import Button from './../Button/Button';
import Alert from './../Alert/Alert';
import Table from './../Table/Table';
import { Link } from "react-router-dom";
import Cookie from "js-cookie";
import TableFooterAr from '../TableFooterAr/TableFooterAr';
import TextInput from '../TextInput/TextInput';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import Dropdown from '../Dropdown/Dropdown';
import FiltersAr from '../FiltersAr/FiltersAr';
import Field from '../Field/Field';
import $ from "jquery";

class ModalAr extends React.Component {
  constructor(props) {
    super(props);

    let tempSearchEntity = {};
    if (props.searchFields) {
      for (let field of props.searchFields) {
        tempSearchEntity[field.backName] = '';
      }
      for (let field of props.searchFields) {
        if (field.type === 'select') {
          this.GetSelect(field.url, field.body, field.label);
        }
      }
    }

    this.state = {
      visible: false,
      data: [],
      rowsNumber: 5,
      rowsTotal: 0,
      currentPage: 1,
      searchEntity: tempSearchEntity
    }
  }

  componentDidMount() {
    if (this.props.read) {
      this.Read(this.props.read.body);
    }

    //on 'Esc' press close modal
    const modal = document.querySelector(`#${this.props.action}`);
    modal.addEventListener("keydown", event => {
      if (event.keyCode === 27) {
        this.props.hideModal(this.props.action)
      }
    });
  }

  componentDidUpdate() {
    //if modal is not visible but props.visible is true
    if (this.props.visible && !this.state.visible) {
      const modal = document.querySelector(`#${this.props.action}`);
      //if there is an input
      if (modal.getElementsByTagName('input')[0]) {
        //focus on first input
        modal.getElementsByTagName('input')[0].focus();
      }
      //Show modal
      this.setState({ visible: true });
      //if modal is visible  but props.visible is false
    } else if (!this.props.visible && this.state.visible) {
      //hide modal
      this.setState({ visible: false });
    }
    //if there is a read operation in this modal and the data is not updated
    if (this.props.read && !this.props.updated) {
      //read data
      this.Read(this.props.read.body);
    }
    //if there is data from outside
    if (this.props.data) {
      //if data from outside not equal to this modal data
      if (this.props.data !== this.state.data) {
        //assign data from outside to modal data
        this.setState({ data: this.props.data });
      }
    }
  }

  ChangeEntityState(state, name, value) {
    let tempEntity = this.state[state];
    tempEntity[name] = value;
    this.setState({ [state]: tempEntity });
  }

  ChangeState(key, value) {
    this.setState({ [key]: value });
  }

  Fetch(entity, token, operation, then) {
    fetch(
      this.props[operation].path ?
        this.props[operation].url + '/' + entity[Object.keys(entity)[0]]
        :
        this.props[operation].query ?
          this.props[operation].url + '?' + Object.keys(entity)[0] + '=' + entity[Object.keys(entity)[0]]
          :
          this.props[operation].url, {
      method: this.props[operation].verb,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: this.props[operation].body ? JSON.stringify(entity) : null
    })
      .then(response => response.json())
      .then((response) => {
        let me = this;
        then(response, me);
      })
      .catch(error => console.error('Unable to ' + operation + '.', error));
  }

  Read(entity) {
    let token = Cookie.get('token');
    let then = (response, me) => {
      if (response.status === 200 || response.success) {
        if (response.success) {
          if (response.data.data) {
            me.setState(
              {
                data: response.data.data.map((row) => {
                  for (const key in me.props.params) {
                    row[key] = me.props.params[key];
                  }
                  return row;
                }),
                rowsTotal: response.data.listCount
              }
            );
            me.props.parent.setState({ modalUpdated: true })
          } else { //response.data
            me.setState(
              {
                data: response.data.map((row) => {
                  for (const key in me.props.params) {
                    row[key] = me.props.params[key];
                  }
                  return row;
                }),
                rowsTotal: response.data.listCount
              }
            );
            me.props.parent.setState({ modalUpdated: true })
          }
        } else { //not success
          console.error(response);
        }
      } else { //not status 200
        console.error(response);
      }
    }
    this.Fetch(entity, token, 'read', then);
  }

  GetSelect(url, body, name) {
    let token = Cookie.get('token');
    fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then((response) => {
        if (response.success) {
          this.setState({ [name]: response.data.data });
        } else {
          console.error(response.message);
        }
      })
      .catch(error => console.error('Unable to Read.', error));
  }

  //Clear search fields in search modal
  Clear() {
    let tempSearchEntity = {};
    if (this.props.searchFields) {
      for (let field of this.props.searchFields) {
        tempSearchEntity[field.backName] = '';
      }
    }

    this.setState({ searchEntity: tempSearchEntity });
  }

  render() {
    let actionAr = '';

    //translate operation name to show in the modal header
    switch (this.props.action) {
      case 'add':
        actionAr = 'إضافة'
        break;
      case 'edit':
        actionAr = 'تعديل'
        break;
      case 'delete':
        actionAr = 'حذف'
        break;
      case 'search':
        actionAr = 'بحث'
        break;
      case 'display':
        actionAr = 'عرض'
        break;
      default:
        break;
    }

    //for Search modal
    let SearchFields;
    if (this.props.searchFields) {
      SearchFields = this.props.searchFields.map(
        (field) => {
          let items;
          switch (field.type) {
            case 'text':
              return (
                <Field
                  title={`${field.label}:`}
                  titleSize={field.titleSize}
                  inputComponent={[
                    <TextInput
                      key={field.label}
                      placeholder={field.label}
                      type="text"
                      value={this.state.searchEntity[field.backName]}
                      onChange={(value) => this.ChangeEntityState('searchEntity', field.backName, value)}
                    />
                  ]}
                />
              )
            case 'select':
              if (field.label === 'Management') {
                items = this.state[field.label] === undefined ?
                  []
                  :
                  this.state[field.label].filter(management => management.branchId === this.state.searchEntity[this.props.searchFields.find(field => field.label === 'Branch').backName])
                    .map((item) => { return { label: item[field.item.label], value: item[field.item.value] } });
              } else if (field.label === 'Department') {
                items = this.state[field.label] === undefined ?
                  []
                  :
                  this.state[field.label].filter(department => department.administrationId === this.state.searchEntity[this.props.searchFields.find(field => field.label === 'Management').backName])
                    .map((item) => { return { label: item[field.item.label], value: item[field.item.value] } });
              } else {
                items = this.state[field.label] === undefined ?
                  []
                  :
                  this.state[field.label].map((item) => { return { label: item[field.item.label], value: item[field.item.value] } });
              }
              return (
                <Field
                  key={field.label}
                  title={`${field.label}:`}
                  titleSize={field.titleSize}
                  inputComponent={[
                    <Dropdown
                      rtl={true}
                      key={field.label}
                      placeholder={field.label}
                      value={items.find(item => item.value === this.state.searchEntity[field.backName])}
                      options={items}
                      onChange={(value) => this.ChangeEntityState('searchEntity', field.backName, value)}
                    />
                  ]}
                />
              )
            default:
              return (
                <Field
                  title={`${field.label}:`}
                  titleSize={field.titleSize}
                  inputComponent={[
                    <TextInput
                      key={field.label}
                      placeholder={field.label}
                      type="text"
                      value={this.state.searchEntity[field.backName]}
                      onChange={(value) => this.ChangeEntityState('searchEntity', field.backName, value)}
                    />
                  ]}
                />
              )
          }
        }
      );
    }

    //filter data in search modal
    let TableHeaders;
    let TableData;
    if (this.props.action === 'search') {
      let me = this;
      let tableRawData = this.state.data;
      for (let prop in this.state.searchEntity) {
        if (this.state.searchEntity[prop]) {
          tableRawData = tableRawData.filter(row => {
            if (row[prop] !== null) {
              return row[prop].toString().toLowerCase().includes(this.state.searchEntity[prop].toString().toLowerCase())

            } else {
              return ''.toLowerCase().includes(this.state.searchEntity[prop].toString().toLowerCase())
            }
          });
        }
      }

      //show only the wanted columns in table
      tableRawData = tableRawData.map((row) => {
        let tablerow = {};
        for (let column of this.props.columns) {
          tablerow[column['backName']] = row[column['backName']];
        }

        return tablerow;
      });

      //if data lenght is not equal to rowsTotal (for pagination)
      if (tableRawData.length !== this.state.rowsTotal) {
        //update rowsTotal
        this.setState({ rowsTotal: tableRawData.length })
      }

      //Table Headers
      TableHeaders = this.props.columns.map((column) => <th key={column['name']}>{column['name']}</th>);

      //get the filtered data with their Ids
      let rawDataIds = me.state.data.filter((data) => {
        for (let raw of tableRawData) {
          if (data[me.props.columns[0]['backName']] === raw[Object.keys(raw)[0]]) {
            return true;
          } else {
            continue;
          }
        }
        return false
      });

      //put in the beginning of the array of table headers
      TableHeaders.unshift(
        <th key={'headCheckBox'}>
          {/* if there is createEntity */}
          {me.props.parent.state.createEntity ?
            <ToggleSwitch
              width={25}
              height={15}
              //if all the ids are selected, then return true, otherwise return false 
              value={rawDataIds.every((value) => me.props.parent.state.createEntity[me.props.backName]?.some((item) => item === value.id))}
              backName={me.props.backName}
              onChange={(name, value) => {
                //get a copy of the createEntity
                let tempEntity = me.props.parent.state.createEntity;
                //if checked
                if (value) {
                  //put all the ids in the property with the name ('backName' value) (all checked)
                  tempEntity[me.props.backName] = rawDataIds.map((data) => data.id);
                  me.props.parent.setState({ createEntity: tempEntity })
                } else {
                  //put an empty array (all unchecked)
                  tempEntity[me.props.backName] = [];
                  me.props.parent.setState({ createEntity: tempEntity })
                }
              }}
            />

            :
            //else, actionEntity
            <ToggleSwitch
              width={25}
              height={15}
              //if all the ids are selected, then return true, otherwise return false 
              value={rawDataIds.every((value) => me.props.parent.state.actionEntity[me.props.backName]?.some((item) => item === value.id))}
              backName={me.props.backName}
              onChange={(name, value) => {
                //get a copy of the actionEntity
                let tempEntity = me.props.parent.state.actionEntity;
                //if checked
                if (value) {
                  //put all the ids in the property with the name ('backName' value) (all checked)
                  tempEntity[me.props.backName] = rawDataIds.map((data) => data.id);
                  me.props.parent.setState({ actionEntity: tempEntity })
                } else {
                  //put an empty array (all unchecked)
                  tempEntity[me.props.backName] = [];
                  me.props.parent.setState({ actionEntity: tempEntity })
                }
              }}
            />
          }
        </th>)


      TableData = tableRawData
        //Client side Pagination
        .slice(((this.state.rowsNumber * this.state.currentPage) - this.state.rowsNumber), (this.state.rowsNumber * this.state.currentPage))
        //Display table Data properly
        .map(
          function (row, rowIndex) {
            return (
              <tr key={row[Object.keys(row)[0]].toString()}>
                <td key={'CheckBox' + rowIndex}>
                  {/* CreateEntity */}
                  {me.props.parent.state.createEntity ?
                    <ToggleSwitch
                      width={25}
                      height={15}
                      //if the current id is included in the createEntity[backName] return true, otherwise false
                      value={me.props.parent.state.createEntity[me.props.backName]?.some((value) =>
                        value === me.state.data.find(item => item[me.props.columns[0]['backName']] === row[Object.keys(row)[0]]).id)}
                      //the id of the current row
                      backName={me.state.data.find(item => item[me.props.columns[0]['backName']] === row[Object.keys(row)[0]]).id}

                      onChange={(name, value) => {
                        //get a copy
                        let tempEntity = me.props.parent.state.createEntity;
                        //if checked
                        if (value) {
                          //add the current row id to the array in the createEntity
                          tempEntity[me.props.backName].push(name);
                          me.props.parent.setState({ createEntity: tempEntity })
                        } else {
                          //remove the current row id from the array in the createEntity
                          tempEntity[me.props.backName] = tempEntity[me.props.backName].filter((value) => value !== name);
                          me.props.parent.setState({ createEntity: tempEntity })
                        }
                      }} />
                    :
                    //ActionEntity
                    <ToggleSwitch
                      width={25}
                      height={15}
                      value={me.props.parent.state.actionEntity[me.props.backName]?.some((value) =>
                        value === me.state.data.find(item => item[me.props.columns[0]['backName']] === row[Object.keys(row)[0]]).id)}
                      backName={me.state.data.find(item => item[me.props.columns[0]['backName']] === row[Object.keys(row)[0]]).id}
                      onChange={(name, value) => {
                        let tempEntity = me.props.parent.state.actionEntity;
                        if (value) {
                          tempEntity[me.props.backName].push(name);
                          me.props.parent.setState({ actionEntity: tempEntity })
                        } else {
                          tempEntity[me.props.backName] = tempEntity[me.props.backName].filter((value) => value !== name);
                          me.props.parent.setState({ actionEntity: tempEntity })
                        }
                      }} />
                  }
                </td>
                {
                  Object.keys(row)
                    .map((columnName, columnIndex) =>
                      me.props.columns[columnIndex]['link'] ?
                        <td key={row[0] + ',' + columnIndex}>
                          <Link to={me.props.columns[columnIndex]['link']['to'].toString() + me.props.columns[columnIndex]['link']['routeValues'].map((value) => me.state.data[rowIndex][value]).join('/')}>
                            {
                              me.props.columns[columnIndex]['link']['icon'] ?
                                me.props.columns[columnIndex]['link']['icon']
                                :
                                me.props.columns[columnIndex]['link']['text'] ?
                                  me.props.columns[columnIndex]['link']['text']
                                  :
                                  row[columnName]
                            }
                          </Link>
                        </td>
                        :
                        me.props.columns[columnIndex]['boolean'] ?
                          <td key={row[0] + ',' + columnIndex}>
                            {row[columnName] ? <span className='badge agree working-time'>Yes</span> : <span className='badge alert working-time'>No</span>}
                          </td>
                          :
                          me.props.columns[columnIndex]['enum'] ?
                            <td key={row[0] + ',' + columnIndex}>
                              {me.props.columns[columnIndex]['enum'].find((item) => item.value == row[columnName])['display']}
                            </td>
                            :
                            <td key={row[0] + ',' + columnIndex}>
                              {row[columnName]}
                            </td>)
                }
              </tr>
            )
          }
        );
    } else if (this.props.action === 'display') { //Display Modal
      let me = this;
      let tableRawData = this.state.data;

      tableRawData = tableRawData.map((row) => {
        let tablerow = {};
        for (let column of this.props.columns) {
          tablerow[column['backName']] = row[column['backName']];
        }

        return tablerow;
      });

      TableHeaders = this.props.columns.map((column) => <th key={column['name']}>{column['name']}</th>);

      TableData = tableRawData
        .map(
          function (row, rowIndex) {
            return (
              <tr key={row[Object.keys(row)[0]].toString()}>
                {
                  Object.keys(row)
                    .map((columnName, columnIndex) =>
                      me.props.columns[columnIndex]['link'] ?
                        <td key={row[0] + ',' + columnIndex}>
                          <Link to={me.props.columns[columnIndex]['link']['to'].toString() + me.props.columns[columnIndex]['link']['routeValues'].map((value) => me.state.data[rowIndex][value]).join('/')}>
                            {
                              me.props.columns[columnIndex]['link']['icon'] ?
                                me.props.columns[columnIndex]['link']['icon']
                                :
                                me.props.columns[columnIndex]['link']['text'] ?
                                  me.props.columns[columnIndex]['link']['text']
                                  :
                                  row[columnName]
                            }
                          </Link>
                        </td>
                        :
                        me.props.columns[columnIndex]['boolean'] ?
                          <td key={row[0] + ',' + columnIndex}>
                            {row[columnName] ? <span className='badge agree working-time'>Yes</span> : <span className='badge alert working-time'>No</span>}
                          </td>
                          :
                          me.props.columns[columnIndex]['enum'] ?
                            <td key={row[0] + ',' + columnIndex}>
                              {me.props.columns[columnIndex]['enum'].find((item) => item.value == row[columnName])['display']}
                            </td>
                            :
                            <td key={row[0] + ',' + columnIndex}>
                              {row[columnName]}
                            </td>)
                }
              </tr>
            )
          }
        );
    }


    return (
      <div
        id={this.props.action}
        className={`Modal--${this.props.visible ? " show" : ""} ${this.props.action} ${this.props.className}`}
        style={{ textAlign: 'right' }}
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>

        <div className="Modal--container">
          <div className="background">
            <div className={`Modal--header ${this.props.action}`}>
              <h5 className="Modal--title"> {this.props.removeModalTitleName ? " " : actionAr} {this.props.title ? this.props.title : null}</h5>  {/* Add, Edit, Delete, ....... */}
              <Button
                className={'cross-btn'}
                onClick={() => this.props.hideModal(this.props.action)}
              >
                x
                    </Button>
            </div>
            <div className="MainLayout--container">
              <div className={`Modal--body ${this.props.action}`}>
                {/* Validation Messages in Alert */}
                {
                  this.props.errors ?
                    this.props.errors.length > 0 ?
                      (
                        <Alert
                          className="Alert danger"
                          style={{
                            marginTop: "-20px",
                            marginBottom: "20px"
                          }}
                        >
                          <ul>
                            {this.props.errors.map((error) => <li key={error}>{error}</li>)}
                          </ul>
                        </Alert>
                      )
                      :
                      null
                    :
                    null
                }

                {/* Modal Body */}
                {
                  this.props.action === 'delete' ? //Delete Modal
                    this.props.deleteMessage
                    :
                    this.props.action === 'search' ? //Search Modal
                      <React.Fragment>
                        <div className="alerts">
                          {/* Message from Back-End */}
                          {
                            this.state.message ?
                              this.state.message['text'] ?
                                <Alert
                                  className={"Alert " + this.state.message['style']}
                                >
                                  {this.state.message['text']}
                                </Alert>
                                :
                                null
                              :
                              null
                          }
                        </div>

                        {/* Search Fields */}
                        {
                          SearchFields ?
                            <FiltersAr
                              SearchFields={SearchFields}
                              Clear={() => this.Clear()}
                            />
                            :
                            null
                        }

                        {/* Pagination */}
                        <TableFooterAr
                          rowsNumber={this.state.rowsNumber}
                          rowsNumberChange={(value) => {
                            value = value > 1 ? value : 1;
                            this.ChangeState('rowsNumber', value);
                            this.setState({ currentPage: 1 });
                          }}
                          total={this.state.rowsTotal}
                          currentPage={this.state.currentPage}
                          currentPageChange={(value) => this.setState({ currentPage: Number(value) })}
                        />

                        {/* Table */}
                        <Table TableHeaders={TableHeaders} data={TableData} />
                      </React.Fragment>
                      :
                      this.props.action === 'display' ? //Display Modal
                        this.props.modalMessage ?
                          <React.Fragment>
                            <h3>{this.props.modalMessage}</h3>
                          </React.Fragment>

                          :
                          <React.Fragment>

                            {/* Table */}
                            <Table TableHeaders={TableHeaders} data={TableData} />
                          </React.Fragment>
                        :

                        this.props.EntityProperties

                }
              </div>
            </div>

            {/* Modal Footer */}
            {
              this.props.action === 'search' ? //Search Modal
                <div className="Modal--footer">
                  <Button
                    className={`btn oval ${this.props.action}`}
                    onClick={() => this.props.fetch()}
                  >
                    إضافة المحدد
                    </Button>

                  <Button
                    className="btn oval cancel"
                    onClick={() => {
                      this.Clear();
                      this.props.hideModal(this.props.action);
                    }}
                  >
                    إلغاء
                    </Button>
                </div>
                :
                this.props.action === 'display' ? //Display Modal
                  <div className="Modal--footer">

                    <Button
                      className="btn oval cancel"
                      onClick={() => this.props.hideModal(this.props.action)}
                    >
                      إلغاء
                    </Button>
                  </div>
                  :
                  <div className="Modal--footer">
                    {this.props.updateAll ? //for Working Hours
                      <Button
                        className={`btn oval ${this.props.action}`}
                        onClick={() => {
                          this.props.updateAll();
                          $(".Modal--container  .MainLayout--container").scrollTop(0);
                          if ($(".Alert").hasClass('d-none')) {
                            $(".Alert").removeClass('d-none');
                          }
                        }}
                      >
                        تكرار على كل الأيام
                      </Button>
                      : null}

                    {/* Action Button */}
                    <Button
                      className={`btn oval ${this.props.action}`}
                      onClick={() => {
                        this.props.fetch();
                        $(".Modal--container  .MainLayout--container").scrollTop(0);
                        if ($(".Alert").hasClass('d-none')) {
                          $(".Alert").removeClass('d-none');
                        }
                      }}
                    >
                      {actionAr}
                    </Button>

                    {/* Cancel Button */}
                    <Button
                      className="btn oval cancel"
                      onClick={() => this.props.hideModal(this.props.action)}
                    >
                      إلغاء
                    </Button>
                  </div>
            }
          </div>
        </div>

        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    )
  }

}
export default ModalAr;