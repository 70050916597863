import React from 'react';
import './ModalPage.css';
import Button from '../Button/Button';
import Cookie from "js-cookie";
import Field from '../Field/Field';
import TextInput from '../TextInput/TextInput';
import Dropdown from '../Dropdown/Dropdown';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import Overlay from '../Overlay/Overlay';
import Loading from '../Loading/Loading';
import Alert from '../Alert/Alert';
import ShiftEndNextDay from '../ShiftEndNextDay/ShiftEndNextDay';
import Exceptions from '../Exceptions/Exceptions';
import $, { error } from "jquery";
import { Link } from "react-router-dom";
import PageHeader from '../PageHeader/PageHeader';
import { adminIp } from './../../data/APIs';
import { ip } from './../../data/APIs';

class ModalPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entity: props.action.body ? props.action.body : props.action,
      readEntity: props.read ? (props.read.body ? props.read.body : (props.read.path ? props.read.path : {})) : undefined,
      errors: [],
      message: { text: '', style: '' },
      loading: false,
      data: [],
      pageName: props.pageName ? props.pageName : '',
      tryEmail: null,
      tryPhone: null
    }

    // Get the Search Fields 'select elements' items from the Database when the page displayed for the first time
    if (props.searchFields) { // to prevent undefined error
      for (let field of props.searchFields) {
        if (field.type === 'select') {
          this.GetSelect(field.url, field.body, field.label);
        }
      }
    }

    // Get the Create & Update 'select elements' items from the Database when the page displayed for the first time
    for (let prop of props.EntityProps) {
      // if the items already exist in the Search Field Don't Get it from the Database again
      if (prop.type === 'select') {
        this.GetSelect(prop.url, prop.body, prop.label);
        // selectShift Components don't have a Label
      } else if (prop.type === 'selectShift') {
        this.GetSelect(prop.url, prop.body, prop.label);
      }
    }

  }

  componentDidMount() {
    if (this.props.read) {
      this.Read(this.state.readEntity);
    }
  }

  // A method for getting the select items from the Database,
  // inputs:
  // url = Web API Service url
  // body = Request body
  // name = the name of the state that the data will be stored in
  GetSelect(url, body, name) {

    let token = Cookie.get('token');
    fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then((response) => {
        if (response.success) {
          this.setState({ [name]: response.data.data });
        } else {
          console.error(response);
        }
      })
      .catch(error => console.error('Unable to Read.', error));
  }

  // A method for changing the entity properties,
  // inputs:
  // state = Name of the entity, e.g: createEntity, updateEntity ... 
  // name = Name of the property, e.g: createEntity.nameAr, updateEntity.nameEn ... 
  // value = value of the property, e.g: createEntity.nameAr = 'أحمد', updateEntity.nameEn = 'Ahmad'... 
  ChangeEntityState(state, name, value) {
    let tempEntity = this.state[state];
    tempEntity[name] = value;
    this.setState({ [state]: tempEntity });
  }

  // A method for Validating the user inputs before Creating or Updating,
  // inputs:
  // entity = the entity that will be sent to the Server for Creating or Updating, e.g: current createEntity.
  // output:
  // Array of errors if exist, otherwise an empty Array
  Validation(entity) {
    let errors = [];
    let i = 0;
    for (let prop of this.props.EntityProps) {
      if (prop.validation) {
        if (prop.validation.includes('Required')) {
          if (!entity[prop.backName]) {
            errors = errors.concat(prop.label + ' field is Required.');
          }
        }
        if (prop.validation.includes('Compare')) {
          if (entity[prop.backName] !== entity[this.props.EntityProps[i - 1].backName]) {
            errors = errors.concat(prop.label + ' لا يطابق ' + this.props.EntityProps[i - 1].label + '.');
          }
        }
        if (prop.validation.includes('ConditionalReq')) {
          if (entity[prop.RequiredCondition] && !entity[prop.backName]) {
            errors = errors.concat(prop.label + ' field is Required.');
          }
        }
        if (prop.validation.includes('orReq')) {
          if (!entity[prop.RequiredCondition] && !entity[prop.backName]) {
            errors = errors.concat(prop.label + ' field is Required.');
          }
        }
        if (prop.validation.includes('from')) {
          let toProp = this.props.EntityProps.find(prop => prop.validation.includes('to'));
          if ((Date.parse(entity[prop.backName]) > Date.parse(entity[toProp[4]]))) {
            errors = errors.concat(prop.label + ' field Should be less than or equal to "' + toProp[0] + '" field.');
          }
        }
        if (prop.validation.includes('to')) {
          let fromProp = this.props.EntityProps.find(prop => prop.validation.includes('from'));

          if (Date.parse(entity[prop.backName]) < Date.parse(entity[fromProp[4]])) {
            errors = errors.concat(prop.label + ' field Should be greater than or equal to "' + fromProp[0] + '" field.');
          }
        }
      }

      if (prop.type === 'email') {
        let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (entity[prop.backName] && !entity[prop.backName].match(mailformat)) {
          errors = errors.concat(prop.label + ' field should be a valid email address.');
        }
      }
      if (prop.type === 'shiftEndNextDay') {
        if (entity[prop.backName[0]]) {
          if (!entity[prop.backName[1]]) {
            errors = errors.concat(prop.label[1] + ' field is Required.');
          }
        }
      }
      if (prop.type === 'selectShift') {
        if (!entity[prop.backName[0]]) {
          errors = errors.concat('Employee field is Required.');
        }
        if (!entity[prop.backName[1]]) {
          errors = errors.concat('Date field is Required.');
        }
        if (!entity[prop.backName[2]]) {
          errors = errors.concat('Selecting a Shift is Required.');
        }
        if (entity[prop.backName[2]] === 'open') {
          entity[prop.backName[2]] = '';
        }
      }
      if (prop.type === 'permissionType') {
        if (!entity[prop.backName[0]]) {
          errors = errors.concat('Permission Type field is Required.');
        } else {
          let timeIn;
          let timeOut;
          let shiftIn;
          let shiftOut;
          let time;
          switch (entity[prop.backName[0]]) {
            case 1:
              if (!entity[prop.backName[1]]) {
                errors = errors.concat('Time In field is Required.');
              } else {
                if (entity.periodShiftId) {
                  time = entity[prop.backName[1]].split(':');
                  timeIn = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).inTime.split(':');
                  shiftIn = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).outTime.split(':');
                  shiftOut = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  if (!(timeIn >= shiftIn && timeIn <= shiftOut)) {
                    errors = errors.concat('Permission Time In Value should be between Shift Time In and Shift Time Out.');
                  }
                }
              }
              if (!entity[prop.backName[2]]) {
                errors = errors.concat('Time Out field is Required.');
              } else {
                if (entity.periodShiftId) {
                  time = entity[prop.backName[2]].split(':');
                  timeOut = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).inTime.split(':');
                  shiftIn = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  time = this.state.shiftsData.find(shift => shift.periodShiftId === entity.periodShiftId).outTime.split(':');
                  shiftOut = new Date(2000, 1, 1, Number.parseInt(time[0]), Number.parseInt(time[1]));

                  if (!(timeOut >= shiftIn && timeOut <= shiftOut)) {
                    errors = errors.concat('Permission Time Out Value should be between Shift Time In and Shift Time Out.');
                  }
                }
              }

              break;
            case 2:
              if (!entity[prop.backName[1]]) {
                errors = errors.concat('Time In field is Required.');
              }
              break;
            case 3:
              if (!entity[prop.backName[2]]) {
                errors = errors.concat('Time Out field is Required.');
              }
              break;
            default:
              break;
          }
        }
      }
      i++;
    }
    return errors;
  }

  // A method for making a fetch Request,
  // inputs:
  // entity = the entity that will be sent to the Server for Creating, Reading, Updating, or Deleting, e.g: current deleteEntity...
  // token = the JWT Barear Authorization token.
  // operation = the operation that will be done against the entity, e.g: create, read, update, or delete
  // then = the method that will be executed when we receive the response
  Fetch(entity, token, operation, then) {
    let url = ''
    let body = {
      "message": entity
    }
    if (operation == 'try') {
      if (this.props.pageName == 'emailEdites') {
        url = ip + "/api/EmailTest";
      }
      else {
        url = ip + "/api/MessageTest";
      }
    }
    else {
      url = this.props[operation].path ?
        this.props[operation].url + '/' + Object.keys(entity).map((key) => entity[key]).join('/')
        :
        this.props[operation].query ?
          this.props[operation].url + '?' + Object.keys(entity).map((key) => key + '=' + entity[key]).join('&')
          :
          this.props[operation].url;
    }


    let end;
    let start = Date.now();
    fetch(url, {
      method: operation == 'try' ? 'POST' : this.props[operation].verb,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'lang': 'en'
      },
      body: operation == 'try' ? JSON.stringify(body) : this.props[operation].body ? JSON.stringify(entity) : null
    })

      .then(response => response.json())
      .then((response) => {
        end = Date.now();
        console.log('End: ' + end);
        console.log('Time: ' + (end - start));
        let me = this;
        then(response, me);
      })
      .catch(error => console.error('Unable to ' + operation + '.', error));
    if (this.state.errors.length === 0) {
      //  window.history.back();
    }

  }

  // A method for Reading data from Database,
  // inputs:
  // entity = the entity that will be sent to the Server in the reaquest body (readEntity)
  Read(entity) {
    this.setState({ loading: true });
    let token = Cookie.get('token');
    console.log(token);
    let then = (response, me) => {
      if (response.status === 200 || response.success) {
        if (response.success) {
          if (response.data.data) {
            me.setState(
              {
                data: response.data.data[0],
                entity: response.data.data[0],
                rowsTotal: response.data.listCount,
                loading: false,
                modalUpdated: false
              }
            );
          } else {

            me.setState(
              {
                data: response.data,
                entity: response.data,
                rowsTotal: response.data.listCount,
                loading: false,
                modalUpdated: false
              }
            );
          }
        } else {
          me.setState({ message: { text: response.message, style: 'danger' }, loading: false });
          setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
        }
      } else {
        if (response.message) {
          me.setState({ message: { text: response.message, style: 'danger' }, loading: false });
          setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
        }
        console.error(response);
      }
    }
    this.Fetch(entity, token, 'read', then);

  }
  // A method for Updating an existing entity in the Database,
  // inputs:
  // entity = the entity that will be sent to the Server for Updating (current updateEntity)
  Action(entity) {
    // Trimming the 'text' user inputs '   ahmed   ' => 'ahmed'
    for (let prop of this.props.EntityProps) {
      if (prop.type === 'text') {
        entity[prop.backName] = entity[prop.backName] ? entity[prop.backName].toString().trim() : '';
      }
    }

    let errors = this.Validation(entity);

    this.setState({ errors: errors });

    // if there is any validation error don't proceed
    if (errors.length === 0) {
      this.setState({ loading: true });
      let token = Cookie.get('token');
      let then = (response, me) => {
        if (response.status === 200 || response.success) {
          if (response.success) {
            me.setState({ message: { text: response.message, style: 'success' }, loading: false });
            setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
          } else {
            me.setState({ message: { text: response.message, style: 'danger' }, loading: false });
            setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
          }
        } else {
          if (response.message) {
            errors.push(response.message);
            me.setState({ errors: errors, loading: false });
          }
          console.error(response);
        }
      }
      this.Fetch(entity, token, 'action', then);
      // window.history.back();
    }
  }
  tryService(entity) {
    // Trimming the 'text' user inputs '   ahmed   ' => 'ahmed'}

    let errors = this.Validation(entity);

    this.setState({ errors: errors });

    // if there is any validation error don't proceed
    if (errors.length === 0) {
      this.setState({ loading: true });
      let token = Cookie.get('token');
      let then = (response, me) => {
        if (response.status === 200 || response.success) {
          if (response.success) {
            me.setState({ message: { text: response.message, style: 'success' }, loading: false });
            setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
          } else {
            me.setState({ message: { text: response.message, style: 'danger' }, loading: false });
            setTimeout(function () { me.setState({ message: { text: '', style: '' } }); }, 10000);
          }
        } else {
          if (response.message) {
            errors.push(response.message);
            me.setState({ errors: errors, loading: false });
          }
          console.error(response);
        }
      }
      this.Fetch(entity, token, 'try', then);
      // window.history.back();
    }
  }

  AddToTextValue(backName, value) {

    let newValue = this.state.entity[backName] ? this.state.entity[backName] : '';
    newValue = newValue + value
    this.ChangeEntityState('entity', backName, newValue)
  }
  trySMS(tryPhone) {
    let token = Cookie.get('token');

    fetch('http://www.jawalsms.net/httpSmsProvider.aspx?username=' + this.state.data.userName + '&password=' + this.state.data.password +
      '&mobile=' + tryPhone + '&unicode=' + "E" + '&message=' + "ًWelcom Apex" + '&sender=' + this.state.data.senderName, { mode: 'no-cors' }, {
      method: 'GET',
    })
      .then(response => {
      })
      .catch(error => console.error('Unable to Check.', error));
  }
  render() {
    let Path = this.props.path.map((folder, i) => i === this.props.path.length - 1 ?
      <Link className="" to={folder.path} key={folder.title}>{folder.title}</Link>
      : <Link className="TreeNav--" to={folder.path} key={folder.title}>{folder.title}</Link>);

    let EntityProperties = this.props.EntityProps.map(
      (prop, i) => {
        let backNames;
        let values;
        let items;
        switch (prop.type) {
          case 'text':
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                dropdownAlign={prop.dropdownAlign}
                // ===========
                title={[prop.label, prop.validation.includes('Required') ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={prop.label}
                    disabled={prop.disabled}
                    type="text"
                    value={this.state.entity[prop.backName]}
                    onChange={(value) => this.ChangeEntityState('entity', prop.backName, value)}
                  />
                ]}
              />
            );

          case 'info':
            return (
              <div className='col-md-6 d-flex'>
                <h5>
                  {prop.label}
                </h5>
                <p> : </p>
                <h6 style={{ fontSize: '1.2rem' }}> {this.state.entity[prop.backName]}</h6>
              </div>
            );
          case 'textarea':
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                dropdownAlign={prop.dropdownAlign}
                noFormCont={prop.noFormCont}
                // ===========
                title={[prop.label, prop.validation.includes('Required') ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <div>
                    <Dropdown
                      rtl={true}
                      key={prop.label + 1}
                      value={null}
                      addStyle={'textDropmenu w-25'}
                      options={prop.items}
                      placeholder={'-- Select --'}
                      onChange={(value) => this.AddToTextValue(prop.backName, value)}
                    />
                    <TextInput
                      key={prop.label}
                      placeholder={prop.label}
                      rows={prop.rows}
                      cols={prop.col}
                      className={prop.class}
                      type="textarea"
                      value={this.state.entity[prop.backName] ? this.state.entity[prop.backName] : ''}
                      onChange={(value) => this.ChangeEntityState('entity', prop.backName, value)}
                    />
                  </div>

                ]}
              />
            );
          case 'number':
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                dropdownAlign={prop.dropdownAlign}
                // ===========
                title={[prop.label, prop.validation.includes('Required') ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <TextInput

                    target={'target' + i}
                    key={prop.label}
                    placeholder={prop.label}
                    type="number"
                    value={this.state.entity[prop.backName]}
                    onChange={(value) => this.ChangeEntityState('entity', prop.backName, value)}
                  />
                ]}
              />
            );
          case 'email':
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                dropdownAlign={prop.dropdownAlign}
                // ===========
                title={[prop.label, prop.validation.includes('Required') ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={prop.label}
                    type="email"
                    value={this.state.entity[prop.backName]}
                    onChange={(value) => this.ChangeEntityState('entity', prop.backName, value)}
                  />
                ]}
              />
            );
          case 'date':
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                dropdownAlign={prop.dropdownAlign}
                // ===========
                title={[prop.label, prop.validation.includes('Required') ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <TextInput
                    arDate={true}
                    key={prop.label}
                    placeholder={prop.label}
                    type="date"
                    value={this.state.entity[prop.backName]}
                    onChange={(value) => this.ChangeEntityState('entity', prop.backName, value)}
                  />
                ]}
              />
            );
          case 'time':
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                dropdownAlign={prop.dropdownAlign}
                // ===========
                title={[prop.label, prop.validation.includes('Required') ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={prop.label}
                    type="time"
                    value={this.state.entity[prop.backName]?.substring(0, 5)}
                    onChange={(value) => this.ChangeEntityState('entity', prop.backName, value + ":00")}
                  />
                ]}
              />
            );
          case 'password':
            return (
              <Field
                // ===========
                style={prop.style}
                dropdownAlign={prop.dropdownAlign}
                titleSize={prop.titleSize}
                // ===========
                title={[prop.label, prop.validation.includes('Required') ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={prop.label}
                    type="password"
                    value={this.state.entity[prop.backName]}
                    onChange={(value) => this.ChangeEntityState('entity', prop.backName, value)}
                  />
                ]}
              />
            );
          case 'file':
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                dropdownAlign={prop.dropdownAlign}
                // ===========
                title={[prop.label, prop.validation.includes('Required') ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={prop.label}
                    type="file"
                    value={this.state.entity[prop.backName]}
                    onChange={(value) => this.ChangeEntityState('entity', prop.backName, value)}
                  />
                ]}
              />
            );
          case 'select':
            if (prop.label === 'Management') {
              items = this.state[prop.label] === undefined ?
                []
                :
                this.state[prop.label].filter(management => management.branchId === this.state.entity[this.props.EntityProps.find(prop => prop.label === 'Branch').backName]).map((item) => { return { label: item[prop.item.label], value: item[prop.item.value] } });
            } else if (prop.label === 'Department') {
              items = this.state[prop.label] === undefined ?
                []
                :
                this.state[prop.label].filter(department => department.administrationId === this.state.entity[this.props.EntityProps.find(prop => prop.label === 'Management').backName]).map((item) => { return { label: item[prop.item.label], value: item[prop.item.value] } });
            } else if (prop.label === 'Employee') {
              items = this.state[prop.label] === undefined ?
                []
                :
                this.state[prop.label].map((item) => { return { label: item.employeeCode + ': ' + item[prop.item.label], value: item[prop.item.value] } });
            } else {
              items = this.state[prop.label] === undefined ?
                []
                :
                this.state[prop.label].map((item) => { return { label: item[prop.item.label], value: item[prop.item.value] } });
            }

            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                dropdownAlign={prop.dropdownAlign}
                // ===========
                title={[prop.label, prop.validation.includes('Required') ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <Dropdown

                    rtl={true}
                    key={prop.label}
                    placeholder={`Choose or type`}
                    value={items.find(item => item.value === this.state.entity[prop.backName])}
                    options={items}
                    onChange={(value) => this.ChangeEntityState('entity', prop.backName, value)}
                  />
                ]}
              />
            );
          case 'staticSelect':
            return (
              <Field
                key={prop.label}
                style={prop.style}
                titleSize={prop.titleSize}
                dropdownAlign={prop.dropdownAlign}
                title={[prop.label, prop.validation.includes('Required') ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <Dropdown

                    target={'drop' + i}
                    rtl={true}
                    key={prop.label}
                    placeholder={prop.label}
                    value={prop.items.find(item => item.value === this.state.entity[prop.backName])}
                    options={prop.items}
                    onChange={(value) => this.ChangeEntityState('entity', prop.backName, value)}
                  />
                ]}
              />
            )
          case 'checkbox':
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                dropdownAlign={prop.dropdownAlign}
                noFormCont={prop.noFormCont}
                // ===========
                title={[prop.label, prop.validation.includes('Required') ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <ToggleSwitch
                    value={this.state.entity[prop.backName]}
                    backName={prop.backName}
                    onChange={(name, value) => this.ChangeEntityState('entity', name, value)}
                    onText={'نعم'}
                    offText={'لا'}
                    height={30}
                    width={70}
                    onHandleColor="#70c767"
                    onColor="#e1fcde"
                    // uncheckedIconWidth="80px"
                    // uncheckedIconMarginLeft="-38px"
                    checkedIconMarginLeft="10px"
                  />
                ]}
              />
            );
          case 'shiftEndNextDay':
            backNames = [];
            for (const backName of prop.backName) {
              backNames.push(backName);
            }
            values = [];
            for (const backName of backNames) {
              values.push(this.state.entity[backName])
            }
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                dropdownAlign={prop.dropdownAlign}
                noFormCont={prop.noFormCont}
                // ===========
                title={''}
                inputComponent={[
                  <ShiftEndNextDay

                    target={'shift' + i}
                    tableborder={'border-left'}
                    dropdownAlign={prop.dropdownAlign}
                    ar={true}
                    type={prop.inputType}
                    labels={prop.label}
                    values={values}
                    backNames={backNames}
                    onChange={(name, value) => this.ChangeEntityState('entity', name, value)}
                  />
                ]}
              />
            );
          case 'exceptions':
            backNames = [];
            for (const backName of prop.backName) {
              backNames.push(backName);
            }
            values = [];
            for (const backName of backNames) {
              values.push(this.state.entity[backName])
            }
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                // ===========
                title={prop.label}
                inputComponent={[
                  <Exceptions
                    values={values}
                    backNames={backNames}
                    onChange={(name, value) => this.ChangeEntityState('entity', name, value)}
                  />
                ]}
              />
            );
          default:
            return (
              <Field
                // ===========
                style={prop.style}
                titleSize={prop.titleSize}
                dropdownAlign={prop.dropdownAlign}
                // ===========
                title={[prop.label, prop.validation.includes('Required') ? <span style={{ color: "red" }}>*</span> : null, ":"]}
                inputComponent={[
                  <TextInput
                    key={prop.label}
                    placeholder={prop.label}
                    type="text"
                    value={this.state.entity[prop.backName]}
                    onChange={(value) => this.ChangeEntityState('entity', prop.backName, value)}
                  />
                ]}
              />
            );
        }

      }
    );

    return (
      <section className="CompanyInfo--dialog">
        <PageHeader nobtn={true} Path={Path}>

        </PageHeader>
        <Overlay visible={this.state.loading}>
          <Loading visible={this.state.loading} />
        </Overlay>
        <div className="StartLayout--container">
          <h4 className="myalert blue-bg w-color py-1 rounded">{this.props.title}</h4>
          <div className="GeneralSettings--form row justify-content-between" style={{ textAlign: 'left' }}>
            {
              this.state.errors ?
                this.state.errors.length > 0 ?
                  (
                    <Alert className="Alert danger">
                      <ul>
                        {this.state.errors.map((error) => <li key={error}>{error}</li>)}
                      </ul>
                    </Alert>
                  )
                  :
                  null
                :
                null
            }
            {
              this.state.message ?
                this.state.message['text'] ?
                  <Alert
                    className={"Alert " + this.state.message['style']}
                  >
                    {this.state.message['text']}
                  </Alert>
                  :
                  null
                :
                null
            }

            {this.state.entity.trial ?
              <div className="container text-center">
                <h4 >Now you are in free trial  </h4>
                <p>Do you want more ??</p>
              </div>
              :

              EntityProperties
            }

            <div className="row justify-content-center w-100">
              <div className={` mr-3 mt-3 row justify-content-center ${this.state.pageName == 'planEdites' ?
                (this.state.entity.trial ? "col-sm-3" : "col-sm-8") : "col-sm-3"}`}>
                {this.state.pageName == 'planEdites' ?
                  this.state.entity.trial ?
                    <div className="col-sm-3">
                      <a className="btn add square gradient"
                        href={adminIp + "/renew?companyName=" + this.state.data.uniqueName + "&status=3"} >  Subscription  </a>
                    </div>

                    :
                    <div className="row justify-content-between">
                      <div className="col-sm-6">
                        <a className="btn add square gradient"
                          href={adminIp + "/renew?companyName=" + this.state.data.uniqueName + "&status=1"} >  Renew plan </a>

                      </div>
                      <div className="col-sm-6">
                        <a className="btn add square gradient"
                          href={adminIp + "/renew?companyName=" + this.state.data.uniqueName + "&status=2"} > Upgrade plan  </a>
                      </div>
                    </div>

                  :
                  <Button
                    id="submitButton"
                    className="btn add square gradient "
                    onClick={() => {
                      this.Action(this.state.entity)
                      var scrollTop = $('.myalert')?.offset().top;
                      $(window).scrollTop(scrollTop);
                      if ($(".Alert").hasClass('d-none')) {
                        $(".Alert").removeClass('d-none');
                      }
                      setTimeout(() => {
                        if (this.state.errors.length <= 0)
                          window.history.back()
                      }, 2000)
                    }}
                  >
                    Save
                    </Button>
                }

              </div>
              <div className={`mr-3 mt-3 row justify-content-center  ${this.state.pageName == 'planEdites' ? "col-sm-4" : "col-sm-3"}`}>
                {this.props.routUnregistered ?
                  <Link to="/Ar/TAW/Main/UnregisteredEmployees"
                    className="btn oval cancel"

                  >
                    cansel 
                       </Link> :
                  <Link to={this.props.path.length > 1 ? this.props.path[this.props.path.length - 2].path : this.props.path[0].path}
                    className="btn oval cancel"

                  >
                    cansel 
                           </Link>

                }

              </div>


            </div>
            {
              this.props.pageName == 'smsEdites' ?
                <div className='mt-5 row container p-0 mx-0 align-items-end'>
                  <div className="col-12">
                    <h5> Try mobile SMS </h5>
                  </div>
                  <Field
                    // ===========
                    style={'col-lg-6'}

                    title={' Phone number '}
                    // ===========
                    inputComponent={[
                      <TextInput
                        key={'prop.label'}
                        placeholder={' EX. 966555123456  '}
                        type="text"
                        value={this.state.tryPhone}
                        onChange={(value) => { }}
                      />
                    ]}
                  />
                  <div className='col-lg-6'>
                    <Button
                      id="submitButton"
                      className="btn add square gradient "
                      onClick={() => {
                        this.trySMS(this.state.tryPhone)
                        var scrollTop = $('.myalert')?.offset().top;
                        $(window).scrollTop(scrollTop);
                        if ($(".Alert").hasClass('d-none')) {
                          $(".Alert").removeClass('d-none');
                        }
                      }}
                    >
                      Send
                    </Button>
                  </div>
                </div>

                :
                this.props.pageName == 'emailEdites' ? <div className='mt-5 row container p-0 mx-0 align-items-end'>
                  <div className="col-12">
                    <h5 className='text-left'> Try emails  </h5>
                  </div>
                  <Field
                    // ===========
                    style={'col-lg-6'}

                    title={'Email'}
                    // ===========
                    inputComponent={[
                      <TextInput
                        key={'prop.label'}
                        placeholder={'Email'}
                        type="email"
                        value={this.state.tryEmail}
                        onChange={(value) => {
                          this.setState({ tryEmail: value })
                        }}
                      />
                    ]}
                  />
                  <div className='col-lg-6'>
                    <Button
                      id="submitButton"
                      className="btn add square gradient "
                      onClick={() => {
                        this.tryService(this.state.tryEmail)
                        var scrollTop = $('.myalert')?.offset().top;
                        $(window).scrollTop(scrollTop);
                        if ($(".Alert").hasClass('d-none')) {
                          $(".Alert").removeClass('d-none');
                        }
                      }}
                    >
                      Send
                    </Button>
                  </div>
                </div> : null}


          </div>
        </div>
      </section>
    )
  }
}

export default ModalPage;